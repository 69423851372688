import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/les')
export default class Les extends BaseService{
    search(data) {
		return this.request({
            data: data,
			url: "/search",
			method: "POST"
		});
	}
    detail(data) {
		return this.request({
            data: data,
			url: "/detail",
			method: "POST"
		});
	}	
}