import { Service, BaseService } from "@/core/auto";

@Service('/api/assentmng/')
export default class assentmng extends BaseService{
    update(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/update",
			method: "POST"
		})
	}

	edit() {
		return this.request({
			url: "/edit",
			method: "GET"
		})
	}
}