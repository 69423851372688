<template>
  <el-dialog v-model="show" :title="$t('usrHelpPages.adminHelp')" width="80%">
    <div class="rsk-p-m">
        <div class="rsk-cal-content rsk-mb-m">
            <div class="rsk-cal-content__item" v-if="infoFlg == 1 && isJcc">
                <div class="rsk-font-xs rsk-font-bold font-black">{{$t("usrHelpPages.adminHelpTelConsultation")}}</div>
                <div class="rsk-cal-content">
                    <div class="rsk-cal-content__item rsk-cal-content__item--none">
                    <div class="rsk-font-xs font-black">フリーコール</div>
                    <div class="rsk-font-xxl rsk-font-bold tel">0120-035-205</div>
                    </div>
                    <div class="rsk-cal-content__item  rsk-cal-content__item--none">
                    <div class="rsk-font-xs font-black">携帯電話</div>
                    <div class="rsk-font-xxl rsk-font-bold tel">03-5202-7121<span class="rsk-font-xs">（{{$t("usrHelpPages.adminHelpFee")}}）</span></div>
                    </div>
                </div>
                <div class="rsk-font-xs font-black">営業日：{{$t("usrHelpPages.adminHelpTelConsultationTip")}}</div>
            </div>
            <div class="rsk-cal-content__item" v-if="infoFlg == 1 && isJcc">
                <div class="rsk-mb-xs rsk-font-xs rsk-font-bold font-black">メールでのお問い合わせ</div>
                <button class="rsk-form-button-wrapper__button rsk-button--outline-admin-primary faq-btn" @click="toUsrquire">
                    <span class="rsk-sub-nav__item--white rsk-button">{{$t("usrHelpPages.adminHelpenquiry")}}</span>
                </button>
            </div>
            <div class="rsk-cal-content__item rsk-cal-content__item--none">
            <div class="rsk-mb-xs rsk-font-xs rsk-font-bold font-black">{{$t("usrHelpPages.adminHelpQuestion")}}</div>
            <div class="rsk-form-button-wrapper__button">
                <a href="https://cybaxuniv-support2user.zendesk.com/hc/ja" target="_blank" class="rsk-sub-nav__item--white rsk-button rsk-button--medium rsk-button--expand rsk-button--admin-primary">
                <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                    <span class="rsk-icon rsk-icon--all-white"></span>
                </span>FAQ
                </a>
            </div>
            </div>
        </div>
        <div class="rsk-font-bold rsk-mb-xs font-black">{{$t("usrHelpPages.adminHelpServiceManual")}}</div>
        <rsk-table
            :titles="titles"
            :total="null"
            :data="tableData"
        />

        <div class="rsk-fixed-bottom-right--popup" v-if="msgBtnShow">
          <div>
            <button @click="openWidget" class="rsk-button rsk-button--admin-primary rsk-button--round rsk-button--round-large">
                <span class="rsk-button__icon-wrapper">
                    <span class="rsk-icon rsk-icon--question-answer-white"></span>
                </span>
            </button>
          </div>
        </div>

    </div>
  </el-dialog>
</template>

<script>
import { ref, inject, onMounted, watch,onBeforeUnmount } from 'vue';
import { UAParser } from "../popenvironment/js/ua-parser.min";
import { useRoute, useRouter } from 'vue-router'
export default {
    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { emit }) {
        const service = inject("service")
        const isJcc = localStorage.getItem('isDispatchAgencyFlag') == 'false' ? ref(true) : ref(false)
        function openWidget(){
          zE('webWidget', 'show');zE('webWidget', 'open');
        }

        const titles = [
            {
                field: "manualNm",
                title: "タイトル",
            },
            {
                field: "appendFileName",
                title: "マニュアルファイル",
            },
        ]
        const tableData = ref([])
        const count = ref(0)
        const show = ref(false)
        const infoFlg = ref(0)
        const router = useRouter()
        const index = () => {
            var manualdivDto = '2';
            if (!isJcc.value) {
                manualdivDto = '6';
            }
            service.popmul.index({ manualdiv: manualdivDto}).then(res => {
                infoFlg.value = res.infoFlg
                if(res.models) {
                    count.value = res.models.length + 1
                    tableData.value.push({
                        manualNm: 'ご利用環境について',
                        appendFileName: {
                            text: 'https://www.cybaxuniv.com/popenvironment',
                            callBack: function(e) {
                                let route = router.resolve({ name: 'Popenvironment'})
		                        window.open(route.href, 'Popenvironment')
                            },
                        }
                    })
                    res.models.forEach(item => {
                        tableData.value.push({
                            manualNm: item.manualNm,
                            appendFileName: {
                                text: item.appendFileName,
                                callBack: function(e) {
                                    downloadFile(item.appendFileName, item.reportFileDir);
                                },
                            }
                        })
                    });
                }
            })
        }

        function downloadFile(filename, fileurl){
            const ua = new UAParser();
            let userAgent = ua.getUA();
            const downloadData = {filename:'',fileurl:'',userAgent:''}
            downloadData.filename = filename;
            downloadData.fileurl = fileurl;
            downloadData.userAgent = userAgent;
            service.common.downloadFile(downloadData)
        }

        function downloadSystemFile(filename, fileurl){
            var ua = new UAParser();
            let userAgent = ua.getUA();
            const downloadData = {filename:'', fileurl:'', userAgent:''}
            downloadData.filename = filename;
            downloadData.fileurl = fileurl;
            downloadData.userAgent = userAgent;
            service.common.downloadSystemFile(downloadData)
        }

        const msgBtnShow = ref(false)
  
        onMounted(() => {
        if(localStorage.getItem('isDispatchAgencyFlag') == 'false' && localStorage.getItem('InfoFlg') == 1){
            msgBtnShow.value = true;
        }
        });
        function closeMsg(){
        if(document.querySelector('#launcher') != null && document.querySelector('#launcher').style.visibility != 'hidden'){
            document.querySelector('#launcher').style.visibility = 'hidden'
        }          
        }
        var interval = setInterval(closeMsg, 200);
        onBeforeUnmount(()=>{
            clearInterval(interval)
        })

        function toUsrquire() {
            window.open('/usrinquire_new', "_blank");
        }

        watch(props, () => {
            show.value = props.modelValue
        })
        watch(show, () => {
            emit('update:modelValue', show.value);
        })

        onMounted(() => {
            index()
        })

        return {
            msgBtnShow,
            infoFlg,
            openWidget,
            show,
            titles,
            count,
            tableData,
            toUsrquire,
            isJcc,
            downloadSystemFile
        }
    }
}
</script>

<style >
.rsk-cal-content {
  display: flex;
  align-items:center;
  justify-content: center;
  padding: 4px;
  height: 140px;
  background-color: #F2F5F7;
}
.rsk-cal-content__item {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right-style: solid;
  border-color: rgba(48,53,59,0.16);
  height: 80%;
  border-width: 2px;
  padding: 10px;
}

.rsk-cal-content__item--none {
  border: none;
}

.el-dialog__title{
    color: #30353B;
    font-weight: bold;
    font-size: 22px;
}
.tel{
    font-size: 24px;
    color: black;
}
.faq-btn{
    height: 40px !important;
}
.font-black{
    color: black;
}
.el-dialog__headerbtn:hover {
  background-color: #D0E5EF;
  border-radius: 100%;
  border-color: black;
}
.el-dialog__headerbtn i {
  background-repeat: no-repeat;
  height: 40px;
  width: 40px;
  background-position: center;
}
.el-dialog__headerbtn i svg {
  height: 24px;
  width: 24px;
}
.el-dialog__headerbtn:hover .el-dialog__close {
    color: #909399 !important;
}
</style>
