<template>
    <div class="rsk-fixed-bottom-right">
        <div v-if="type.includes('backtop')" class="rsk-mb-xs">
            <button class="rsk-button rsk-button--white-admin rsk-button--round rsk-button--round-large">
                <span class="rsk-button__icon-wrapper">
                <span class="rsk-icon rsk-icon--arrow-upward-admin-primary"></span>
                </span>
            </button>
        </div>
        <div v-else-if="type.includes('feedback')">
            <button class="rsk-button rsk-button--user-primary rsk-button--round rsk-button--round-large">
                <span class="rsk-button__icon-wrapper">
                <span class="rsk-icon rsk-icon--question-answer-white"></span>
                </span>
            </button>
        </div>
  </div>
</template>
<script>
import { ref } from 'vue';
export default {
    props: {
        title: '',
        /**
         * backtop | feedback
         */
        type:[],
        options:null
    },
    setup(props) {
        let selectValue = ref('');
        return {
            selectValue,
            ...props
        }
    },
}
</script>
<style>
.rsk-select .el-input__inner {
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #A8B0BB;
    color: #30353B;
}
</style>