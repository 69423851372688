<template>
    <div :class="'rsk-form'+(isExpand?'--expand':'')">
        <input :type="type" :placeholder="placeholder" :class="isExpand?'rsk-input rsk-input--date start1-date rsk-input--expand':'rsk-input rsk-input--date start1-date'" 
               :maxlength="max == null ? -1 : max" v-model="modelValue" @input="input" max="9999-12-31"/>
    </div>
</template>
<script>
import $ from 'jquery'
import 'jquery-ui/ui/widgets/datepicker'
import 'jquery-ui/ui/i18n/datepicker-ja'
import { on } from 'element-plus/lib/utils'
import { nextTick, onMounted } from 'vue'

export default {
    props: {
        type: {
            type: String,
            default: 'date'
        },
        modelValue: {
            type: String,
            default: ""
        },
        placeholder:{
            type: String,
            default: ""
        },
        isExpand:{
            type: Boolean,
            default: false
        },
        max:{
            type: String,
            default: '10'
        }
    },
    setup(props, { emit }) {
        function input(  ) {
            emit("update:modelValue", props.modelValue);
            emit("input", props.modelValue);
        }
        onMounted(() => {
            $.datepicker.setDefaults($.datepicker.regional["ja-JP"]);
            $('.start1-date').datepicker({
                dateFormat: 'yy-mm-dd',
	            changeMonth: true,
	            changeYear: true,
                onSelect: function(dateText){     
                    emit("update:modelValue", dateText);        
                }
            })
        })
        return {
            ...props,
            input,
        }
    },
}
</script>

<style scoped>
.rsk-input--date[type="date"]::-webkit-calendar-picker-indicator{display:none;}
.rsk-input--date{
    background-image: unset;
}
</style>