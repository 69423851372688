<template>
    <div :class="'rsk-tabs rsk-tabs--'+ type +' rsk-mb-l'">
        <template v-for="(item, index) in list" :key="index">
            <a href="#" v-show="item.show == null || item.show == true" :class="(index == select? 'rsk-tabs__item--active rsk-tabs__item': 'rsk-tabs__item') + (size =='large'?' rsk-width-180':'')"
            @click="change(item, index)"
             >
            {{ getItem(item) }}
        </a>
        </template>
        
    </div>
</template>
<script>
import { computed, ref } from 'vue';
export default {
    props: {
        size: {
            type: String,
            default: 'small'
        },
        type:{
            type: String,
            default: 'user'
        },
        modelValue: {
            type: Number,
            default: 0
        },
        list:{
            type: Array,
            default: []
        },
    },
    setup(props, { emit }) {
        function getItem (item){
            if(item) {
                if(typeof(item) == 'string') return item;
                return item.label 
            }
            return ''
            
        }
        var select = computed(() => {
            return props.modelValue
        })
        console.log('select', select);
        function change(item,  index ) {
            select = index
            let value = index
            if(typeof(item) == 'object') {
                value == item.value
            }
            emit("update:modelValue", value);
            emit('onChange',value)
            
        }
        return {
            getItem,
            change,
            select,
            ...props
        }
    },
}
</script>