<template>
  <div class="printpdf" id="printpdf" style="margin-top: 150px; margin: auto;">
		<img src="../../assets/completion.jpg" encode="false" />" alt="">
		<div class="pdfcontent">
			<div class="usrNm">
				<tr>
					<span class="rsk-pdf_font">{{ model.usrNm }}</span>
				</tr>
			</div>
			<div class="elearningName">
				<tr>
					<span class="rsk-pdf_font">{{ model.elearningName }}</span>
				</tr>
			</div>
			<div class="elearningName_date">
				<tr>
					<span class="rsk-pdf_font">{{ model.studyEndDtm }}</span>
				</tr>
			</div>
		</div>
	</div>
	<div id="notprint" class="notprint" style="width: 879px; margin: auto;">
    <div style="text-align:center; margin-top: 20px; font-size: 24px; margin-top: 15px;">
      <rsk-form-submit style="border: none">
          <div class="rsk-form-button-wrapper__button">
            <button class="rsk-button rsk-button--medium rsk-button--expand rsk-button--primary" @click="print()">
              印刷
            </button>
          </div>
      </rsk-form-submit>
    </div>  
	</div>
</template>

<script>
import { ref, inject, onMounted, reactive} from 'vue';
import { useRouter,useRoute } from 'vue-router'
import { dateFtt } from "@/utils/common";
export default {
    setup() {
        const router = useRouter()
        const service = inject("service")
        const route = useRoute();
        const model = reactive({});
        function index(ids) {
            var formdata = {
              ids: ids
            }
            service.elgstudy.getCompletionPdf(formdata).then((res) => {
              model.usrNm = res.model.usrNm;
              model.elearningName = res.model.elearningName;
              model.studyEndDtm = res.model.studyEndDtm;
              model.studyEndDtm = dateFtt("auto_date", model.studyEndDtm, 'ja')
            })
        }
        function print() {
          window.print();
        }
        onMounted(() => {
          var ids = "";
          if(typeof(route.query.id) != "undefined") {
            ids = route.query.id
          }
          index(ids)
        })

        return {
            index,
            model,
            print
        }
    }
}
</script>

<style scoped>
.printpdf{
  overflow:hidden;
  width: 600px;
  height: 800px;
}

.printpdf img{
  position:absolute;
  z-index:0;
  width: 600px;
  height: 800px;
}

.pdfcontent{
    position:relative;
    z-index:1;
}

@media print {
  @page { margin: 0; }

  .notprint {display: none;}
  
  .printpdf{
    display: block;
  }
}

.rsk-pdf_font {
  color: #2B3236;
  font-size: 20px; 
  font-family: sans-serif;
}
.usrNm {
  margin-top: 210px; 
  margin-left: 70px; 
  max-width: 450px; 
  max-height: 90px;
  min-height: 90px;
}
.elearningName {
  margin-top: 160px; 
  margin-left: 70px; 
  max-width: 460px; 
  min-height: 180px;
  max-height: 180px;
}
.elearningName_date {
  margin-top: 4px; 
  margin-left: 360px;
}
</style>
