<template>
    <div class="body">
        <header id="g-header" class="cf">
            <div class="logo inner">
                <a><img src="./png/ope_elgant_logo.png" encode="false" width="259" height="50" alt="リスクモンスター株式会社"></a>
            </div>
        </header>
        <div id="page">

            <div class="contents">
                <slot></slot>
            </div>

            <div class="footer">Copyright &copy; 2012-{{ year }} Riskmonster.com All Rights Reserved.
            </div>

        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
    setup() {
        const year = ref('')

        onMounted(() => {
            var date = new Date();
            year.value = date.getFullYear();
            console.log("year", year);
        })

        // (function (i, s, o, g, r, a, m) {
        // i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function () {
        //     (i[r].q = i[r].q || []).push(arguments)
        // }, i[r].l = 1 * new Date(); a = s.createElement(o),
        //     m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m)
        // });

        // ga('create', 'UA-44897026-1', 'cybaxuniv.com');
        // ga('send', 'pageview');

        return {
            year
        }
    }
}

</script>

<style lang="scss" src="./css/ope_elgant.scss" scoped/>