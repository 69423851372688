<template>
    <OpeElgant>
        <div class="main-one-col">
            <div id="contents-wrap" class="inner-form">
                <section>
                <h1 class="ttl-head01 center">コンテンツ満足度アンケート</h1>
                <div class="sub_ttl"><span>アンケート完了</span></div>
                    アンケートにご協力いただきありがとうございました。<br>
                    受講者様からのご意見を元にして、システムの利便性向上・コンテンツの充実化に努めてまいりますので、今後ともよろしくお願いいたします。
                </section>
            </div>
        </div>
        <div class="formSubmit txtC" v-if="showFlag">
            <button @click="close()" class="btn-base inline-b">閉じる</button>
        </div>

    </OpeElgant>

</template>

<script>
import OpeElgant from "./ope_elgant.vue"
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

    export default {
    components: {
        OpeElgant
    },
    setup() {
        const router = useRouter()
        const route = useRoute();
        let showFlag = ref(false)
        onMounted(() => {
            const windowOpen = route.params.windowOpen;
            if(windowOpen === '2'){
                showFlag.value = true
            }
        })

        function close(){
            window.close();
        }
        return {
            OpeElgant,
            showFlag,
            close
        }
    }
}

</script>
<style lang="scss" src="./css/ope_elgant.scss" scoped/>
