<template>
  <nav class="rsk-global-nav rsk-global-nav--user">
    
    <a v-for="(item,index) in titles" :key="index" @click="toUrl(item.url); select = item.url; zSelect = '/trastudy'" :class="'rsk-global-nav__item cursor-pointer '+ (item.url == select?'rsk-global-nav__item--active':'')">{{item.title}}</a>

  </nav>
  <nav v-if="select == '/elgstudy'" class="rsk-sub-nav rsk-sub-nav--user rsk-sub-nav--white">
    <a v-for="(item, index) in zTitle" :key="index" @click="toUrl(item.url); zSelect = item.url;" :class="'rsk-sub-nav__item ' + (zSelect == item.url?'rsk-sub-nav__item--active':'') " href="#">
      <span class="rsk-sub-nav__item-label">{{item.title}}</span>
    </a>
  </nav>
</template>

<script>
import { reactive, toRefs, ref, computed, watch, watchEffect, } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const useNavbarEffect = () => {

  const store = useStore()
  const route = useRoute()

  const useFlag = ref(false)
  const displayFlg1 = ref(false)
  const displayFlg2 = ref(false)
  const displayFlg5 = ref(false)
  const agencyId  = ref(false)

  const useRadio = ref('page')
  const useTab = ref('')

  displayFlg1.value = localStorage.getItem('displayFlg1') == "true" ? true : false
  displayFlg2.value = localStorage.getItem('displayFlg2') == "true" ? true : false
  displayFlg5.value = localStorage.getItem('displayFlg5') == "true" ? true : false
  agencyId.value = store?.getters?.getAgencyId

  const isCurrent = (paths) => {
    const current = route.name

    for (const path of paths) {
      if (path === current) {
        return true
      }
      return false
    }
  }

  watch(useRadio, (currValue, prevValue) => {
    switch (currValue) {
      case 'page':
        console.log(currValue, prevValue)
        break
        case 'elearn':
        // this.$router.push({
        //   path: '/common/405'
        // })
        console.log(currValue, prevValue)
        break
      case 'training':
        console.log(currValue, prevValue)
        break
      case 'support':
        console.log(currValue, prevValue)
        break
      case 'deliver':
        console.log(currValue, prevValue)
        break
    }
  }, { immediate: true })

  return { useRadio, useTab,  displayFlg1, displayFlg2, displayFlg5, agencyId, isCurrent }
}

export default {
  name: "Navbar",
  setup () {
    const { useRadio, useTab,  displayFlg1, displayFlg2, displayFlg5, agencyId , isCurrent } = useNavbarEffect()
    const route = useRoute()
    const useFlag = localStorage.getItem('useFlag') == "true" ? true : false
    const { locale, t } = useI18n({ useScope: 'global' })
    locale.value = localStorage.getItem('lang')
    let titles = [
      {
        url:'/usr_top',
        title:t('common.adminApplicationMyPage')
      },
    ]
    if (!useFlag) {
      if (displayFlg1.value == true) {
         titles.push({
          url:'/elearning',
          title:t('common.adminApplicationElearning')
         }) 
      }
      if (displayFlg2.value == true) {
        titles.push({
          url:'/elgstudy',
          title:t('common.adminApplicationElearningManage')
        }) 
      }
      if (displayFlg5.value == true) {
        titles.push({
          url:'/usrinf/usr_inf',
          title:t('common.adminApplicationInformation')
        }) 
      }

    } else {
      if (displayFlg5.value == true) {
         titles.push({
          url:'/usrinf/usr_inf',
          title:t('common.adminApplicationInformation')
         }) 
      }
    }
    
    const zTitle = [
      { 'url':'/elgstudy', title:t("usrTrainingPages.adminTrainingManagement")},
      { 'url':'/les', title:t("usrTrainingPages.adminTrainingBox") },
      { 'url':'/listen/tes', title:t("usrTrainingPages.adminTrainingTask") },
    ]

    const traTitle = [
      { 'url':'/trastudy', title:'研修管理' },
      { 'url':'/traselfstudy', title:'研修管理' },
      { 'url':'/trastudyShow', title:'研修管理' },
      { 'url':'/traselfstudyShow', title:'研修管理' },
    ]
    
    const router = useRouter()
    
    function toUrl(url,index){      
      if(url.indexOf(router.currentRoute.value.fullPath)!=-1){
        router.go(0);
      }else{
        router.push({ path: url});
      }      
    }
    const select = ref('/usr_top')
    const zSelect = ref('/elgstudy')
    let fullPath = router.currentRoute.value.fullPath
    for(let i = 0;i<titles.length;i++){
      if(titles[i].url.indexOf(fullPath)!=-1){
        select.value = titles[i].url
        break
      }
    }
    for(let i = 0;i<zTitle.length;i++){
      if(zTitle[i].url.indexOf(fullPath)!=-1){
        zSelect.value = zTitle[i].url
        select.value = '/elgstudy'
        break
      }
    }
    for(let i = 0;i<traTitle.length;i++){
      if(traTitle[i].url.indexOf(fullPath)!=-1){
        zSelect.value = '/elgstudy'
        select.value = '/elgstudy'
        break
      }
    }
    if(fullPath.indexOf('tes') != -1 || fullPath.indexOf('ant') != -1 || fullPath.indexOf('rep') != -1) {
      select.value = '/elgstudy'
      zSelect.value = '/listen/tes';
    }
    
    watch(()=>route.path,(to)=> {
       if(to.indexOf('study') != -1){
          zSelect.value = '/elgstudy';
        if(to.indexOf('elgstudy') != -1) {
          select.value = '/elgstudy';
        }
       } else if(to.indexOf('les') != -1){
          select.value = '/elgstudy';
          zSelect.value = '/les';
       }  else if(to.indexOf('listen') != -1){
          select.value = '/elgstudy';
          zSelect.value = '/listen/tes';
       } else if(to.indexOf('usr_top') != -1) {
          select.value = '/usr_top';
       } else if(to.indexOf('/elearning/info') != -1) {
         select.value = '/elearning';
       } else if(to.indexOf('/elearning') != -1) {
         select.value = '/elearning';
       } else if(to.indexOf('/usrinf/usr_inf') != -1) {
         select.value = '/usrinf/usr_inf';
       } else if(to.indexOf('/notification') != -1) {
         select.value = '/usrinf/usr_inf';
       }
    }, { immediate: true })    
    
    
    return {
      route,
      zTitle,
      zSelect,
      toUrl,
      select,
      titles,
      useRadio,useFlag, useTab, displayFlg1, displayFlg2, displayFlg5, agencyId, isCurrent
    }
  }
}
</script>

<!-- private scss-->
<style lang="scss" scoped>
@import '../style/viriables.scss';
.rsk-global-nav {
  align-items:	center;
  display:	flex;
  height:	48px;
  padding: 4px;
}
.rsk-global-nav--user {
  background-color:	$bg-user-primary-low;
}
.rsk-global-nav__item {
  align-items:	center;
  border-color:	$fg-boder-divider-black;
  border-right-style:	solid;
  border-width:	1px;
  display:	flex;
  flex-grow:	1;
  font-weight:	bold;
  height:	100%;
  justify-content:	center;
}
.rsk-global-nav--user .rsk-global-nav__item {
  color:	$fg-user-primary;
  text-decoration:	none;
}
.rsk-global-nav__item:last-child {
  border:	none;
}
.rsk-global-nav--user .rsk-global-nav__item.rsk-global-nav__item--active {
  color:	$fg-light;
}
.rsk-global-nav__item--active {
  background-color: $bg-user-primary;
  border-radius:	4px;
  cursor:	default;
}
.rsk-global-nav--user .rsk-global-nav__item:hover:not(.rsk-global-nav__item--active) {
  background-color:	$bg-user-primary-hover;
  border-radius:	4px;
  color:	$fg-light;
}
.rsk-sub-nav {
  align-items:	center;
  background-color:	transparent;
  border-bottom-style:	solid;
  border-color:	$fg-boder-divider-black;
  border-width:	1px;
  display:	flex;
  height:	40px;
}
.rsk-sub-nav__item {
  align-items:	center;
  border-bottom:	none;
  display:	inline-flex;
  height:	100%;
  justify-content:	center;
  margin-right:	16px;
}
.rsk-sub-nav__item--active {
  border-bottom-style:	solid;
  cursor:	default;
}
a.rsk-sub-nav__item, .rsk-sub-nav__item {
  text-decoration:	none;
}
.rsk-sub-nav--user .rsk-sub-nav__item {
  color: $fg-user-primary;
}
.rsk-sub-nav__divider {
  border-color:	$fg-boder-divider-black;
  border-right-style:	solid;
  border-width:	1px;
  height:	calc(100% - 16px);
  margin-right:	16px;
}
.rsk-sub-nav--user .rsk-sub-nav__item:hover:not(.rsk-sub-nav__item--active) .rsk-sub-nav__item-label {
  background-color: $bg-user-primary-hover-low;
  border-radius:	4px;
}
.rsk-sub-nav__item-label {
  padding:	3px 4px 2px 4px;
}
.rsk-sub-nav--white {
  background-color:	$bg-light;
  border-bottom:	none;
  padding-left:	24px;
}
</style>

<!-- public scss-->
<style lang="scss">
@import '../style/viriables.scss';
</style>
