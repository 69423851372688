<template>
    <div class="content">
        <rsk-container title="Webセミナー詳細" :buttons="buttons">
            <div class="detail-text-box__header">
                <div>
                    <div class="rsk-page-title rsk-mb-xs">{{tableData.trainName}}</div>
                    <div class="rsk-text-gray080 rsk-font-l">研修ID：{{tableData.trainId}}</div>
                </div>
                <div>
                    <div class="rsk-flex rsk-mb-xs">
                        <div class="rsk-text-gray080 rsk-mr-s">分類・階層</div>
                        <div>
                            {{trainType}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="container"  v-html="tableData.unitDetail">
            </div>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>
            <info-container>
                <div class="rsk-text-gray080" style="width: 10%">注意事項</div>
                <div v-html="tableData.newExamPreMessage" style="width: 90%;white-space: pre-wrap"></div>
            </info-container>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>    
            <info-container>
                <info-content label="定価（税抜）"  style="align-items:normal">
                    <div class="rsk-text-box__footer-column-right">
                        <div v-if="tableData.fixedPrice == 0">0円</div>
                        <div v-else-if="loginRank > rank" v-text="formatMoney(tableData.fixedPrice) + '円'"></div>
                        <div v-else><s v-text="formatMoney(tableData.fixedPrice) + '円'"></s>（ご契約料金内でご利用いただけます）</div>
                    </div>
                </info-content>
                <info-content label="研修期間" style="align-items:normal">
                    <div class="rsk-text-box__footer-column-right">
                        {{openStartAndEnd}}
                    </div>
                </info-content>
                <info-content :label="'研修場所'" style="align-items:normal">
                    <span>{{trainPlace}}</span>
                </info-content>
            </info-container>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>
            <info-container>
                <info-content label="受付終了日">
                    <span>{{endDate}}</span>
                </info-content>
                <info-content label="キャンセル<br>受付終了日" style="align-items:center">
                    <span>{{cancelEndDate}}</span>
                </info-content>
            </info-container>
            <info-content label="申込人数" style="align-items:center">
                <div class="rsk-form">
                    <select class="rsk-input" 
                        style="min-width: 0px"
                        @change="changeTrainAppNum($event)" 
                    >
                        <option v-for="(item, index) in items" :key="index" >{{item.label}}</option>
                    </select>
                </div>
            </info-content>
        </rsk-container>
        <popTraComment ref="poptracommemtRefs" :fromWhere="5" :trainId="tableData.trainId" :usrId="tableData.usrId" v-model="dialogFormVisible" @setUserCommentValue="getUserComment"></popTraComment>
    </div>
</template>
<script>
import { ref, onBeforeMount, inject} from 'vue';
import infoContent from "../../component/info-content";
import infoContainer from "../../component/info-container";
import infoHeader from "../../component/info-header";
import { dateFtt } from "@/utils/common";
import { useRoute,useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import input from '../../../../core/components/rsk/input.vue';
import popTraComment from "../../../poptracommemt/index";
export default {
    components: {
        popTraComment,
        infoHeader,
        infoContent,
        infoContainer
    },
    props: {
        setStatus:null
    },
    setup(props) {
        const router = useRouter();
        const route = useRoute();
        const service = inject("service");
        const trainId = route.params.trainId;
        const trainName = route.params.trainName;
        const rank = route.params.rank;
        const loginRank = route.params.loginRank;
        const dispaly37 = route.params.dispaly37;
        const jyukoufukaFlg = route.params.jyukoufukaFlg;
        const trainFlg = ref(2);
        const userComment = route.params.userComment;
        const oldFlg = route.params.oldFlg;
        const userCommentValue = ref();
        userCommentValue.value = userComment;
        let trainAppNum = 1;
        const priceApplication = route.params.priceApplication;
        const alertMessage = ref();
        const tableData = ref([]);
        const openStartAndEnd = ref();
        const endDate = ref();
        const cancelEndDate = ref();
        const trainPlace = ref();
        const trainType = ref();
        const typeObject = ref();
        const poptracommemtRefs = ref();
        const dialogFormVisible = ref(false);
        const commentInputFlg = ref();
        const commentShowFlag = ref();
        const commentPastFlag = ref();
        const items = ref([]);
        const fixedPrice = ref();

        const buttons = ref({
            left: {
                text: '戻る',
                callBack: function() {
                    router.push({ name: 'elearning', params: {tabSelect: 1, isFromInfo: 1}});
                }
            },
            centre: {
                text: '申し込む',
                callBack: (tableData) => {
                if(jyukoufukaFlg == "1"){
                    alert("全体管理者または所属組織管理者のため、研修申込できません。");
                    return;
                }
                if(commentInputFlg.value != 1 && commentShowFlag.value == 0){
                    if(commentPastFlag.value == 0) {
                        alert("コメント入力期限を過ぎています。\nカスタマーセンターまでご連絡ください。");
                        return;
                    }
                    setTimeout(()=>{
                        poptracommemtRefs.value.open();
                    },0)
                }else{
                    if(priceApplication == "有料" || priceApplication == "有料申込済"){
                        alertMessage.value = "有料の研修（定価" + formatMoney(fixedPrice.value) + "円）のため課金が発生しますが、申込してよろしいでしょうか？";
                    }else if(priceApplication == "申込済"){
                        alertMessage.value = "「" + trainName + "」は申込済みです。申込してもよろしいですか？（申込済の研修は「研修管理」から確認できます。）";
                    }else{
                        alertMessage.value = "申込してもよろしいですか？";
                    }

                    ElMessageBox.confirm(alertMessage.value,
                        "研修",{
                            cancelButtonClass: "btn-custom-cancel",
                            confirmButtonText: "OK",
                            cancelButtonText: "キャンセル",
                            type: "warning",
                        }
                        ).then(() => {
                            let formdata = {trainId: trainId, trainName: trainName, loginRank: loginRank, rank: rank, trainFlg: trainFlg.value, userComment: userCommentValue.value, trainAppNum: trainAppNum}
                            service.usrstdtraInfo.studyStart(formdata).then((res) => {
                                ElMessageBox({
                                    cancelButtonClass: "btn-custom-cancel",
                                    type:'success',
                                    message: res,
                                    showClose: true,
                                    duration:3000
                                })
                                router.push({ name: 'elearning', params: {tabSelect: 1, isFromInfo: 1}});
                            }).catch((error) => {

                            });
                        }).catch(() => {
                    });
                }



                },
            }
        })

        onBeforeMount(()=>{
            if(!route.params.trainId) {
                router.push({ name: 'elearning', params: {tabSelect: 1, isFromInfo: 1}});
                return;
            }
            service.common.typeObject().then((res) => {
                typeObject.value = res;
            }).catch((error) => {
                console.log(error);
            });
            if(dispaly37 == "false"){
                buttons.value.centre.show = function() { return false }
            }
            if(oldFlg != null && oldFlg == 1){
                buttons.value.centre.show = function() { return false }
            }
            init();
        })

        function init() {
            let formdata = {trainId: trainId, rank: rank};
            service.usrstdtraInfo.init(formdata).then(res=>{
                tableData.value = res;
                if(tableData.value.openStartDtm == null && tableData.value.openEndDtm == null){
                    openStartAndEnd.value = "";
                }else if(tableData.value.openStartDtm != null && tableData.value.openEndDtm == null) {
                    openStartAndEnd.value = dateFtt("auto_time",tableData.value.openStartDtm,"en");
                }else{
                    openStartAndEnd.value = dateFtt("auto_time",tableData.value.openStartDtm,"en") + " ～ " + dateFtt("auto_time",tableData.value.openEndDtm,"en");
                }
                if(tableData.value.endDate == null){
                    endDate.value = "";
                }else{
                    endDate.value = dateFtt("auto",tableData.value.endDate,"en");
                }
                if(tableData.value.cancelEndDate == null){
                    cancelEndDate.value = "";
                }else{
                    cancelEndDate.value = dateFtt("auto",tableData.value.cancelEndDate,"en");
                }
                if(tableData.value.trainAppNumList == null) {
                    items.value = [];
                }else {
                    items.value = tableData.value.trainAppNumList;
                }
                trainPlace.value = tableData.value.trainPlace;
                trainType.value = getLable(tableData.value.trainTypeObject);
                commentInputFlg.value = tableData.value.commentInputFlg;
                commentShowFlag.value = tableData.value.commentShowFlag;
                commentPastFlag.value = tableData.value.commentPastFlag;
                fixedPrice.value = tableData.value.fixedPrice;
                trainFlg.value = tableData.value.trainFlg
            })
        }
        function getLable(value) {
        for(let i = 0;i<typeObject.value.length;i++) {
            if(value == typeObject.value[i].value) {
                return typeObject.value[i].label;
            }
        }
    }
        function changeTrainAppNum(event) {
            trainAppNum = event.target.value.replace("人","");
        }

    const getUserComment = function(changeUserComment) {
        userCommentValue.value = changeUserComment;
        if(priceApplication == "有料" || priceApplication == "有料申込済"){
            alertMessage.value = "有料の研修（定価" + formatMoney(fixedPrice.value) + "円）のため課金が発生しますが、申込してよろしいでしょうか？";
        }else if(priceApplication == "申込済"){
            alertMessage.value = "「" + trainName + "」は申込済みです。申込してもよろしいですか？（申込済の研修は「研修管理」から確認できます。）";
        }else{
            alertMessage.value = "申込してもよろしいですか？";
        }

        ElMessageBox.confirm(alertMessage.value,
            "研修",{
                cancelButtonClass: "btn-custom-cancel",
                confirmButtonText: "OK",
                cancelButtonText: "キャンセル",
                type: "warning",
            }
            ).then(() => {
                let formdata = {trainId: trainId, trainName: trainName, loginRank: loginRank, rank: rank, trainFlg: trainFlg.value, userComment: userCommentValue.value, trainAppNum: trainAppNum}
                service.usrstdtraInfo.studyStart(formdata).then((res) => {
                    ElMessageBox({
                        cancelButtonClass: "btn-custom-cancel",
                        type:'success',
                        message: res,
                        showClose: true,
                        duration:3000
                    })
                    router.push({ name: 'elearning', params: {tabSelect: 1, isFromInfo: 1}});
                }).catch((error) => {

                });
            }).catch(() => {
        });
    }

    function formatMoney(data) {
      if(data == '' || data == null) {
        return ''
      } else {
          return (
            (String(data).split(",").join("") || 0)
              .toString()
              .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
            );
        }
    }
    
        return { poptracommemtRefs, dialogFormVisible, buttons, open, init, tableData, rank, loginRank, openStartAndEnd, endDate, cancelEndDate, getUserComment,
                trainPlace, getLable, typeObject, trainType, commentInputFlg, commentShowFlag, commentPastFlag, oldFlg, items, changeTrainAppNum, formatMoney}
    },
}
</script>
<style scoped>
.container {
    padding-bottom: 16px;
}
</style>
