import { Service, BaseService } from "@/core/auto";

@Service('/api/assentusr/')
export default class assentusr extends BaseService{
    editNew(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/editNew",
			method: "POST"
		})
	}
    create(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/create",
			method: "POST"
		})
	}
}