<template>
    <div>
        <rsk-table 
        cacheKey="traselfcache" 
        :titles="titles"
        :data="tableData"
        :total="count"
        @init="tableConfigInit"
        @pageInfoChange="tableChange"
        />       
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref,onMounted } from 'vue';
import { inject } from "vue";
import { dateFtt } from "@/utils/common";
export default {
    setup() {
        const router = useRouter()
        const service = inject("service");

        const titles = ref([
        {
            field:'trainName',
            title:'自社研修名',
        },
        {
            field:'studyFlag',
            title:'学習形式'
        },
        {
            field:'appUsr',
            title:'申込者'
        },
        {
            field:'openStartDtm',
            title:'研修期間'
        },
        {
            field:'statusName',
            title:'研修状况'
        },
        {
            field:'userScore',
            title:'点数',
            postfix: "点"
        }
    ])

    const tableData = ref([]);
    const count = ref();
    let sort = {}
    let paging = {}
    function tableConfigInit( sorts,p) {
      sort = sorts
      paging = p
    }    
    function tableChange(sorts,p) {
      sort = sorts
      paging = p
      study();
    }
    onMounted(() => {
        study();
    }); 

    function getLable(list,value) {
        for(let i = 0;i<list.length;i++) {
            if(list[i].value == value) {
                return list[i].label;
            }
        }
    }

    function study() {
            // let condition = JSON.parse(localStorage.getItem("traCondition" || null))
            let formdata = {condition:{...sort,...paging}}
            service.traselfstudy.study(formdata).then((res) => {
                count.value = res.count;
                if(count.value > 0) {
                    for(let i = 0;i<res.list.length;i++) {
                        res.list[i].trainName = {
                            text: res.list[i].trainName,
                                callBack: (item) => {
                                    router.push({ name: 'traselfstudyShow',params:{trainId:res.list[i].trainId,rank:res.list[i].rank}})
                            }
                        }                        
                        res.list[i].studyFlag = getLable(res.studyFlagList,res.list[i].studyFlag)
                        res.list[i].statusName = getLable(res.studystatuList,res.list[i].statusName)
                        res.list[i].openStartDtm = dateFtt("auto_time",res.list[i].openStartDtm,"en") + "～" + dateFtt("auto_time",res.list[i].openEndDtm,"en");
                    }
                    tableData.value = res.list;
                }
            }).catch((error) => {
                console.log(error);           
            });        
    }
        return {
            titles,tableData,count,
            tableConfigInit,tableChange,
            getLable,study
        }
    },
}
   
</script>