<template>
  <div class="content">
    <rsk-form :rules="rules" ref="formRef" v-model="model">
      <rsk-input type="hidden" v-model="model.lstEntDtm" />
      <div class="rsk-p-xl">
        <h2 class="rsk-page-title">{{ $t("usrPasPages.adminPasEditTitle") }}</h2>
        <rsk-tabs :list="tabList" v-model="tabSelect" @onChange="tabChange" />
        <div v-if="tabSelect == 1">
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :hintText="$t('common.adminApplicationRequired')" :label="$t('usrPasPages.adminPasEditOldPsd')" prop="oldPsd">
            <rsk-input  v-model="model.oldPsd" type="password" max="20"  :placeholder="$t('usrPasPages.adminPasEditOldPsd')"/>
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :hintText="$t('common.adminApplicationRequired')" :label="$t('usrPasPages.adminPasEditNewPsd')" prop="newPsd" :annotation=annotationMsg>
            <rsk-input  v-model="model.newPsd" type="password" max="20" :placeholder="$t('usrPasPages.adminPasEditNewPsd')" />
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :hintText="$t('common.adminApplicationRequired')" :label="$t('usrPasPages.adminPasEditNewPsdReinput')" prop="newPsdReinput" :annotation=annotationMsg>
            <rsk-input  v-model="model.newPsdReinput" type="password" max="20" :placeholder="$t('usrPasPages.adminPasEditNewPsdReinput')" />
          </rsk-form-item>
        </div>
        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
        <div class="rsk-p-s">
          <button class="rsk-button rsk-button--text-user-primary" @click="back">
              <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                  <span class="rsk-icon rsk-carret-left-admin-primary"></span>
              </span>
              {{ $t("common.adminBackButton") }}
          </button> 
        </div> 
        <rsk-form-submit class="rsk-no-border">
          <rsk-button isExpand type="primary" @click="update">{{ $t("common.adminUpdateButton") }}</rsk-button>
        </rsk-form-submit>
      </div>
    </rsk-form>
  </div>
</template>

<script>
  export { default } from './js/usr_pas_view.js'
</script>
<style lang="scss" scoped>
.rsk-no-border {
    border: none;
}
</style>
