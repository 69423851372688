import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/notification')
export default class notification extends BaseService{
    getNotificationList() {
		return this.request({
			url: "/getNotificationList",
			method: "POST"
		});
	}

	updateNotificationDetailAnswerFlg(informationId) {
		const data = {
			informationId:informationId
		}
		return this.request({
			data: data,
			url: "/updateNotificationDetailAnswerFlg",
			method: "POST"
		});
	}

	getInfo(informationId) {
		const data = {
			informationId:informationId
		}
		return this.request({
			data:data,
			url: "/getInformations",
			method: "POST"
		});
	}
}
