import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/popmul')
export default class PopMul extends BaseService {
	index(data) {
		return this.request({
			data: data,
			url: "/index",
			method: "POST"
		});
	}
}