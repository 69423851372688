import { useRouter, useRoute } from 'vue-router'
import { inject, ref,onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { mail } from '@/utils/validateUtils'
import { ElMessage, ElMessageBox } from 'element-plus'
import { validEmail } from '@/utils/validate'

export default {
    name: 'Pasdeh',
    setup() {
        const router = useRouter()
        const route = useRoute()
        const service = inject("service");
        const { locale,t } = useI18n({ useScope: 'global' });
        if(localStorage.getItem('lang') == null) {
            localStorage.setItem('lang', 'ja')
        }
        locale.value = localStorage.getItem('lang');
        var mailFormatMsg = '';
        if('ja' == locale.value){
            mailFormatMsg = 'メールアドレス は Email の形式(例 XXXXXXX@XX.com)で入力してください。';
        } else {
            mailFormatMsg = 'Enter Email address in email address format (e.g., XXXXXXX@XX.com).';
        }

        const sendData = ref({comId:'', loginId: '', usrMlAdr:'',loginFlg:'',isI18nJp:'',comIdShowFlg:''})
        const fromRismonFlag = route.params.fromRismonFlag ? route.params.fromRismonFlag : localStorage.getItem('fromRismonFlag');

        const rules = ref(
            {
                comId:[
                    { required: true, message: t('pasdehPages.comIdEmpty') },
                    {
                        min:15,
                        message: t('pasdehPages.comIdLength')
                    },
                    {
                        max:15,
                        message: t('pasdehPages.comIdLength')
                    }
                ],
                loginId:[
                    {validator: (rule, value, callback) => {
                        if(fromRismonFlag !== '0') {
                            if(value) {
                                if(value.length > 9) {
                                    callback(new Error(t('pasdehPages.loginIdMax')));
                                }
                                callback();
                            } else {
                                callback(new Error(t('pasdehPages.loginIdEmpty')));
                            }
                        } else {
                            callback();
                        }
                    }}
                ],
                usrMlAdr:[
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if(!(/^(.+)@(.+)$/.test(value))) {
                                    callback(new Error(mailFormatMsg));
                                } else {
                                    const validateData = { invalidStr: value } 
                                    validEmail(validateData,callback,t('common.adminErrorsEmailRfc'))
                                }
                            } else {
                                callback(new Error(t('pasdehPages.mailAdrEmpty')));
                            }
                        }
                    }
                ]
            }
        )

        const formRef = ref()
        
        // function init() {
        //     if(localStorage.getItem('comId')) {
        //         sendData.value.comId = localStorage.getItem('comId');
        //     }
        //     if(localStorage.getItem('comIdShowFlg')) {
        //         sendData.value.comIdShowFlg = localStorage.getItem('comIdShowFlg');
        //     }
        // }
        // init();

        onMounted(() => {
            if(localStorage.getItem('comId')) {
                sendData.value.comId = localStorage.getItem('comId');
            }
            if(localStorage.getItem('comIdShowFlg')) {
                sendData.value.comIdShowFlg = localStorage.getItem('comIdShowFlg');
            }
        });

        function send(){
            ElMessageBox.confirm(
                t('common.adminAskSend'),
                {
                cancelButtonClass: "btn-custom-cancel" ,
                confirmButtonText: 'OK',
                cancelButtonText: t('common.adminCancelButton'),
                type: 'warning',
                }
            )
            .then(() => {
                formRef.value.validate(sendData.value).then((res) => {
                    if(res){                   
                        if(fromRismonFlag == '0') {
                            service.pasdehyoshin.pasdehyoshin.send(sendData.value,t).then(res=>{
                                router.push({ 
                                    name: 'Login',
                                    params: {
                                        fromRismonFlag : '0'
                                    } 
                                })
                            }).catch(error => {
                                console.log(error)
                            })
                        } else {
                            sendData.value.loginFlg = localStorage.getItem('loginFlg');
                            if(locale.value == 'ja') {
                                sendData.value.isI18nJp = 'true'
                            } else {
                                sendData.value.isI18nJp = 'false'
                            }
        
                            service.pasdeh.pasdeh.send(sendData.value,t).then(res=>{
                                if(sendData.value.comIdShowFlg != '1') {
                                    router.push({ name: 'Login'})
                                } else {
                                    router.push({ name: 'SpecialLogin'})
                                }
                            }).catch(error => {
                                console.log(error)
                            })
                        }
                    }
                })
            })
        }
        function loginback(){
            if(sendData.value.comIdShowFlg != '1') {
                router.push({ 
                    name: 'Login',
                    params: {
                        fromRismonFlag : fromRismonFlag
                    } 
                })
            } else {
                router.push({ name: 'SpecialLogin'})
            }
        }
        return {
            fromRismonFlag,
            mailFormatMsg,
            sendData,
            rules, formRef,
            loginback,send
        }
    }
}