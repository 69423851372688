<template>
    <div class="content">
        <rsk-container title="自社研修詳細" :buttons="buttons">
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs" v-for="(item, index) in infos" :key="index">
                <div class="rsk-flex rsk-justify-between rsk-width-212">
                    <div class="rsk-table-like-form__heading rsk-table-like-form__table-column">{{item.title}}</div>
                </div>
                <div class="rsk-form rsk-form">
                    <div v-if="item.title == '研修内容'" v-html="item.value"></div>
                    <div v-else-if="!item.callBack" class="rsk-text">{{ item.value }}</div>
                    <div v-else-if="item.callBack && (item.value == '' || item.value == null)" class="rsk-text">{{item.value}}</div>
                    <el-button v-else type="text" @click="item.callBack(item)">{{item.value}}</el-button>
                    <!-- <div v-if="!item.callBack" class="table-like-list__column rsk-text">{{ item.value }}</div>
                    <el-button v-else type="text" @click="item.callBack(item)">{{ item.value }}</el-button> -->
                </div>
            </div>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>
        </rsk-container>
    </div>
</template>
<script>
import { ref } from 'vue';
import { useRoute,useRouter } from 'vue-router';
import { inject } from "vue";
import { dateFtt } from "@/utils/common";
import { ElMessageBox } from 'element-plus'
import input from '../../../../core/components/rsk/input.vue';
import { UAParser } from "../../../popenvironment/js/ua-parser.min";
export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const service = inject("service");
        const trainId = route.params.trainId;
        const trainName = route.params.trainName;
        const rank = route.params.rank;
        const loginRank = route.params.loginRank;
        const dispaly37 = route.params.dispaly37;
        const jyukoufukaFlg = route.params.jyukoufukaFlg;
        const priceApplication = route.params.priceApplication;
        const alertMessage = ref();
        const trainFlg = ref();
        const userComment = route.params.userComment;
        const trainAppNum = route.params.trainAppNum;
        const infos = ref([]);
        const typeObject = ref([]);
        const studyFlagList = ref([]);

        if(!route.params.trainId) {
            router.push({ name: 'elearning', params: {tabSelect: 2, isFromInfo: 1}});
            return;
        }

        service.common.typeObject().then((res) => {
            typeObject.value = res;
        }).catch((error) => {
            console.log(error);
        });

        let formData = {trainId:trainId}
        service.usrstdtraInfo.init(formData).then((res) => {
            studyFlagList.value = res.studyFlagList;
            infos.value.push({title: '研修ID',value: res.trainId})
            infos.value.push({title: '研修名',value: res.trainName})
            infos.value.push({title: '学習形式',value: getStudyFlagLable(res.studyFlag)})
            infos.value.push({title: '研修内容',value: res.unitDetail})
            if(res.baseStudyTime != ''){
                infos.value.push({title: '標準学習時間',value: res.baseStudyTime + "時間"})
            }else{
                infos.value.push({title: '標準学習時間',value: ""})
            }
            infos.value.push({title: '研修場所',value: res.trainPlace})
            infos.value.push({title: '研修所在地',value: res.trainAddress})
            infos.value.push({title: '研修期間',value: dateFtt("auto_time",res.openStartDtm,"en") + " ～" + dateFtt("auto_time",res.openEndDtm,"en")})

            infos.value.push({title: '添付ファイル1',value: res.name1,callBack: (item) => {
                downloadFile(res.name1, res.dir1);
            }})
            infos.value.push({title: '添付ファイル2',value: res.name2,callBack: (item) => {
                downloadFile(res.name2, res.dir2);
            }})

            if(res.reserveCount !== null){
                infos.value.push({title: '予定人数',value: res.reserveCount + "人"})
            }else{
                infos.value.push({title: '予定人数',value: ''})
            }
            trainFlg.value = res.trainFlg;
        }).catch((error) => {
            console.log(error);
        });

        function downloadFile(filename,fileurl){
                var ua = new UAParser();
                let userAgent = ua.getUA();
                const downloadData = {filename:'',fileurl:'',userAgent:''}
                downloadData.filename = filename;
                downloadData.fileurl = fileurl;
                downloadData.userAgent = userAgent;
                service.common.downloadFile(downloadData)
            }
        function getLable(value) {
            for(let i = 0;i<typeObject.value.length;i++) {
                if(value == typeObject.value[i].value) {
                    return typeObject.value[i].label;
                }
            }
        }

        function getStudyFlagLable(value) {
            for(let i = 0;i<studyFlagList.value.length;i++) {
                if(value == studyFlagList.value[i].value) {
                    return studyFlagList.value[i].label;
                }
            }
        }

        const buttons = ref({
            left: {
                text: '戻る',
                callBack: function() {
                    router.push({ name: 'elearning', params: {tabSelect: 2, isFromInfo: 1}});
                }
            },
            centre: {
                text: '申し込む',
                callBack: (tableData) => {
                if(jyukoufukaFlg == "1"){
                    alert("全体管理者または所属組織管理者のため、研修申込できません。");
                    return;
                }
                let formdata = {trainId: trainId, trainName: trainName, loginRank: loginRank, rank: rank, trainFlg: trainFlg.value, userComment: userComment, trainAppNum: trainAppNum}

                if(priceApplication == "有料"){
                    alertMessage.value = "有料の研修ですが、申込してよろしいでしょうか？";
                }else if(priceApplication == "有料申込済" || priceApplication == "申込済"){
                    alertMessage.value = "「" + trainName + "」は申込済みです。申込してもよろしいですか？（申込済の研修は「研修管理」から確認できます。）";
                }else{
                    alertMessage.value = "申込してもよろしいですか？";
                }
                ElMessageBox.confirm(alertMessage.value,
                    "研修",{
                        cancelButtonClass: "btn-custom-cancel",
                        confirmButtonText: "OK",
                        cancelButtonText: "キャンセル",
                        type: "warning",
                    }
                    ).then(() => {
                        service.usrstdtraInfo.studyStart(formdata).then((res) => {
                            ElMessageBox({
                                cancelButtonClass: "btn-custom-cancel",
                                type:'success',
                                message: res,
                                showClose: true,
                                duration:3000
                            })
                            router.push({ name: 'elearning', params: {tabSelect: 2, isFromInfo: 1}});
                        }).catch((error) => {
                        });
                    }).catch(() => {
                });
                },

            }
        })

        if(dispaly37 == "false"){
            buttons.value.centre.show = function() { return false }
        }

        return { buttons,infos, downloadFile, getLable, typeObject}
    },
}
</script>
<style scoped>
.container {
    padding-bottom: 16px;
}
</style>
