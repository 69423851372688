<template>
    <div class="content">
        <div class="rsk-p-xl">
            <div class="rsk-message-bar--error rsk-mb-l" v-if="errMsg.length > 0">
                <div class="rsk-items-center rsk-flex">
                    <span class="rsk-icon rsk-icon--alert-white rsk-mr-xs"></span>
                    <b>
                    入力項目に誤りがあります。<br>
                    <div v-for="(item, index) in errMsg" :key="index">{{item}}</div>
                    </b>
                </div>
            </div>
            <div class="rsk-form-control">
                <h2 class="rsk-page-title rsk-mb-s">アンケート回答</h2>
            </div>
            <div class="rsk-panel rsk-mb-m">
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">アンケート名</div>
                    </div>
                    <div v-text="model.anketoName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">ボックス名</div>
                    </div>
                    <div v-text="model.lessonName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">内容</div>
                    </div>
                    <div v-text="model.unitDetail" class="rsk-form rsk-form--expand rsk-text-gray060 rsk-pre-wrap text-wrap"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>                                                                              
            </div>
            <rsk-form class="rsk-mb-xxs" :rules="rules" ref="formRef">
                <div class="rsk-form-control rsk-toggle-form rsk-mb-xs" v-for="(item, index) in questionList" :key="index">
                    <div class="rsk-flex rsk-justify-between rsk-width-212">
                        <div class="rsk-table-like-form__heading rsk-table-like-form__table-column rsk-font-l">
                            問題{{ item.sortNo }}
                            <div><span class="rsk-label-must rsk-mr-s" v-if="item.requisite == 1">必須</span></div>
                        </div>
                    </div>
                    <div class="rsk-form rsk-form--expand rsk-form-width" v-if="item.questionType == 1">
                        <div class="rsk-form rsk-form--expand">
                            <div class="rsk-table-like-form__heading rsk-table-like-form__table-column question-text" v-html="item.questionName"></div>
                            <div class="rsk-p-s"></div>
                            <template  v-for="(item2, index2) in item.detailList" :key="index2">
                            <div class="rsk-flex rsk-mb-xxs">
                                 <div class="rsk-radio">
                                    <label class="rsk-radio--label">
                                        <input  type="radio" v-model="questionList[index].detailId" :value="item2.detailId" class="rsk-radio__input" />
                                        <span class="rsk-radio__text">
                                            <span class="select-wrap">{{ item2.selection }}</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-mb-xs" v-if="item2.otherFlg == 1">
                                <div class="rsk-text-gray060 rsk-font-xs rsk-text-right rsk-textarea">{{questionList[index].answer ? questionList[index].answer.length : 0}}/500</div>
			        		    <textarea placeholder="(500文字以内で入力してください)" maxlength="500" class="rsk-input rsk-input--expand rsk-textarea__input" v-model="questionList[index].answer"></textarea>
			        	    </div>
                            </template>
                        </div>
                    </div>
                    <div class="rsk-form rsk-form--expand rsk-form-width" v-if="item.questionType == 2">
                        <div class="rsk-form rsk-form--expand">
                            <div class="rsk-table-like-form__heading rsk-table-like-form__table-column question-text" v-html="item.questionName"></div>
                            <div class="rsk-p-s"></div>
                            <template  v-for="(item2, index2) in item.detailList" :key="index2">
                            <div class="rsk-flex rsk-mb-xxs">
                                <div class="rsk-checkbox-container">
                                    <input class="rsk-checkbox" :value="item2.detailId" v-model="questionList[index].checkBoxList" type="checkbox" :id="'list[' + index + '].detailList[' + index2 + ']'" />
                                    <label class="rsk-checkbox__image" :for="'list[' + index + '].detailList[' + index2 + ']'"></label>
                                    <label :for="'list[' + index + '].detailList[' + index2 + ']'" class="rsk-checkbox__label rsk-text-gray100">
                                        {{ item2.selection }}
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-mb-xs" v-if="item2.otherFlg == 1">
                                <div class="rsk-text-gray060 rsk-font-xs rsk-text-right rsk-textarea">{{questionList[index].answer ? questionList[index].answer.length : 0}}/500</div>
                                <textarea placeholder="(500文字以内で入力してください)" maxlength="500" class="rsk-input rsk-input--expand rsk-textarea__input" v-model="questionList[index].answer"></textarea>
                            </div>
                            </template>
                        </div>
                    </div>
                    <div class="rsk-form rsk-form--expand rsk-form-width" v-if="item.questionType == 3">
                        <div class="rsk-form rsk-form--expand">
                            <div class="rsk-table-like-form__heading rsk-table-like-form__table-column question-text" v-html="item.questionName"></div>
                            <div class="rsk-p-s"></div>
                            <div class="rsk-text-gray060 rsk-font-xs rsk-text-right rsk-textarea">{{questionList[index].answer ? questionList[index].answer.length : 0}}/500</div>
                            <rsk-input placeholder="(500文字以内で入力してください)" isExpand max="500" v-model="questionList[index].answer"  />
                        </div>
                    </div>
                    <div class="rsk-form rsk-form--expand rsk-form-width" v-if="item.questionType == 4">
                        <div class="rsk-form rsk-form--expand">
                            <div class="rsk-table-like-form__heading rsk-table-like-form__table-column question-text" v-html="item.questionName"></div>
                            <div class="rsk-p-s"></div>
                            <div class="rsk-mb-xs">
                                <div class="rsk-text-gray060 rsk-font-xs rsk-text-right rsk-textarea">{{questionList[index].answer ? questionList[index].answer.length : 0}}/3000</div>
                                <textarea placeholder="(3000文字以内で入力してください)" maxlength="3000" class="rsk-input rsk-input--expand rsk-textarea__input rsk-input-width" v-model="questionList[index].answer"></textarea>
                            </div>
                        </div>
                    </div>
                </div> 
            </rsk-form>
            <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>     
            <div class="rsk-p-s">
                <button @click="index" class="rsk-button rsk-button--text-user-primary">
                    <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                        <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                    </span>
                    戻る
                </button> 
            </div>
            <rsk-form-submit class="rsk-no-border">
                <rsk-button isExpand @click="answer" type="primary">回答</rsk-button>
            </rsk-form-submit>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, inject, onBeforeUnmount, watch, toRaw } from 'vue';
import { useRoute,useRouter } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import { checkInvalid } from '@/utils/validate'
import { full } from "@/utils/validateUtils";
import { useStore } from 'vuex'
export default {
    props: {
        modelValue: {
            type: Array,
            default: []
        },
    },
    setup(props) {
        const model = ref({});
        const questionModel = ref({});
        const questionList = ref([]);
        const route = useRoute();
        const router = useRouter()
        let errMsg = ref([])
        const store = useStore();
        let id = "";
        let goUrl = "ant";
        const lesDetailUrl = "lesDetail";
        onMounted(() => {
            if(typeof(route.params.id) != "undefined") {
                id = route.params.id;
                localStorage.anketoLessonId = id;
            } else {
                id = localStorage.anketoLessonId
            }
            if(!id) {
                router.push({ name: 'ant'});
                return;
            }

            store.commit('setCourseId', id);

            if(typeof(route.params.fromUrl) != "undefined") {
                goUrl = route.params.fromUrl;
                localStorage.fromLesUrl = goUrl;
            } else if(localStorage.fromLesUrl) {
                goUrl = localStorage.fromLesUrl
            }
            getAnketoAnswerEffect(); 
        })

        function getAnketoAnswerEffect() {
            service.anketo.getAnketoAnswer(store?.getters?.getCourseId).then(res => {
                model.value = res.model
                questionModel.value = res.questionModel
                questionList.value = res.questionList;
                for(let i in questionList.value) {
                    questionList.value[i].checkBoxList = ref(props.modelValue)
                } 
            })
        }
        
        function index() {
            router.push({ 
                name: goUrl,
                params: {
                    lessonId: model.value.lessonId
                }
            });   
        }

        function setCourseId() {
            localStorage.anketoLessonId = store?.getters?.getCourseId; 
        }

        window.addEventListener('beforeunload', setCourseId);

        onBeforeUnmount(() => {
            localStorage.removeItem("anketoLessonId");
            localStorage.removeItem("fromLesUrl");
            window.removeEventListener('beforeunload', setCourseId)
        })

        function answer() {
            var unAnswerFlag = true;
            for (let i = 0; i < questionList.value.length; i++) {
                var element = questionList.value[i];
                element = toRaw(element)
                if (element.questionType == 1) {
                  if (element.detailId != null && element.detailId != '') {
                    unAnswerFlag = false;
                    break;
                  }
                } else if (element.questionType == 2) {
                  if (JSON.stringify(element.checkBoxList._rawValue.length) > 0) {
                    unAnswerFlag = false;
                    break;
                  }
                } else if (element.questionType == 3) {
                  if (element.answer != null && element.answer != '') {
                    unAnswerFlag = false;
                    break;
                  }
                } else if (element.questionType == 4) {
                  if (element.answer != null && element.answer != '') {
                    unAnswerFlag = false;
                    break;
                  }
                }              
            }

            if (unAnswerFlag) {
                ElMessageBox.confirm(
                  '回答が設定されていませんが、よろしいですか？',
                   {
                      ancelButtonClass: "btn-custom-cancel" ,
                      confirmButtonText: 'OK',
                      cancelButtonText: 'キャンセル',  
                      type: 'warning',
                   }
                )
                .then(() => {
                   ElMessageBox.confirm(
                     '回答してもよろしいですか？',
                     {
                       cancelButtonClass: "btn-custom-cancel" ,
                       confirmButtonText: 'OK',
                       cancelButtonText: 'キャンセル',  
                       type: 'warning',
                     }
                   )
                   .then(() => {
                      submit();
                   })
                   .catch(() => {

                   })
                })
                .catch(() => {

                })
            } else {
                ElMessageBox.confirm(
                  '回答してもよろしいですか？',
                  {
                    cancelButtonClass: "btn-custom-cancel" ,
                    confirmButtonText: 'OK',
                    cancelButtonText: 'キャンセル',  
                    type: 'warning',
                  }
                )
                .then(() => {
                  submit();
                })
                .catch(() => {

                })
            }
        }

        function toTop() {
             document.body.scrollTop ? document.body.scrollTop = 0 : document.documentElement.scrollTop = 0
        }

        function goConfirm(anketoId, lessonId) {
        if(lessonId == null) {
            lessonId = 0;
        }
        let id = anketoId + "-" + lessonId
        router.push({
            name: 'anketoConfirm',
            params: {
                id : id,
                fromUrl: goUrl,
                lessonId: lessonId
            }
        })
    }

    const service = inject("service");
    function submit() {
        service.anketo.answerConfirm({'model': model.value, 'questionList' : questionList.value}).then(res => {
            if(!res.validate) {
                errMsg.value = res.errMsgList;
                questionList.value  = res.questionList;
                for(let i in questionList.value) {
                    questionList.value[i].checkBoxList = ref(props.modelValue)
                } 
                if (errMsg.value.length > 0) {
                    toTop();
                }
            } else {
                if (goUrl == lesDetailUrl) {
                    index();
                } else {
                    goConfirm(model.value.anketoId, model.value.lessonId);
                }
                ElMessage({
                        type:'success',
                        message: res.message,
                        showClose: true,
                        duration:3000
                })
                
            }             
        }).catch(err => {
        }) 
    }
    const rules =  ref(
        {
            answer: [
                full('アンケート名は全角で入力してください'),
                { 
                    validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value, isUnicoe: true }
                        checkInvalid(validateData, service, callback, 'アンケート名')
                    }
                },
                {
                    max: 50,
                    message: 'アンケート名は50文字以内で入力してください。'
                },
            ]
        }
    )
    const formRef = ref();

        return {
            model,
            questionModel,
            questionList,
            index,
            answer,
            rules,
            formRef,
            errMsg
        }
    }
}
</script>

<style lang="scss" scoped>
.rsk-label-must {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #EE4967;
    border-radius: 4px;
    font-size: 11px;
    color: #FFFFFF;
    width: 38px;
    height: 21px;
}
.rsk-textarea__input {
    height: 200px;
}
.rsk-message-bar--error {
    background-color: #EE4967;
    color: #fff;
    width: 100%;
    border-radius: 4px;
    font-weight: bold;
    padding: 10px 16px;
}
.rsk-no-border {
    border: none;
}

.question-text {
    width: 100% !important;
    max-width: 100% !important;
    word-wrap: break-word;
}

.text-wrap {
    width: calc(100% - 196px);
    word-wrap: break-word;
}

.select-wrap {
    left: 24px;
    position: relative;
}
</style>