<template>
  <section class="common-layout">
    <header class="rsk-header rsk-header--admin">
      <div class="rsk-header__left-block">
        <!-- <a href="javascript:void(0);" style="background-image: url('/static/image/logo.png');" class="rsk-header__logo"></a> -->
        <img src="../../assets/logo.png" alt="サイバックスUniv." style="width:166px;height:32px" />
      </div>
    </header>
    <nav class="rsk-global-nav rsk-global-nav--user" style="height: 2px"></nav>
    <section class="main" style="min-width: 9.8rem; min-height: 58.8rem;">
      <br/>
      <br/>
      <br/>
      <div>
        <div
          style="width: 385px; margin-left: 41.6%; margin-bottom: 20px"
          type="flex"
          v-show="model.errorMsg"
        >
          <span class="err__msg" style="height: 60px">
            <img
              style="width: 25px; height: 25px"
              src="../../assets/icon_err.gif"
              alt="."
            />&nbsp;
            <b v-html="model.errorMsg" style="color: red; font-size: 13px"></b>
          </span>
        </div>
      </div>
      <br/>
      <br/>
      <br/>
    </section>
  </section>
</template>
<style lang="scss" src="../login/css/login.scss"/>
<script>
import { ref, onMounted } from 'vue'
import { getUsrMenu, ssoUniv } from '../login/cont/login_cont.js'
import { UAParser } from "../popenvironment/js/ua-parser.min";
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { setToken } from '../../utils/CookieUtils'
export default {
  setup () {
    const ua = new UAParser();
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const model = ref({errorMsg: ''})
    var ssoId;
    var displayLogoutBtn;
    var targetKind;
    var userAgent = ua.getUA();
    
    onMounted(() => {
      if (route.query.sso_id) {
        ssoId = route.query.sso_id;
	    }
      if (route.query.display_logout_button) {
        displayLogoutBtn = route.query.display_logout_button;
	    }
      if (route.query.target_kind) {
        targetKind = route.query.target_kind;
	    }
      const formData = {
        ssoId: ssoId,
        userAgent: userAgent,
        displayLogoutButton: displayLogoutBtn,
        targetKind: targetKind,
      }
      ssoUniv(formData).then(res => {
        if(res.data == null) {
          model.value.errorMsg = res.message;
        } else {
          if(res.data.token != null){
            model.value.errorMsg = null;

          setToken(res.data.token)

          getUsrMenu().then(async result => {
            if (res.data.loginInfo.useFlag && res.data.loginInfo.useFlag === 1) {
              localStorage.useFlag = true
            } else {
              localStorage.useFlag = false
            }
            if (result.data.displayFlg1) {
              store.commit('changeDisplayFlg1')
              localStorage.displayFlg1 = true
            } else {
              localStorage.displayFlg1 = false
            }
            if (result.data.displayFlg2) {
              store.commit('changeDisplayFlg2')
              localStorage.displayFlg2 = true
            } else {
              localStorage.displayFlg2 = false
            }
            if (result.data.displayFlg5) {
              store.commit('changeDisplayFlg5')
              localStorage.displayFlg5 = true
            } else {
              localStorage.displayFlg5 = false
            }
            if (result.data.agencyId) {
              store.commit('changeAgencyId')
              localStorage.agencyId = true
            } else {
              localStorage.agencyId = false
            }
            await store.dispatch("permMenu", router)
            if('/usr_top' == res.data.targetUrl){
              localStorage.isLogin = true
              router.push({ path: '/usr_top' })
            }
            if('/elearning' == res.data.targetUrl){
              localStorage.isLogin = true
              router.push({ name: 'elearning'})
            }
            if('/assentusr' == res.data.targetUrl){
              localStorage.setItem('hennkouFlag',res.data.hennkouFlag);
              setloginToSession(res.data.loginInfo);
              router.push({ name: 'Assentusr'})
            }
            if('/assentmng' == res.data.targetUrl){
              setloginToSession(res.data.loginInfo);
              router.push({ name: 'Assentmng'})
            }
            if('/passaiedit' == res.data.targetUrl){
              setloginToSession(res.data.loginInfo);
              localStorage.setItem('displayPopFlag',JSON.stringify(res.data.displayPopFlag));
              router.push({ name: 'Passaiedit'})
            } else {
              localStorage.removeItem('displayPopFlag');
            }
            if('/popenvironment' == res.data.targetUrl){
              setloginToSession(res.data.loginInfo);
              router.push({ name: 'Popenvironment',query:{popbtnShow:true}})
            }
            localStorage.setItem('getCopyRightFlg',res.data.getCopyRightFlg);
            localStorage.setItem('getComIdFlag',res.data.getComIdFlag);
            localStorage.setItem('traShowFlag',res.data.traShowFlag);
            localStorage.setItem('traSelfShowFlag',res.data.traSelfShowFlag);
            localStorage.setItem('isDispatchAgencyFlag',res.data.isDispatchAgencyFlag)
            localStorage.setItem('InfoFlg',res.data.InfoFlg)
            localStorage.setItem('logoUrl',res.data.logoUrl)
            localStorage.setItem('logoutBtnFlag',res.data.loginInfo.logoutFlg);
            localStorage.setItem('comNm', res.data.loginInfo.comNm)
          }).catch(err => {
          return err
          })
			}
		}
		if(loginChecked.value){
			localStorage.setItem('comid',data.value.comId)
			localStorage.setItem('userid',data.value.userId)
			if (res.data.loginInfo.comIdNew) {
				localStorage.setItem('comidnew',res.data.loginInfo.comIdNew)
			}
		} else {
			localStorage.removeItem('comid')
			localStorage.removeItem('userid')
			localStorage.removeItem('comidnew')
		}
    }).catch(err => {
        return err
      })
    })

    function setloginToSession(loginInf) {
      var information = {
        comId: loginInf.comId,
        loginComId: loginInf.loginComId,
        usrId: loginInf.usrId,
        userProjectShowFlag: loginInf.userProjectShowFlag,
        loginAgencyId: loginInf.loginAgencyId,
        fromRismonFlag: loginInf.fromRismonFlag,
        pwdChgDiv: loginInf.pwdChgDiv,
        jccFlag: loginInf.jccFlag,
        loginId: loginInf.loginId
      }
      localStorage.setItem('login',JSON.stringify(information));
    }
    return {
      model
    }
  }
}
</script>