/**
 * Auto Registration Component
 * @param {APP} app 
 */
export default function (app) {
	const requireComponent = require.context('@/core/components/', true, /\.vue$/)
	requireComponent
		.keys()
		.forEach(fileName => {
			const componentConfig = requireComponent(fileName)
			const paths = fileName.toLowerCase().split('/')
			const fileItemName = paths.pop().replace(/\.\w+$/, '')
			const dirName = paths.pop()
			const componentName = dirName + '-' + fileItemName
			app.component(
				componentName,
				componentConfig.default || componentConfig
			)
		})
}