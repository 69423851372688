<script type="text/javascript" src="./js/ua-parser-custom.js"></script>;
import { useRouter,useRoute } from "vue-router";
import { UAParser } from "./ua-parser.min";
import { inject } from "vue";
import { ref } from 'vue';
import { cookieIsValid } from '../../../utils/CookieUtils.js'

const environmentEffect = () => {
  const router = useRouter();
  const state = router.currentRoute._value.params.resData; 

  const jump = async () => {
    if(cookieIsValid()) {
      const login = JSON.parse(localStorage.getItem("login"));
      if (!login) {
        router.push({ path: '/login' });
        return;
      }
      if(login.pwdChgDiv == 1) {
        router.push({ name: 'Passaiedit'})
      } else if (login.fromRismonFlag == "0") {
        localStorage.isLogin = true
        router.push({ path: "/elearning" });
      } else {
        localStorage.isLogin = true
        router.push({ path: "/usr_top" });
      }
    } else {
     return;
    }
  };

  const OS = (versionWin) => {
    var uaParser = new UAParser();
    var os = uaParser.getOS().name;
    var version = uaParser.getOS().version;
    if (versionWin != '') {
      version = '11'
    }
    return os === "Mac OS" ? os : os + " " + version;
  };

  const base_browser = () => {
    var ua = window.navigator.userAgent.toLowerCase();
    var ver = window.navigator.appVersion.toLowerCase();
    var name = "unknown";

    if (ua.indexOf("msie") != -1) {
      if (ver.indexOf("msie 6.") != -1) {
        name = "6";
      } else if (ver.indexOf("msie 7.") != -1) {
        name = "7 (ブラウザモード)";
      } else if (ver.indexOf("msie 8.") != -1) {
        name = "8 (ブラウザモード)";
      } else if (ver.indexOf("msie 9.") != -1) {
        name = "9 (ブラウザモード)";
      } else if (ver.indexOf("msie 10.") != -1) {
        name = "10 (ブラウザモード)";
      } else {
        name = "Internet Explorer";
      }
    } else if (ua.indexOf("trident/7") != -1) {
      name = "11 (ブラウザモード)";
    } else if (ua.indexOf("edge") != -1) {
      name = "Legacy版 Edge";
    } else if (ua.indexOf("chrome") != -1 && ua.indexOf("edg") != -1) {
      name = "Chromium版 Edge";
    } else if (ua.indexOf("chrome") != -1) {
      name = "Google Chrome";
    } else if (ua.indexOf("safari") != -1) {
      name = "Safari";
    } else if (ua.indexOf("opera") != -1) {
      name = "Opera";
    } else if (ua.indexOf("firefox") != -1) {
      name = "Mozilla Firefox";
    }
    return name;
  };

  const document_brower = () => {
    var ieFlg = navigator.userAgent.toLocaleLowerCase().indexOf("msie") != -1;

    var lteIE8Flg = ieFlg && !+[1];
    var documentMode = document.documentMode;

    if (documentMode) {
      switch (documentMode) {
        case 5:
          return "5 (ドキュメントモード)";
        case 6:
          return "6 (ドキュメントモード)";
        case 7:
          return "7 (ドキュメントモード)";
        case 8:
          return "8 (ドキュメントモード)";
        case 9:
          return "9 (ドキュメントモード)";
        case 10:
          return "10 (ドキュメントモード)";
        case 11:
          return "11 (ドキュメントモード)";
      }
    } else {
      var IE5 =
        lteIE8Flg &&
        (!document.compatMode || document.compatMode === "BackCompat");
      var IE6 = lteIE8Flg && !IE5 && !XMLHttpRequest;

      if (IE5) {
        return "5";
      } else if (IE6) {
        return "6";
      } else if (lteIE8Flg && !IE6 && !document.documentMode) {
        return "7 (ドキュメントモード)";
      } else if (lteIE8Flg && document.documentMode) {
        return "8 (ドキュメントモード)";
      } else if (
        !lteIE8Flg &&
        (function() {
          "use strict";
          return !!this;
        })()
      ) {
        return "9 (ドキュメントモード)";
      } else if (
        ieFlg &&
        !!document.attachEvent &&
        (function() {
          "use strict";
          return !this;
        })()
      ) {
        return "10 (ドキュメントモード)";
      } else if (ieFlg && !document.attachEvent) {
        return "11 (ドキュメントモード)";
      }
    }
  };

  const osVerify = (versionWin) => {
    var os = false;

    switch (OS(versionWin)) {
      case "Windows 7":
        return (os = true);
      case "Windows Vista":
        return (os = false);
      case "Windows 10":
        return (os = true);
      case "Windows 11":
        return (os = true);  
      case "Mac OS":
        return (os = true);
    }

    return os;
  };

  const browerVerify = (versionWin) => {
    var browerFlg = false;
    var os = "";
    var browser = "";
    var documentBrower = "";

    switch (OS(versionWin)) {
      case "Windows 11":
        os = "windows11";
        break;
      case "Windows 10":
        os = "windows10";
        break;
      case "Windows 8":
        os = "windows8";
        break;
      case "Windows 7":
        os = "windows7";
        break;
      case "Windows Vista":
        os = "vista";
        break;
      case "Mac OS":
        os = "mac";
        break;
    }

    switch (base_browser()) {
      case "9 (ブラウザモード)":
        browser = "9";
        break;
      case "10 (ブラウザモード)":
        browser = "10";
        break;
      case "11 (ブラウザモード)":
        browser = "11";
        break;
      case "Legacy版 Edge":
        browser = "Legacy版 Edge";
        break;
      case "Chromium版 Edge":
        browser = "Chromium版 Edge";
        break;
      case "Google Chrome":
        browser = "chrome";
        break;
      case "Safari":
        browser = "safari";
        break;
    }

    switch (document_brower()) {
      case "9 (ドキュメントモード)":
        documentBrower = "9";
        break;
      case "10 (ドキュメントモード)":
        documentBrower = "10";
        break;
      case "11 (ドキュメントモード)":
        documentBrower = "11";
        break;
    }

    if (os != "mac" && browser === "chrome") {
      return (browerFlg = true);
    }

    if (os === "vista") {
      if (
        browser === "10" ||
        browser === "11" ||
        documentBrower === "10" ||
        documentBrower === "11"
      ) {
        return (browerFlg = true);
      }
    } else if (os === "windows7" || os === "windows8") {
      if (browser === "11" || documentBrower === "11") {
        return (browerFlg = true);
      }
    } else if (os == "windows10" || os === "windows11") {
      if (browser === "11" || documentBrower === "11") {
        return (browerFlg = true);
      } else if (browser === "Legacy版 Edge") {
        return (browerFlg = false);
      } else if (browser === "Chromium版 Edge") {
        return (browerFlg = true);
      }
    } else if (os === "mac") {
      if (browser === "safari") {
        return (browerFlg = true);
      }
    }
    return browerFlg;
  };

  const cookieCheck = () => {
    return cookieIsValid()
  }

  return {
    jump,
    OS,
    osVerify,
    browerVerify,
    document_brower,
    base_browser,
    cookieCheck,
    window,
    state
  };
};

export default {
  name: "popenvironment",
  setup() {
    const {
      jump,
      OS,
      osVerify,
      browerVerify,
      document_brower,
      base_browser,
      cookieCheck,
      ActiveXObject,
      window,
      state
    } = environmentEffect();
    const btnShowFlag = ref();
    const versionWin = ref('');
    const service = inject("service");
    const route = useRoute();
    if(route.query.popbtnShow == 'true'){
      btnShowFlag.value=1;
    }
    if (window.navigator.userAgentData != undefined) {
      window.navigator.userAgentData.getHighEntropyValues(["platformVersion"]).then(ua => {
          if (navigator.userAgentData.platform === "Windows") {
            const majorPlatformVersion = parseInt(ua.platformVersion.split('.')[0]);
            if (majorPlatformVersion >= 13) {
                versionWin.value = '11'
            }
        }
      })
    }
    return {
      jump,
      OS,
      osVerify,
      browerVerify,
      document_brower,
      base_browser,
      cookieCheck,
      window,
      state,
      btnShowFlag,
      versionWin
    };
    
  },
};
