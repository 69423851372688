export const permissionMenuFlg = false
let title = "サイバックスUniv.({0})"
String.prototype.format = function() {
    if(arguments.length == 0) return this;
    let param = arguments[0];
    let s = this;
    if(typeof(param) == 'object') {
     for(let key in param)
      s = s.replace(new RegExp("\\{" + key + "\\}", "g"), param[key]);
     return s;
    } else {
     for(let i = 0; i < arguments.length; i++)
      s = s.replace(new RegExp("\\{" + i + "\\}", "g"), arguments[i]);
     return s;
    }
}
export const menuList = [
    {
      id: '1',
      name: 'usrpro',
      path: 'usrpro',
      viewPath:'./usrpro/usr_pro.vue',
      meta:{title: title.format("アカウント管理")}
    },
    {
      id: '1',
      name: 'usrpas',
      path: 'usrpas',
      viewPath:'./usrpas/usr_pas.vue',
      meta:{title: title.format("パスワード変更")}
    },
    {
        id: '2',
        name: 'notification',
        path: 'notification',
        viewPath:'./notification/index.vue',
        meta:{title: title.format("お知らせ確認"),}
    },
    {
        id: '3',
        name: 'elearning',
        path: 'elearning',
        viewPath:'./elearning/index.vue',
        meta:{
            titleUse: false
         }
    },
    {
        id: '4',
        name: 'trastudyList',
        path: 'listen/trastudy',
        viewPath:'./listen/trastudy.vue',
        meta:{title: title.format("Webセミナー")}
    },
    {
        id: '11',
        name: 'elgstudyList',
        path: 'listen/elgstudy',
        viewPath:'./listen/elgstudy.vue',
        meta:{title: title.format("eラーニング")}
    },
    {
        id: '5',
        name: 'tes',
        path: 'listen/tes',
        viewPath:'./listen/test/index.vue',
        meta:{title: title.format("テスト")}
    },
    {
        id: '6',
        name: 'ant',
        path: 'listen/ant',
        viewPath:'./listen/anketo/index.vue',
        meta:{title: title.format("アンケート")}
    },
    {
        id: '7',
        name: 'rep',
        path: 'listen/rep',
        viewPath:'./listen/report/index.vue',
        meta:{title: title.format("レポート")}
    },
    {
        id: '9',
        name: 'elearning-info',
        path: 'elearning/info',
        viewPath:'./elearning/view/e/info.vue',
        meta:{title: title.format("研修(eラーニング詳細)")}
    },
    {
        id: '10',
        name: 'elgstudy',
        path: 'elgstudy',
        viewPath:'./study/elg/elgstudy.vue',
        meta:{title: title.format("研修管理(eラーニング)")}
    },
    {
        id: '10',
        name: 'trastudy',
        path: 'trastudy',
        viewPath:'./study/tra/trastudy.vue',
        meta:{title: title.format("研修管理(Webセミナー)")}
    },
    {
        id: '10',
        name: 'trastudyShow',
        path: 'trastudyShow',
        viewPath:'./study/tra/trastudy_show.vue',
        meta:{title: title.format("研修管理(Webセミナー詳細)")}
    },
    {
        id: '10',
        name: 'traselfstudy',
        path: 'traselfstudy',
        viewPath:'./study/traself/traselfstudy.vue',
        meta:{title: title.format("研修管理(自社研修)")}
    },
    {
        id: '10',
        name: 'traselfstudyShow',
        path: 'traselfstudyShow',
        viewPath:'./study/traself/traselfstudy_show.vue',
        meta:{title: title.format("研修管理(自社研修詳細)")}
    },
    {
        id: '10',
        name: 'les',
        path: 'les',
        viewPath:'./les/les.vue',
        meta:{title: title.format("ボックス")}
    },
    {
        id: '10',
        name: 'lesDetail',
        path: 'lesDetail',
        viewPath:'./les/les_detail.vue',
        meta:{title: title.format("ボックス詳細")}
    },
    {
        id: '10',
        name: 'oneself-info',
        path: 'oneself/info',
        viewPath:'./elearning/view/oneself/info.vue',
        meta:{title: title.format("研修(自社研修詳細)")}
    },
    {
        id: '9',
        name: 'public-info',
        path: 'public/info',
        viewPath:'./elearning/view/public/info.vue',
        meta:{title: title.format("研修(Webセミナー詳細)")}
    },

    {
        id: '9',
        name: 'testConfirm',
        path: 'listen/tes/confirm',
        viewPath:'./listen/test/confirm.vue',
        meta:{title: title.format("テスト解答内容")}
    },
    {
        id: '11',
        name: 'testAnswer',
        path: 'listen/tes/answer',
        viewPath:'./listen/test/answer.vue',
        meta:{title: title.format("テスト解答")}
    },
    {
        id: '12',
        name: 'anketoConfirm',
        path: 'listen/ant/confirm',
        viewPath:'./listen/anketo/confirm.vue',
        meta:{title: title.format("アンケート回答内容")}
    },
    {
        id: '13',
        name: 'anketoAnswer',
        path: 'listen/ant/answer',
        viewPath:'./listen/anketo/answer.vue',
        meta:{title: title.format("アンケート回答")}
    },
    {
        id: '14',
        name: 'reportConfirm',
        path: 'listen/rep/confirm',
        viewPath:'./listen/report/confirm.vue',
        meta:{title: title.format("レポート提出内容")}
    },
    {
        id: '15',
        name: 'reportSend',
        path: 'listen/rep/send',
        viewPath:'./listen/report/send.vue',
        meta:{title: title.format("レポート提出")}
    },
]
