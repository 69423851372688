<template>
    <div>
        <div class="rsk-p-xl">
            <div v-if="title" class="rsk-flex rsk-items-center rsk-justify-between">
                <div class="rsk-mb-m">
                    <h2 class="rsk-page-title">{{title}}</h2>
                </div>
            </div>
            <form class="rsk-mb-xxs rsk-panel">
                <slot />
            </form>
        </div>
        <div class="rsk-p-s" v-if="buttons">
            <div class="skip">
                <button v-if="buttons.left && (buttons.left.show == null || buttons.left.show())" class="rsk-button rsk-button--text-user-primary rsk-mb-l cursor-pointer" @click="buttons.left.callBack">
                <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                    <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                </span>
                {{ buttons.left.text }}
                </button> 
                <div v-else></div>
                
            <button v-if="buttons.right && (buttons.right.show == null || buttons.right.show())" class="rsk-button rsk-button--text-user-primary rsk-mb-l cursor-pointer" @click="buttons.right.callBack">
                {{ buttons.right.text }}
                <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                    <span class="rsk-icon rsk-icon--carret-right-user"></span>
                </span>
            </button> 
            </div>
            <div class="rsk-flex rsk-justify-center" v-if="buttons.centre && (buttons.centre.show == null || buttons.centre.show())">
                <div class="rsk-form-button-wrapper__button">
                    <button @click="buttons.centre.callBack" class="rsk-button rsk-button--medium rsk-button--expand  rsk-button--user-primary">{{buttons.centre.text}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
            title: '',

            /**
             * left: {
             *  text: buttonName,
             *  show: function() {},
             *  callBack: function(){}
             * },
             * right: {
             *  text: buttonName,
             *  show: function() {},
             *  callBack: function(){}
             * }
            * centre: {
             *  text: buttonName,
             *  show: function() {},
             *  callBack: function(){}
             * }
             */
            buttons: {},

    },
    setup(props) {
        return {...props}
    }
}
</script>
<style scoped>
.skip {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.isHidden{
    visibility:hidden
}
</style>