<template>
  <footer class="rsk-footer">
    <div class="rsk-footer__links">
      <a class="rsk-footer_link" v-if="agreeShow" href="#" @click="downloadAgree">会員規約</a>
      <div class="rsk-footer__links-divider" v-if="agreeShow"></div>
      <a class="rsk-footer_link" v-if="personShow" href="#" @click="downloadPerson">個人情報の取り扱い</a>
      <div class="rsk-footer__links-divider" v-if="personShow"></div>
      <a class="rsk-footer_link" v-if="faqShow" href="#" @click="openFaq">FAQ</a>
    </div>
    <div class="rsk-footer_copylight" v-if="copyRightShow">
      Copyright © 2012-{{year}} Riskmonster.com All Reserved.︎
    </div>
  </footer>
</template>

<script>
import { onMounted, ref } from "vue";
import { inject } from "vue";
import { UAParser } from "../views/popenvironment/js/ua-parser.min";
export default {
  name: "Footer",
  setup () {
    const service = inject("service");
    const year = ref('');
    const faqShow = ref(false);
    const agreeShow = ref(false);
    const personShow = ref(false);
    const copyRightShow = ref(false);
    onMounted(() => {
      const meta = document.createElement("meta")
      meta.name = "viewport"
      meta.content = "width=device-width,initial-scale=1.0"
      const head = document.getElementsByTagName("head")[0]
      head.replaceChild(meta, head.getElementsByTagName("meta")[2])

      var date = new Date();
      year.value = date.getFullYear();

      if(localStorage.getItem('isDispatchAgencyFlag') == 'true'){
        faqShow.value = true;
      } else {
        agreeShow.value = true;
        personShow.value = true;
        faqShow.value = true;
      }
      if(localStorage.getItem('getCopyRightFlg') == 0){
        copyRightShow.value = true;
      } else {
        copyRightShow.value = false;
      }
    });

    function openFaq(){
      window.open('https://cybaxuniv-support2user.zendesk.com/hc/ja');
    }

	function downloadAgree(){
    var ua = new UAParser();
    let userAgent = ua.getUA();
    const downloadData = {filename:'サイバックスUniv.会員規約.pdf',fileurl:'/pdf_file/サイバックスUniv.会員規約.pdf',userAgent:userAgent}
		service.common.downloadSystemFile(downloadData)
	  }
	function downloadPerson(){
    var ua = new UAParser();
    let userAgent = ua.getUA();
    const downloadData = {filename:'個人情報の取扱い.pdf',fileurl:'/pdf_file/個人情報の取扱い.pdf',userAgent:userAgent}
		service.common.downloadSystemFile(downloadData)
	  }

    return {
      faqShow,
      agreeShow,
      personShow,
      copyRightShow,
      year,
      openFaq,
      downloadAgree,
      downloadPerson
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style/viriables.scss';
.rsk-footer {
  background-color: #F2F5F7;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  align-items: center;
  height: 36px;
  color: #8B949E;
  border-top: 1px solid;
  border-color: rgba(48, 53, 59, 0.16);
  font-size: 12px
}
.rsk-footer__links {
  display: flex;
  align-items: center
}
.rsk-footer__links .rsk-footer_link {
  color: #8B949E
}
.rsk-footer__links-divider {
  height: 11px;
  width: 1px;
  margin: 0 8px;
  background-color: rgba(48, 53, 59, 0.16)
}
</style>
