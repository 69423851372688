import { Service, BaseService } from "@/core/auto";

@Service('/api/pasdph/')
export default class pasdeh extends BaseService{
    send(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/send",
			method: "POST"
		})
	}
}