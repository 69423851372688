import { inject, ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import { phone } from '@/utils/validateUtils'
import { checkInvalid } from '@/utils/validate'
import { HalfToFull } from '@/utils/common'

export default {
  name: "assent",
  setup() {
    const router = useRouter();
    const service = inject("service");
    const state = router.currentRoute._value.params.resData;
    const rule = ref(false);
    const fromWhere = ref(false);
    const modelInf = ref({});
    const initModelInf = ref({});
    const login = JSON.parse(localStorage.getItem("login"));
    const birthYear = ref([])
    const birthMonth = ref([])
    const birthDay = ref([])
    const formRef = ref({})

    /**start 初期化年月*/
    let day = ref(0)
    let myDate = new Date()
    for (let i = myDate.getFullYear(); i >= 1971; i--) {
      birthYear.value.push(i)
    }
    for (let i = 1; i <= 12; i++) {
      birthMonth.value.push(i)
    }
    /**end 初期化年月*/

    if (!login) {
      router.push({ path: "/login" });
    }

    /**start フォーム検証*/
    const rules = ref(
      {
        usrTel: [
          {
            max: 32,
            message: '電話番号は32文字以内で入力してください。'
          },
          phone('電話番号は電話番号の形式(例 11-1111-1111)で入力してください。')
        ],
        usrZip: [
          {
            validator: (rule, value, callback) => {
              zipCodeValidate(callback);
            }
          }
        ],
        usrAdr: [
          {
            validator: (rule,value,callback) => {
              if (value.length > 250) {
                callback(new Error('住所は250文字以内で入力してください。'))
              } else {
                const validateData = { invalidStr: value,isUnicoe:true }
                checkInvalid(validateData,service,callback,'住所')
              }
            }
          }
        ],
        post: [
          {
            validator: (rule,value,callback) => {
              if (value.length > 30) {
                callback(new Error('役職は30文字以内で入力してください。'))
              } else {
                const validateData = { invalidStr: value,isUnicoe:true }
                checkInvalid(validateData,service,callback, '役職')
              }
            }
          }
        ]
      }
    )
    /**end フォーム検証*/

    /**
     * @description 郵便番号検証
     * @method
     * @param {*} callback コールバック関数
     */
    function zipCodeValidate(callback) {
      let zipCode = modelInf.value.usrZip;
      const pattern = /^[0-9]{3}\-[0-9]{4}$/
      if(!zipCode) {
        callback();
      } else if (zipCode.length == 7) {
        zipCode = modelInf.value.usrZip.substring(0, 3) + "-" + modelInf.value.usrZip.substring(3);
      }
      if (pattern.test(zipCode)) {
        callback();
      }
      callback(new Error('郵便番号は郵便番号の形式(例 1111111)で入力してください。'))
    }

    /**
     * @description 利用環境チェックページへclick
     * @method
     */
    function jump() {
      if (rule.value == false) {
        alert("同意をチェックしてください。");
      } else {
        const login = JSON.parse(localStorage.getItem("login"));
        formRef.value.validate(modelInf.value).then((res) => {
          if (res) {
            modelInf.value.usrAdr = HalfToFull( modelInf.value.usrAdr);
            modelInf.value.post = HalfToFull( modelInf.value.post);
            var formReqDto = {
              modelInf: modelInf.value,
              login: login
            }
            service.assentmng.assentmng.update(formReqDto).then(res => {
              router.push({ name: 'Popenvironment',query:{popbtnShow:true}})
            }).catch(error => {
              ElMessage({
                type:'error',
                message: error.message,
                showClose: true,
                duration:3000
              })
            })
            // service.usrpro.update(model.value).then((res) => {
            //   getUsrPro()
            //   ElMessage({
            //       type:'success',
            //       message: res,
            //       showClose: true,
            //       duration:3000
            //   })
            // }).catch((error) => {
            //   ElMessage({
            //     type:'error',
            //     message: error.message,
            //     showClose: true,
            //     duration:3000
            //   })
            // })
          }
        })
      }
    };

    /**
     * @description 郵便番号から検索
     * @method
     * @param {*} zipCode 郵便番号
     */
    function researchZip(zipCode) {
      service.common.researchZip(zipCode).then((res) => {
        const zipData = res
        if (!zipCode) {
          alert('郵便番号検索に失敗しました。')
        } else if (!zipData) {
          alert('郵便番号検索に失敗しました。')
        } else {
          modelInf.value.usrAdr = zipData.address1 + zipData.address2 + zipData.address3
          initModelInf.value.usrAdr = zipData.address1 + zipData.address2 + zipData.address3
        }
      }).catch((error) => {
        alert('郵便番号検索に失敗しました。')
      })
    }

    /**
     * @description 月に基づく日数の生成
     */
    watch(modelInf, val => {
        if(val.month){
          switch (parseInt(val.month)) {
            case 1: case 3: case 5: case 7: case 8: case 10:
            case 12: day.value = 31
              break
            case 2:
              if ((modelInf.value.year % 400 == 0) || (modelInf.value.year % 4 == 0 && modelInf.value.year % 100 != 0)) {
                day.value = 29
              } else {
                day.value = 28
              }
              break
            default: day.value = 30
              break
          }
          birthDay.value = []
          for (let i = 1; i <= day.value; i++) {
            birthDay.value.push(i)
          }
          if(modelInf.value.day > day.value){
            modelInf.value.day = null
          }
        }
    },{ deep:true })

        /**
     * @description 画面の初期化
     */
    onMounted(() => {
      if(localStorage.getItem('assEntmngPagemsg')){
        ElMessage({
            type:'success',
            message: localStorage.getItem('assEntmngPagemsg'),
            showClose: true,
            duration:3000
      })
      localStorage.removeItem('assEntmngPagemsg');
      }
      service.assentmng.assentmng.edit().then(res => {
        if (res) {
          fromWhere.value = res.fromWhere
          modelInf.value = {...res.modelInf};
          initModelInf.value = {...res.modelInf};
        }
      }).catch(error => {
        console.log(error)
      })
    })

    return {
      state,
      rule,
      jump,
      fromWhere,
      modelInf,
      birthYear, 
      birthMonth, 
      birthDay,
      day,
      rules,
      formRef,
      initModelInf,
      zipCodeValidate,
      researchZip
    };
  }
};
