<template>
    <div class="lec-header">
        <img class="logo" src="../../assets/logo.png" />
    </div>
    <div class="line" />
    <div class="lec-body">
        <div class="lec-title">講師登録申請フォーム</div>

        <ol class="stepBar step3">
            <li class="step barTextSize">講師登録申請フォーム</li>
            <li class="step barTextSize">入力内容確認</li>
            <li class="step barTextSize current">登録完了</li>
        </ol>

        <div class="finish-text">登録したメールアドレスに登録内容をお送りしておりますのでご確認ください。</div>
    </div>
</template>



<script>
import { useRouter } from 'vue-router'
import { inject, onMounted, ref, watch, onUnmounted} from 'vue'

    export default {
        name: 'lec_reg_show',
        setup() {
            const router = useRouter()

            onMounted(() => {
                if(JSON.parse(localStorage.getItem("sendMail")) == null) {
                   router.push({path:'/500'})
                }else {
                    localStorage.removeItem("sendMail")
                }
                
            })

            return {
            }
        },
    }
</script>

<style lang="scss" src="./css/common.scss" scoped />