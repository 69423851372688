import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/pop_tracommemt')
export default class PopTracommemt extends BaseService {
	index(from) {
		return this.request({
			data: from,
			url: "/index",
			method: "POST"
		});
	}
    create(from) {
		return this.request({
            data: from,
			url: "/create",
			method: "POST"
		});
	}
}