<template>
    <div :class="'rsk-form'+(isExpand?'--expand':'')">
            <select :class="clazz" v-model="modelValue" @change="change">
                <option :value="item.value" v-for="(item, index) in options" >{{item.label}}</option>
            </select>
        </div>
</template>
<script>
import { ref } from 'vue';
export default {
    props: {
        modelValue: {
            type: [String, Number],
            default: ""
        },
        isMin:{
            type: Boolean,
            default: false
        },
        isExpand:{
            type: Boolean,
            default: false
        },
        /**
         * {label, value}
         */
        options: {
            type: Array,
            default: []
        }
    },
    setup(props, { emit }) {
        function change(  ) {
            emit("update:modelValue", props.modelValue);
            emit("onChange", props.modelValue);
        }
        const clazz = ref('rsk-input rsk-input')
        if(props.isMin || props.isExpand) {
            if(props.isExpand) {
                clazz.value = 'rsk-input rsk-input--expand'
            }else {
                clazz.value = ''
            }
        }
        return {
            ...props,
            clazz,
            change,
        }
    },
}
</script>
