import { inject, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { getUsrMenu, login } from '../cont/login_cont'
import { dateFtt } from '@/utils/common'
import { setToken } from '../../../utils/CookieUtils'
import { useI18n } from 'vue-i18n'
import { UAParser } from "../../popenvironment/js/ua-parser.min";
import { cookieIsValid } from '../../../utils/CookieUtils.js'

const youshinErrMsg = "ログインに失敗しました。ログインＩＤとパスワードを確認してください。<br>※ＩＤ・パスワードを忘れた場合は必須項目を入力し、「新規登録」ボタンをクリックしてください。（登録したメールアドレスにＩＤ・ＰＷお知らせメールを配信します）"

export default {
  name: 'Login',
  setup () {
	const ua = new UAParser();
	let currentUrl=window.location.href
	let manualSelection=false
	manualSelection=currentUrl.includes('manualSelection=1')
	if(ua.getDevice().type === 'mobile'&&manualSelection!==true){
		var word = 'login'
		if( window.location.href.indexOf('jcclogin') !== -1){
			word = 'jcclogin';
		}
        var loginIndex = window.location.href.indexOf(word)
	    var prefix = window.location.href.substring(0,loginIndex)
        var suffix = window.location.href.substring(loginIndex+word.length,window.location.href.length)
		if(suffix == 'n'){
            suffix = '/'
        }
        window.location.href = prefix + 'smt/' + word + suffix
	}

  const service = inject("service");
  const store = useStore()
  const router = useRouter()
  const route = useRoute()
  const year = ref('');

  var userAgent = ua.getUA();
  let data = ref({ comId: '', userId: '', password: '', errorMsg: '', trainId: '', classFlg: '' })
  const loginChecked = ref(true)
  const cookieInvalidFlg = ref(false) 

  localStorageClear();

	onMounted(() => {
	var date = new Date();
	year.value = date.getFullYear();  
    if(route.params.message) {
      data.value.errorMsg = route.params.message
	}
	if (route.query.trainId || route.query.classFlg) {
      data.value.trainId = route.query.trainId;
      data.value.classFlg = route.query.classFlg;
    }
  })

  function handleLogin() {
    const formData = {
      comId: data.value.comId,
      userId: data.value.userId,
      password: data.value.password,
	  userAgent: userAgent,
	  fromflg:fromRismonFlag.value,
	  isjcc:isJcc.value,
	  locale:locale.value,
	  comIdNew: data.value.comId,
	  trainId: data.value.trainId,
	  classFlg: data.value.classFlg,
    }
    login(formData).then(res => {
		if(res.data == null){
			if (fromRismonFlag.value == '0' && res.message == youshinErrMsg) {
				router.push({
					name: 'YoshinNew',
					params: {
						message: res.message
					}
				})
			} else {
				data.value.errorMsg = res.message;
				loginChecked.value = false;
			}
			
		} else {
			if(res.data.token != null){
			data.value.errorMsg = null;

			setToken(res.data.token)

			getUsrMenu().then(async result => {
			localStorage.setItem('comNm', res.data.loginInfo.comNm)
			localStorage.setItem('logoutBtnFlag',res.data.loginInfo.logoutFlg)
			if (res.data.loginInfo.useFlag && res.data.loginInfo.useFlag === 1) {
				localStorage.useFlag = true
			} else {
				localStorage.useFlag = false
			}
			if (result.data.displayFlg1) {
				store.commit('changeDisplayFlg1')
				localStorage.displayFlg1 = true
			} else {
				localStorage.displayFlg1 = false
			}
			if (result.data.displayFlg2) {
				store.commit('changeDisplayFlg2')
				localStorage.displayFlg2 = true
			} else {
				localStorage.displayFlg2 = false
			}
			if (result.data.displayFlg5) {
				store.commit('changeDisplayFlg5')
				localStorage.displayFlg5 = true
			} else {
				localStorage.displayFlg5 = false
			}
			if (result.data.agencyId) {
				store.commit('changeAgencyId')
				localStorage.agencyId = true
			} else {
				localStorage.agencyId = false
			}
			await store.dispatch("permMenu", router)
			if('/usr_top' == res.data.targetUrl){
				localStorage.isLogin = true
				router.push({ path: '/usr_top' })
			}
			if('/elearning' == res.data.targetUrl){
				localStorage.isLogin = true
				router.push({ name: 'elearning'})
			}
			if('/assentusr' == res.data.targetUrl){
				localStorage.setItem('hennkouFlag',res.data.hennkouFlag);
				setloginToSession(res.data.loginInfo);
				router.push({ name: 'Assentusr'})
			}
			if('/assentmng' == res.data.targetUrl){
				setloginToSession(res.data.loginInfo);
				router.push({ name: 'Assentmng'})
			}
			if('/passaiedit' == res.data.targetUrl){
				setloginToSession(res.data.loginInfo);
				localStorage.setItem('displayPopFlag',JSON.stringify(res.data.displayPopFlag));
				router.push({ name: 'Passaiedit'})
			} else {
				localStorage.removeItem('displayPopFlag');
			}
			if('/popenvironment' == res.data.targetUrl){
				setloginToSession(res.data.loginInfo);
        		router.push({ name: 'Popenvironment',query:{popbtnShow:true}})
			}
			localStorage.setItem('getCopyRightFlg',res.data.getCopyRightFlg);
			localStorage.setItem('getComIdFlag',res.data.getComIdFlag);
			localStorage.setItem('traShowFlag',res.data.traShowFlag);
			localStorage.setItem('traSelfShowFlag',res.data.traSelfShowFlag);
			localStorage.setItem('isDispatchAgencyFlag',res.data.isDispatchAgencyFlag)
			localStorage.setItem('InfoFlg',res.data.InfoFlg)
			localStorage.setItem('logoUrl',res.data.logoUrl)
			}).catch(err => {
			return err
			})
			}
		}
		if(loginChecked.value){
			localStorage.setItem('comid',data.value.comId)
			localStorage.setItem('userid',data.value.userId)
			if (res.data.loginInfo.comIdNew) {
				localStorage.setItem('comidnew',res.data.loginInfo.comIdNew)
			}
		} else {
			localStorage.removeItem('comid')
			localStorage.removeItem('userid')
			localStorage.removeItem('comidnew')
		}
    }).catch(err => {
      return err
    })
  }

    const { locale } = useI18n({ useScope: 'global' })
	locale.value = cookieIsValid() ? localStorage.getItem('lang') : null

	var isJcc = ref(false);
	if( window.location.href.indexOf('jcc') !== -1){
		isJcc.value = true;
	}
	function init(){
		let list = []
		reqData.forEach(item => {
			let d = {}
			d = JSON.parse(JSON.stringify(item))
			if(locale.value == null){
				locale.value = 'ja'
			}
			d.dateYmd = dateFtt('auto', item.dateYmd, locale.value)
			list.push(d)
		});
		noticeList.value = list;

		if(localStorage.getItem('loginPageErrmsg')){
			data.value.errorMsg = localStorage.getItem('loginPageErrmsg');
		    localStorage.removeItem('loginPageErrmsg');
		  }
	}

	const downloadData = {filename:'',fileurl:'',userAgent:'',loginPage:''}
	function downloadFile(filename,fileurl){
		downloadData.filename = filename;
		downloadData.fileurl = fileurl;
		downloadData.userAgent = userAgent;
		downloadData.loginPage = "1";
		service.common.downloadSystemFile(downloadData)
	}

	function selectJa() {
		locale.value = 'ja'
		localStorage.setItem('lang', locale.value)
		init();
	}

	function selectEn() {
		locale.value = 'en'
		localStorage.setItem('lang', locale.value)
		init();
	}

	function toMobile() {
		// location.href = location.protocol+ '//' +location.hostname + ':' + location.port + '/smt/login'
		//router.push({ path: '/smt/login' })
		if (isJcc.value) {
			window.location = '/smt/jcclogin'
		} else {
			window.location = '/smt/login'
		}
	}

	function toPC() {
		// location.href = location.protocol+ '//' +location.hostname + ':' + location.port + '/login'
		//router.push({ path: '/login' })
		if (isJcc.value) {
			window.location = '/jcclogin'
		} else {
			window.location = '/login'
		}
	}

	function winopen(url){
		window.open(url);
	}

	const url = window.location.href;
	var start = 0;
	if(isJcc.value){
		start = url.indexOf('/jcclogin') + 10;
	} else {
		if(window.location.href.indexOf('/login') !== -1){
			start = url.indexOf('/login') + 7;
		} else {
			start = url.length;
		}
	}

	if (cookieIsValid()) {
		localStorage.setItem('isJcc',isJcc.value)
	}
	var urlComid = '';
	if (route.query.trainId || route.query.classFlg) { 
		localStorage.setItem('trainId', route.query.trainId);
		localStorage.setItem('classFlg', route.query.classFlg);
	} else {
		urlComid = route.params.comid;
	}

	function toAppStore() {
		window.open('https://apps.apple.com/jp/app/id1639044644')
	}

	function toGooglePlay() {
		window.open('https://play.google.com/store/apps/details?id=com.riskmonster.android.univapp')
	}

	function topasdeh(){
		if (urlComid) {
			localStorage.setItem('loginFlg','1');
			localStorage.setItem('comIdShowFlg','1');
			localStorage.setItem('comId',urlComid);
			router.push({ name: 'SpecialPasdeh'})
		} else {
			localStorage.setItem('loginFlg','1');
			router.push({ name: 'Pasdeh'})
		}
	}


	function addYoshinUsr(){
		router.push({ name: 'YoshinNew'})
	}

	function toEnv() {
		let route = router.resolve({ name: 'Popenvironment'})
		window.open(route.href, 'Popenvironment', 'top=50,left=400,width=1000,height=800')
		// window.open('https://testweb.cybaxuniv.com/admin/pop_environment!index','Popenvironment', 'top=50,left=400,width=1000,height=800')
		
	}

	function toZendesk() {
		window.open('https://cybaxuniv-support2user.zendesk.com/hc/ja')
	}

	function toUrl() {
		window.open('https://cybaxunivfaq.zendesk.com/hc/ja/articles/900003924866');
	}

	const fromRismonFlag = ref('1');
	const noticeList = ref({});
	  const formData = { urlComid: '', urlComidNew: '', trainId: '', classFlg: '' }
	const inetAddress = ref('');
	let reqData = []
	if (urlComid) {
		formData.urlComid = urlComid;
		formData.urlComidNew = urlComid;
	  }
	if (localStorage.getItem('trainId') || localStorage.getItem('classFlg')) {
		formData.trainId = localStorage.getItem('trainId');
		formData.classFlg = localStorage.getItem('classFlg');
	}
	formData.referer = document.referrer;

	service.login.loginInit(formData).then(res=>{
		if(localStorage.getItem('comid') && localStorage.getItem('userid')){
			data.value.comId =  localStorage.getItem('comid');
			data.value.userId = localStorage.getItem('userid');
		}
		if(res.loginInfo.comId){
			data.value.comId = res.loginInfo.comId;
		}
		if (data.value.comId == res.loginInfo.comIdNew) {
			data.value.comId = res.loginInfo.comIdNew
		}
		if(localStorage.getItem('fromRismonFlag')){
			fromRismonFlag.value = localStorage.getItem('fromRismonFlag');
		} else if(res.loginInfo.fromRismonFlag){
			fromRismonFlag.value = res.loginInfo.fromRismonFlag;
		}

		if(route.params.fromRismonFlag) {
			fromRismonFlag.value = route.params.fromRismonFlag
		}

		localStorage.setItem('fromRismonFlag',fromRismonFlag.value)
		reqData = res.noticeList;
		inetAddress.value = res.inetAddress;
		init();
	}).catch(error => {
		if (!cookieIsValid()) {
			data.value.errorMsg = 'Cookieが無効です。<br>'+
			'Cookieを有効にする方法はこちらをご覧ください。<br>' +
			'<a href="https://cybaxuniv-support2user.zendesk.com/hc/ja/articles/12565828981145" target="_blank">' +
			'https://cybaxuniv-support2user.zendesk.com/hc/ja/articles/12565828981145</a>'
			cookieInvalidFlg.value = true;
			
		} else {
			data.value.errorMsg = error.message;
			cookieInvalidFlg.value = false;
		}
		
	})

	function setloginToSession(loginInf) {
		var information = {
			comId: loginInf.comId,
			loginComId: loginInf.loginComId,
			usrId: loginInf.usrId,
			userProjectShowFlag: loginInf.userProjectShowFlag,
			loginAgencyId: loginInf.loginAgencyId,
			fromRismonFlag: loginInf.fromRismonFlag,
			pwdChgDiv: loginInf.pwdChgDiv,
			jccFlag: loginInf.jccFlag,
			loginId: loginInf.loginId
		}
		localStorage.setItem('login',JSON.stringify(information));
	}
	function localStorageClear() {
		if (cookieIsValid()) {
		localStorage.removeItem('notificationList')
		localStorage.removeItem('notifiId')
		localStorage.removeItem('notifiIndex')
		localStorage.removeItem('formCondition')
		localStorage.removeItem('tableCondition')
		localStorage.removeItem('usrMes')
		localStorage.removeItem('traCondition')
		localStorage.removeItem('hennkouFlag')
		localStorage.removeItem('login')
		localStorage.removeItem('traShowFlag')
		localStorage.removeItem('traSelfShowFlag')
		localStorage.removeItem('isDispatchAgencyFlag')
		localStorage.removeItem('InfoFlg')
		localStorage.removeItem('logoUrl')
		localStorage.removeItem('comId')
		localStorage.removeItem('lang')
		localStorage.removeItem('isJcc')
		localStorage.removeItem('loginFlg')
		localStorage.removeItem('comIdShowFlg')
		localStorage.removeItem('fromRismonFlag')
		localStorage.removeItem('trainId')
		localStorage.removeItem('classFlg')
		// localStorage.removeItem('loginPageErrmsg')
		localStorage.removeItem('assEntmngPagemsg')
		localStorage.removeItem('comidnew')
		localStorage.removeItem('comNm')
	}
	}
	
    return {
	  data,
	  fromRismonFlag,
	  isJcc,
	  noticeList,
	  inetAddress,
      selectJa, selectEn,
	  toMobile, toPC,
      loginChecked, handleLogin, userAgent,
	  winopen,
	  downloadFile,
	  topasdeh, addYoshinUsr, toEnv, toUrl, toZendesk, toGooglePlay, toAppStore,
	  cookieInvalidFlg,
	  locale,
	  year
    }
  },
}
