<template>
  <button @click="openWidget" class="rsk-button icon--question-answer-bg rsk-button--round rsk-button--round-large">
      <span class="rsk-button__icon-wrapper">
          <span class="rsk-icon rsk-icon--question-answer-dark"></span>
      </span>
  </button>
</template>

<script>
import { onMounted, ref } from "vue";
import { inject } from "vue";
import { useRouter } from 'vue-router';
import { UAParser } from "../../../views/popenvironment/js/ua-parser.min";
export default {
  name: "FooterHelp",
  setup() {
    const router = useRouter();
    const service = inject("service");

    const exts = document.createElement('script');
    exts.type = 'text/javascript';
    exts.id = 'ze-snippet'
    exts.src = 'https://static.zdassets.com/ekr/snippet.js?key=eaeeb030-513f-4edb-8f24-63b080e7239e';
    document.body.appendChild(exts);

    function openWidget(){
      zE('webWidget', 'show');zE('webWidget', 'open');
    }

    onMounted(() => {
      const meta = document.createElement("meta")
      meta.name = "viewport"
      meta.content = "width=device-width,initial-scale=1.0"
      const head = document.getElementsByTagName("head")[0]
      head.replaceChild(meta, head.getElementsByTagName("meta")[2])

      var close = false;
      function closeMsg(){
        if(close){
          clearInterval(interval)
        }
        if(document.querySelector('#launcher') != null && document.querySelector('#launcher').style.visibility != 'hidden'){
            zE('webWidget', 'hide');
            zE('webWidget:on', 'close', function(){zE('webWidget', 'hide');})
            document.querySelector('#launcher').style.visibility = 'hidden'
            close = true;
        }
      }
      var interval = setInterval(closeMsg, 200);
    });

    function openFaq(){
      window.open('https://cybaxuniv-support2user.zendesk.com/hc/ja');
    }

    function downloadAgree(){
      var ua = new UAParser();
      let userAgent = ua.getUA();
      const downloadData = {filename:'サイバックスUniv.会員規約.pdf',fileurl:'/pdf_file/サイバックスUniv.会員規約.pdf',userAgent:userAgent}
      service.common.downloadSystemFile(downloadData)
      }

    function downloadPerson(){
      var ua = new UAParser();
      let userAgent = ua.getUA();
      const downloadData = {filename:'個人情報の取扱い.pdf',fileurl:'/pdf_file/個人情報の取扱い.pdf',userAgent:userAgent}
      service.common.downloadSystemFile(downloadData)
      }

    return {
      openWidget,
      openFaq,downloadAgree,downloadPerson
    }
  }
}
</script>

<style scoped>
.icon--question-answer-bg{
  background-color: #fff;
}
</style>