import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/pro')
export default class UsrPro extends BaseService {
  get() {
		return this.request({
			url: "/get",
			method: "POST"
		});
	};
  update(from) {
		return this.request({
      data: from,
			url: "/update",
			method: "POST"
		});
	};
}
