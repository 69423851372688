<template>
  <section class="common-layout">
    <app-header />
    <app-main />
    <app-aside />
    <app-footer />
  </section>

</template>

<script>
import AppHeader from './AppHeader'
import AppMain from './AppMain'
import AppAside from './AppAside'
import AppFooter from './AppFooter'

export default {
  name: 'Layout',
  components: { AppHeader, AppMain, AppAside, AppFooter }
}
</script>

<style lang="scss" scoped>
.common-layout {
  //background-color: #E5E5E5;
  background-color: #F5F7FA;
}
.common-appMain {
}
</style>
