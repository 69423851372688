import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/popelgmov')
export default class PopElgmov extends BaseService {
	init(data) {
		return this.request({
			showError: false,
			data: data,
			url: "/init",
			method: "POST"
		});
	};
    update(from) {
		return this.request({
            data: from,
			url: "/update",
			method: "POST"
		});
	}
}