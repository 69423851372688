import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/common')
export default class Common extends BaseService {
	researchZip(zipCode) {
		const data = {
			zipCode: zipCode
		}
		return this.request({
			data: data,
			url: "/researchZip",
			method: "POST"
		});
	}
	downloadFile(data) {
		return this.request({
			showError: false,
			data: data,
			url: "/download",
			method: "POST",
			responseType: 'blob'
		});
	}
	downloadSystemFile(data) {
		return this.request({
			showError: false,
			data: data,
			url: "/downloadSystemFile",
			method: "POST",
			responseType: 'blob'
		});
	}
	typeObject() {
		return this.request({
			showError: false,
			url: "/typeObject",
			method: "POST",
		});
	}
	evaluationContent() {
		return this.request({
			showError: false,
			url: "/evaluationContent",
			method: "POST",
		});
	}
}