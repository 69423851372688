<template>
  <div class="content">
    <div class="rsk-px-xl rsk-pt-m">
      <div class="rsk-font-bold rsk-font-xl rsk-mb-s">{{$t("common.adminApplicationElearning")}}</div>
      <rsk-tabs :list="tabList" v-model="tabSelect" @onChange="resetPage"/>
      <e-list v-if="tabSelect == 0" :isFromInfo="isFromInfo"/>
      <p-list v-else-if="tabSelect == 1" :isFromInfo="isFromInfo"/>
      <o-list v-else-if="tabSelect == 2" :isFromInfo="isFromInfo"/>
    </div>
  </div>
</template>
<script>
import { ref, inject } from "vue";
import { useRouter, useRoute} from "vue-router";
import eList from "./view/e/list";
import pList from "./view/public/list";
import oList from "./view/oneself/list";
import { useI18n } from 'vue-i18n'
export default {
  components: {
      eList,
      pList,
      oList
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const tabSelect = ref(0);
    const isFromInfo = ref();

    function resetPage(tabSelect){
      //The value of tabSelect has changed, so set isFromInfo to 0.
      isFromInfo.value = "0";
    }

    if(route.params.isFromInfo != undefined ){
      isFromInfo.value = route.params.isFromInfo;
    }else{
      isFromInfo.value = "0";
    }

    if(route.params.tabSelect != undefined ){
        tabSelect.value = route.params.tabSelect;
    } else {
      tabSelect.value = 0;
    }
    const { t } = useI18n()
    const tabList = ref([
      { label: t("usrTrainingPages.adminTrainingElearning"), value: 0, show: true },
      { label: t("usrTrainingPages.adminTrainingWebSeminar"), value: 1, show: true },
      { label: t("usrTrainingPages.adminTrainingInternalTraining"), value: 2, show: true },
    ]);
     const service = inject("service");

     service.elearning.init().then((res) => {
       if(res.fromRismonFlag == 0){
          tabSelect.value = 1;
       }
       tabList.value[1].show = res.traShowFlag == 0;
       tabList.value[2].show = res.traSelfShowFlag == 0;
     });

    return {
      tabList,
      tabSelect,
      isFromInfo,
      resetPage
    };
  },
};
</script>