const fullKnaPattern = /^[^ -~｡-ﾟ]*$/;//判断全角
const phonePattern = /^[0-9]+-{0,1}[0-9]+-{0,1}[0-9]+$/;
const zipCodePattern = /^[0-9]{3}\-[0-9]{4}$/;
const mailAdrPattern = /^(.+)@(.+)$/;
const halfNum = /[0-9]+/;
const REGEX_SIGN_ALPHA_NUMERIC = /^[!-~\\ ]*$/
const threeTypeUsePattern1 = /^(?=.*[0-9])(?=.*[^0-9a-zA-Z])(?=.*[a-z])[!-~\\ ]+/
const threeTypeUsePattern2 = /^(?=.*[0-9])(?=.*[^0-9a-zA-Z])(?=.*[A-Z])[!-~\\ ]+/
const threeTypeUsePattern3 = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[!-~\\ ]+/
const threeTypeUnUsePattern = /^(?=.*[0-9])(?=.*[a-zA-Z])[!-~\\ ]+/
const threeTypeUnUse = 2
const threeTypeUse = 3

import { HalfToFull } from './common.js'

export function checkFullKnaNecessary(id,modelValue,message,service,max,modelName) {
    var flag = false;
    document.getElementById(id).style.borderColor = 'red';
    if (modelValue == null || modelValue.length <= 0) {
        message.value =modelName +  " を入力してください。";                       
    }else if (modelValue.length > max) {
        message.value =modelName + " は"+ max +"文字以内で入力してください。";                     
    }else if (!(fullKnaPattern.test(modelValue))) {
        modelValue = HalfToFull(modelValue);
        modelValue = modelValue.toZenkanaCase();
        flag = checkFullKnaNecessary(id,modelValue,message,service,max,modelName)[0];
        document.getElementById(id).style.borderColor = '#00aeed';
    }else {
        flag = true;
        message.value = "";
        document.getElementById(id).style.borderColor = '#00aeed';
    }
    return [flag, modelValue];  
}

export function checkFullKna(id,modelValue,message,service,max,modelName) {
    var flag = false;
    document.getElementById(id).style.borderColor = '#00aeed';
    if (modelValue == null || modelValue.length <= 0) {
        message.value = "";
        flag = true;                    
    }else if (modelValue.length > max) {
        message.value =modelName + " は"+ max +"文字以内で入力してください。";  
        document.getElementById(id).style.borderColor = 'red';                   
    }else if (!(fullKnaPattern.test(modelValue))) {
        modelValue = toFullKna(modelValue);
        flag = checkFullKna(id,modelValue,message,service,max,modelName);
    }else {
        message.value = "";
        const validateData = { invalidStr: modelValue,isUnicoe:true };
        flag = true;
    }
    return [flag, modelValue];  
}

export function checkPhone(id,modelValue,message,modelName) {
    var flag = false;
    document.getElementById(id).style.borderColor = 'red';
    if(modelValue == null || modelValue.length <= 0) {
        document.getElementById(id).style.borderColor = '#00aeed';
        message.value = "";
        flag = true;
    }else if (modelValue.length > 32) {
        message.value = modelName + " は32文字以内で入力してください。"; 
    }else if (!(phonePattern.test(modelValue))) {
        message.value = modelName + " は電話番号の形式(例 11-1111-1111)で入力してください。";
    }else {
        document.getElementById(id).style.borderColor = '#00aeed';
        message.value = "";
        flag = true;
    }
    return flag;
}

export function checkPhoneNecessary(id,modelValue,message,modelName) {
    var flag = false;
    document.getElementById(id).style.borderColor = 'red';
    if(modelValue == null || modelValue.length <= 0) {
        message.value = modelName +  " を入力してください。";
    }else if (modelValue.length > 32) {
        message.value = modelName + " は32文字以内で入力してください。"; 
    }else if (!(phonePattern.test(modelValue))) {
        message.value = modelName + " は電話番号の形式(例 11-1111-1111)で入力してください。";
    }else {
        document.getElementById(id).style.borderColor = '#00aeed';
        message.value = "";
        flag = true;
    }
    return flag;
}

export function checkZipCode(id,modelValue,message,modelName) {
    var flag = false;
    document.getElementById(id).style.borderColor = 'red';
    if (modelValue == null || modelValue.length <= 0) {
        message.value = modelName + " を入力してください。";
    }else if (modelValue.length > 32) {
        message.value = modelName + " は32文字以内で入力してください。";
    }else if (!(zipCodePattern.test(modelValue))) {
        if (halfNum.test(modelValue) && modelValue.length == 7) {
            modelValue = modelValue.substring(0, 3) + "-" + modelValue.substring(3);
        } else{
            message.value = modelName + " は郵便番号の形式(例 1111111)で入力してください。";
        }
    } else {
        document.getElementById(id).style.borderColor = '#00aeed';
        message.value = "";
        flag = true;
    }
    return [flag,modelValue];
}

export function checkMailAdr(id,modelValue,message,modelName) {
    var flag = false;
    document.getElementById(id).style.borderColor = 'red';
    if (modelValue == null || modelValue.length <= 0) {
        message.value = modelName + " を入力してください。";
    }else if (modelValue.length > 128) {
        message.value = modelName + " は128文字以内で入力してください。";
    }else if (!(mailAdrPattern.test(modelValue))) {
        message.value = modelName + " は Email の形式(例 XXXXXXX@XX.com)で入力してください。";
    }else {
        document.getElementById(id).style.borderColor = '#00aeed';
        message.value = "";
        flag = true;
    }
    return flag;
}

export function checkMailAdrConfirm(id,confirmValue,modelValue,message) {
    var flag = false;
    document.getElementById(id).style.borderColor = 'red';
    if (modelValue == null || modelValue.length <= 0) {
        message.value = "メールアドレス（確認）を入力してください。";
    }else if (modelValue.length > 128) {
        message.value = "メールアドレス（確認）は128文字以内で入力してください。";
    }else if (!(mailAdrPattern.test(modelValue))) {
        message.value = "メールアドレス（確認）はEmailの形式(例 XXXXXXX@XX.com)で入力してください。";
    }else if (modelValue != confirmValue) {
        message.value = "入力されたメールアドレス(確認用)が一致しません。";
    }else {
        document.getElementById(id).style.borderColor = '#00aeed';
        message.value = "";
        flag = true;
    }
    return flag;
}             

export function checkVerify(id,modelValue,message,identifyCode) {
    var flag = false;
    document.getElementById(id).style.borderColor = 'red';
    if (modelValue == null || modelValue.length <= 0) {
        message.value = "文字認証 を入力してください。";
    }else if (identifyCode.value != modelValue) {   // 验证码验证
        message.value = "入力された文字認証が一致しません。";
    }else {
        document.getElementById(id).style.borderColor = '#00aeed';
        message.value = "";
        flag = true;
    }
    return flag;
}

export function checkBox(modelValue,message,text1,text2) {
    var flag = false;
    if (modelValue == undefined || modelValue.length <= 0) {
        message.value = text1 + "の" + text2 + " を選択してください。";
    } else {
        message.value = "";
        flag = true;
    }
    return flag;
}

export function checkHalfNumEgNecessary(id,modelValue,message,service,max,modelName) {
    var flag = false;
    document.getElementById(id).style.borderColor = 'red';
    if (modelValue == null || modelValue.length <= 0) {
        message.value = modelName +  " を入力してください。";                       
    } else if (modelValue.length > max) {
        message.value = modelName + " は"+ max +"文字以内で入力してください。";                     
    } else if (!(halfNum.test(modelValue))) {
        message.value = modelName +  "は半角数字で入力してください";   
    } else {
        flag = true;
        document.getElementById(id).style.borderColor = '#00aeed';
        message.value = "";
    }
    return [flag, modelValue];  
}

/**
 * 半角のカタカナを全角のカタカナに変換します。
 * @example
 * "ｱｲｳｴｵ".toZenkanaCase(); // アイウエオ
 * @return {String}
 */
String.prototype.toZenkanaCase = function()
{
	var i, f, c, a = [], m = String.prototype.toZenkanaCase.MAPPING;

	for(i = 0, f = this.length;i < f; i++)
	{
		c = this.charCodeAt(i);
		a.push(m[c] || c);
	};

	return String.fromCharCode.apply(null, a);
};

String.prototype.toZenkanaCase.MAPPING =
{
		0xFF67:0x30A1,
		0xFF68:0x30A3,
		0xFF69:0x30A5,
		0xFF6A:0x30A7,
		0xFF6B:0x30A9,
		0xFF70:0x30FC,
		0xFF71:0x30A2,
		0xFF72:0x30A4,
		0xFF73:0x30A6,
		0xFF74:0x30A8,
		0xFF75:0x30AA,
		0xFF76:0x30AB,
		0xFF77:0x30AD,
		0xFF78:0x30AF,
		0xFF79:0x30B1,
		0xFF7A:0x30B3,
		0xFF7B:0x30B5,
		0xFF7C:0x30B7,
		0xFF7D:0x30B9,
		0xFF7E:0x30BB,
		0xFF7F:0x30BD,
		0xFF80:0x30BF,
		0xFF81:0x30C1,
		0xFF82:0x30C4,
		0xFF83:0x30C6,
		0xFF84:0x30C8,
		0xFF85:0x30CA,
		0xFF86:0x30CB,
		0xFF87:0x30CC,
		0xFF88:0x30CD,
		0xFF89:0x30CE,
		0xFF8A:0x30CF,
		0xFF8B:0x30D2,
		0xFF8C:0x30D5,
		0xFF8D:0x30D8,
		0xFF8E:0x30DB,
		0xFF8F:0x30DE,
		0xFF90:0x30DF,
		0xFF91:0x30E0,
		0xFF92:0x30E1,
		0xFF93:0x30E2,
		0xFF94:0x30E4,
		0xFF95:0x30E6,
		0xFF96:0x30E8,
		0xFF97:0x30E9,
		0xFF98:0x30EA,
		0xFF99:0x30EB,
		0xFF9A:0x30EC,
		0xFF9B:0x30ED,
		0xFF9C:0x30EF,
		0xFF9D:0x30F3,
		0xFF9E:0x309B,
		0xFF9F:0x309C,
		0xFF66:0x30F2
};

function toFullKna(str) {
    var result = "";
    var code = "";
    for (var i = 0; i < str.length; i++) {
        code = str.charCodeAt(i);
        if (code >= 33 && code <= 126) {
        result += String.fromCharCode(str.charCodeAt(i) + 65248);
        } else if (code == 32) {
        result += String.fromCharCode(str.charCodeAt(i) + 12288 - 32);
        } else {
        result += str.charAt(i);
        }
    }
    return result;
}

export function updatePwdCheck(value, morethenThreeTypeMonogram, minimumLength, ) {
    if (value) {
        if (value.length < minimumLength || value.length > 20) {
          return false;
        } else {
          if (REGEX_SIGN_ALPHA_NUMERIC.test(value)) {  
            if (morethenThreeTypeMonogram == threeTypeUnUse) {
                if (!(threeTypeUnUsePattern.test(value))) {
                    return false;
                }
            } else if (morethenThreeTypeMonogram == threeTypeUse) {
                if (!(threeTypeUsePattern1.test(value)) && !(threeTypeUsePattern2.test(value)) && !(threeTypeUsePattern3.test(value))) {
                    return false;
                }
            }
          } else {
            return false;
          }
        }
    }
    return true;
}

export function toFull(str) {
    if (!str) {
        return "";
    }
    str = HalfToFull(str);
	const chars = [...str];
	const result = chars.map((char) => {
        if (char in toHanKanaMap) {
            return toHanKanaMap[char];
        }
		return char;
	}).join('');
	return result;
}

export const toHanKanaMap = {
    'ｶﾞ':'ガ',
    'ｷﾞ':'ギ',
    'ｸﾞ':'グ',
    'ｹﾞ':'ゲ',
    'ｺﾞ':'ゴ',
    'ｻﾞ':'ザ',
    'ｼﾞ':'ジ',
    'ｽﾞ':'ズ',
    'ｾﾞ':'ゼ',
    'ｿﾞ':'ゾ',
    'ﾀﾞ':'ダ',
    'ﾁﾞ':'ヂ',
    'ﾂﾞ':'ヅ',
    'ﾃﾞ':'デ',
    'ﾄﾞ':'ド',
    'ﾊﾞ':'バ',
    'ﾋﾞ':'ビ',
    'ﾌﾞ':'ブ',
    'ﾍﾞ':'ベ',
    'ﾎﾞ':'ボ',
    'ﾊﾟ':'パ',
    'ﾋﾟ':'ピ',
    'ﾌﾟ':'プ',
    'ﾍﾟ':'ペ',
    'ﾎﾟ':'ポ',
    'ｳﾞ':'ヴ',
    'ｱ':'ア',
    'ｲ':'イ',
    'ｳ':'ウ',
    'ｴ':'エ',
    'ｵ':'オ',
    'ｶ':'カ',
    'ｷ':'キ',
    'ｸ':'ク',
    'ｹ':'ケ',
    'ｺ':'コ',
    'ｻ':'サ',
    'ｼ':'シ',
    'ｽ':'ス',
    'ｾ':'セ',
    'ｿ':'ソ',
    'ﾀ':'タ',
    'ﾁ':'チ',
    'ﾂ':'ツ',
    'ﾃ':'テ',
    'ﾄ':'ト',
    'ﾅ':'ナ',
    'ﾆ':'ニ',
    'ﾇ':'ヌ',
    'ﾈ':'ネ',
    'ﾉ':'ノ',
    'ﾊ':'ハ',
    'ﾋ':'ヒ',
    'ﾌ':'フ',
    'ﾍ':'ヘ',
    'ﾎ':'ホ',
    'ﾏ':'マ',
    'ﾐ':'ミ',
    'ﾑ':'ム',
    'ﾒ':'メ',
    'ﾓ':'モ',
    'ﾔ':'ヤ',
    'ﾕ':'ユ',
    'ﾖ':'ヨ',
    'ﾗ':'ラ',
    'ﾘ':'リ',
    'ﾙ':'ル',
    'ﾚ':'レ',
    'ﾛ':'ロ',
    'ﾜ':'ワ',
    'ｦ':'ヲ',
    'ﾝ':'ン',
    'ｧ':'ァ',
    'ｨ':'ィ',
    'ｩ':'ゥ',
    'ｪ':'ェ',
    'ｫ':'ォ',
    'ｬ':'ャ',
    'ｭ':'ュ',
    'ｮ':'ョ',
    'ｯ':'ッ',
    'ｰ':'ー',
};
