import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/exa')
export default class Exa extends BaseService{
    show(data) {
		return this.request({
            data: data,
			url: "/show",
			method: "POST"
		});
	}
}