import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/top')
class Top extends BaseService{
  getUsrTopInfo() {
		return this.request({
			url: "/get",
			method: "POST"
		});
	}
	getHotList(type) {
    const data = {'type': type}
		return this.request({
			data: data,
			url: "/getHotList",
			method: "POST"
		});
	}
}

export default Top;
