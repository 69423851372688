
import path from "path"
import { provide } from "vue";

/**
 * Auto Registration Apis
 * @param {APP} app 
 */
export default function(app){
    const files = require.context('@/apis/', true, /\.js$/)
    const ignore = [];
    let modules = {};

    files.keys().filter(e => !ignore.includes(e))
        .map(e => {
            let list = e.substr(2).split("/");
			let parents = list.slice(0, list.length - 1);
			let name = path.basename(e, ".js");

            let curr = modules;
			let prev = null;
			let key = null;

            parents.forEach(k => {
				if (!curr[k]) {
					curr[k] = {};
				}

				prev = curr;
				curr = curr[k];
				key = k;
			});

            let ep = files(e);

            if (ep.default) {
				let service = new ep.default();

				if (name == "index") {
					prev[key] = service;
				} else {
					curr[name] = service;
				}
			} else {
				console.error(`Service must export default [${e}]`);
			}
        })
		app.provide('service',modules)
}