<template>
  <body>
    <header>
      <div class="header" id="top">
        <p class="header-logo"><img src="./png/cx_univ_logo.png" alt="サイバックスUniv."></p>
        <div class="headline-board">
          <p class="header-headline">無料体験版お申込みフォーム</p>
        </div>
      </div>
    </header>

    <section>
      <div class="form-step">
        <ul class="form-step-list full-step input">
          <li class="form-step-item on">
            <span class="form-step-num">01</span>
            <p class="form-step-text">お客様情報<br>の入力</p>
          </li>
          <li class="form-step-item on">
            <span class="form-step-num">02</span>
            <p class="form-step-text">入力内容<br>の確認</p>
          </li>
          <li class="form-step-item">
            <span class="form-step-num">03</span>
            <p class="form-step-text">お申込み<br>完了</p>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div class="confirm-msg">
        <div class="inner">
          <div class="confirm-box">
            <div class="confirm-info-area">

              <dl class="form-confirm-set">
                <dt>貴社名</dt>
                <dd>
                  <p>{{model.comNm}}</p>
                </dd>
              </dl>
              <dl class="form-confirm-set">
                <dt>部署名</dt>
                <dd>
                  <p>{{model.appDepNmPost}}</p>
                </dd>
              </dl>
              <dl class="form-confirm-set">
                <dt>担当者名</dt>
                <dd>
                  <p>{{model.appUsrNm}}</p>
                </dd>
              </dl>
              <dl class="form-confirm-set">
                <dt>メールアドレス</dt>
                <dd>
                  <p>{{model.appUsrMlAdr}}</p>
                </dd>
              </dl>
              <dl class="form-confirm-set">
                <dt>電話番号</dt>
                <dd>
                  <p>{{model.appUsrTel}}</p>
                </dd>
              </dl>
              <dl class="form-confirm-set">
                <dt>代理店紹介</dt>
                <dd>
                  <p>{{model.causeWayContent}}</p>
                </dd>
              </dl>
              <dl class="form-confirm-set">
                <dt>お問い合わせ内容</dt>
                <dd>
                  <div class="form-item-box">
                    <p>{{model.cause}}</p>
                  </div>
                </dd>
              </dl>
              
            </div>

          <div class="confirm-btn-double">
          <div class="btn-back">
                <span><input type="submit"
                    @click="editNew"
                    id="backto"
                    name="backto"
                    value="修正する"
                ></span>
                </div>
                <div class="btn-complete">
                <span><input type="submit"
                    href="#"
                    @click="show"
                    id="opeComSend"
                    name="opeComSend"
                    value="送信する"
                ></span>
                </div>
            </div>

          </div><!-- end confirm-box -->
        </div><!-- end inner -->
      </div><!-- end confirm-msg -->
    </section>


    <footer>
      <div class="footer">
        <div class="inner">
          <p class="footer-headline">本件に関するお問い合わせ先</p>
          <dl class="footer-menu-box">
            <dt class="footer-menu-headline">カスタマーセンター</dt>
            <dd class="footer-menu-contents">
              <ul class="footer-menu-list">
                <li class="footer-menu-item"><b>フリーダイアル</b><span>0120-035-205</span></li>
                <li class="footer-menu-item"><b>携帯電話</b><span>03-5202-7121</span></li>
                <li class="footer-menu-item"><b>受付時間</b><span>平日10:00～16:00</span></li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </footer>
  </body>
</template>

<script>
export { default } from "./js/ope_com_show_view.js";
</script>

<style lang="scss" src="./css/contact.scss" scoped />