<template>
    <div class="rsk-header__notification rsk-mr-m">
        <div>
            <button @click="openNotification" class="rsk-button rsk-button--text-tertialy rsk-button--round rsk-button--medium cursor-pointer" @mouseenter="msenter" @mouseleave="mleave">
            <span class="rsk-icon rsk-icon--notification-gray"></span>
            </button>
            <div class="notice-tooltip rsk-tooltip--top" v-show="isShowTooltip">
                <span>Information</span>
            </div>
        </div>
        <span @click="openNotification" class="rsk-header__notification-num cursor-pointer" :style="{display: list.length > 0 ? '' : ' none'}">{{list.length>99?'99+':list.length}}</span>
        <div :class="'rsk-header__notification__menu ' + (show?'':'rsk-display-none')">

        <div class="rsk-notification-list--user">
            <div class="rsk-notification-item" v-for="(item, index) in list" :key="index">
              <div class="rsk-toggle-form rsk-p-xs" @click="toInformationsDetail(item,index)">
                  <div class="rsk-mb-xs rsk-ellipsis ">
                      <div class="rsk-justify-between rsk-flex">
                          <div class="rsk-graph-panel--m">
                              <span class="rsk-topic--unread rsk-mr-xs">{{item.date}}</span><span class="rsk-topic-icon--new" v-if="item.newFlag == 'New'" >NEW！</span>
                          </div>
                      </div>
                  </div>
                  <span class="rsk-topic--unread rsk-mr-xs">{{item.title}}</span>
              </div>
            </div>

            <div class="rsk-toggle-form rsk-ellipsis rsk-p-xs" v-if='list.length == 0'>
                <div class="rsk-mb-xs">
                    <div class="rsk-justify-between rsk-flex">
                        <div class="rsk-graph-panel--m">
                            <span class="rsk-text-gray060 rsk-mr-xs">登録されているお知らせはありません。</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="rsk-mask" @click="show = !show"></div></div>
      </div>
</template>
<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { dateFtt } from '@/utils/common'
export default {
    props: {
        title: '',
        /**
         * backtop | feedback
         */
        type:[],
        options:null
    },
    setup() {
        const router = useRouter()
        const store = useStore()
        const list = ref([])
        const service = inject("service");
        service.notification.getNotificationList().then(res=>{
            list.value.length = 0
            res.forEach(item => {
                list.value.push({
                    date: dateFtt('yyyy/MM/dd', item.iniEntDtm),
                    title: item.title,
                    id: item.informationId,
                    readFlag: item.readFlag,
                    newFlag: item.newFlag
                })
            });
            // store.commit('setNotificationList', list)
            localStorage.setItem('notificationList',JSON.stringify(list.value));
        })
        const show = ref(false)
        function openNotification(){
            show.value = !show.value

            if(show.value == true) {
                service.notification.getNotificationList().then(res=>{
                    list.value.length = 0
                    res.forEach(item => {
                        list.value.push({
                            date: dateFtt('yyyy/MM/dd', item.iniEntDtm),
                            title: item.title,
                            id: item.informationId,
                            readFlag: item.readFlag,
                            newFlag: item.newFlag
                        })
                    });
                    // store.commit('setNotificationList', list)
                    localStorage.setItem('notificationList',JSON.stringify(list.value));
                })
            }
        }
        function toInformationsDetail(item, index){
            show.value = !show.value;
            router.push({
                path: '/notification'
            })
            localStorage.setItem('notifiId',item.id);
            localStorage.setItem('notifiIndex',index);
        }

        const isShowTooltip = ref(false)
        function msenter() {
            isShowTooltip.value = true;
        }
        function mleave() {
            isShowTooltip.value = false;
        }
        return {
            toInformationsDetail,
            openNotification,
            show,
            list,
            isShowTooltip,
            msenter,
            mleave
        }
    },
}
</script>
<style scoped>
.rsk-mask {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}
.rsk-header__notification__menu {
    background-color: #fff;
    width: 450px;
    position: absolute;
    margin-top: 12px;
    left: 16px;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgb(36 135 251 / 16%);
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(48,53,59,0.16);
    z-index: 5;
}
.rsk-p-xs {
    padding: 8px;
}
.rsk-notification-item:hover {
    background-color:#EEF1F3
}
.rsk-notification-list--user {
    height: 345px;
    overflow-y: scroll;
}
.rsk-ellipsis{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.rsk-display-none {
    display: none !important;
}
.rsk-topic-icon--new {
  position: relative;
  display: inline-block;
  color: #f05365;
  border: solid 1px #f05365;
  padding: 2px 5px;
}
.rsk-topic--unread {
  color: #374a67;
  font-weight: bold;
}

.rsk-topic--read {
  color: #999999;
  font-weight: nomal;
}

.notice-tooltip {
    background-color: rgba(48, 53, 59, 0.9);
    color: #fff;
    border-radius: 4px;
    padding: 4px 8px;
    position: absolute;
    left:-24px;
    top:40px;
    z-index: 1031;
    font-size: 12px
}
</style>
