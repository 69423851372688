<template>
  <div class="s-canvas">
    <canvas id="s-canvas" :width="contentWidth" :height="contentHeight"></canvas>
  </div>
</template>
<script>
export default {
  name: "SIdentify",
  props: {
    isRefreshCode: {
      type: Boolean,
      default: false
    },
    identifyCodes: {
      type: Array
    },
    identifyLen: {
      type: Number,
      default: 6
    },
    fontSizeMin: {
      type: Number,
      default: 22
    },
    fontSizeMax: {
      type: Number,
      default: 22
    },
    backgroundColorMin: {
      type: Number,
      default: 180
    },
    backgroundColorMax: {
      type: Number,
      default: 240
    },
    colorMin: {
      type: Number,
      default: 50
    },
    colorMax: {
      type: Number,
      default: 160
    },
    contentWidth: {
      type: Number,
      default: 85
    },
    contentHeight: {
      type: Number,
      default: 30
    }
  },
  data() {
    return {
      nums: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "0"
      ],
      identifyText: ""
    };
  },
  methods: {
    // 乱数を生成
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // ランダムな色を生成
    randomColor(min, max) {
      let r = this.randomNum(min, max);
      let g = this.randomNum(min, max);
      let b = this.randomNum(min, max);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
    // 干渉点の描画
    drawDot(ctx) {
      for (let i = 0; i < 50; i++) {
        ctx.fillStyle = this.randomColor(0, 255);
        ctx.beginPath();
        ctx.arc(
          this.randomNum(0, this.contentWidth),
          this.randomNum(0, this.contentHeight),
          1,
          0,
          Math.PI
        );
        ctx.fill();
      }
    },
    // l長の認証コードを生成
    makeCode(o, l) {
      let identifyCodes = o;
      this.identifyText = "";
      for (let i = 0; i < l; i++) {
        this.identifyText +=
          identifyCodes[this.randomNum(0, identifyCodes.length)];
      }
    },
    // 認証コードテキスト
    drawText(ctx, txt, i) {
      ctx.fillStyle = this.randomColor(this.colorMin, this.colorMax);
      ctx.font = this.randomNum(this.fontSizeMin, this.fontSizeMax) + "px SimHei";
      let x = (i + 1) * (this.contentWidth / (this.identifyText.length + 1));
      let y = this.randomNum(this.fontSizeMax, this.contentHeight - 5);
      var deg = this.randomNum(0, 0);
      // 座標の原点と回転角度の変更
      ctx.translate(x, y);
      ctx.rotate(deg * Math.PI / 180);
      ctx.fillText(txt, 0, 0);
      // 座標の原点と回転角度の復元
      ctx.rotate(-deg * Math.PI / 180);
      ctx.translate(-x, -y);
    },
    // 認証コードパターン
    drawPic() {
      let identifyCodesArray = this.identifyCodes ? this.identifyCodes : this.nums;
      this.makeCode(identifyCodesArray, this.identifyLen);
      let identifyCode = "";
      let canvas = document.getElementById("s-canvas");
      let ctx = canvas.getContext("2d");
      ctx.textBaseline = "bottom";
      // 背景を描画
      ctx.fillStyle = this.randomColor(
        this.backgroundColorMin,
        this.backgroundColorMax
      );
      ctx.fillRect(0, 0, this.contentWidth, this.contentHeight);
      // テキストの描画
      for (let i = 0; i < this.identifyLen; i++) {
        this.drawText(ctx, this.identifyText[i], i);
        identifyCode += this.identifyText[i];
      }

      this.drawDot(ctx);
      // 親コンポーネントに値を渡す
      this.$emit("func", identifyCode);
    }
  },
  watch: {
    isRefreshCode(val) {
      if (val === true) {
        this.drawPic();
      }
    }
  },
  mounted() {
    this.drawPic();
  }
};
</script>
<style lang="scss" scoped>
.s-canvas {
  canvas {
    vertical-align: middle;
    cursor: pointer;
    border-radius: 3px;
  }
}
</style>
