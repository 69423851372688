import { Service, BaseService } from "@/core/auto";

@Service('/api/yoshinusr/')
export default class yoshinusr extends BaseService{
    create(from) {
		return this.request({
            showError: false,
            data: from,
			url: "/create",
			method: "POST"
		});
	}
}