import { useRouter } from "vue-router";
import { inject } from "vue";
import { ref, onMounted} from 'vue'
import { useI18n } from 'vue-i18n'
import { ElMessageBox, ElMessage } from 'element-plus'
import { updatePwdCheck } from '../../../utils/editnew.js'
export default {
    name: 'Passai',
    setup() {
        const router = useRouter();
        const service = inject("service");
        
        const login = JSON.parse(localStorage.getItem("login"));
        const updData = ref({newPsd: '',newPsdReinput:''})
        const model = ref();
        const annotationMsg = ref();
        const { locale, t } = useI18n({ useScope: 'global' })

        const rules = ref(
            {
                newPsd:[
                    { required: true, message: '新パスワードを入力してください。' },
                    {validator: (rule, value, callback) => {
                        if (!updatePwdCheck(value, model.value.morethenThreeTypeMonogram, model.value.minimumLength)) {
                            callback(new Error('※半角英数字を必ず含む（' + model.value.morethenThreeTypeMonogram + '種類以上の文字種で、記号も可）' 
                            + model.value.minimumLength + '桁以上20桁以内で設定してください。'))
                        }
                        if(value !== updData.value.newPsdReinput){
                            callback(new Error('入力されたパスワード(確認用)が一致しません。'))
                        } else {
                            callback()
                        }
                    }}
                ],
                newPsdReinput:[
                    { required: true, message: '新パスワード確認を入力してください。' },
                    {validator: (rule, value, callback) => {
                        if (!updatePwdCheck(value, model.value.morethenThreeTypeMonogram, model.value.minimumLength)) {
                            callback(new Error('※半角英数字を必ず含む（' + model.value.morethenThreeTypeMonogram + '種類以上の文字種で、記号も可）' 
                            + model.value.minimumLength + '桁以上20桁以内で設定してください。'))
                        }
                        if(value !== updData.value.newPsd){
                            callback(new Error('入力されたパスワード(確認用)が一致しません。'))
                        } else {
                            callback()
                        }
                    }}
                ]
            }
        )

        const formRef = ref()

        function confirmUpdate(){
            
            formRef.value.validate(updData.value).then((res) => {
                if (res) {
                    const data = {updData:updData.value,login}
                    service.passai.passai.update(data).then(res => {
                        localStorage.isLogin = true
                        if (login.fromRismonFlag == "0") {
                            router.push({ path: "/elearning" });
                        } else {
                            router.push({ path: '/usr_top' })
                        }
                    }).catch(error => {
                        ElMessage({
                            type:'error',
                            message: error.message,
                            showClose: true,
                            duration:3000
                        })
                    })
                }
            })
        }

        onMounted(() => {
            if (!login) {
                router.push({ path: '/login' });
                return;
            }
            service.passai.passai.init().then(res => {
                model.value = res.model;
                annotationMsg.value = '※半角英数字を必ず含む（' + model.value.morethenThreeTypeMonogram + '種類以上の文字種で、記号も可）' 
                + model.value.minimumLength + '桁以上20桁以内で設定してください。'
            }).catch(error => {
                console.log(error)
            })

            if (localStorage.getItem('displayPopFlag')) {
                if (localStorage.getItem('displayPopFlag') == 1) {
                    ElMessageBox({
                        cancelButtonClass: "btn-custom-cancel",
                        message: "パスワードの有効期限が切れました。パスワードを変更してください。",
                        showClose: true,
                        duration:3000
                    })
                }
            }
            
            
        })
        

        return {
            updData,
            rules, formRef,model,annotationMsg,
            confirmUpdate
          };
    }
}