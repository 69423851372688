<template>
<div class="rsk-flex rsk-justify-center rsk-container">
    <div class="rsk-pt-m rsk-pb-m">
        <div class="rsk-font-bold rsk-font-xl rsk-mb-s" style="color: #004F9A;font-size: 24px;"><img src="../../assets/pagetitle.gif" alt="."/>お問い合わせフォーム</div>
        <div class="rsk-mb-s">
            この内容で送信します。よろしいですか。
        </div>
        <div class="rsk-panel rsk-mb-xl">
            <ul class="table-like-list">
                <li class="table-like-list__item_new">
                    <div class="table-like-list__column">お問い合わせの種類</div>
                    <div class="table-like-list__column rsk-text-gray060">
                        <div v-for="(item, index) in formData.inquireType" :key="index">{{item}}</div>
                    </div>
                </li>
                <li class="table-like-list__item_new">
                    <div class="table-like-list__column">会社名</div>
                    <div class="table-like-list__column rsk-text-gray060">{{formData.comNm}}</div>
                </li>
                <li class="table-like-list__item_new">
                    <div class="table-like-list__column">会員ID</div>
                    <div class="table-like-list__column rsk-text-gray060">{{formData.comId}}</div>
                </li>
                <li class="table-like-list__item_new">
                    <div class="table-like-list__column">お名前</div>
                    <div class="table-like-list__column rsk-text-gray060">{{formData.usrNm}}</div>
                </li>
                <li class="table-like-list__item_new">
                    <div class="table-like-list__column">電話番号</div>
                    <div class="table-like-list__column rsk-text-gray060">{{formData.tel}}</div>
                </li>
                <li class="table-like-list__item_new">
                    <div class="table-like-list__column">メールアドレス</div>
                    <div class="table-like-list__column rsk-text-gray060">{{formData.mlAdr}}</div>
                </li>
                <li class="table-like-list__item_new">
                    <div class="table-like-list__column">添付ファイル</div>
                    <div class="table-like-list__column rsk-text-gray060">{{formData.fileName}}</div>
                </li>
                <li class="table-like-list__item_new">
                    <div class="table-like-list__column">対象の研修名</div>
                    <div class="table-like-list__column rsk-text-gray060">{{formData.trainName}}</div>
                </li>
                <li class="table-like-list__item_new" >
                    <div class="table-like-list__column">問い合わせ内容	</div>
                    <div class="table-like-list__column rsk-text-gray060">{{formData.content}}</div>
                </li>
            </ul>
        
            <div class="rsk-p-s">
                <button class="rsk-button rsk-button--text-user-primary rsk-mb-l">
                    <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                        <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                    </span>
                    <a href="#" @click="back">戻る</a>  
                </button> 
            </div>
            <div class="rsk-flex rsk-justify-center">
                <div class="rsk-form-button-wrapper__button">
                    <button @click="sendMail" class="rsk-button rsk-button--medium rsk-button--expand  rsk-button--user-primary">送信する</button>
                </div>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import { reactive, inject, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router'
export default {
    setup() {
        const formData = reactive({
            comId: '',
            comNm: '',
            usrNm: '',
            tel: '',
            mlAdr: '',
            trainName: '',
            inquireType: [],
            content: '',
            fileName: '',
        })
        

        const router = useRouter()
        const route = useRoute()
        const service = inject("service");

        onMounted(() => {
            if(null != route.params.model || undefined != route.params.model) {
                let params = JSON.parse(route.params.model)
                formData.comId = params.comId
                formData.comNm = params.comNm
                formData.usrNm = params.usrNm
                formData.tel = params.tel
                formData.mlAdr = params.mlAdr
                formData.trainName = params.trainName
                formData.inquireType = params.inquireType
                formData.content = params.content
                formData.fileName = params.fileName

            } else {
                router.push({ path: '/usrinquire_new'});
            }
        })

        function back() {
            router.push({name:'usrinquirenew', params: { model: JSON.stringify(formData) }})
        }

        function sendMail() {
            service.usrinquire.send().then(res => {
                window.open('/usrinquire_confirm', "_self");
            })
        }

        return {
            formData,
            back,
            sendMail
        }
    },
}
</script>

<style scoped>
.rsk-container {
    background: #f5f7fa;
}
.new-textarea {
    height: initial !important;
}
.table-like-list__item_new {
    height: 100%;
    min-height: 45px;
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 1px solid;
    word-break: break-all;
    border-color: rgba(48, 53, 59, 0.16)
} 
</style>