import { Service, BaseService } from "@/core/auto";

@Service('/api/assent/')
export default class assent extends BaseService{
    update(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/update",
			method: "POST"
		})
	}
}