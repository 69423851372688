<template>
    <div class="rsk-form-button-wrapper">
        <slot></slot>
    </div>
</template>
<script>
export default {

    setup() {
        return {}
    },
}
</script>