<template>
    <div class="rsk-round-meter rsk-mb-m">
        <div :style="'width: '+ count +'%'" class="rsk-round-meter__value">
            <slot name="text">
                {{count}}%
            </slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        count: ''
    },
    setup(props) {
        return {
            ...props
        }
    },
}
</script>