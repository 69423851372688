import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/ant')
export default class Anketo extends BaseService{
    search(data) {
		return this.request({
            data: {
				'condition' : data
			},
			url: "/search",
			method: "POST"
		});
	}
	getAnketoConfirm(id) {
		const data = {'id': id}
		return this.request({
			data: data,
			url: "/confirm",
			method: "POST"
		});
	}
	getAnketoAnswer(id) {
		const data = {'id': id}
		return this.request({
			data: data,
			url: "/answer",
			method: "POST"
		});
	}
	answerConfirm(data) {
		return this.request({
			data: data,
			url: "/answerConfirm",
			method: "POST"
		});
	}
}