<template>
<div class="ass-bac-div">
    <div class="main-wrap">
        <rsk-form labelWidth="220px" ref="formRef" :rules="rules">
            <div class="main-div">
                <img src="../../assets/pagetitle.gif" alt="." style="margin-left: 35%;"/>
                <h2 class="rsk-page-title">ユーザー情報</h2>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                <rsk-form-item isList hintText="必須" label="会社名" annotation="例）サイバックス株式会社" prop="comNm">
                    <rsk-input v-model="updData.comNm"/>
                </rsk-form-item>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                <rsk-form-item isList label="所在地" annotation="例）東京都中央区日本橋二丁目１６番５号 ＲＭＧビル ※都道府県必須" prop="comAdr">
                    <rsk-input v-model="updData.comAdr"/>
                </rsk-form-item>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                <rsk-form-item isList label="氏名" hintText="必須" annotation="例）千代太郎" prop="usrNm">
                    <rsk-input v-model="updData.usrNm"/>
                </rsk-form-item>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                <rsk-form-item isList label="フリガナ" annotation="例）チヨタロウ" prop="appUsrKna">
                    <rsk-input v-model="updData.appUsrKna"/>
                </rsk-form-item>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                <rsk-form-item isList label="部署" annotation="例）システム開発第一開発部" prop="appUserDepNm">
                    <rsk-input v-model="updData.appUserDepNm"/>
                </rsk-form-item>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                <rsk-form-item isList label="役職" annotation="例）課長" prop="appPost">
                    <rsk-input v-model="updData.appPost"/>
                </rsk-form-item>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                <rsk-form-item isList label="電話番号" hintText="必須" annotation="例）XX-XXXX-XXXX" prop="appUsrTel">
                    <rsk-input v-model="updData.appUsrTel"/>
                </rsk-form-item>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                <rsk-form-item isList label="Ｅｍａｉｌアドレス(1)" hintText="必須" annotation="例）XXXXXXX@XX.com" prop="usrMlAdr">
                    <rsk-input v-model="updData.usrMlAdr"/>
                </rsk-form-item>
                <rsk-form-submit>
                    <rsk-button isExpand type="primary" @click="usrConfirm">利用環境チェックページへ</rsk-button>
                </rsk-form-submit> 
            </div>                   
        </rsk-form>
    </div>
    
    <div class="footer-wrap">
    <rsk-login-footer />
    </div>
</div>
</template>
<script>
  export { default } from './js/ass_entusrNew_vue.js'
</script>

<style lang="scss" src="./css/ass_entusrNew.scss" />