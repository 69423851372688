<template>
    <div>
        <div>
            <template v-if="!dispaly37">
                <rsk-paging @pagingButtonClick="pagingButtonClick" v-if="total>0" @pageInfoChange="pageInfoChange" :total="total" :dispaly37="dispaly37" :pageinfo="pageInfo_table" direction="top" :pageBoxFlag="pageDisFlag"/>
            </template>
            <template v-else>
                <rsk-paging @pagingButtonClick="pagingButtonClick" v-if="total>0" :pagingButton="pagingButton" @pageInfoChange="pageInfoChange" :total="total" :dispaly37="dispaly37" :pageinfo="pageInfo_table" direction="top" :pageBoxFlag="pageDisFlag" />
            </template>

            <slot name="content"></slot>
            <div>
                <table class="rsk-table" id="rsk-table" border="0" cellspacing="0" cellpadding="0">
                    <thead class="rsk-table__thead">
                        <tr class="rsk-table__tr">
                            <th v-if="checkbox" class="rsk-table__th rsk-table__th--xxs">
                                <div class="rsk-table__th-content">
                                    <!-- <a @click="selectAllTableCheckBox" class="rsk-table__checkbox-controller rsk-id-class-parent"></a> -->
                                    <input class="rsk-checkbox rsk-table__checkbox rsk-id-class-parent" type="checkbox" style="margin-left:8px"
                                        @change="selectAllTableCheckBox"
                                        v-model="selectAllFlg"
                                        value="0"
                                        id="rsk-table-checkbox0">
                                    <label class="rsk-checkbox__image" for="rsk-table-checkbox0"></label>
                                </div>
                            </th>
                            <th v-for="(item, index) in tableTitle" :key="index" :class="'rsk-table__th rsk-table__th--' + (item.size ? item.size : 'expand')">
                                <div v-if="item.order && pageDisFlag" style="display:flex;justify-content: center;">
                                    <div @click="orderFieldDesc(index)" :class="'rsk-table__th-content rsk-table__th-content--select rsk-table__th-content--select-upside-down'"></div>
                                    <div :class="'rsk-table__th-content'">{{item.title}}</div>
                                    <div @click="orderFieldAsc(index)" :class="'rsk-table__th-content rsk-table__th-content--select'"></div>
                                </div>
                                <!-- <div @click="orderField(index)" v-if="item.order" :class="'rsk-table__th-content ' + 'rsk-table__th-content--select' ">{{item.title}}</div> -->
                                <div v-else-if="item.tooltip" style="display:flex;justify-content: center;gap: 8px">
                                    <div :class="'rsk-table__th-content'">{{item.title}}</div>
                                    <div class="rsk-form" style="padding: 4px;height: 32px">
                                      <span class="rsk-icon rsk-icon--question-gray">
                                      <label class="rsk-tooltip rsk-tooltip--content rsk-tooltip--bottom-start">
                                        <span v-html="item.tooltip" class="rsk-tooltip--font"></span>
                                      </label>
                                      </span>
                                    </div>
                                </div>
                                <div v-else-if="!item.icon" class="rsk-table__th-content">{{item.title}}</div>
                                <div v-else :class="'rsk-table__th-content '">
                                    {{item.title}}
                                    <el-popover placement="bottom" :width="item.icon.width?item.icon.width:'initial'" trigger="click">
                                        <template #reference>
                                            <i :class="item.icon.iconClass + ' cursor-pointer'"></i>
                                        </template>
                                        <div class="rsk-checkbox">
                                            <rsk-checkbox  :options="item.icon.list" @onChange="checkboxChange(item.icon,$event)"/>
                                        </div>
                                        <!-- <el-checkbox-group class="rsk-checkbox" v-if="item.icon.list" v-model="checkboxSelect[item.icon.iconClass + index]" @change="checkboxChange(item.icon,$event)">
                                            <el-checkbox v-for="(boxItem,boxIndex) in item.icon.list" :key="boxIndex" :label="boxItem.value" >{{boxItem.label}}</el-checkbox>
                                            <el-checkbox label="Option B" >2</el-checkbox>
                                        </el-checkbox-group> -->
                                    </el-popover>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="rsk-table__tbody">
                        <tr v-for="(item, index) in data" :key="index" :class="'rsk-table__tr ' +((item.disabled || item.oldFlg == 1)?'rsk-table__tr--disabled':'')">
                            <td class="rsk-table__td" v-if="checkbox">
                                <template v-if="item.oldFlg == 1">
                                    <div class="rsk-table__td-content">
                                    <input  class="rsk-checkbox rsk-table__checkbox rsk-id-class-parent" type="checkbox" readonly disabled="disabled">
                                    <label class="rsk-checkbox__image" :for="'rsk-table-checkbox0'+index"></label>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="rsk-table__td-content">
                                    <input class="rsk-checkbox rsk-table__checkbox rsk-id-class-parent" type="checkbox"
                                        @change="tableCheckBoxChange(index)"
                                        v-model="selectTableCheckBox"
                                        :value="index"
                                        :id="'rsk-table-checkbox0'+index">
                                    <label class="rsk-checkbox__image" :for="'rsk-table-checkbox0'+index"></label>
                                    </div>
                                </template>
                            </td>
                            <td v-for="(titleItem, tIndex) in tableTitle" :key="tIndex" :class="'rsk-table__td'">
                                <div :class="titleItem.title.match(RegExp(/名/))||titleItem.title=='お知らせタイトル'||titleItem.title=='研修' || titleItem.title=='タイトル' || titleItem.title=='マニュアルファイル' ? 'table__td-content' : 'rsk-table__td-content'" v-if="item[titleItem.field] != null && !(item[titleItem.field]  instanceof Array)">
                                    <el-button class="text-btn" v-if="item[titleItem.field].callBack" @click="item[titleItem.field].callBack(item[titleItem.field]);toTop()" type="text" style="height: auto">
                                        <div v-if="titleItem.BoldWord == true && haveTrainName != ''" v-html="toBoldWord(getFieldText(titleItem, item), haveTrainName)"></div>
                                        <div v-else>{{getFieldText(titleItem, item)}}</div>
                                    </el-button>
                                    <div v-else-if="titleItem.label && getFieldText(titleItem, item) != ''" :class="item.disabled?'rsk-label-enable':'rsk-label-disabled'">
                                        <div :class="item.priceApplication=='有料' ? 'back-red' : 'back-grey' ">{{getFieldText(titleItem, item)}}</div>
                                    </div>
                                    <div v-else-if="titleItem.changeEvaluationContent == true" class="rsk-table__td-content rsk-table__td-content-center" v-html="changeEvaluationContent(getFieldText(titleItem, item))"></div>
                                    <template v-else>{{getFieldText(titleItem, item)}}</template>
                                </div>
                                <div class="rsk-table__td-content" v-if="item[titleItem.field] != null && (item[titleItem.field]  instanceof Array)">
                                     <div>
                                        <div v-for="(arrItem, arrIndex) in item[titleItem.field]" :key="arrIndex">
                                            <el-button v-if="arrItem.callBack" @click="arrItem.callBack(arrItem)" type="text">{{arrItem.text}}</el-button>
                                            <template v-else>{{arrItem.text}}</template>
                                        </div>
                                     </div>
                                </div>
                            </td>
                            <td v-if="buttons && buttons.length > 0" class="rsk-table__td rsk-table__td-buttons-wrapper">
                                <div class="rsk-table__td-buttons">
                                <!-- <a class="rsk-table__link">　</a> -->
                                <div class="rsk-buttons">
				<template v-for="(buttonItem, bIndex) in buttons" :key="bIndex">
                                    <button v-if="buttonItem.show == null || buttonItem.show(item)"  @click="buttonItem.callBack(item)" :class="'rsk-button rsk-button--text-' + buttonItem.type">
                                        <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                                            <span :class="'rsk-icon rsk-icon--' + buttonItem.icon"></span>
                                        </span>
                                        {{ buttonItem.text }}
                                    </button>
                                </template>
                                </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div  v-if="total==0" style="padding:10px;">指定された条件に一致する情報は見つかりませんでした。</div>
                <div class="rsk-mb-l"></div>
            </div>
            <rsk-paging @pageInfoChange="pageInfoChange" v-if="total>0" :pageinfo="pageInfo_table" :total="total" :pageBoxFlag="pageDisFlag" />
        </div>
    </div>
</template>
<script>
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex'
import { getStoreCondition, putStoreCondition } from '@/utils/common'
export default {
    props: {
        checkbox:{
            type: Boolean,
            default: false
        },
        data:{
            type: Array,
            default: []
        },
        total: {
            type: Number,
            default: 0
        },
        disabledIndexs:{
            type: Array,
            default: []
        },
        pageinfo:{
            type: Object,
            default: {
                page: 1,
                size: 20
            }
        },
        /*
        {
            text: buttonName,
            callBack:function(){}
        }
        @pagingButtonClick
        */
        pagingButton: {
            type: Object,
            default: null
        },
        sizes: {
            type: Array,
            default: [20, 50, 100, 1000]
        },
        buttons:{
            type: Array,
            default: []
        },
        // table title
        titles:{
            type: Array,
            default: []
        },
        cacheKey:{
            type:String,
            default: null
        },
        dispaly37:{
            type:String,
            default: null
        },
        haveTrainName:{
            type:String,
            default: null
        },
        evaluationContentList:{
            type: Array,
            default: []
        },
        pageDisFlag:{
            type: Boolean,
            default: true
        },
    },
    setup(props, { emit }) {
        const store = useStore()

        const pageInfo_table = ref({...props.pageinfo, sizes:props.sizes})

        const trainId = localStorage.getItem('trainId')

        const classFlg = localStorage.getItem('classFlg')

        const fromRismonFlag = localStorage.getItem('fromRismonFlag')

        const tableTitle = ref([])
        tableTitle.value = props.titles

        if(props.cacheKey) {
            const s = getStoreCondition('tableCondition',props.cacheKey, store)
            if(s) {
                pageInfo_table.value = s.pageInfo_table
                tableTitle.value = s.tableTitle
            }
        }

        formattingTitle()

        watch(()=>props.titles,(old, newV)=>{
            selectTableCheckBox.value = []
            selectAllFlg.value = []
        })

        onMounted(() => {
            if(fromRismonFlag == '0'){
                for(let i = 0; i<props.data.length;i++) {
                    if(trainId == props.data[i].id) {
                        selectTableCheckBox.value.push(i)
                        localStorage.removeItem('trainId')
                    }
                }
            }
        })

        function formattingTitle() {
            let index = 0
            for(let i=0;i<tableTitle.value.length;i++) {
                if(tableTitle.value[i].order) {
                    tableTitle.value[i].column = index
                    if(tableTitle.value[i].order){
                        tableTitle.value[i].defaultOrder =  tableTitle.value[i].order;
                    }else{
                        tableTitle.value[i].defaultOrder =  '';
                    }
                    index++
                }
            }
        }
        function getOrderCss(order) {
            if( order == 'asc') {
                return 'rsk-table__th-content--select rsk-table__th-content--select-upside-down'
            } else if( order == 'desc') {
                return 'rsk-table__th-content--select'
            }else {
                return 'rsk-table__th-content--select rsk-table__th-content--select-upside-none'
            }
        }
        function getOrders() {
            let orders = {};
            for(let i = 0;i<tableTitle.value.length;i++){
                if(tableTitle.value[i].order && tableTitle.value[i].order != 'none'){
                    orders.column = tableTitle.value[i].column
                    orders.order = tableTitle.value[i].order == 'asc'? '2' :'1'
                    break
                }
            }
            if(props.cacheKey) {
                putStoreCondition('tableCondition', props.cacheKey, { tableTitle: tableTitle.value, pageInfo_table:pageInfo_table.value }, store)
            }
            return orders;
        }
        function toDefaultOrder(index) {
            for(let i = 0;i< tableTitle.value.length;i++) {
                if(index != i && tableTitle.value[i].order) {
                    tableTitle.value[i].order = 'none'
                }
            }
        }

        let columnAndOrder = {};
        // function orderField(index){
        //     toDefaultOrder(index)
        //     tableTitle.value[index].order = tableTitle.value[index].order == 'asc'? 'desc' : 'asc'
        //     // if (tableTitle.value[index].ascCoulmn != null && tableTitle.value[index].descCoulmn != null) {
        //     //     if (tableTitle.value[index].order == 'asc') {
        //     //         columnAndOrder["column"] = tableTitle.value[index].ascCoulmn
        //     //         columnAndOrder["order"] = 1
        //     //     } else {
        //     //         columnAndOrder["column"] = tableTitle.value[index].descCoulmn
        //     //         columnAndOrder["order"] = 2
        //     //     }
        //     // }

        //     emit('sortChange',getOrders(), pageCalculate(pageInfo_table.value.page, pageInfo_table.value.size))
        // }
        function orderFieldAsc(index){
            toDefaultOrder(index)
            tableTitle.value[index].order = tableTitle.value[index].order = 'asc'

            emit('sortChange',getOrders(), pageCalculate(pageInfo_table.value.page, pageInfo_table.value.size))
        }
        function orderFieldDesc(index){
            toDefaultOrder(index)
            tableTitle.value[index].order = tableTitle.value[index].order = 'desc'

            emit('sortChange',getOrders(), pageCalculate(pageInfo_table.value.page, pageInfo_table.value.size))
        }

        let pageInfo = ''
        function pageInfoChange(info) {
            selectTableCheckBox.value = []
            selectAllFlg.value = []
            pageInfo_table.value.page = info.page
                pageInfo_table.value.size = info.size

            let json = JSON.stringify(pageInfo_table.value)
            if( pageInfo != json){
                pageInfo = json
                emit('pageInfoChange',getOrders(), pageCalculate(info.page, info.size), columnAndOrder)

            }
            putStoreCondition('tableCondition', { tableTitle: tableTitle.value, pageInfo_table:pageInfo_table.value }, store)
        }
        function pageCalculate(page, size) {
            let offset = 0
            if(page !=1){
                offset = (page - 1)*size
            }
            return {offset, limit:size}
        }
        emit('init',getOrders(), pageCalculate(pageInfo_table.value.page, pageInfo_table.value.size))

        function getFieldText(titleItem, fieldItem) {
            let text = ''
            if(typeof(fieldItem[titleItem.field]) == 'object'){
                text = fieldItem[titleItem.field].text
            }else{
                text = fieldItem[titleItem.field]
            }

            if(text != null && text.toString() != '') {
                if(titleItem.options) {
                    for(let i = 0;i<titleItem.options.length;i++) {
                        if(titleItem.options[i].value == text) {
                            text = titleItem.options[i].label
                            break
                        }
                    }
                }
                if(titleItem.prefix){
                    text = titleItem.prefix + text
                }
                if(titleItem.postfix){
                    text = text + titleItem.postfix
                }
            }
            return text
        }


        function checkboxChange(icon,val) {
            icon.callBack(val)
        }
        const selectTableCheckBox = ref([])
        function tableCheckBoxChange(){
            if(props.data.length != 0) {
                if(props.data.length == selectTableCheckBox.value.length ) {
                    selectAllFlg.value = [0]
                } else {
                    selectAllFlg.value = []
                }
            }

            emit('tableCheckBoxChange',getTableCheckBoxList())
        }
        function pagingButtonClick() {
            if(props.pagingButton.callBack) {
                props.pagingButton.callBack(getTableCheckBoxList())
            }
        }
        const selectAllFlg = ref([])
        function selectAllTableCheckBox() {
            selectTableCheckBox.value = []
            if(selectAllFlg.value.length !=0 ) {
                for(let i = 0; i<props.data.length;i++) {
                    selectTableCheckBox.value.push(i)
                }
            }
        }
        function getTableCheckBoxList() {
            let list = []
            for(let i = 0;i<selectTableCheckBox.value.length;i++) {
                list.push(props.data[selectTableCheckBox.value[i]])
            }
            return list;
        }
        const clear = () => {
            if(props.cacheKey) {
                putStoreCondition('tableCondition', props.cacheKey, null, store)
            }
            for(let i = 0;i< tableTitle.value.length;i++) {
                if(tableTitle.value[i].order){
                    tableTitle.value[i].order = tableTitle.value[i].defaultOrder
                }
            }
            pageInfo_table.value.page = 1;
            pageInfo_table.value.size = 20;
            emit('sortChange',getOrders(), pageCalculate(pageInfo_table.value.page, pageInfo_table.value.size))
        }

        function toBoldWord (str, keyword) {
            const reg = new RegExp(keyword, 'ig')
            return str.replace(reg, (substring) => {
                return "<span style='font-weight:bold;'>" + substring + "</span>"
            })
        }


        function getLableEvaluationContent(value) {
            for(let i = 0;i<props.evaluationContentList.length;i++) {
                if(value == props.evaluationContentList[i].value) {
                    return props.evaluationContentList[i].label;
                }
            }
        }

        function changeEvaluationContent (str) {
            if(str == 1){
                return '<span class="rsk-icon rsk-icon--no1"><div class="rsk-table__td-content-tooltip"><div class="rsk-tooltip rsk-tooltip--bottom">'+getLableEvaluationContent(str)+'</div></div></span>'
            }else if(str == 2){
                return '<span class="rsk-icon rsk-icon--content10000"><div class="rsk-table__td-content-tooltip"><div class="rsk-tooltip rsk-tooltip--bottom">'+getLableEvaluationContent(str)+'</div></div></span>'
            }else if(str == 3){
                return '<span class="rsk-icon rsk-icon--hot"><div class="rsk-table__td-content-tooltip"><div class="rsk-tooltip rsk-tooltip--bottom">'+getLableEvaluationContent(str)+'</div></div></span>'
            }else if(str == 4){
                return '<span class="rsk-icon rsk-icon--content5000"><div class="rsk-table__td-content-tooltip"><div class="rsk-tooltip rsk-tooltip--bottom">'+getLableEvaluationContent(str)+'</div></div></span>'
            }else if(str == 5){
                return '<span class="rsk-icon rsk-icon--like"><div class="rsk-table__td-content-tooltip"><div class="rsk-tooltip rsk-tooltip--bottom">'+getLableEvaluationContent(str)+'</div></div></span>'
            }else if(str == 6){
                return '<span class="rsk-icon rsk-icon--good"><div class="rsk-table__td-content-tooltip"><div class="rsk-tooltip rsk-tooltip--bottom">'+getLableEvaluationContent(str)+'</div></div></span>'
            }else if(str == 7){
                return '<span class="rsk-icon rsk-icon--new"><div class="rsk-table__td-content-tooltip"><div class="rsk-tooltip rsk-tooltip--bottom">'+getLableEvaluationContent(str)+'</div></div></span>'
            }else{
                return '<span></span>'
            }
        }

        function toTop() {
            document.body.scrollTop ? document.body.scrollTop = 0 : document.documentElement.scrollTop = 0
        }


        watch(()=>props.data,(old, newV)=>{
            selectTableCheckBox.value = []
            selectAllFlg.value = []
        })

        const checkboxSelect = ref({})
        return {
            clear,
            getOrderCss,
            selectAllFlg,
            selectAllTableCheckBox,
            selectTableCheckBox,
            pagingButtonClick,
            tableCheckBoxChange,
            checkboxSelect,
            checkboxChange,
            getFieldText,
            pageInfoChange,
            tableTitle,
            orderFieldAsc,
            orderFieldDesc,
            pageInfo_table,
            toBoldWord,
            changeEvaluationContent,
            getLableEvaluationContent,
            toTop,
            ...props
        }
    },
}
</script>
<style>
.rsk-checkbox .rsk-checkbox__label {
    width: 100%;
}
</style>
<style scoped>
.rsk-checkbox {
    display: flex;
    flex-direction: column;
}

.table__td-content {
    display: flex;
    color: #30353B;
    height: 100%;
    min-width: 30px;
    align-items: center;
    overflow-wrap: break-word;
    word-break: break-all;
    word-wrap: break-word;
    position: relative;
    min-height: 36px;
    padding: 0 8px;
    font-size: 12px;
}

.rsk-icon--question-gray:hover .rsk-tooltip--content {
    display:inline-flex;
}

.rsk-tooltip--content {
    width: fit-content;
    position: absolute;
    display: none;
    z-index: 3;
    height: fit-content;
    bottom: 30px;
    left: -15px;
}
.rsk-tooltip--bottom-start::after{
    border-width: 5px 5px 0 5px;
    border-style: solid;
    border-color: rgba(48, 53, 59, 0.9) transparent transparent transparent;
    bottom: -5px;
    width: 0;
    height: 0;
    right: calc(100% - 32px);
    position: absolute;
    content: "";
}

.rsk-tooltip--font {
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    text-align: left;
}

</style>
