import { Service, BaseService } from "@/core/auto";

@Service('/api/pasdph/')
export default class pasdeh extends BaseService{
    send(data,t) {
		return this.request({
            showError: true,
            data: data,
			url: "/send",
			method: "POST",
			t
		})
	}
}