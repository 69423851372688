import { checkInvalid, validEmail } from '@/utils/validate'
import { setToken } from '../../../utils/CookieUtils'
import { ref, onMounted } from 'vue'
import { inject } from "vue";
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { halfNumEg2,full,phone } from '@/utils/validateUtils'

export default {
    name: 'YoshinusrNew',
    setup() {
        const router = useRouter()
        const route = useRoute()
        const service = inject("service");
        const year = ref(new Date().getFullYear())

        const rules = ref(
            {
                yoshinLoginID: [
                    { required: true, message: '与信管理サービスログインIDまたは与信管理サービス会員IDを入力してください。' },
                    {
                        max: 18,
                        message: '与信管理サービスログインIDまたは与信管理サービス会員IDは半角英数字10文字以上18文字以下で入力してください。'
                    },
                    {
                        min: 10,
                        message: '与信管理サービスログインIDまたは与信管理サービス会員IDは半角英数字10文字以上18文字以下で入力してください。'
                    },
                    halfNumEg2('与信管理サービスログインIDまたは与信管理サービス会員IDは半角英数字10文字以上18文字以下で入力してください。')
                ],
                comNm: [
                    { required: true, message: '会社名を入力してください。' },
                    {
                        max: 60,
                        message: '会社名 は 60 文字以内で入力してください。'
                    },
                    full('会社名は全角で入力してください。'),
                    {validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value,isUnicoe:true }
                        checkInvalid(validateData,service,callback,'会社名')
                    }}
                ],
                appUserDepNm: [
                    {
                        max: 30,
                        message: '部署名 は 30 文字以内で入力してください。'
                    },
                    full('部署名は全角で入力してください。'),
                    {validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value,isUnicoe:true }
                        checkInvalid(validateData,service,callback,'部署名')
                    }}
                ],
                usrNm: [
                    { required: true, message: '氏名を入力してください。' },
                    {
                        max: 30,
                        message: '氏名 は 30 文字以内で入力してください。'
                    },
                    full('氏名は全角で入力してください。'),
                    {validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value,isUnicoe:true }
                        checkInvalid(validateData,service,callback,'氏名')
                    }}
                ],
                usrTel: [
                    { required: true, message: '電話番号を入力してください。' },
                    phone('電話番号は電話番号の形式(例 11-1111-1111)で入力してください。')
                ],
                usrMlAdr: [
                    { required: true, message: 'Emailアドレスを入力してください。' },
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if(!(/^(.+)@(.+)$/.test(value))){
                                    callback(new Error('Emailアドレスは Email の形式(例 XXXXXXX@XX.com)で入力してください。'));
                                } else {
                                    const validateData = { invalidStr: value } 
                                    validEmail(validateData,callback,'RFC違反のメールアドレスの為、登録できません。')
                                }
                            } else {
                                callback(new Error('Emailアドレスを入力してください。'));
                            }
                        }
                    }
                ]
            }
        )
        const formRef = ref()

        const agreeChecked = ref(false)
        
        const usrData = ref({yoshinLoginID:'',comNm:'',appUserDepNm:'',usrNm:'',usrTel:'',usrMlAdr:''})

        onMounted(() => {
            if (route.params.message) {
                ElMessage({
                    type:'error',
                    dangerouslyUseHTMLString: true,
                    message: route.params.message,
                    showClose: true,
                    duration:10000
                }) 
            }
        })

        function addusr() {
            if (!agreeChecked.value) {
                alert("個人情報の取扱いについての同意する を選択してください。");
                return false;
            }
    
            formRef.value.validate(usrData.value).then((res) => {
                if (res) {
                    service.yoshinusr.yoshinusr.create(usrData.value).then(res => {
                         if(res.errmsg){
                            ElMessage({
                                type:'error',
                                message: res.errmsg,
                                showClose: true,
                                duration:10000
                            })
                        }                       
                        if(res.pageName && res.pagemsg){
                            if('userLogin' == res.pageName){
                                router.push({
                                    name: 'Login',
                                    params: {
                                        fromRismonFlag : '0'
                                    } 
                                })
                                localStorage.setItem('loginPageErrmsg',res.pagemsg)
                            }
                            if('assEntmng' == res.pageName) {
                                setToken(res.token)
                                localStorage.setItem('assEntmngPagemsg',res.pagemsg)
                                setloginToSession(res.sessionLoginVo);
                                router.push({ name: 'Assentmng'})
                            }
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        }

        function loginback(){     
            window.location = '/login'
        }

        function forgetPwd() {
            router.push({ 
                name: 'Pasdeh',
                params: {
                  fromRismonFlag : '0'
                } 
            })
        }

        function toLink() {
            window.open("https://www.riskmonster.co.jp/privacy/")
        }

        function setloginToSession(loginInf) {
            var information = {
                comId: loginInf.comId,
                loginComId: loginInf.loginComId,
                usrId: loginInf.usrId,
                userProjectShowFlag: loginInf.userProjectShowFlag,
                loginAgencyId: loginInf.loginAgencyId,
                fromRismonFlag: loginInf.fromRismonFlag,
                pwdChgDiv: loginInf.pwdChgDiv,
                jccFlag: loginInf.jccFlag,
                loginId: loginInf.loginId
            }
            localStorage.setItem('login',JSON.stringify(information));
        }
        return {
            rules, formRef,agreeChecked,
            usrData,
            addusr,loginback,forgetPwd,toLink,
            year
        };
    }
}