<template>
  <div>
    <div class="line">
      <div class="item"><h2>{{title}}</h2></div>
      <div class="item-right">
        <div class="label">分類・階層&nbsp;&nbsp;&nbsp;</div>
        <span class="value value-color">{{type}}</span>
      </div>
    </div>
    <div class="line">
      研修ID：{{id}}
      <div class="item" style="align-items: center">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>

export default {
    props:{
        id:'',
        type:'',
        title:'',

    },
    setup(props) {
        return {
            ...props
        }
    },
}
</script>
<style scoped>
.container {
    padding-bottom: 16px;
}
.line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.line h2 {
    font-size: 20px;
}
.line .item {
    display: flex;
    width: 450px;
}
.line .item-right {
    display: flex;
    width: 150px;
}
.line .item .value-color {
    color: initial;
}
.line .item .value {
    padding: 0 10px;
    width: 75%;
}
.item .label {
    width:25%;
}
</style>