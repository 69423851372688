import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/elgstudy')
export default class Elgstudy extends BaseService{
    search(data) {
		return this.request({
            data: data,
			url: "/search",
			method: "POST"
		});
	}
	cancel(data){
		return this.request({
            data: data,
			url: "/cancel",
			method: "POST"
		});		
	}
	getCompletionPdf(data) {
		return this.request({
            data: data,
			url: "/getCompletionPdf",
			method: "POST"
		});
	}
}