<template>
    <div class="rsk-form-button-wrapper__button">
        <button
            :class="'rsk-button rsk-button--medium ' + (isExpand?'rsk-button--expand':'') + ' rsk-button--'+type">
            <slot></slot>
            </button>
    </div>
</template>
<script>
import { ref } from 'vue';
export default {
    props: {
        type:{
            type: String,
            default: 'primary'
        },
        isExpand: {
            type: Boolean,
            default: false
        },
        text: String,
    },
    setup(props) {
        return {
            ...props,
        }
    },
}
</script>