<template>
  <div class="pas-bac-div">
    <div class="main-wrap">
    <rsk-form ref="formRef" :rules="rules">
      <div class="main-div">
        <img
          src="../../assets/pagetitle.gif"
          alt="."
          style="margin-left: 35%;margin-bottom: 10px;"
        />
        <div
          class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"
        ></div>
        <rsk-form-item
          isList
          label="新パスワード"
          :annotation=annotationMsg
          prop="newPsd"
        >
          <rsk-input type="password" v-model="updData.newPsd"/>
        </rsk-form-item>
        <div
          class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"
        ></div>
        <rsk-form-item
          isList
          label="新パスワード確認"
          :annotation=annotationMsg
          prop="newPsdReinput"
        >
          <rsk-input type="password" v-model="updData.newPsdReinput"/>
        </rsk-form-item>
        <rsk-form-submit>
          <rsk-button isExpand type="primary" @click="confirmUpdate">更新</rsk-button>
        </rsk-form-submit>
      </div>
    </rsk-form>
  </div>
  
    <div class="footer-wrap">
      <rsk-login-footer />
    </div>
  </div>
</template>
<script>
export { default } from "./js/pas_saiedit_view.js";
</script>

<style lang="scss" src="./css/pas_saiedit.scss" />
