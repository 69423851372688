import { Service, BaseService } from "@/core/auto";

@Service('/api/lecturer')
export default class lecturerREG extends BaseService{
    insert(data) {
		return this.request({
            data: data,
			url: "/insert",
			method: "POST"
		});
	}
	findMail(data) {
		return this.request({
            data: data,
			url: "/findMail",
			method: "POST"
		});
	}
}