<template>
    <div>
        <slot></slot>
    </div>
</template>
<script>
import { ref, provide, computed, toRefs, reactive } from 'vue';
import AsyncValidator from 'async-validator'
import { useStore } from 'vuex'
import { getStoreCondition, putStoreCondition } from '@/utils/common'
export default {
    props: {
        labelWidth: {
            type:String,
            default: '212px'
        },
        labelUsrProWidth: {
            type:String,
            default: '250px'
        },
        modelValue: {
            type: Object,
            default: {}
        },
        // model: Object,
        rules: Object,
        cacheKey: {
            type:String,
            default: null
        },
    },
    setup(props, { emit }) {
        const store = useStore()
        if(props.cacheKey) {
            const s = getStoreCondition('formCondition',props.cacheKey, store)
            if(s) {
                emit("update:modelValue", s);
            }
        }
        
        // let rules = ref(props.rules)
        // let model = ref(props.model)

        const fs = computed(()=>{
            let f = {}
            for(let key in props.rules){
                f[key] = {validate:true, msg:''}
            }
            return f
        })
        const fields = ref({})
        for(let key in props.rules){
            fields.value[key] = {validate:true, msg:''}
        }
        const clear = () => {
            if(props.cacheKey) {
                putStoreCondition('formCondition', props.cacheKey, null, store)
            }
        }
        // validate => https://element-plus.org/en-US/component/form.html#delete-or-add-form-items-dynamically
        // https://www.jb51.net/article/132533.htm
        const validate = (model) => {
            if(props.cacheKey) {
                putStoreCondition('formCondition', props.cacheKey, model, store)
            }
            

            if (!props.rules){
                console.error('rules is null')
                return;
            }
            const validator = new AsyncValidator(props.rules)
            return new Promise((resolve) => {
                validator.validate(model, (errors) => {
                    for(let key in fields.value){
                        fields.value[key] = {validate:true, msg:''}
                    }
                    if(errors && errors.length>0) {
                        for(let i = 0; i < errors.length;i++) {
                            if(fs.value[errors[i].field]) {
                                fields.value[errors[i].field] = {}
                                fields.value[errors[i].field].validate = false;
                                fields.value[errors[i].field].msg = errors[i].message;
                            }
                        }
                        resolve(false)
                    }else{
                        resolve(true)
                    }
                }).catch(e=>{
                    // console.error(e)
                })
            })
        }
        const rskForm = reactive({
            ...toRefs(props),
            validate,
            fields
        })
        provide('rskForm', rskForm)
        provide('rskForm-item-width', props.labelWidth)
        provide('resForm-item-usrPro-width', props.labelUsrProWidth)
        return {
            clear,
            validate,
            ...props,
        }
    },
}
</script>