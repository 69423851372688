<template>
    <div v-if="treaty == '01'" class="privacy-box">
      <div class="privacy-box-inner"><dt> 動画提供規約 </dt><br/>
        <p class="privacy-info-text text-weight">第１条（本規約の目的）</p>
        <p class="privacy-info-text">１．本規約は、リスクモンスター株式会社（以下「当社」という）が利用する研修動画を提供し、その利用を許諾する講師の、これに関する一切の行為に適用する。</p>
        <p class="privacy-info-text">２．本規約は、講師が、当社に対し、講師が著作権又は利用権を有するコンテンツ（以下「本件コンテンツ」といい、e ラーニングコンテンツ、テキスト等の教材を含むが、これに限られない。）を利用許諾するにあたっての条件を定めることを目的とする。</p>
        <p class="privacy-info-text">３．本件コンテンツに講師の肖像が含まれる場合、講師は本規約の目的及び本規約第３条の利用許諾の範囲内で講師の肖像を利用することについても予め同意するものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第２条（コンテンツ）</p>
        <p class="privacy-info-text">本規約に基づき、講師が当社に対して利用許諾する本件コンテンツの詳細は、注文書に従うものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第３条（利用許諾）</p>
        <p class="privacy-info-text">講師は、当社に対し、当社が人材開発・教育サービスの提供及び当該サービス提供のための販促活動に用いる目的（以下「本目的」という）で本件コンテンツの利用（複製、翻案、改変、販売、送信可能化・公衆送信、頒布、譲渡、貸与及び内容の変更を伴わない範囲の体裁の変更を含む技術的な対応を加えることを含む。以下同じ。）をすることを許諾する。 </p>
        <br>
        <p class="privacy-info-text text-weight">第４条（対価）</p>
        <p class="privacy-info-text">１．当社は、本規約に基づく講師への本件コンテンツ及び本件コンテンツに含まれる著作権等の知的財産権に関する利用許諾の対価（以下、あわせて「ロイヤリティ」という。）を講師に支払うものとする。対価の支払方法は注文書に従う。 </p>
        <p class="privacy-info-text">２．本規約第１条第３項に掲げる本件肖像権の利用許諾及び著作者人格権の不行使の対価が無償であることを確認する。 </p>
        <p class="privacy-info-text">３．第１項の定めに関わらず、当社は、次の各号の一に該当する本件コンテンツについては、講師へのロイヤリティの支払いを要しないものとする。 </p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）当社の指定する「サイバックス Univ.フリーランス動画投稿システム 動画制作ガイドライン」（以下、「本ガイドライン」という。）に準拠していない本件コンテンツ</li>
            <li class="privacy-info-item">（２）初期不良等により当社の顧客から申込キャンセルとなった本件コンテンツ</li>
            <li class="privacy-info-item">（３）デモンストレーション又は評価目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（４）バックアップ目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（５）保守または品質不良、瑕疵の補修目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（６）当社又は当社代理店の顧客に配信されることなくキャンセルとなった本件コンテンツ</li>
            <li class="privacy-info-item">（７）当社及び当社代理店の人材開発・教育サービスの提供のための販促活動として配信した本件コンテンツ</li>
        </ul>
        <br/>   
        <p class="privacy-info-text text-weight">第５条（納入及び検収）</p>
        <p class="privacy-info-text">１．講師は、講師が制作した本件コンテンツを、当社が指定する方法により、当社に納入するものとする。このために必要な費用は講師の負担とする。 </p>
        <p class="privacy-info-text">２．前項のほか、納入に必要な手順等については当社および講師の協議の上で本件コンテンツごとに決定するものとする。 </p>
        <p class="privacy-info-text">３．当社は、前二項に基づく納入があった場合、納入日から 60 日以内に本件コンテンツの検収を行うものとし、検収後、瑕疵が発見された場合には、講師に対し、その旨を通知するものとする。 </p>
        <p class="privacy-info-text">４．講師は、前項の当社からの通知があった場合、ただちに自己の負担と責任において補修を行うほか瑕疵による当社の損害を賠償するものとする。納入時にただちに発見できない瑕疵があり、納入日から1年以内かつ瑕疵の発見後60日以内に、当社が講師にその旨を通知した場合も同様とする。</p>
        <p class="privacy-info-text">５．納入後の経過時期にかかわらず、本件コンテンツに瑕疵（ただし軽微な瑕疵を除く）があり、講師がこれを補修しなかった場合は、当社は予告なく当該本件コンテンツの配信及び当該本件コンテンツに関するロイヤリティの支払いを終了することができる。</p>
        <p class="privacy-info-text">６．講師は本ガイドラインに違反した本件コンテンツを納品してはならない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第６条（コースの改訂・バージョンアップについて）</p>
        <p class="privacy-info-text">１．当社の責に帰すべき場合を除き、前条の納入方法の過誤、環境変化、その他の事情（本ガイドラインの不遵守も含む。）により、本件コンテンツの内容や規格・様式に改訂を加える必要があるときは、当社はその旨を理由とともに、講師に通知するものとし、講師は自らの費用と負担において必要な範囲で内容の改訂を行うものとする。当社からの通知があったにもかかわらず、必要な内容の改定その他の対応がとられない場合には、当社から講師に再度通知することにより当該コンテンツを本規約の対象外とし、当社の判断で当該コンテンツの不具合を修正して利用することができるものとする。</p>
        <p class="privacy-info-text">２．前項の通知により、本件コンテンツの全部又は一部が本規約の対象外となった場合は、当社は当該対象外となった本件コンテンツにかかるロイヤリティの変更について講師に協議を申し出ることができる。</p>
        <br/>
        <p class="privacy-info-text text-weight">第７条（保証）</p>
        <p class="privacy-info-text">１．講師は、本件コンテンツについて、法令に違反せず、また、第三者の著作権を含む一切の権利を侵害していないことを保証し、これに違反した場合には当社に生じた一切の損害（当社の第三者への支払いや当社が対応に要した弁護士費用や各種費用を含む）を賠償する責に任ずるものとする。</p>
        <p class="privacy-info-text">２．講師は、本件コンテンツについて第三者の権利侵害があった場合には直ちに当社に通知するとともに自らの負担と責任においてこれを解決するものとする。</p>
        <p class="privacy-info-text">３．講師は、当社及び当社が利用許諾した当該サービスの受講者その他当社が指定した者に対し、コンテンツに関する著作者人格権及び肖像権を行使しないこと及び第三者をして行使させないことを保証する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第８条（契約期間）</p>
        <p class="privacy-info-text">本規約は、締結のときから効力を生じ、相手方に対する解約の通知がない限り、本規約はその後１年間、同一の内容で更新されることとし、以後も同様とする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第９条（契約終了後の措置）</p>
        <p class="privacy-info-text">１．本規約の適用される当該講師に係る契約（以下「本件個別契約」という）が終了した場合には、当社は、当該本件個別契約に係る本件コンテンツを利用することはできないものとし、速やかに送信可能化措置等の解除及び当社が利用許諾した第三者に対する再許諾の効力を終了するものとする。</p>
        <p class="privacy-info-text">２．前項の定めに関わらず、本件個別契約終了時に当該本件個別契約に係る本件コンテンツを利用中の当社の顧客がまだいる場合など本件個別契約の終了が第三者に不利益を及ぼす場合には、契約終了後最大６か月の間、当社の請求により引き続き当該第三者のために本件個別契約の効力を存続することができるものとする。</p>
        <p class="privacy-info-text">３．本件個別契約終了後においても、講師に本規約に基づく債務の未履行や損害賠償等の責任があるときは、当該債務又は責任の消滅時点まで本規約の効力が存続するものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 10 条（秘密保持）</p>
        <p class="privacy-info-text">当社及び講師は、本規約を通して知り得た相手方の技術上、営業上その他の秘密を、本規約期間中のみならず本規約終了後も自己の役員及び従業員以外の第三者（当社のグループ会社及び法律上守秘義務を負う専門家を除く。）に洩らしてはならないものとし、双方の担当者にも同様の義務を負わせるものとする。ただし、次の各号に該当する情報についてはこの限りではない。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）開示の時点で既に公知のもの、又は開示後、情報を受領した当事者の責によらずして公知となったもの。</li>
            <li class="privacy-info-item">（２）当社又は講師が開示を行なった時点で、既に相手方が保有しているもの。</li>
            <li class="privacy-info-item">（３）第三者から秘密保持義務を負うことなく正当に入手したもの。</li>
            <li class="privacy-info-item">（４）相手方からの開示以降に開発されたもので、相手方からの情報によらないもの。</li>
            <li class="privacy-info-item">（５）監督官庁、裁判所、捜査機関より法令に基づき開示を要求されたもの。</li>
        </ul>
        <br/>
        <p class="privacy-info-text text-weight">第 11 条（権利義務の譲渡の禁止）</p>
        <p class="privacy-info-text">当社及び講師は、本規約によって生ずる権利もしくは義務の全部又は一部を事前に相手方の書面による承諾を得ること無しに第三者に譲渡もしくは承継させ、又は、第三者の担保に供してはならない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 12 条（契約解除）</p>
        <p class="privacy-info-text">１．当社は、講師が本規約に違反した時は、相当の期間を定めて講師に催告を行った後、本件個別契約の全部又は一部を解除できるものとし、かつ、当該違反により損害を被った場合は、講師はその賠償の責に任ずるものとする。</p>
        <p class="privacy-info-text">２．当社は、講師が次の各号の一に該当するときは、何らの事前の催告なく本件個別契約の全部又は一部を解除する事が出来るものとする。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）本規約上の重大な義務違反又は背信行為があったとき。</li>
            <li class="privacy-info-item">（２）手形又は小切手の不渡を出し、銀行取引停止処分を受けたとき。</li>
            <li class="privacy-info-item">（３）差押・仮差押・仮処分・競売・強制執行・延滞処分等、公権力の行使を受けたとき。</li>
            <li class="privacy-info-item">（４）破産・会社更生・特別清算又は民事再生その他の法的整理手続きの申立てをし、又はその申立てを受けたとき。</li>
            <li class="privacy-info-item">（５）その他前各号に準ずる事態が生じたとき。</li>
        </ul>
        <p class="privacy-info-text">３．本アライアンス契約が終了した時には、本件個別契約は当然に解除されることを確認する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 13 条（反社会的勢力の排除）</p>
        <p class="privacy-info-text">１．当社及び講師は、それぞれ相手方に対し、現在及び将来に渡って、次の各号の事項を確約する。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１） 自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成員（以下総称して「反社会的勢力」という）ではないこと。</li>
            <li class="privacy-info-item">（２）自らの役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者をいう）が 反社会的勢力ではないこと。</li>
            <li class="privacy-info-item">（３）反社会的勢力に自己の名義を利用させ、この契約を締結するものでないこと。</li>
            <li class="privacy-info-item">（４）本規約が終了するまでの間に、自ら又は第三者を利用して、この契約に関して次の行為をしないこと。</li>
        </ul>
        <p class="privacy-info-text">ア 相手方に対する脅迫的な言動又は暴力を用いる行為</p>
        <p class="privacy-info-text">イ 偽計又は威力を用いて相手方の業務を妨害し、又は信用を毀損する行為</p>
        <p class="privacy-info-text">２. 当社又は講師の一方について、次のいずれかに該当した場合には、その相手方は、何らの催告を要せずして、この契約及び当事者間の全ての契約を解除することができる。</p>
        <p class="privacy-info-text">ア 前項（１）又は（２）の確約に反する申告をしたことが判明した場合イ 前項（３）の確約に反し契約をしたことが判明した場合</p>
        <p class="privacy-info-text">ウ 前項（４）の確約に反した行為をした場合</p>
        <p class="privacy-info-text">３．前項の規定により本規約を解除した契約当事者は、解除により生じた相手方の損害を賠償する責を負わない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 14 条（規約の範囲及び変更）</p>
        <p class="privacy-info-text">１．当社は、講師の事前の承諾なく本規約を随時変更・改訂できるものとする。本規約が変更された場合、変更後の本件コンテンツを提供するにあたって適用される条件は、変更後の本規約に従うものとし、講師は、あらかじめこれを承諾するものとする。本規約の変更・改訂の結果、講師に不利益が生じた場合でも、当社は補償その他の義務を負わない。</p>
        <p class="privacy-info-text">２．当社は、前項の変更を行う場合は、変更後の本規約の効力発生日の前に相当な期間をもって、変更後の本規約の内容とかかる効力発生日を講師に通知又は、当社のサイトへ提示するものとする。ただし、変更が軽微で講師に特に不利益にならないと当社が判断した場合は通知をせず、当社サイトへの提示のみを行うものとする。</p>
        <p class="privacy-info-text">３．当社が講師に変更後の本規約の内容を通知又は、当社サイトへ提示し、変更後の本規約の効力発生日以降に講師が当社に本件コンテンツを提供した場合は、講師は本規約の変更に同意したものとみなす。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 15 条（存続規定）</p>
        <p class="privacy-info-text">本規約の終了に関わらず、本条、第９条、第10条、第16条の規定は、引き続きその効力を有する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 16 条（協議）</p>
        <p class="privacy-info-text">１．本規約に定めのない事項及び本規約の条項の解釈に疑義が生じた事項については、当社講師互いに信義、誠実の原則に従い協議決定するものとする。</p>
        <p class="privacy-info-text">２．本規約は日本法を準拠法とし、これにしたがって解釈されるものとする。本規約に関する一切の紛争は東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とする。</p>
        <br/>
      </div>
    </div>
    <div v-if="treaty == '02'" class="privacy-box">
      <div class="privacy-box-inner"><dt> 動画提供規約 </dt><br/>
        <p class="privacy-info-text text-weight">第１条（本規約の目的）</p>
        <p class="privacy-info-text">１．本規約は、リスクモンスター株式会社（以下「当社」という）が利用する研修動画を提供し、その利用を許諾する講師の、これに関する一切の行為に適用する。</p>
        <p class="privacy-info-text">２．本規約は、講師が、当社に対し、講師が著作権又は利用権を有するコンテンツ（以下「本件コンテンツ」といい、e ラーニングコンテンツ、テキスト等の教材を含むが、これに限られない。）を利用許諾するにあたっての条件を定めることを目的とする。</p>
        <p class="privacy-info-text">３．本件コンテンツに講師の肖像が含まれる場合、講師は本規約の目的及び本規約第３条の利用許諾の範囲内で講師の肖像を利用することについても予め同意するものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第２条（コンテンツ）</p>
        <p class="privacy-info-text">本規約に基づき、講師が当社に対して利用許諾する本件コンテンツの詳細は、注文書に従うものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第３条（利用許諾）</p>
        <p class="privacy-info-text">講師は、当社に対し、当社が人材開発・教育サービスの提供及び当該サービス提供のための販促活動に用いる目的（以下「本目的」という）で本件コンテンツの利用（複製、翻案、改変、販売、送信可能化・公衆送信、頒布、譲渡、貸与及び内容の変更を伴わない範囲の体裁の変更を含む技術的な対応を加えることを含む。以下同じ。）をすることを許諾する。 </p>
        <br>
        <p class="privacy-info-text text-weight">第４条（対価）</p>
        <p class="privacy-info-text">１．当社は、本規約に基づく講師への本件コンテンツ及び本件コンテンツに含まれる著作権等の知的財産権に関する利用許諾の対価（以下、あわせて「ロイヤリティ」という。）を講師に支払うものとする。対価の支払方法は注文書に従う。 </p>
        <p class="privacy-info-text">２．本規約第１条第３項に掲げる本件肖像権の利用許諾及び著作者人格権の不行使の対価が無償であることを確認する。 </p>
        <p class="privacy-info-text">３．第１項の定めに関わらず、当社は、次の各号の一に該当する本件コンテンツについては、講師へのロイヤリティの支払いを要しないものとする。 </p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）当社の指定する「サイバックス Univ.フリーランス動画投稿システム 動画制作ガイドライン」（以下、「本ガイドライン」という。）に準拠していない本件コンテンツ</li>
            <li class="privacy-info-item">（２）初期不良等により当社の顧客から申込キャンセルとなった本件コンテンツ</li>
            <li class="privacy-info-item">（３）デモンストレーション又は評価目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（４）バックアップ目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（５）保守または品質不良、瑕疵の補修目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（６）当社又は当社代理店の顧客に配信されることなくキャンセルとなった本件コンテンツ</li>
            <li class="privacy-info-item">（７）当社及び当社代理店の人材開発・教育サービスの提供のための販促活動として配信した本件コンテンツ</li>
        </ul>
        <br/>   
        <p class="privacy-info-text text-weight">第５条（納入及び検収）</p>
        <p class="privacy-info-text">１．講師は、講師が制作した本件コンテンツを、当社が指定する方法により、当社に納入するものとする。このために必要な費用は講師の負担とする。 </p>
        <p class="privacy-info-text">２．前項のほか、納入に必要な手順等については当社および講師の協議の上で本件コンテンツごとに決定するものとする。 </p>
        <p class="privacy-info-text">３．当社は、前二項に基づく納入があった場合、納入日から 60 日以内に本件コンテンツの検収を行うものとし、検収後、瑕疵が発見された場合には、講師に対し、その旨を通知するものとする。 </p>
        <p class="privacy-info-text">４．講師は、前項の当社からの通知があった場合、ただちに自己の負担と責任において補修を行うほか瑕疵による当社の損害を賠償するものとする。納入時にただちに発見できない瑕疵があり、納入日から1年以内かつ瑕疵の発見後60日以内に、当社が講師にその旨を通知した場合も同様とする。</p>
        <p class="privacy-info-text">５．納入後の経過時期にかかわらず、本件コンテンツに瑕疵（ただし軽微な瑕疵を除く）があり、講師がこれを補修しなかった場合は、当社は予告なく当該本件コンテンツの配信及び当該本件コンテンツに関するロイヤリティの支払いを終了することができる。</p>
        <p class="privacy-info-text">６．講師は本ガイドラインに違反した本件コンテンツを納品してはならない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第６条（コースの改訂・バージョンアップについて）</p>
        <p class="privacy-info-text">１．当社の責に帰すべき場合を除き、前条の納入方法の過誤、環境変化、その他の事情（本ガイドラインの不遵守も含む。）により、本件コンテンツの内容や規格・様式に改訂を加える必要があるときは、当社はその旨を理由とともに、講師に通知するものとし、講師は自らの費用と負担において必要な範囲で内容の改訂を行うものとする。当社からの通知があったにもかかわらず、必要な内容の改定その他の対応がとられない場合には、当社から講師に再度通知することにより当該コンテンツを本規約の対象外とし、当社の判断で当該コンテンツの不具合を修正して利用することができるものとする。</p>
        <p class="privacy-info-text">２．前項の通知により、本件コンテンツの全部又は一部が本規約の対象外となった場合は、当社は当該対象外となった本件コンテンツにかかるロイヤリティの変更について講師に協議を申し出ることができる。</p>
        <br/>
        <p class="privacy-info-text text-weight">第７条（保証）</p>
        <p class="privacy-info-text">１．講師は、本件コンテンツについて、法令に違反せず、また、第三者の著作権を含む一切の権利を侵害していないことを保証し、これに違反した場合には当社に生じた一切の損害（当社の第三者への支払いや当社が対応に要した弁護士費用や各種費用を含む）を賠償する責に任ずるものとする。</p>
        <p class="privacy-info-text">２．講師は、本件コンテンツについて第三者の権利侵害があった場合には直ちに当社に通知するとともに自らの負担と責任においてこれを解決するものとする。</p>
        <p class="privacy-info-text">３．講師は、当社及び当社が利用許諾した当該サービスの受講者その他当社が指定した者に対し、コンテンツに関する著作者人格権及び肖像権を行使しないこと及び第三者をして行使させないことを保証する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第８条（契約期間）</p>
        <p class="privacy-info-text">本規約は、締結のときから効力を生じ、相手方に対する解約の通知がない限り、本規約はその後１年間、同一の内容で更新されることとし、以後も同様とする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第９条（契約終了後の措置）</p>
        <p class="privacy-info-text">１．本規約の適用される当該講師に係る契約（以下「本件個別契約」という）が終了した場合には、当社は、当該本件個別契約に係る本件コンテンツを利用することはできないものとし、速やかに送信可能化措置等の解除及び当社が利用許諾した第三者に対する再許諾の効力を終了するものとする。</p>
        <p class="privacy-info-text">２．前項の定めに関わらず、本件個別契約終了時に当該本件個別契約に係る本件コンテンツを利用中の当社の顧客がまだいる場合など本件個別契約の終了が第三者に不利益を及ぼす場合には、契約終了後最大６か月の間、当社の請求により引き続き当該第三者のために本件個別契約の効力を存続することができるものとする。</p>
        <p class="privacy-info-text">３．本件個別契約終了後においても、講師に本規約に基づく債務の未履行や損害賠償等の責任があるときは、当該債務又は責任の消滅時点まで本規約の効力が存続するものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 10 条（秘密保持）</p>
        <p class="privacy-info-text">当社及び講師は、本規約を通して知り得た相手方の技術上、営業上その他の秘密を、本規約期間中のみならず本規約終了後も自己の役員及び従業員以外の第三者（当社のグループ会社及び法律上守秘義務を負う専門家を除く。）に洩らしてはならないものとし、双方の担当者にも同様の義務を負わせるものとする。ただし、次の各号に該当する情報についてはこの限りではない。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）開示の時点で既に公知のもの、又は開示後、情報を受領した当事者の責によらずして公知となったもの。</li>
            <li class="privacy-info-item">（２）当社又は講師が開示を行なった時点で、既に相手方が保有しているもの。</li>
            <li class="privacy-info-item">（３）第三者から秘密保持義務を負うことなく正当に入手したもの。</li>
            <li class="privacy-info-item">（４）相手方からの開示以降に開発されたもので、相手方からの情報によらないもの。</li>
            <li class="privacy-info-item">（５）監督官庁、裁判所、捜査機関より法令に基づき開示を要求されたもの。</li>
        </ul>
        <br/>
        <p class="privacy-info-text text-weight">第 11 条（権利義務の譲渡の禁止）</p>
        <p class="privacy-info-text">当社及び講師は、本規約によって生ずる権利もしくは義務の全部又は一部を事前に相手方の書面による承諾を得ること無しに第三者に譲渡もしくは承継させ、又は、第三者の担保に供してはならない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 12 条（契約解除）</p>
        <p class="privacy-info-text">１．当社は、講師が本規約に違反した時は、相当の期間を定めて講師に催告を行った後、本件個別契約の全部又は一部を解除できるものとし、かつ、当該違反により損害を被った場合は、講師はその賠償の責に任ずるものとする。</p>
        <p class="privacy-info-text">２．当社は、講師が次の各号の一に該当するときは、何らの事前の催告なく本件個別契約の全部又は一部を解除する事が出来るものとする。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）本規約上の重大な義務違反又は背信行為があったとき。</li>
            <li class="privacy-info-item">（２）手形又は小切手の不渡を出し、銀行取引停止処分を受けたとき。</li>
            <li class="privacy-info-item">（３）差押・仮差押・仮処分・競売・強制執行・延滞処分等、公権力の行使を受けたとき。</li>
            <li class="privacy-info-item">（４）破産・会社更生・特別清算又は民事再生その他の法的整理手続きの申立てをし、又はその申立てを受けたとき。</li>
            <li class="privacy-info-item">（５）その他前各号に準ずる事態が生じたとき。</li>
        </ul>
        <p class="privacy-info-text">３．本アライアンス契約が終了した時には、本件個別契約は当然に解除されることを確認する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 13 条（反社会的勢力の排除）</p>
        <p class="privacy-info-text">１．当社及び講師は、それぞれ相手方に対し、現在及び将来に渡って、次の各号の事項を確約する。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１） 自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成員（以下総称して「反社会的勢力」という）ではないこと。</li>
            <li class="privacy-info-item">（２）自らの役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者をいう）が 反社会的勢力ではないこと。</li>
            <li class="privacy-info-item">（３）反社会的勢力に自己の名義を利用させ、この契約を締結するものでないこと。</li>
            <li class="privacy-info-item">（４）本規約が終了するまでの間に、自ら又は第三者を利用して、この契約に関して次の行為をしないこと。</li>
        </ul>
        <p class="privacy-info-text">ア 相手方に対する脅迫的な言動又は暴力を用いる行為</p>
        <p class="privacy-info-text">イ 偽計又は威力を用いて相手方の業務を妨害し、又は信用を毀損する行為</p>
        <p class="privacy-info-text">２. 当社又は講師の一方について、次のいずれかに該当した場合には、その相手方は、何らの催告を要せずして、この契約及び当事者間の全ての契約を解除することができる。</p>
        <p class="privacy-info-text">ア 前項（１）又は（２）の確約に反する申告をしたことが判明した場合イ 前項（３）の確約に反し契約をしたことが判明した場合</p>
        <p class="privacy-info-text">ウ 前項（４）の確約に反した行為をした場合</p>
        <p class="privacy-info-text">３．前項の規定により本規約を解除した契約当事者は、解除により生じた相手方の損害を賠償する責を負わない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 14 条（規約の範囲及び変更）</p>
        <p class="privacy-info-text">１．当社は、講師の事前の承諾なく本規約を随時変更・改訂できるものとする。本規約が変更された場合、変更後の本件コンテンツを提供するにあたって適用される条件は、変更後の本規約に従うものとし、講師は、あらかじめこれを承諾するものとする。本規約の変更・改訂の結果、講師に不利益が生じた場合でも、当社は補償その他の義務を負わない。</p>
        <p class="privacy-info-text">２．当社は、前項の変更を行う場合は、変更後の本規約の効力発生日の前に相当な期間をもって、変更後の本規約の内容とかかる効力発生日を講師に通知又は、当社のサイトへ提示するものとする。ただし、変更が軽微で講師に特に不利益にならないと当社が判断した場合は通知をせず、当社サイトへの提示のみを行うものとする。</p>
        <p class="privacy-info-text">３．当社が講師に変更後の本規約の内容を通知又は、当社サイトへ提示し、変更後の本規約の効力発生日以降に講師が当社に本件コンテンツを提供した場合は、講師は本規約の変更に同意したものとみなす。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 15 条（存続規定）</p>
        <p class="privacy-info-text">本規約の終了に関わらず、本条、第９条、第10条、第16条の規定は、引き続きその効力を有する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 16 条（協議）</p>
        <p class="privacy-info-text">１．本規約に定めのない事項及び本規約の条項の解釈に疑義が生じた事項については、当社講師互いに信義、誠実の原則に従い協議決定するものとする。</p>
        <p class="privacy-info-text">２．本規約は日本法を準拠法とし、これにしたがって解釈されるものとする。本規約に関する一切の紛争は東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とする。</p>
        <br/>
      </div>
    </div>
    <div v-if="treaty == '03'" class="privacy-box">
      <div class="privacy-box-inner"><dt> 動画提供規約 </dt><br/>
        <p class="privacy-info-text text-weight">第１条（本規約の目的）</p>
        <p class="privacy-info-text">１．本規約は、リスクモンスター株式会社（以下「当社」という）が利用する研修動画を提供し、その利用を許諾する講師の、これに関する一切の行為に適用する。</p>
        <p class="privacy-info-text">２．本規約は、講師が、当社に対し、講師が著作権又は利用権を有するコンテンツ（以下「本件コンテンツ」といい、e ラーニングコンテンツ、テキスト等の教材を含むが、これに限られない。）を利用許諾するにあたっての条件を定めることを目的とする。</p>
        <p class="privacy-info-text">３．本件コンテンツに講師の肖像が含まれる場合、講師は本規約の目的及び本規約第３条の利用許諾の範囲内で講師の肖像を利用することについても予め同意するものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第２条（コンテンツ）</p>
        <p class="privacy-info-text">本規約に基づき、講師が当社に対して利用許諾する本件コンテンツの詳細は、注文書に従うものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第３条（利用許諾）</p>
        <p class="privacy-info-text">講師は、当社に対し、当社が人材開発・教育サービスの提供及び当該サービス提供のための販促活動に用いる目的（以下「本目的」という）で本件コンテンツの利用（複製、翻案、改変、販売、送信可能化・公衆送信、頒布、譲渡、貸与及び内容の変更を伴わない範囲の体裁の変更を含む技術的な対応を加えることを含む。以下同じ。）をすることを許諾する。 </p>
        <br>
        <p class="privacy-info-text text-weight">第４条（対価）</p>
        <p class="privacy-info-text">１．当社は、本規約に基づく講師への本件コンテンツ及び本件コンテンツに含まれる著作権等の知的財産権に関する利用許諾の対価（以下、あわせて「ロイヤリティ」という。）を講師に支払うものとする。対価の支払方法は注文書に従う。 </p>
        <p class="privacy-info-text">２．本規約第１条第３項に掲げる本件肖像権の利用許諾及び著作者人格権の不行使の対価が無償であることを確認する。 </p>
        <p class="privacy-info-text">３．第１項の定めに関わらず、当社は、次の各号の一に該当する本件コンテンツについては、講師へのロイヤリティの支払いを要しないものとする。 </p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）当社の指定する「サイバックス Univ.フリーランス動画投稿システム 動画制作ガイドライン」（以下、「本ガイドライン」という。）に準拠していない本件コンテンツ</li>
            <li class="privacy-info-item">（２）初期不良等により当社の顧客から申込キャンセルとなった本件コンテンツ</li>
            <li class="privacy-info-item">（３）デモンストレーション又は評価目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（４）バックアップ目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（５）保守または品質不良、瑕疵の補修目的で提供された本件コンテンツ</li>
            <li class="privacy-info-item">（６）当社又は当社代理店の顧客に配信されることなくキャンセルとなった本件コンテンツ</li>
            <li class="privacy-info-item">（７）当社及び当社代理店の人材開発・教育サービスの提供のための販促活動として配信した本件コンテンツ</li>
        </ul>
        <br/>   
        <p class="privacy-info-text text-weight">第５条（納入及び検収）</p>
        <p class="privacy-info-text">１．講師は、講師が制作した本件コンテンツを、当社が指定する方法により、当社に納入するものとする。このために必要な費用は講師の負担とする。 </p>
        <p class="privacy-info-text">２．前項のほか、納入に必要な手順等については当社および講師の協議の上で本件コンテンツごとに決定するものとする。 </p>
        <p class="privacy-info-text">３．当社は、前二項に基づく納入があった場合、納入日から 60 日以内に本件コンテンツの検収を行うものとし、検収後、瑕疵が発見された場合には、講師に対し、その旨を通知するものとする。 </p>
        <p class="privacy-info-text">４．講師は、前項の当社からの通知があった場合、ただちに自己の負担と責任において補修を行うほか瑕疵による当社の損害を賠償するものとする。納入時にただちに発見できない瑕疵があり、納入日から1年以内かつ瑕疵の発見後60日以内に、当社が講師にその旨を通知した場合も同様とする。</p>
        <p class="privacy-info-text">５．納入後の経過時期にかかわらず、本件コンテンツに瑕疵（ただし軽微な瑕疵を除く）があり、講師がこれを補修しなかった場合は、当社は予告なく当該本件コンテンツの配信及び当該本件コンテンツに関するロイヤリティの支払いを終了することができる。</p>
        <p class="privacy-info-text">６．講師は本ガイドラインに違反した本件コンテンツを納品してはならない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第６条（コースの改訂・バージョンアップについて）</p>
        <p class="privacy-info-text">１．当社の責に帰すべき場合を除き、前条の納入方法の過誤、環境変化、その他の事情（本ガイドラインの不遵守も含む。）により、本件コンテンツの内容や規格・様式に改訂を加える必要があるときは、当社はその旨を理由とともに、講師に通知するものとし、講師は自らの費用と負担において必要な範囲で内容の改訂を行うものとする。当社からの通知があったにもかかわらず、必要な内容の改定その他の対応がとられない場合には、当社から講師に再度通知することにより当該コンテンツを本規約の対象外とし、当社の判断で当該コンテンツの不具合を修正して利用することができるものとする。</p>
        <p class="privacy-info-text">２．前項の通知により、本件コンテンツの全部又は一部が本規約の対象外となった場合は、当社は当該対象外となった本件コンテンツにかかるロイヤリティの変更について講師に協議を申し出ることができる。</p>
        <br/>
        <p class="privacy-info-text text-weight">第７条（保証）</p>
        <p class="privacy-info-text">１．講師は、本件コンテンツについて、法令に違反せず、また、第三者の著作権を含む一切の権利を侵害していないことを保証し、これに違反した場合には当社に生じた一切の損害（当社の第三者への支払いや当社が対応に要した弁護士費用や各種費用を含む）を賠償する責に任ずるものとする。</p>
        <p class="privacy-info-text">２．講師は、本件コンテンツについて第三者の権利侵害があった場合には直ちに当社に通知するとともに自らの負担と責任においてこれを解決するものとする。</p>
        <p class="privacy-info-text">３．講師は、当社及び当社が利用許諾した当該サービスの受講者その他当社が指定した者に対し、コンテンツに関する著作者人格権及び肖像権を行使しないこと及び第三者をして行使させないことを保証する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第８条（契約期間）</p>
        <p class="privacy-info-text">本規約は、締結のときから効力を生じ、相手方に対する解約の通知がない限り、本規約はその後１年間、同一の内容で更新されることとし、以後も同様とする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第９条（契約終了後の措置）</p>
        <p class="privacy-info-text">１．本規約の適用される当該講師に係る契約（以下「本件個別契約」という）が終了した場合には、当社は、当該本件個別契約に係る本件コンテンツを利用することはできないものとし、速やかに送信可能化措置等の解除及び当社が利用許諾した第三者に対する再許諾の効力を終了するものとする。</p>
        <p class="privacy-info-text">２．前項の定めに関わらず、本件個別契約終了時に当該本件個別契約に係る本件コンテンツを利用中の当社の顧客がまだいる場合など本件個別契約の終了が第三者に不利益を及ぼす場合には、契約終了後最大６か月の間、当社の請求により引き続き当該第三者のために本件個別契約の効力を存続することができるものとする。</p>
        <p class="privacy-info-text">３．本件個別契約終了後においても、講師に本規約に基づく債務の未履行や損害賠償等の責任があるときは、当該債務又は責任の消滅時点まで本規約の効力が存続するものとする。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 10 条（秘密保持）</p>
        <p class="privacy-info-text">当社及び講師は、本規約を通して知り得た相手方の技術上、営業上その他の秘密を、本規約期間中のみならず本規約終了後も自己の役員及び従業員以外の第三者（当社のグループ会社及び法律上守秘義務を負う専門家を除く。）に洩らしてはならないものとし、双方の担当者にも同様の義務を負わせるものとする。ただし、次の各号に該当する情報についてはこの限りではない。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）開示の時点で既に公知のもの、又は開示後、情報を受領した当事者の責によらずして公知となったもの。</li>
            <li class="privacy-info-item">（２）当社又は講師が開示を行なった時点で、既に相手方が保有しているもの。</li>
            <li class="privacy-info-item">（３）第三者から秘密保持義務を負うことなく正当に入手したもの。</li>
            <li class="privacy-info-item">（４）相手方からの開示以降に開発されたもので、相手方からの情報によらないもの。</li>
            <li class="privacy-info-item">（５）監督官庁、裁判所、捜査機関より法令に基づき開示を要求されたもの。</li>
        </ul>
        <br/>
        <p class="privacy-info-text text-weight">第 11 条（権利義務の譲渡の禁止）</p>
        <p class="privacy-info-text">当社及び講師は、本規約によって生ずる権利もしくは義務の全部又は一部を事前に相手方の書面による承諾を得ること無しに第三者に譲渡もしくは承継させ、又は、第三者の担保に供してはならない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 12 条（契約解除）</p>
        <p class="privacy-info-text">１．当社は、講師が本規約に違反した時は、相当の期間を定めて講師に催告を行った後、本件個別契約の全部又は一部を解除できるものとし、かつ、当該違反により損害を被った場合は、講師はその賠償の責に任ずるものとする。</p>
        <p class="privacy-info-text">２．当社は、講師が次の各号の一に該当するときは、何らの事前の催告なく本件個別契約の全部又は一部を解除する事が出来るものとする。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１）本規約上の重大な義務違反又は背信行為があったとき。</li>
            <li class="privacy-info-item">（２）手形又は小切手の不渡を出し、銀行取引停止処分を受けたとき。</li>
            <li class="privacy-info-item">（３）差押・仮差押・仮処分・競売・強制執行・延滞処分等、公権力の行使を受けたとき。</li>
            <li class="privacy-info-item">（４）破産・会社更生・特別清算又は民事再生その他の法的整理手続きの申立てをし、又はその申立てを受けたとき。</li>
            <li class="privacy-info-item">（５）その他前各号に準ずる事態が生じたとき。</li>
        </ul>
        <p class="privacy-info-text">３．本アライアンス契約が終了した時には、本件個別契約は当然に解除されることを確認する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 13 条（反社会的勢力の排除）</p>
        <p class="privacy-info-text">１．当社及び講師は、それぞれ相手方に対し、現在及び将来に渡って、次の各号の事項を確約する。</p>
        <ul class="privacy-info-list text-padding-l">
            <li class="privacy-info-item">（１） 自らが、暴力団、暴力団関係企業、総会屋若しくはこれらに準ずる者又はその構成員（以下総称して「反社会的勢力」という）ではないこと。</li>
            <li class="privacy-info-item">（２）自らの役員（業務を執行する社員、取締役、執行役又はこれらに準ずる者をいう）が 反社会的勢力ではないこと。</li>
            <li class="privacy-info-item">（３）反社会的勢力に自己の名義を利用させ、この契約を締結するものでないこと。</li>
            <li class="privacy-info-item">（４）本規約が終了するまでの間に、自ら又は第三者を利用して、この契約に関して次の行為をしないこと。</li>
        </ul>
        <p class="privacy-info-text">ア 相手方に対する脅迫的な言動又は暴力を用いる行為</p>
        <p class="privacy-info-text">イ 偽計又は威力を用いて相手方の業務を妨害し、又は信用を毀損する行為</p>
        <p class="privacy-info-text">２. 当社又は講師の一方について、次のいずれかに該当した場合には、その相手方は、何らの催告を要せずして、この契約及び当事者間の全ての契約を解除することができる。</p>
        <p class="privacy-info-text">ア 前項（１）又は（２）の確約に反する申告をしたことが判明した場合イ 前項（３）の確約に反し契約をしたことが判明した場合</p>
        <p class="privacy-info-text">ウ 前項（４）の確約に反した行為をした場合</p>
        <p class="privacy-info-text">３．前項の規定により本規約を解除した契約当事者は、解除により生じた相手方の損害を賠償する責を負わない。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 14 条（規約の範囲及び変更）</p>
        <p class="privacy-info-text">１．当社は、講師の事前の承諾なく本規約を随時変更・改訂できるものとする。本規約が変更された場合、変更後の本件コンテンツを提供するにあたって適用される条件は、変更後の本規約に従うものとし、講師は、あらかじめこれを承諾するものとする。本規約の変更・改訂の結果、講師に不利益が生じた場合でも、当社は補償その他の義務を負わない。</p>
        <p class="privacy-info-text">２．当社は、前項の変更を行う場合は、変更後の本規約の効力発生日の前に相当な期間をもって、変更後の本規約の内容とかかる効力発生日を講師に通知又は、当社のサイトへ提示するものとする。ただし、変更が軽微で講師に特に不利益にならないと当社が判断した場合は通知をせず、当社サイトへの提示のみを行うものとする。</p>
        <p class="privacy-info-text">３．当社が講師に変更後の本規約の内容を通知又は、当社サイトへ提示し、変更後の本規約の効力発生日以降に講師が当社に本件コンテンツを提供した場合は、講師は本規約の変更に同意したものとみなす。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 15 条（存続規定）</p>
        <p class="privacy-info-text">本規約の終了に関わらず、本条、第９条、第10条、第16条の規定は、引き続きその効力を有する。</p>
        <br/>
        <p class="privacy-info-text text-weight">第 16 条（協議）</p>
        <p class="privacy-info-text">１．本規約に定めのない事項及び本規約の条項の解釈に疑義が生じた事項については、当社講師互いに信義、誠実の原則に従い協議決定するものとする。</p>
        <p class="privacy-info-text">２．本規約は日本法を準拠法とし、これにしたがって解釈されるものとする。本規約に関する一切の紛争は東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とする。</p>
        <br/>
      </div> 
    </div>
    
</template>

<script>
    export default {
        props: {
            treaty: {
                type: String,
            },
        }
    }
</script>

<style lang="scss" src="./css/common.scss" scoped />