<template>
  <div class="content">
    <div class="rsk-px-xl rsk-pt-m">
      <div class="rsk-font-bold rsk-font-xl rsk-mb-s">{{$t("usrTrainingPages.adminTrainingBox")}}</div>
      <lesList/>
    </div>
  </div>
</template>

<script>
import lesList from "../listen/les";
export default {
  components: {
      lesList
  },
  setup() {
    return {

    };
  },
};
</script>