<template>
    <div class="rsk-panel rsk-mb-m">
      <slot name="title">
          <div v-if="!titleR" class="rsk-flex rsk-items-center rsk-mb-s">
              <div class="rsk-text-gray100 rsk-font-bold rsk-font-l rsk-mr-m">{{title}}</div>
          </div>
          <div v-else class="rsk-flex rsk-items-center rsk-mb-s rsk-justify-between">
            <div class="rsk-text-gray100 rsk-font-bold rsk-font-l rsk-mr-m">{{title}}</div>
            <a @click="aClick(item)" class="rsk-flex rsk-text-primary">{{titleR}}</a>
          </div>
      </slot>
      
      <slot name="items">
        <div class="rsk-mb-xs rsk-flex" v-for="(item, index) in items" :key="index">
          <div class="rsk-text-gray060 rsk-width-112 rsk-mr-s">{{item.date}}</div>
          <a @click="aClick(item,index)" class="rsk-text-primary rsk-flex">{{item.text}}</a>
        </div>
      </slot>
    </div>
</template>
<script>
export default {
    props: {
        title: '',
        titleR: '',
        titleRUrl:'',
        type: '',
        /**
        {
            date，
            text，
            url
        }
         */
        items:[]
    },
    setup(props, { emit }) {
        function aClick(item, index){
          emit('select', item, index)
        }
        return {
          aClick,
            ...props
        }
    },
}
</script>
<style scoped>
.rsk-flex a{
  cursor: pointer;
}
</style>