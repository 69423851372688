import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/pas')
export default class UsrPro extends BaseService {
  get() {
		return this.request({
			showError: false,
      url: "/get",
			method: "POST"
		});
	};
  update(from) {
		return this.request({
      showError: false,
      data: from,
			url: "/update",
			method: "POST"
		});
	};
}
