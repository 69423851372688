<template>
    <div>
        <div :class="'rsk-snack-bar rsk-snack-bar--'+ type +' rsk-mb-l'">
      <div class="rsk-flex rsk-items-center">
        <span :class="'rsk-icon rsk-icon--'+ type +'-white rsk-mr-xs'"></span>
        {{message}}
      </div>
      <div class="rsk-flex rsk-items-center">
        <span class="rsk-icon rsk-icon--carret-right-white"></span>
      </div>
    </div>
    </div>
</template>
<script>
export default {
    props: {
        message: '',
        type: ''
    },
    setup(props) {
        return {
            ...props
        }
    },
}
</script>
