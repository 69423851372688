import { useRouter } from 'vue-router'
import { ref, inject } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { entPwd } from '@/utils/validateUtils'
import { useI18n } from 'vue-i18n'
import { updatePwdCheck } from '../../../utils/editnew.js'

export default {
  setup () {
    const model = ref({})
    const formRef  = ref({})
    const router = useRouter()
    const service = inject("service")
    const { locale, t } = useI18n({ useScope: 'global' })
    locale.value = localStorage.getItem('lang')
    const annotationMsg = ref()
    const pasPolicymodel = ref()
    const tabSelect = ref(1)
    const tabList = ref([
      { label: t('common.adminApplicationUsrPro'), value: 0, show: true },
      { label: t('common.adminApplicationPassword'), value: 1, show: true }
    ])
    const rules = ref(
      {
        oldPsd: [
          { required: true, 
            // message: '現在のパスワードを入力してください。'
            message: t('common.adminErrorsRequiredstring', [t('usrPasPages.adminPasEditOldPsd')])
          },
          { min: 8, max: 20, 
            // message: 'パスワードは 8 文字以上、 20 文字以内で英数字（記号も可）を含めて入力してください。' 
            message: t('common.adminErrorsPasswordNow')
          },
          // entPwd('パスワードは 8 文字以上、 20 文字以内で英数字（記号も可）を含めて入力してください。')
          entPwd(t('common.adminErrorsPasswordNow'))
        ],
        newPsd: [
          { required: true, 
            // message: '新パスワードを入力してください。' 
            message: t('common.adminErrorsRequiredstring', [t('usrPasPages.adminPasEditNewPsd')])
          },
          {validator: (rule, value, callback) => {
              if (!updatePwdCheck(value, pasPolicymodel.value.morethenThreeTypeMonogram, pasPolicymodel.value.minimumLength)) {
                callback(new Error(t('common.adminErrorsPassword', [pasPolicymodel.value.morethenThreeTypeMonogram, pasPolicymodel.value.minimumLength])))
              }

              if(value && model.value.newPsdReinput && value !== model.value.newPsdReinput){
                // callback(new Error('入力されたパスワード(確認用)が一致しません。'))
                callback(new Error(t('common.adminErrorsPasswordConfirm')))
              } else {
                callback()
              }
          }}
        ],
        newPsdReinput: [
          { required: true, 
            // message: '新パスワード確認を入力してください。' 
            message: t('common.adminErrorsRequiredstring', [t('usrPasPages.adminPasEditNewPsdReinput')])
          },
          {validator: (rule, value, callback) => {
              if (!updatePwdCheck(value, pasPolicymodel.value.morethenThreeTypeMonogram, pasPolicymodel.value.minimumLength)) {
                callback(new Error(t('common.adminErrorsPassword', [pasPolicymodel.value.morethenThreeTypeMonogram, pasPolicymodel.value.minimumLength])))
              }
              if(value && model.value.newPsd && value !== model.value.newPsd){
                // callback(new Error('入力されたパスワード(確認用)が一致しません。'))
                callback(new Error(t('common.adminErrorsPasswordConfirm')))
              } else {
                callback()
              }
          }}
        ]
      }
    )
    get()

    function get() {
      service.usrpas.get().then((res) => {
        model.value = {...res};
        pasPolicymodel.value = model.value.pasPolicyModel;
        annotationMsg.value = t('usrPasPages.adminPasEditNewPsdExpository', [pasPolicymodel.value.morethenThreeTypeMonogram, pasPolicymodel.value.minimumLength])
      }).catch((error) => {
        ElMessage({
          type:'error',
          message: error.message,
          showClose: true,
          duration:3000
        })
      })
    }
    function tabChange(index) {
      if(index == 0) {
        router.push({ name: 'usrpro'})
      } else if(index == 1) {
        router.push({ name: 'usrpas'})
      }
    }
    function update() {
      ElMessageBox.confirm(
          // '更新してもよろしいですか？',
          t('common.adminAskUpdate'),
          {
          cancelButtonClass: "btn-custom-cancel" ,
          confirmButtonText: 'OK',
          cancelButtonText: t('common.adminCancelButton'),  
          type: 'warning',
          }
      )
      .then(() => {
        formRef.value.validate(model.value).then((res) =>{ 
          if(res) {
            service.usrpas.update(model.value).then((res) => {
              get()
              ElMessage({
                type:'success',
                message: res,
                showClose: true,
                duration:3000
              })
            }).catch((error) => {
              ElMessage({
                type:'error',
                message: error.message,
                showClose: true,
                duration:3000
              })
            })
          }
        })
      })
      .catch(() => {

      })
    }

    function back(){
      router.push({path:'/usr_top'})
    }

    return {
      model,
      rules,
      formRef,
      tabSelect,
      tabList,
      annotationMsg,
      pasPolicymodel,
      tabChange,
      update,
      back
    }
  }
}
