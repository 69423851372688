<template>
    <div class="item" :style="'align-items: '+ align +';'">
        <div v-html="label" class="label"></div>
        <div class="value value-color" >
            <template v-if="value">
                <span >{{value}}</span>
                <div>{{describe}}</div>
            </template>
            <template v-else>
                <slot />
            </template>
            
        </div>
    </div>
</template>
<script>
export default {
    props:{
        align: {
            type: String,
            default: 'baseline'
        },
        label:'',
        value:'',
        describe:''
    },
    setup(props) {
        return {
            ...props
        }
    },
}
</script>

<style scoped>
.container {
    padding-bottom: 16px;
}
 h2 {
    font-size: 20px;
}
 .item {
    color: #666;
    display: flex;
    width: 450px;
}
.item .value-color {
    color: initial;
}
.item .value {
    padding: 0 10px;
    width: 75%;
}
.item .label {
    width:25%;
}
</style>