<template>
    <!-- <div class="container"></div> -->
    <div v-loading.fullscreen.lock=true class="cover"></div>
</template>

<script>
import { useRouter } from 'vue-router'
import { inject,ref } from 'vue'
import { useStore } from 'vuex'
import { UAParser } from "../popenvironment/js/ua-parser.min";
import { setToken } from '../../utils/CookieUtils'
import { getUsrMenu } from './cont/login_cont'

export default({
    setup() {

    const router = useRouter()
    const service = inject("service");
    const store = useStore();
    var ua = new UAParser();

    let formData = {
      dataStr: ''
    }

    setTimeout(() => {
        ssoLogin();
    }, 2000);

    formData.dataStr = router.currentRoute.value.query.params

    function ssoLogin(){
        service.login.ssoLogin(formData).then((res) => {
            if (res.mngtopFlag) {
              location.href = location.protocol + '//' + location.hostname + res.targetUrl
            }
            if(res.token != null){
            setToken(res.token)

            getUsrMenu().then(async result => {
            localStorage.setItem('getCopyRightFlg',res.getCopyRightFlg);
			localStorage.setItem('getComIdFlag',res.getComIdFlag);
            localStorage.setItem('traShowFlag',res.traShowFlag);
            localStorage.setItem('traSelfShowFlag',res.traSelfShowFlag);
            localStorage.setItem('isDispatchAgencyFlag',res.isDispatchAgencyFlag)
            localStorage.setItem('InfoFlg',res.InfoFlg)
            localStorage.setItem('logoUrl',res.logoUrl)
            localStorage.setItem('logoutBtnFlag',res.loginInfo.logoutFlg);
            localStorage.setItem('comNm', res.data.loginInfo.comNm)
            if (res.loginInfo.useFlag && res.loginInfo.useFlag === 1) {
                localStorage.useFlag = true;
            } else {
                localStorage.useFlag = false;
            }
            if (result.data.displayFlg1) {
                store.commit('changeDisplayFlg1')
                localStorage.displayFlg1 = true
            } else {
                localStorage.displayFlg1 = false
            }
            if (result.data.displayFlg2) {
                store.commit('changeDisplayFlg2')
                localStorage.displayFlg2 = true
            } else {
                localStorage.displayFlg2 = false
            }
            if (result.data.displayFlg3) {
                store.commit('changeDisplayFlg3')
                localStorage.displayFlg3 = true
            } else {
                localStorage.displayFlg3 = false
            }
            if (result.data.displayFlg4) {
                store.commit('changeDisplayFlg4')
                localStorage.displayFlg4 = true
            } else {
                localStorage.displayFlg4 = false
            }
            if (result.data.displayFlg5) {
                store.commit('changeDisplayFlg5')
                localStorage.displayFlg5 = true
            } else {
                localStorage.displayFlg5 = false
            }
            if (result.data.displayFlg6) {
                store.commit('changeDisplayFlg6')
                localStorage.displayFlg6 = true
            } else {
                localStorage.displayFlg6 = false
            }
            if (result.data.agencyId) {
                store.commit('changeAgencyId')
                localStorage.agencyId = true
            } else {
                localStorage.agencyId = false
            }
            await store.dispatch("permMenu", router)
            
            if('/usr_top' == res.targetUrl){
              localStorage.isLogin = true
                router.push({ path: '/usr_top' })
            }
            if('/elearning' == res.targetUrl){
              localStorage.isLogin = true
                router.push({ name: 'elearning'})
            }
            if('/assentusr' == res.targetUrl){
                localStorage.setItem('hennkouFlag',res.hennkouFlag);
                setloginToSession(res.loginInfo);
                router.push({ name: 'Assentusr'})
            }
            if('/assentmng' == res.targetUrl){
                setloginToSession(res.loginInfo);
                router.push({ name: 'Assentmng'})
            }
            if('/passaiedit' == res.targetUrl){
                setloginToSession(res.loginInfo);
                router.push({ name: 'Passaiedit'})
            }
            if('/popenvironment' == res.targetUrl){
                setloginToSession(res.loginInfo);
                router.push({ name: 'Popenvironment',query:{popbtnShow:true}})
            }
            
            }).catch(err => {
            return err
            })
            }
        }).catch((error) => {
          if(error.code == 500) {
            router.push({name: 'Login', params:{message: error.message}})
            return
          }
        });
    }

    // onMounted(() => {
    //     service.login.ssoLogin(formData).then((res) => {
    //         if(res.token != null){
	// 		setToken(res.token)

	// 		getUsrMenu().then(async result => {
	// 		if (result.data.useFlag) {
	// 			localStorage.useFlag = true
	// 		} else {
	// 			localStorage.useFlag = false
	// 		}
	// 		if (result.data.displayFlg1) {
	// 			store.commit('changeDisplayFlg1')
	// 			localStorage.displayFlg1 = true
	// 		} else {
	// 			localStorage.displayFlg1 = false
	// 		}
	// 		if (result.data.displayFlg2) {
	// 			store.commit('changeDisplayFlg2')
	// 			localStorage.displayFlg2 = true
	// 		} else {
	// 			localStorage.displayFlg2 = false
	// 		}
	// 		if (result.data.displayFlg3) {
	// 			store.commit('changeDisplayFlg3')
	// 			localStorage.displayFlg3 = true
	// 		} else {
	// 			localStorage.displayFlg3 = false
	// 		}
	// 		if (result.data.displayFlg4) {
	// 			store.commit('changeDisplayFlg4')
	// 			localStorage.displayFlg4 = true
	// 		} else {
	// 			localStorage.displayFlg4 = false
	// 		}
	// 		if (result.data.displayFlg5) {
	// 			store.commit('changeDisplayFlg5')
	// 			localStorage.displayFlg5 = true
	// 		} else {
	// 			localStorage.displayFlg5 = false
	// 		}
	// 		if (result.data.displayFlg6) {
	// 			store.commit('changeDisplayFlg6')
	// 			localStorage.displayFlg6 = true
	// 		} else {
	// 			localStorage.displayFlg6 = false
	// 		}
	// 		if (result.data.agencyId) {
	// 			store.commit('changeAgencyId')
	// 			localStorage.agencyId = true
	// 		} else {
	// 			localStorage.agencyId = false
	// 		}
	// 		await store.dispatch("permMenu", router)
	// 		if('/usr_top' == res.targetUrl){
	// 			localStorage.isLogin = true
	// 			router.push({ path: '/usr_top' })
	// 		}
	// 		if('/elearning' == res.targetUrl){
	// 			localStorage.isLogin = true
	// 			router.push({ name: 'elearning'})
	// 		}
	// 		if('/assentusr' == res.targetUrl){
	// 			localStorage.setItem('hennkouFlag',res.data.hennkouFlag);
	// 			localStorage.setItem('login',JSON.stringify(res.data.loginInfo));
	// 			router.push({ name: 'Assentusr'})
	// 		}
	// 		if('/assentmng' == res.targetUrl){
	// 			localStorage.setItem('login',JSON.stringify(res.data.loginInfo));
	// 			router.push({ name: 'Assentmng'})
	// 		}
	// 		if('/passaiedit' == res.targetUrl){
	// 			localStorage.setItem('login',JSON.stringify(res.data.loginInfo));
	// 			router.push({ name: 'Passaiedit'})
	// 		}
	// 		localStorage.setItem('traShowFlag',res.traShowFlag);
	// 		localStorage.setItem('traSelfShowFlag',res.traSelfShowFlag);
	// 		localStorage.setItem('isDispatchAgencyFlag',res.isDispatchAgencyFlag)
	// 		localStorage.setItem('InfoFlg',res.InfoFlg)
	// 		localStorage.setItem('logoUrl',res.logoUrl)
	// 		}).catch(err => {
	// 		return err
	// 		})
	// 		}
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // });

    function setloginToSession(loginInf) {
		var information = {
            comId: loginInf.comId,
            loginComId: loginInf.loginComId,
            usrId: loginInf.usrId,
            userProjectShowFlag: loginInf.userProjectShowFlag,
            loginAgencyId: loginInf.loginAgencyId,
            fromRismonFlag: loginInf.fromRismonFlag,
            pwdChgDiv: loginInf.pwdChgDiv,
            jccFlag: loginInf.jccFlag,
            loginId: loginInf.loginId
        }
		localStorage.setItem('login',JSON.stringify(information));
	}

    return {
        ssoLogin
    }
},

})
</script>

<style scoped>
    .container {
        position: relative;
        width: 2000px;
        height: 1000px;
        background-color: lightpink;
    }
    .cover {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        z-index: 200;
        opacity:0.8;
    }
</style>
