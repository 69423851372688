import { Service, BaseService } from "@/core/auto";

@Service('/api/opecom/')
export default class opecom extends BaseService {
	create(data) {
		return this.request({
			showError: true,
			data: data,
			url: "/create",
			method: "POST",
		})
	}

	show(data) {
		return this.request({
			showError: true,
			data: data,
			url: "/show",
			method: "POST",
		})
	}
}