<template>
  <section class="common-layout">
    <header class="rsk-header rsk-header--admin">
      <div class="rsk-header__left-block">
        <!-- <a href="javascript:void(0);" style="background-image: url('/static/image/logo.png');" class="rsk-header__logo"></a> -->
        <img src="../../assets/logo.png" alt="サイバックスUniv." style="width:166px;height:32px" />
      </div>
    </header>
    <nav class="rsk-global-nav rsk-global-nav--user" style="height: 2px"></nav>
    <section class="main" style="min-width: 9.8rem; min-height: 58.8rem;">
      <br/>
      <br/>
      <br/>
      <div style="text-align: center;">
        <div style="font-size: 16px;margin-bottom: 15px;"><b> セッションタイムアウトしました。ログインし直してください。</b></div>
        <div><a href="/login" class="w120">会員様ログイン画面へ</a></div>
      </div>
      <br/>
      <br/>
      <br/>
    </section>
  </section>
</template>

<script>
import { ref, onMounted, computed } from 'vue'
export default {
  setup () {
    const year = ref('')
    onMounted(() => {
      var date = new Date()
      year.value = date.getFullYear()
    })
    return {
      year
    }
  }
}
</script>