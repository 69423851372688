<template>
    <div class="content">
        <div class="rsk-p-xl">
            <div class="rsk-form-control">
                <h2 class="rsk-page-title rsk-mb-s">テスト解答</h2>
            </div>
            <div class="rsk-panel rsk-mb-m">
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">テスト名</div>
                    </div>
                    <div v-text="model.testName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">ボックス名</div>
                    </div>
                    <div v-text="model.lessonName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">内容</div>
                    </div>
                    <div v-text="model.unitDetailC" class="rsk-form rsk-form--expand rsk-text-gray060 rsk-pre-wrap"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">受験前注意事項</div>
                    </div>
                    <div v-text="model.examPreMessageC" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>                                                                                
            </div>
            <div class="rsk-flex rsk-mb-s rsk-items-center rsk-justify-flex-end" v-if="model.examTime != null && model.examTime != ''">
            残り時間&nbsp;&nbsp;<span v-text="timeText"></span>&nbsp;&nbsp;<el-progress :show-text=false :stroke-width="20" :percentage="progress" />&nbsp;&nbsp;制限時間&nbsp;&nbsp;<span v-text="minuteText"></span>
            </div>
            <table class="rsk-table rsk-mb-l" id="rsk-table" v-if="questionList.length > 0">
                <thead class="rsk-table__thead">
                    <tr class="rsk-table__tr">                        
                        <th class="rsk-table__th rsk-table__th--xs">
                            <div class="rsk-table__th-content-center">出題順</div>
                        </th>
                        <th class="rsk-table__th rsk-table__th--expand">
                            <div class="rsk-table__th-content-center ">問題</div>
                        </th>                        
                    </tr>
                </thead>
                <tbody class="rsk-table__tbody">
                    <template v-if="model.dispTypeId == 0">              
                        <tr class="rsk-table__tr" v-for="(item, index) in questionList" :key="index">
                            <td class="rsk-table__td">
                                <div class="rsk-table__td-content">
                                問題{{ index + 1 }}
                                </div>
                            </td>
                            <td class="rsk-table__td" v-if="item.questionType == 1">
                                <div class="table__td-content rsk-img-container rsk-mt-s rsk-block whiteSpace ck-content" v-html="item.questionName"></div>
                                <div class="table__td-content text-wrap" v-for="(item2, index2) in item.detailList" :key="index2">
                                    <div class="rsk-radio">
                                        <label class="rsk-radio--label">
                                            <input type="radio" :value="item2.detailId" v-model="item.radionAnswer"  class="rsk-radio__input" />
                                            <span class="rsk-radio__text">
                                                <span class="select-wrap">{{ item2.selection }}</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </td>
                            <td class="rsk-table__td" v-if="item.questionType == 2">
                                <div class="table__td-content rsk-img-container rsk-mt-s rsk-block whiteSpace ck-content" v-html="item.questionName"></div>
                                <div class="table__td-content" v-for="(item2, index2) in item.detailList" :key="index2">
                                    <div class="rsk-checkbox-container text-wrap">
                                        <input type="checkbox" class="rsk-checkbox" v-model="item.checkBoxAnswer" :value="item2.detailId" :id="index + 'list'  + index2" />
                                        <label class="rsk-checkbox__image" :for="index + 'list'  + index2"></label>
                                        <label :for="index + 'list'  + index2" class="rsk-checkbox__label rsk-text-gray100" v-text="item2.selection"></label>
                                    </div>     
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-if="model.dispTypeId == 1">
                        <tr class="rsk-table__tr">
                            <td class="rsk-table__td">
                                <div class="rsk-table__td-content">
                                問題{{ questionModel.correctIndex + 1 }}
                                </div>
                            </td>
                            <td class="rsk-table__td" v-if="questionModel.questionType == 1">
                                <div class="table__td-content rsk-img-container rsk-mt-s rsk-block whiteSpace ck-content" v-html="questionModel.questionName"></div>
                                <div class="table__td-content" v-for="(item2, index2) in questionModel.detailList" :key="index2">
                                    <div class="rsk-radio">
                                        <label class="rsk-radio--label">
                                            <input type="radio" :value="item2.detailId" v-model="questionList[questionModel.correctIndex].radionAnswer"  class="rsk-radio__input" />
                                            <span class="rsk-radio__text">
                                                <span class="select-wrap">{{ item2.selection }}</span>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </td>

                            <td class="rsk-table__td" v-if="questionModel.questionType == 2">
                                <div class="table__td-content rsk-img-container rsk-mt-s rsk-block whiteSpace ck-content" v-html="questionModel.questionName"></div>
                                <div class="table__td-content" v-for="(item2, index2) in questionModel.detailList" :key="index2">
                                    <div class="rsk-checkbox-container text-wrap">
                                        <input type="checkbox" class="rsk-checkbox" v-model="questionList[form.correctIndex].checkBoxAnswer" :value="item2.detailId" :id="form.correctIndex + 'list'  + index2" />
                                        <label class="rsk-checkbox__image" :for="form.correctIndex + 'list'  + index2"></label>
                                        <label :for="form.correctIndex + 'list'  + index2" class="rsk-checkbox__label rsk-text-gray060" v-text="item2.selection"></label>
                                    </div>                                    
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class="rsk-p-s">
                <button @click="index" class="rsk-button rsk-button--text-user-primary">
                    <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                        <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                    </span>
                    戻る
                </button> 
            </div>
            <div class="rsk-flex rsk-justify-center" v-if="model.dispTypeId == 0">
                <div class="rsk-form-button-wrapper__button">
                <button @click="answer('2')" class="rsk-button rsk-button--user-primary rsk-button--medium rsk-button--expand">
                    解答終了
                </button>
                </div>
            </div>
            <template  v-else>
                <div class="rsk-flex rsk-justify-center rsk-mb-xl">
                    <div class="rsk-form-button-wrapper__button">      	
                        <button @click="sub('0')" class="rsk-button rsk-button--medium rsk-button--expand rsk-button--user-primary" v-if="questionModel.correctIndex != 0">前問題へ</button>
                    </div>
                    <div class="rsk-form-button-wrapper__button" v-if="model.testCount > questionModel.sortNo">
                        <button @click="sub('1')" class="rsk-button rsk-button--medium rsk-button--expand rsk-button--user-primary">次問題へ</button>
                    </div>
                    <div class="rsk-form-button-wrapper__button" v-if="model.testCount == questionModel.sortNo">
                        <button @click="answer('2')" class="rsk-button rsk-button--medium rsk-button--expand rsk-button--user-primary">
                        解答終了
                        </button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { ref, onBeforeMount, inject, onBeforeUnmount, watch } from 'vue';
import { useRoute,useRouter } from 'vue-router'
import { ElMessageBox, ElMessage } from 'element-plus'
import { cryptoEffect } from '@/utils/cryptoUtil'
export default {
    props: {
        modelValue: {
            type: Array,
            default: []
        },
    },
    setup(props) {
        const model = ref({});
        const questionModel = ref({});
        const questionList = ref([]);
        let questionListTemp = ref([]);
        const route = useRoute();
        const router = useRouter()
        const form = ref({
            actionFlgs: '',
            correctIndex: '',
            testId: '',
            lessonId: '',
            checkBoxAnswer: [],
            radionAnswer: ''
        })
        // 制限時間
        let minute = ref();
        // 残り時間
        let time = ref();
        let progress = ref();
        let totalSecond = ref();
        let minuteText = ref();
        let timeText = ref();
        const store = useStore();
        let id = {};
        let goUrl = "tes"
        const lesDetailUrl = "lesDetail";
        let timer;
        const {encrypt, decrypt} = cryptoEffect()
        onBeforeMount(() => {
            if(typeof(route.params.id) != "undefined") {
                id = route.params.id;
                localStorage.testLessonId = id;
            } else {
                id = localStorage.testLessonId
            }
            if(!id) {
                router.push({ name: 'tes'});
                return;
            }
            store.commit('setCourseId', id);

            if(typeof(route.params.fromUrl) != "undefined") {
                goUrl = route.params.fromUrl;
                localStorage.fromLesUrl = goUrl;
            } else if(localStorage.fromLesUrl) {
                goUrl = localStorage.fromLesUrl
            }
            getTestAnswerEffect();
        })

        const service = inject("service");
        function getTestAnswerEffect() {
            const localTestTime = localStorage.testStartTime;
            store.commit('setTestStartTime', localTestTime);
            let sessionTestQustionList = null;
            let sessionTestCorrectIndex = null;
            if (typeof(localStorage.testAnswerQuestionListTemp) != 'undefined') {
                sessionTestQustionList = JSON.parse(decrypt(localStorage.testAnswerQuestionListTemp));
            }
            if (typeof(localStorage.testCorrectIndex) != 'undefined') {
                sessionTestCorrectIndex = JSON.parse(localStorage.testCorrectIndex);          
            }
            service.test.getTestAnswer(store?.getters?.getCourseId, store?.getters?.getTestStartTime, sessionTestQustionList, sessionTestCorrectIndex).then(res => {
                model.value = res.model
                questionModel.value = res.questionModel
                if (res.questionList) {
                    for(let i in questionList.value) {
                        questionList.value[i].checkBoxAnswer = ref(props.modelValue)
                    }
                    questionList.value = res.questionList;
                } else {
                    router.push({ name: 'tes'})
                }

                const testStartTime = res.startTime;
                store.commit('setTestStartTime', testStartTime);                     
                form.value.testId = model.value.testId;
                form.value.lessonId = model.value.lessonId;
                totalSecond.value = model.value.remainingTime;
                form.value.correctIndex = questionModel.value.correctIndex;

                if (totalSecond.value) {
                    minuteText.value = formatTime(model.value.examTime * 60);
                    minute.value = model.value.examTime * 60;
                    startTime();
                }
            })
        }

        function index() {
            ElMessageBox.confirm(
                '中断してもよろしいですか？',
                {
                cancelButtonClass: "btn-custom-cancel" ,
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル',
                type: 'warning',
                }
            )
            .then(() => {
                router.push({ 
                    name: goUrl,
                    params: {
                        lessonId: form.value.lessonId
                    }
                });
            })
            .catch(() => {

            })
            
        }

        function formatTime(totalSecond) {
            let hour = Math.floor(totalSecond / 3600);
    		let minute = Math.floor((totalSecond % 3600) / 60);
    		let second = Math.floor(totalSecond % 60);

    		hour = formatTimeNumber(hour);
    		minute  = formatTimeNumber(minute)
    		second  = formatTimeNumber(second);

    		return (hour +":"+minute+":"+second)
        }
        function formatTimeNumber(number){
    		if(number < 10){
    			return "0"+number;
    		}else{
    			return number;
    		}
    	}

    	function startTime(){
    		timeText.value = formatTime(totalSecond.value);
    		timer = setInterval(function(){
    			totalSecond.value -= 1 ;
    		}, 1000);
    	}

        watch(
            () => totalSecond.value,
            (curtotalSecond, pretotalSecond) => {
                if(model.value.examTime != null && model.value.examTime != '') {
                    model.value.remainingTime = curtotalSecond;
                    timeText.value = formatTime(curtotalSecond);
                    if(curtotalSecond > 0){
                        time.value = curtotalSecond;
                    } else {
                        clearInterval(timer);
                        form.value.actionFlgs = 3;
                        ElMessageBox.alert('解答時間切れ.採点をします.', '提示', {
                            confirmButtonText: 'OK',
                            callback: () => {
                                submit(true);
                            },
                        })
                    }
                    progress.value = 100 - (time.value / minute.value) * 100;    
                }       
            },
        )

        function setTestStartTime() {
            localStorage.testStartTime = store?.getters?.getTestStartTime; 
        }

        function setCourseId() {
            localStorage.testLessonId = store?.getters?.getCourseId; 
        }

        function setQuestion() {
            for(let i in questionList.value) {
                let questionModelTemp = {
                    questionId: questionList.value[i].questionId,
                    checkBoxAnswer: questionList.value[i].checkBoxAnswer,
                    radionAnswer: questionList.value[i].radionAnswer
                };
                questionListTemp.value.push(questionModelTemp)
            }
            if(questionListTemp.value.length > 0) {
                localStorage.testAnswerQuestionListTemp = encrypt(JSON.stringify(questionListTemp.value)); 
            }
        }

        onBeforeUnmount(() => {
            window.removeEventListener('beforeunload', setTestStartTime)
            window.removeEventListener('beforeunload', setCourseId)
            window.removeEventListener('beforeunload', setQuestion)
            clearInterval(timer);
            localStorage.removeItem("testStartTime");
            localStorage.removeItem("fromLesUrl");
            localStorage.removeItem("testLessonId");
            localStorage.removeItem('testAnswerQuestionListTemp')
            localStorage.removeItem('testCorrectIndex')
        })

        window.addEventListener('beforeunload', setTestStartTime);
        window.addEventListener('beforeunload', setCourseId);
        window.addEventListener('beforeunload', setQuestion);

        function answer(str) {
            form.value.actionFlgs = str;
            ElMessageBox.confirm(
                'テストを終了し、結果を表示してもよろしいですか？',
                {
                cancelButtonClass: "btn-custom-cancel",
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル',  
                type: 'warning',
                }
            )
            .then(() => {
                submit(true);
            })
            .catch(() => {

            })

        }

        function sub(str){
            form.value.actionFlgs = str;
            if (str == '2') {
                if (window.confirm('テストを終了し、結果を表示してもよろしいですか？')){
                    submit(false);
                }
            } else {
               submit(false);
            }
        }

        function goConfirm(testId, lessonId, questionList) {
            if(lessonId == null) {
                lessonId = 0;
            }
            let id = testId + "-" + lessonId
            router.push({
                name: 'testConfirm',
                params: {
                    id : id,
                    list: questionList,
                    fromUrl: goUrl,
                    lessonId: lessonId
                }
            })
        }

        function sortCheckbox(checkBoxAnswer) {
           return checkBoxAnswer.sort();
        }

        function submit(back) {
            if (back) {
                for(let i in questionList.value) {
                    if (questionList.value[i].questionType == 2) {
                        questionList.value[i].checkBoxAnswer = sortCheckbox(questionList.value[i].checkBoxAnswer);
                    }
                }
            }
            if (model.value.dispTypeId == 0) {
                service.test.answerConfirm({...form.value,'questionList' : questionList.value}).then(res => {
                model.value = res.model;
                if(back) {
                    if(goUrl == lesDetailUrl) {
                        lesDetail();
                    } else {
                        goConfirm(form.value.testId, form.value.lessonId, JSON.stringify(questionList.value));
                    }
                    ElMessage({
                            type:'success',
                            message: res.message,
                            showClose: true,
                            duration:3000
                    })
    
                }
                }).catch(err => {
                    console.log(err);
                })                
            } else if (model.value.dispTypeId == 1) {
                service.test.answerConfirm({'questionModel' : questionModel.value, ...form.value,'questionList' : questionList.value}).then(res => {
                    questionModel.value = res.questionModel;
                    model.value = res.model;
                    form.value.correctIndex = questionModel.value.correctIndex;
                    localStorage.testCorrectIndex = form.value.correctIndex;
                    if(back) {
                        if(goUrl == lesDetailUrl) {
                            lesDetail();
                        } else {
                            goConfirm(form.value.testId, form.value.lessonId, JSON.stringify(questionList.value));
                            ElMessage({
                                type:'success',
                                message: res.message,
                                showClose: true,
                                duration:10000
                            })
                        }
                        
                    }
                }).catch(err => {
                    console.log(err);
                })
            }
        }

        function lesDetail() {
            router.push({ 
                name: goUrl,
                params: {
                    lessonId: model.value.lessonId
                }
            });   
        }

        return {
            model,
            questionModel,
            questionList,
            index,
            answer,
            sub,
            form,
            progress,
            minute,
            time,
            totalSecond,
            minuteText,
            timeText
        }
    }
}
</script>

<style scoped lang="scss">
.el-progress--line {
  width: 350px;
}

.table__td-content {
    color: #30353B;
    height: 100%;
    min-width: 30px;
    overflow-wrap: break-word;
    word-break: break-all;
    word-wrap: break-word;
    position: relative;
    min-height: 36px;
    padding: 0 8px;
    font-size: 14px;
}

.whiteSpace {
    white-space: normal;
}

.select-wrap {
    left: 24px;
    position: relative;
}

.text-wrap {
    width: calc(100% - 24px);
    white-space: normal;
}
</style> 
