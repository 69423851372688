<template>
    <div :class="'rsk-form'+(isExpand?'--expand':'')">
        <input :type="type" :placeholder="placeholder" :class="isExpand?'rsk-input rsk-input--expand':'rsk-input rsk-input'" :maxlength="max == null ? -1 : max" v-model="modelValue" @input="input"/>
    </div>
</template>
<script>
import { ref } from 'vue';
export default {
    props: {
        type: {
            type: String,
            default: 'text'
        },
        modelValue: {
            type: [String, Number],
            default: ""
        },
        placeholder:{
            type: String,
            default: ""
        },
        isExpand:{
            type: Boolean,
            default: false
        },
        max:String
    },
    setup(props, { emit }) {
        function input(  ) {
            emit("update:modelValue", props.modelValue);
            emit("input", props.modelValue);
        }

        return {
            ...props,
            input,
        }
    },
}
</script>