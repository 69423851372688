import { email } from './validate_cont'
export const checkInvalid = (value,service,callback,lable) => {
    service.invalid.invalid.validate(value).then(res => {
        callback();
    }).catch((error) => {
        callback(new Error(lable + error.message));
        console.log(error)
    })
}

export const validEmail = (value,callback,errMsg) => {
    email(value).then(res => {
        if (res.code == 201) {
            callback(new Error(errMsg));
        } else if (res.code == 200) {
            callback();
        }
    }).catch((error) => {
        callback(new Error(errMsg + error.message));
        console.log(error)
    })
}