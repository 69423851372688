<template>
    <div class="rsk-flex">
        <template v-for="(item1, index) in list" :key="index">
            <div v-if="index!=0" class="rsk-divider rsk-divider--vertical-expand rsk-mr-s"></div>
            <div class="rsk-mr-s rsk-width-212">
                <div class="rsk-text-gray080">{{item1.title}}</div>
                <div class="rsk-emphasis-number rsk-mr-s">
                <template v-for="(item2, index2) in item1.items" :key="index2">
                    <span>{{item2.value}}</span>
                    {{item2.title}}
                </template>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        /**
         * {
         *   title
         *   items:[
         *     title:
         *     value:
         *   ]
         * }
         */
        list: []
    },
    setup(props) {
        
        return {
            ...props
        }
    },
}
</script>