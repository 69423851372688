<template>
  <el-dialog v-model="dialogFormVisible" title="コメント入力">
    <div class="rsk-font-xl rsk-mb-s">Webセミナー情報</div>
    <rsk-form labelWidth="120px" ref="formRef" :rules="rules">
      <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
      <rsk-form-item isList label="研修名">
          {{form.trainName}}
      </rsk-form-item>
      <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
      <div class="rsk-form-control">
        <div class="rsk-flex rsk-justify-between" style="width: 120px">コメント設問</div>
        <div v-html="form.commentQuestion"></div>
      </div>
      <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-l" ></div>
        <div v-if="form.commentExistDiv == 1" class="rsk-mb-xs">コメント入力欄（任意）</div>
        <div v-else-if="form.commentExistDiv == 2" class="rsk-mb-xs">コメント入力欄（必須）</div>

      <textarea class="rsk-input rsk-input--expand rsk-textarea__input new-textarea rsk-mb-l" rows="8" v-model="form.userComment" placeholder="※500文字以内で入力してください。"></textarea>

      <div class="rsk-flex rsk-justify-center">
        <div class="rsk-form-button-wrapper__button">
          <rsk-button isExpand v-if="props.fromWhere == 5" @click="confirm" type="primary">申込内容確認</rsk-button>
          <rsk-button isExpand v-if="props.fromWhere == 6" @click="confirm" type="primary">コメントを変更する</rsk-button>
        </div>
        <div class="rsk-form-button-wrapper__button">
          <rsk-button isExpand type="tertiary" @click="close">キャンセル</rsk-button>
        </div>
      </div>
    </rsk-form>
  </el-dialog>
</template>

<script>
import { ref, inject, reactive } from "vue";
import { ElMessage } from 'element-plus'

export default {
      props: {
        fromWhere: {
            type: String,
            default: ""
        },
        courseIndex: {
            type: String,
            default: ""
        },
        usrId:{
          type: String,
           default: ""
        },
        usrIds:{
            type: String,
            default: ""
        },
        trainId:{
            type: String,
            default: ""
        },
        modelValue: {
            type: Boolean,
            default: false
        },
    },
  setup(props, { emit }) {
    const dialogFormVisible = ref(false);
    const service = inject("service");

    function confirm(){
      if(form.userComment == null){
        form.userComment = "";
      }
      if(form.userComment == "" && form.commentExistDiv == 2){
        alert('Webセミナーのコメントを入力してください。');
        return;
      }
      if (form.userComment.length > 500) {
          alert('コメント は 500 文字以内で入力してください。');
          return;
      }
      if(props.fromWhere == 5){
        emit("setUserCommentValue", form.userComment);
        dialogFormVisible.value = !dialogFormVisible.value;
        return;
      }
      let formData = {model:form}
      service.poptracommemt.create(formData).then(res => {
            ElMessage({
                type:'success',
                message: res,
                showClose: true,
                duration:3000
            })
      })
      dialogFormVisible.value = !dialogFormVisible.value;
    }

    function close() {
      // if(props.fromWhere == 5 && form.commentExistDiv == 1){
      //   emit("setUserCommentValue", form.userComment);
      // }
      dialogFormVisible.value = !dialogFormVisible.value;

    }

    function open() {
      index();
      dialogFormVisible.value = true
    }



    const form = reactive({
      commentQuestion: '',
      trainId: '',
      userComment: '',
      fromWhere: '',
      courseIndex: '',
      unitDetail: '',
      commentExistDiv: '',
      trainName: '',
      usrIds: '',
    });

    const index = () => {
      let reqDto = {
        fromWhere: props.fromWhere,
        courseIndex: props.courseIndex,
        usrId: props.usrId,
        usrIds: props.usrIds,
        trainId: props.trainId,
      }
      service.poptracommemt.index(reqDto).then(res => {
          if(res) {
            form.commentQuestion = res.model.commentQuestion
            form.trainId = res.model.trainId
            form.fromWhere = res.model.fromWhere
            form.courseIndex = res.model.courseIndex
            form.unitDetail = res.model.unitDetail
            form.commentExistDiv = res.model.commentExistDiv
            form.trainName = res.model.trainName
            form.usrId = res.model.usrId
            form.usrIds = res.model.usrIds
            form.userComment = res.model.userComment
          }
      })
    }

    return {
        open,
        dialogFormVisible,
        confirm,close,
        form,
        props,
    }
  },
};
</script>
<style scoped>
.new-textarea {
    height: initial !important;
}
</style>
