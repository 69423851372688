<template>
  <el-container class="yoshin-bac-div">
    <el-main class="main-wrap">
    <rsk-form labelWidth="50px" ref="formRef" :rules="rules">
      <div class="main-div">
        <div class="yoshin-logo-warp">
          <img
            src="../../assets/pagetitle.gif"
            alt="."
          />
        </div>
        <div class="rsk-page-title">ユーザー新規登録</div>
        <div class="yoshin-link-warp">
          <div class="yoshin-link-item">
            <el-link type="primary" @click="loginback">
                ログインはこちら
                <img
                  style="height: 20px"
                  src="./png/carret_right_user_primary.svg"/>
            </el-link>
          </div>
          <div class="yoshin-link-item">
            <el-link type="primary" @click="forgetPwd">
                パスワードを忘れた方はこちら
                <img
                  style="height: 20px"
                  src="./png/carret_right_user_primary.svg"/>
            </el-link>
            
          </div>
        </div>
        <div class="form-warp">
          <rsk-form-item
            isList
            isYoshin
            hintText="必須"
            prop="yoshinLoginID"
          >
            <rsk-input v-model="usrData.yoshinLoginID" max="18" placeholder="与信管理サービスログインID または与信管理サービス会員ID" class="yoshin-input-warp"/>
          </rsk-form-item>
          <rsk-form-item
            isList
            hintText="必須"
            prop="comNm"
          >
            <rsk-input v-model="usrData.comNm" placeholder="会社名 例）サイバックス株式会社"/>
          </rsk-form-item>
          <rsk-form-item
            isList
            prop="appUserDepNm"
          >
            <rsk-input v-model="usrData.appUserDepNm" placeholder="部署名 例）システム開発第一開発部"/>
          </rsk-form-item>
          <rsk-form-item
            isList
            hintText="必須"
            prop="usrNm"
          >
            <rsk-input v-model="usrData.usrNm" placeholder="氏名 例）千代太郎"/>
          </rsk-form-item>
          <rsk-form-item
            isList
            hintText="必須"
            prop="usrTel"
          >
            <rsk-input v-model="usrData.usrTel" placeholder="電話番号 例）XX-XXXX-XXXX"/>
          </rsk-form-item>
          <rsk-form-item
            isList
            hintText="必須"
            prop="usrMlAdr"
          >
            <rsk-input v-model="usrData.usrMlAdr" placeholder="Emailアドレス 例）toukyou@cybaxuniv.com"/>
          </rsk-form-item>
        </div>
        <div class="privacy-box">
          <div class="privacy-box-inner">
            <dt style="margin-bottom:14px"> 【個人情報の取扱いについて】</dt>
            <p class="privacy-info-text" style="margin-bottom:24px">リスクモンスター株式会社（以下、「当社」という）は、与信管理サービスや研修サービスをはじめ、当社サービスにおいて、お客様の個人情報を利用するにあたり、個人情報を適切に取り扱うことは社会的責務であると考えております。<br>
              つきましては、以下をご確認いただき、同意をいただきますようお願い申し上げます。同意いただけない場合は、本フォームへの入力はできませんので、予めご了承願います。 </p>
            <p class="privacy-info-text text-weight">１．個人情報の利用目的</p>
            <p class="privacy-info-text">当社は、収集した個人情報について、以下の目的で個人情報を利用させて頂くことがございます。 </p>
            <ul class="privacy-info-list text-padding-l">
                <li class="privacy-info-item">（１）当社グループ（下記３の「共同利用する者の範囲」に記載する全ての会社を意味する。以下同様。）の商品・サービスの提供その他それらに付随する諸対応のため</li>
                <li class="privacy-info-item">（２）当社グループの及び提携先企業等の新商品、新サービス、優待、イベント等に関する情報をご送付・ご案内するため</li>
                <li class="privacy-info-item">（３）当社グループへの各種お問い合わせ、資料請求等に関する対応等を行うため</li>
                <li class="privacy-info-item">（４）当社グループからのアンケート依頼、作品公募、市場調査・顧客動向分析、特定個人を識別できない統計的データの作成及びその公表のため</li>
                <li class="privacy-info-item">（５）当社グループのサービス・商品の改良や、新たなサービス・商品を開発するため</li>
            </ul><br/>
            <p class="privacy-info-text text-weight">２．個人情報を入力するに当たっての注意事項</p>
            <p class="privacy-info-text">当社サービス利用に際して当社に個人情報を提供されることは任意ですが、必要な情報が提供されていない場合、もしくは提供された情報に誤りがあった場合、お問い合わせ等にご対応ができない場合があります。</p><br/>
            <p class="privacy-info-text text-weight">３．当社グループにおける共同利用</p>
            <p class="privacy-info-text">当社は、会員様、お取引先様の個人情報を、上記の利用目的のため以下のとおり当社グループ内で共同利用いたします。この場合には、当社が当該情報の管理についての責任を負います。 </p>
            <div style="padding-left: 30px;">
              <table width="80%" style="border: solid 1px #000;border-collapse: collapse;border-spacing: 0">
                <tbody>
                  <tr>
                    <td width="30%" align="center" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">共同利用する者の範囲</td>
                    <td style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">リスクモンスター株式会社並びにその子会社（外国含む）及び関連会社</td>
                  </tr>
                  <tr>
                    <td width="30%" align="center" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">共同利用する個人情報の項目</td>
                    <td style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">氏名、生年月日、性別、住所、電話番号、FAX番号、メールアドレス、勤務先の情報（会社名、所属部課、役職、住所、電話番号、FAX番号）、当社グループのサービスご利用実績</td>
                  </tr>
                  <tr>
                    <td width="30%" align="center" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">共同利用する個人情報の管理責任者</td>
                    <td style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">リスクモンスター株式会社　セキュリティ向上委員会　委員長（東京都中央区日本橋二丁目１６号５号　代表取締役社長　藤本 太一）</td>
                  </tr>
                  <tr>
                    <td width="30%" align="center" style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">お問い合わせ</td>
                    <td style="border: solid 1px #000;font-size: 12px;line-height: 1.5;">下記お問い合わせ窓口までお願いします。</td>
                  </tr>
                </tbody>
              </table>
            </div><br>
            <p class="privacy-info-text text-weight">４．個人情報の第三者への提供</p>
            <p class="privacy-info-text">当社は、個人情報について以下の場合を除き、あらかじめご本人の同意を得ること無く個人情報を第三者に提供することはございません。 </p>
            <ul class="privacy-info-list text-padding-l">
                <li class="privacy-info-item">（１）法令にもとづく場合。</li>
                <li class="privacy-info-item">（２）人の生命、健康、財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
                <li class="privacy-info-item">（３）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
                <li class="privacy-info-item">（４）警察・裁判所等の公的機関又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがあるとき。</li>
                <li class="privacy-info-item">（５）利用目的の範囲内において当社の業務を委託する場合。</li>
                <li class="privacy-info-item">（６）当社プライバシーポリシーにしたがい当社グループ各社間で共同利用する場合。</li>
            </ul><br/>
            <p class="privacy-info-text text-weight">５．開示対象個人情報の開示について</p>
            <p class="privacy-info-text">当社は、ご本人様からお申し出があったときは、当社が指定する手続に基づいて、当社保有の個人情報の利用目的の通知・開示・内容の訂正・追加または削除・利用の停止・消去および第三者への提供の停止に応じます。開示等のお申し出については以下のお問い合わせ窓口にて受け付けいたします。なお、開示、利用目的の通知に置きましては手数料をいただくことがありますので、あらかじめ御了承ください。 </p><br/>
            <p class="privacy-info-text text-weight">６．個人情報に関するお問い合わせ先</p>
            <p class="privacy-info-text">
                〒103-0027　東京都中央区日本橋２-16-5　RMGビル<br>
                リスクモンスター株式会社　セキュリティ向上委員会　委員長<br>
                TEL：03-6214-0331<br>
                窓口受付時間；当社営業日の９：00～18：00
            </p><br>
            
          </div>
			  </div>

        <rsk-form-item
          isList
          hintText="必須"
          style="margin-top: 30px;"
        >
          <div style="color: #30353B; text-align: center;font-size: 14px;">
            ＜個人情報の取扱いについて＞に同意頂ける場合は、「同意します」にチェックを入れて下さい。
          </div>
          </rsk-form-item>
          <el-row type="flex" justify="center" align="middle">
              <el-checkbox
                v-model="agreeChecked"
                style="padding: 3px 10px 3px 0px"
              ></el-checkbox>
              <span style="color: black;font-size: 14px;">同意します</span>
          </el-row>

          <rsk-form-submit class="form-submit-warp">
            <rsk-button isExpand type="primary" @click="addusr">新規登録</rsk-button>
          </rsk-form-submit>
          <div class="yoshin-synopsis-warp">
            <div style="color: #30353B;font-size: 14px;">
              <div class="synopsis-title" style="margin-bottom:10px;">【与信管理サービスをご利用の会員様は、サイバックスUniv.IDを発行してご利用下さい。】</div>
              <div class="synopsis-content" style="margin-bottom:20px;">
                ◆ ＩＤ発行のメリット ◆<br />
                ・ビジネスに役立つｅラーニングを一部無料でご受講いただけます。<br />
                ・IDは最大300まで発行が可能です。<br />
                ・参加セミナーの管理が可能です。 （申込み～履歴管理～キャンセル）<br />
                ・通常は有料でご提供している一部の公開型講座を、無料でご受講いただけます。<br />
                ・セミナー・公開型講座はすべてオンライン受講スタイルです。<br />
              </div>
              <div class="synopsis-other">
                ※ログインID（メールアドレス）は、与信サービス管理者様のメールドメインと同じである必要がございます。<br />
                サイバックスUniv.からのお申込みができない会員様はメールでもセミナーのお申込みを受付しております。<br />
                メールアドレス：seminar@riskmonster.co.jp<br />
                弊社にてメール受領後、セミナー参加受付のご案内と、サイバックスUniv.のご登録情報をメールでお送りいたします。
              </div>
            </div>
            
        </div>
      </div>
    </rsk-form>
    </el-main>
    <el-footer class="footer-wrap">
      <div class="footer_copylight">
        <rsk-footer-help class="rsk-footer-help"/>
        <div class="rsk-footer_copylight">
          Copyright © 2012-{{year}} Riskmonster.com All Reserved.︎
        </div>
     </div>
    </el-footer>
  </el-container>
</template>
<script>
export { default } from "./js/yoshin_usr_vue.js";
</script>

<style scoped lang="scss" src="./css/yoshin_usr.scss" />