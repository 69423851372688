import { post } from '../../../utils/request'

export async function login(formData) {
  return await post('/api/user/login', formData)
}

export async function getUsrMenu() {
  return await post('/api/user/getUsrMenu')
}

export async function ssoUniv(formData) {
  return await post('/api/user/ssoUniv', formData)
}

