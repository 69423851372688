import { createRouter, createWebHistory } from 'vue-router'
import Common from '../components/Layout.vue'
import { getToken } from '../utils/CookieUtils'
import { menuList } from '@/config/menu'
const routes = [
  {
    path: '/',
    name: 'usr_top',
    component: Common,
    meta: { title: 'サイバックスUniv.(ＴＯＰページ)' },
    children: [
      { path: '', redirect: 'usr_top' },
      {
        path: 'usr_top',
        name: 'usr_top_children',
        component: () => import('../views/usrtop/usr_top.vue')
      },
      {
        path: 'notification',
        name: 'notification',
        component: () => import('../views/notification/index.vue')
      }
      // {
      //   path: 'elearning',
      //   name: 'elearning',
      //   component: () => import('../views/elearning/index.vue')
      // }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    meta: { title: 'ログイン｜サイバックスUniv.' }
  },
  {
    path: '/login/:comid([a-zA-Z0-9]*)',
    name: 'SpecialLogin',
    component: () => import(/* webpackChunkName: "specialLogin" */ '../views/login/Login.vue'),
    meta: { title: 'ログイン｜サイバックスUniv.' }
  },
  {
    path: '/jcclogin',
    name: 'Jcclogin',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    meta: { title: 'ログイン｜サイバックスUniv.' }
  },
  {
    path: '/jcclogin/:comid([a-zA-Z0-9]*)',
    name: 'SpecialJcclogin',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    meta: { title: 'ログイン｜サイバックスUniv.' }
  },
  {
    path: '/pasdeh',
    name: 'Pasdeh',
    component: () => import(/* webpackChunkName: "pasdeh" */ '../views/login/pasdeh.vue'),
    meta: { title: 'サイバックスUniv.(パスワード再発送)' }
  },
  {
    path: '/pasdeh/:comid([a-zA-Z0-9]*)',
    name: 'SpecialPasdeh',
    component: () => import(/* webpackChunkName: "specialPasdeh" */ '../views/login/pasdeh.vue'),
    meta: { title: 'サイバックスUniv.(パスワード再発送)' }
  },
  {
    path: '/assentmng',
    name: 'Assentmng',
    component: () => import(/* webpackChunkName: "assentmng" */ '../views/login/ass_entmng.vue'),
    meta: { title: '利用規約' }
  },
  {
    path: '/assentusr',
    name: 'Assentusr',
    component: () => import(/* webpackChunkName: "assentmng" */ '../views/login/ass_entusr.vue'),
    meta: { title: '利用規約' }
  },
  {
    path: '/popenvironment',
    name: 'Popenvironment',
    component: () => import(/* webpackChunkName: "popenvironment" */ '../views/login/pop_environment.vue'),
    meta: { title: 'サイバックスUniv.' }
  },
  {
    path: '/assentusrNew',
    name: 'AssentusrNew',
    component: () => import(/* webpackChunkName: "assentusrNew" */ '../views/login/ass_entusrNew.vue'),
    meta: { title: '利用規約' }
  },
  {
    path: '/passaiedit',
    name: 'Passaiedit',
    component: () => import(/* webpackChunkName: "assentusrNew" */ '../views/login/pas_saiedit.vue'),
    meta: { title: 'パスワード変更' }
  },
  {
    path: '/yoshin_usr',
    name: 'YoshinNew',
    component: () => import(/* webpackChunkName: "assentusrNew" */ '../views/login/yoshin_usr.vue'),
    meta: { title: 'ユーザー新規登録' }
  },
  {
    path: '/usrinf',
    name: 'usrinf',
    component: Common,
    redirect: '/usrinf/usr_inf',
    children: [
      {
        path: 'usr_inf',
        name: 'usr_inf',
        component: () => import(/* webpackChunkName: "usr_top" */ '../views/usrinf/usr_inf.vue'),
        meta: { title: 'サイバックスUniv.(お知らせ)' }
      }
    ]
  },
  {
    path: '/usrinquire_new',
    name: 'usrinquirenew',
    component: () => import(/* webpackChunkName: "assentusrNew" */ '../views/usrinquire/usrinquire_new.vue'),
    meta: { title: 'サイバックスUniv.' }
  },
  {
    path: '/usrinquire_show',
    name: 'usrinquireshow',
    component: () => import(/* webpackChunkName: "assentusrNew" */ '../views/usrinquire/usrinquire_show.vue'),
    meta: { title: 'サイバックスUniv.' }
  },
  {
    path: '/usrinquire_confirm',
    name: 'usrinquire_confirm',
    component: () => import(/* webpackChunkName: "assentusrNew" */ '../views/usrinquire/usrinquire_confirm.vue'),
    meta: { title: 'サイバックスUniv.' }
  },
  {
    path: '/popmul',
    name: 'popmul',
    component: () => import(/* webpackChunkName: "assentusrNew" */ '../views/popmul/index.vue')
  },
  {
    path: '/pop_tracommemt',
    name: 'pop_tracommemt',
    component: () => import(/* webpackChunkName: "assentusrNew" */ '../views/poptracommemt/index.vue')
  },
  {
    path: '/session_timeout',
    name: 'session_timeout',
    component: () => import('../views/error/session_timeout.vue'),
    meta: { title: '' }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/error/404.vue'),
    meta: { title: '' }
  },
  {
    path: '/500',
    name: '500',
    component: () => import('../views/error/500.vue'),
    meta: { title: '' }
  },
  {
    path: '/ope_com/new',
    name: 'ope_com_editnew',
    component: () => import(/* webpackChunkName: "ope_com_editnew" */ '../views/login/ope_com_editnew.vue')
  },
  {
    path: '/ope_com_show',
    name: 'ope_com_show',
    component: () => import(/* webpackChunkName: "ope_com_show" */ '../views/login/ope_com_show.vue')
  },
  {
    path: '/ope_com_finish',
    name: 'ope_com_finish',
    component: () => import(/* webpackChunkName: "ope_com_finish" */ '../views/login/ope_com_finish.vue')
  },
  {
    path: '/ope_elgant_completed',
    name: 'ope_elgant_completed',
    component: () => import(/* webpackChunkName: "ope_elgant_completed" */ '../views/login/ope_elgant_completed.vue')
  },
  {
    path: '/ope_elgant_confirm',
    name: 'ope_elgant_confirm',
    component: () => import(/* webpackChunkName: "ope_elgant_confirm" */ '../views/login/ope_elgant_confirm.vue')
  },
  {
    path: '/ope_elgant/:comid(.*?)/edit',
    name: 'ope_elgant_edit',
    component: () => import(/* webpackChunkName: "ope_elgant_edit" */ '../views/login/ope_elgant_edit.vue')
  },
  {
    path: '/ope_elgant_finish',
    name: 'ope_elgant_finish',
    component: () => import(/* webpackChunkName: "ope_elgant_finish" */ '../views/login/ope_elgant_finish.vue')
  },
  {
    path: '/ssoLogin',
    name: 'ssoLogin',
    component: () => import(/* webpackChunkName: "specialLogin" */ '../views/login/ssoLogin.vue')
  },
  {
    path: '/popElgmov',
    name: 'popElgmov',
    component: () => import(/* webpackChunkName: "PopElgmov" */ '../components/PopElgmov.vue')
  },
  {
    path: '/supplier/new',
    name: 'lec_reg_editnew',
    component: () => import(/* webpackChunkName: "lec_reg_editnew" */ '../views/lecturerREG/lec_reg_editnew.vue')
  },
  {
    path: '/lec_reg_show',
    name: 'lec_reg_show',
    component: () => import(/* webpackChunkName: "lec_reg_show" */ '../views/lecturerREG/lec_reg_show.vue')
  },
  {
    path: '/lec_reg_finish',
    name: 'lec_reg_finish',
    component: () => import(/* webpackChunkName: "lec_reg_finish" */ '../views/lecturerREG/lec_reg_finish.vue')
  },
  {
    path: '/ope_jassacom/new',
    name: 'jassa_editnew',
    component: () => import(/* webpackChunkName: "jassa_editnew" */ '../views/jassa/jassa_editnew.vue')
  },
  {
    path: '/ope_jassacom/JgVXwxjneTo=o1smNmmWiEs=/index',
    name: 'jassa_editnew1',
    component: () => import(/* webpackChunkName: "jassa_editnew" */ '../views/jassa/jassa_editnew.vue')
  },
  {
    path: '/ope_jassacom_show',
    name: 'jassa_show',
    component: () => import(/* webpackChunkName: "jassa_editnew" */ '../views/jassa/jassa_show.vue')
  },
  {
    path: '/ope_jassacom_finish',
    name: 'jassa_finish',
    component: () => import(/* webpackChunkName: "jassa_editnew" */ '../views/jassa/jassa_finish.vue')
  },
  {
    path: '/completion_pdf',
    name: 'completionPdf',
    component: () => import(/* webpackChunkName: "jassa_editnew" */ '../views/popcompletionpdf/index.vue')
  },
  {
    path: '/admin/sso_univ!application',
    name: 'ssoUniv',
    component: () => import(/* webpackChunkName: "jassa_editnew" */ '../views/ssouniv/sso_univ.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const whiteList = ['/login', '/ssoLogin', '/jcclogin', '/pasdeh', '/assentmng', '/popenvironment', '/yoshinlogin', '/yoshin_usr', '/ope_com/new', '/ope_com_show', '/ope_com_finish', '/supplier/new', '/lec_reg_show', '/lec_reg_finish', '/500', '/404', '/ope_jassacom/new', '/ope_jassacom/JgVXwxjneTo=o1smNmmWiEs=/index', '/ope_jassacom_show', '/ope_jassacom_finish', '/admin/sso_univ!application']
router.beforeEach((to, from, next) => {
  if (typeof (to.meta.title) !== 'undefined' && typeof (to.meta.titleUse) === 'undefined' && to.meta.titleUse !== false) {
    document.title = to.meta.title
  }
  if (getToken()) {
    if (to.matched.length === 0) {
      const u = to.path.replaceAll('/', '')
      const r = menuList.filter((e) => e.path.replaceAll('/', '') === u)
      if (r && r.length !== 0) {
        next({ ...to, replace: true })
        return
      } else if (whiteList.indexOf(to.path) === -1) {
        next({ name: '500' })
      }
    }
    next()
  } else {
    if ((whiteList.indexOf(to.path) !== -1)) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/login') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/pasdeh') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/assentmng') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/popenvironment') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/jcclogin') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/yoshin_usr') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/ssoLogin') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/ope_com/new') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/ope_com_show') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/ope_com_finish') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/supplier/new') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/lec_reg_show') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/500') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/404') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/session_timeout') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/admin/sso_univ!application') !== -1) {
      next()
    } else if (whiteList.indexOf(to.path) === -1 && to.path.indexOf('/ope_elgant') !== -1) {
      next()
    } else {
      next({ name: 'Login' })
    }
  }
})

export default router
