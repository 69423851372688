import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/trastudy')
export default class Trastudy extends BaseService{
	index() {
		return this.request({
			url: "/index",
			method: "POST"
		});
	}
    search(data) {
		return this.request({
            data: data,
			url: "/search",
			method: "POST"
		});
	}
	cancel(data){
		return this.request({
            data: data,
			url: "/cancel",
			method: "POST"
		});		
	}
	show(data){
		return this.request({
            data: data,
			url: "/show",
			method: "POST"
		});		
	}	
}