<template>
    <div class="rsk-checkbox-container" v-for="(item, index) in options" :key="index">
      <input class="rsk-checkbox" type="checkbox" :id="item.label+'_'+item.value" :value="item.value" v-model="select" @change="change">
      <label class="rsk-checkbox__image" :for="item.label+'_'+item.value"></label>
      <label  class="rsk-checkbox__label" :for="item.label+'_'+item.value">
        {{item.label}}
      </label>
    </div>
</template>
<script>
import { ref, watch } from 'vue';
export default {
    props: {
        modelValue: {
            type: Array,
            default: []
        },
        /**
         * {label, value}
         */
        options: {
            type: Array,
            default: []
        }
    },
    emits:['update:modelValue', 'onChange'],
    setup(props, { emit }) {
        watch(()=>props.modelValue,(old, newV)=>{
            select.value = old
        },{
            deep:true
        }
        )
        const select = ref(props.modelValue)
        function change( ) {
            emit("update:modelValue", select);
            emit("onChange", props.modelValue);
        }
        return {
            change,
            select,
            list:props.list
        }
    },
}
</script>
