import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/traselfstudy')
export default class Traselfstudy extends BaseService{
    study(data) {
		return this.request({
            data: data,
			url: "/study",
			method: "POST"
		});
	}
	show(data){
		return this.request({
            data: data,
			url: "/show",
			method: "POST"
		});		
	}	
}