import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/usrinf')
export default class UsrInf extends BaseService {
	index(from) {
		return this.request({
			data: from,
			url: "/index",
			method: "POST"
		});
	}
    search(from) {
		return this.request({
            data: from,
			url: "/search",
			method: "POST"
		});
	}
}