<template>
  <section class="aside">
    <div class="rsk-fixed-bottom-right">
      <div>
        <button @click="openWidget" class="rsk-button rsk-button--admin-primary rsk-button--round rsk-button--round-large">
            <span class="rsk-button__icon-wrapper">
                <span class="rsk-icon rsk-icon--question-answer-white"></span>
            </span>
        </button>
      </div>
    </div>
  </section>
  <footer class="rsk-footer">
    <!-- <div class="rsk-footer__links">
      <a class="rsk-footer_link" @click="downloadAgree">会員規約</a>
      <div class="rsk-footer__links-divider"></div>
      <a class="rsk-footer_link" @click="downloadPerson">個人情報の取り扱い</a>
      <div class="rsk-footer__links-divider"></div>
      <a class="rsk-footer_link" @click="openFaq">FAQ</a>
    </div> -->
    <div class="rsk-footer_copylight">
      Copyright © 2012-{{year}} Riskmonster.com All Reserved.︎
    </div>
  </footer>
</template>

<script>
import { onMounted, ref } from "vue";
import { inject } from "vue";
import { useRouter } from 'vue-router';
import { UAParser } from "../../../views/popenvironment/js/ua-parser.min";
export default {
  name: "loginFooter",
  setup() {
    const router = useRouter();
    const service = inject("service");
    const year = ref('');

    const exts = document.createElement('script');
    exts.type = 'text/javascript';
    exts.id = 'ze-snippet'
    exts.src = 'https://static.zdassets.com/ekr/snippet.js?key=eaeeb030-513f-4edb-8f24-63b080e7239e';
    document.body.appendChild(exts);

    function openWidget(){
      zE('webWidget', 'show');zE('webWidget', 'open');
    }

    onMounted(() => {
      const meta = document.createElement("meta")
      meta.name = "viewport"
      meta.content = "width=device-width,initial-scale=1.0"
      const head = document.getElementsByTagName("head")[0]
      head.replaceChild(meta, head.getElementsByTagName("meta")[2])

      var date = new Date();
      year.value = date.getFullYear();
      var close = false;
      function closeMsg(){
        if(close){
          clearInterval(interval)
        }
        if(document.querySelector('#launcher') != null && document.querySelector('#launcher').style.visibility != 'hidden'){
            zE('webWidget', 'hide');
            zE('webWidget:on', 'close', function(){zE('webWidget', 'hide');})
            document.querySelector('#launcher').style.visibility = 'hidden'
            close = true;
        }
      }
      var interval = setInterval(closeMsg, 200);
    });

    function openFaq(){
      window.open('https://cybaxuniv-support2user.zendesk.com/hc/ja');
    }

    function downloadAgree(){
      var ua = new UAParser();
      let userAgent = ua.getUA();
      const downloadData = {filename:'サイバックスUniv.会員規約.pdf',fileurl:'/pdf_file/サイバックスUniv.会員規約.pdf',userAgent:userAgent}
      service.common.downloadSystemFile(downloadData)
      }

    function downloadPerson(){
      var ua = new UAParser();
      let userAgent = ua.getUA();
      const downloadData = {filename:'個人情報の取扱い.pdf',fileurl:'/pdf_file/個人情報の取扱い.pdf',userAgent:userAgent}
      service.common.downloadSystemFile(downloadData)
      }

    return {
      year,
      openWidget,
      openFaq,downloadAgree,downloadPerson
    }
  }
}
</script>

<style scoped>
  .aside {
  min-width: 980px;
  max-width: 1260px;
}
.rsk-footer {
    background-color: #F2F5F7;
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
    align-items: center;
    height: 36px;
    color: #8B949E;
    border-top: 1px solid;
    border-color: rgba(48, 53, 59, 0.16);
    font-size: 12px
}
</style>
