<template>
    <div class="content">
        <rsk-container title="ボックス詳細" :buttons="buttons">
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs" v-for="(item, index) in infos" :key="index">
                <div class="rsk-flex rsk-justify-between rsk-width-212">
                    <div class="rsk-table-like-form__heading rsk-table-like-form__table-column">{{item.title}}</div>
                </div>
                <div class="rsk-form rsk-form">
                    <div v-if="item.title == '研修内容'" v-html="item.value"></div>
                    <div v-else-if="!item.callBack" class="table-like-list__column rsk-text">{{ item.value }}</div>
                    <div v-else-if="item.callBack && (item.value == '' || item.value == null)" class="rsk-text">{{item.value}}</div>
                    <el-button v-else type="text" @click="item.callBack(item)" >{{item.value}}</el-button>
                </div>
            </div>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>
        </rsk-container>
        <div class="rsk-panel rsk-mb-m">
            <rsk-table
            :titles="titles"
            :buttons="tableButtons"
            :data="tableData"
            :total="count"
            @init="tableConfigInit"
            @pageInfoChange="tableChange"
            />
        </div>
        <div class="rsk-p-s">
            <button class="rsk-button rsk-button--text-user-primary" @click="back">
                <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                    <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                </span>
                戻る
            </button>
        </div>
        <el-dialog title="成績" v-model="dialogVisible" width="60%">
        <el-divider></el-divider>
        ID<a style="color:rgb(96, 98, 102);margin-left:45px;" >{{exaElearningId}}</a>
        <el-divider></el-divider>
        研修名<a style="color:rgb(96, 98, 102);margin-left:20px;" >{{exaElearningName}}</a>
        <el-divider></el-divider>
        <rsk-table
        :titles="exatitles"
        :total="exacount"
        :data="exatableData"
        @init="exatableConfigInit"
        @pageInfoChange="exatableChange"
        @sortChange="exatableChange"
        />
        <rsk-form-submit style="border: none">
        <div class="rsk-form-button-wrapper__button" v-if="isCompletion">
          <button class="rsk-button rsk-button--medium rsk-button--expand rsk-button--primary" @click="toCompletionPdf()">
            修了証印刷
          </button>
        </div>
        <div class="rsk-form-button-wrapper__button" v-else>
          <button class="rsk-button rsk-button--medium rsk-button--expand rsk-button--tertiary" disabled>
            修了証印刷
          </button>
        </div>
      </rsk-form-submit>
        </el-dialog>
    </div>
</template>

<script>
import { useRouter,useRoute } from 'vue-router'
import { ref,onMounted, onBeforeUnmount } from "vue";
import { inject } from "vue";
import { UAParser } from "../popenvironment/js/ua-parser.min";
import { dateFtt } from "@/utils/common";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const service = inject("service");
    const infos = ref([]);
    const exaform = ref({});

    const exaElearningId = ref('');
    const exaElearningName = ref('');
    const isCompletion = ref(true);
    const completionElgId = ref('');
    const completionDtm = ref('');
    const completionUsrId = ref('');

        const titles = ref([
        {
            field:'studyysort',
            title:'学習順',
            tooltip: '※「学習順」が設定されている場合、前の研修の学習を修了すると<br/>次の研修に進むことができます。',
        },
        {
            field:'preconditiontype',
            title:'カテゴリ'
        },
        {
            field:'name',
            title:'研修'
        },
        {
            field:'baseStudyTime',
            title:'標準学習時間'
        },
        {
            field:'learningTime',
            title:'学習時間'
        },
        {
            field:'progressRate',
            title:'進捗率'
        },
        {
            field:'studyflg',
            title:'ステータス',
            tooltip: '※「カテゴリ」によって「ステータス」の表示は異なります。次の<br/>ような表示の場合、学習修了となります。eラーニング：「修<br/>了」、自社研修：「修了」、レポート：「評価済」、アンケート：<br/>「回答」、テスト：「合格」<br/>※自社研修・レポートの「ステータス」は管理者が登録します。',
        },
        {
            field:'startdate',
            title:'研修開始日'
        },
        {
            field:'enddate',
            title:'研修終了日',
        },
        {
            field:'limitDtm',
            title:'研修期限日',
        }
    ])

    const tableButtons = [
      {
        type: "tertialy",
        text: "成績・修了証",
        icon: "copy-admin",
        show: (item) => {
          if (item.elearningStudyflg == '1') {
            return true;
          } else {
            return false;
          }
        },
        callBack: (item) => {
        exaform.value = {elearningId:item.id,applicationDtm:dateFtt("auto",item.applicationDtm,"en"),elearningName:item.name}
        if (item.studyflg == "修了") {
          isCompletion.value = true;
          completionElgId.value = item.id;
          completionDtm.value = item.applicationDtm.replace(new RegExp("/","gm"), "");
        } else {
          isCompletion.value = false;
        }
        dialogVisible.value = true;
        setTimeout(showExa, 200)
        },
      },
    ]
    const tableData = ref([]);
    const count = ref();
    let sort = {}
    let paging = {}
    let goUrl = "les"
    function tableConfigInit( sorts,p) {
      sort = sorts
      paging = p
    }
    function tableChange(sorts,p) {
      sort = sorts
      paging = p
      infos.value = ([])
      init();
    }
    function updateElgmov(content) {
      service.popelgmov.update(content).then((res) => {
      })
    }
    onMounted(() => {
        window.reConnect = (content) => {
            updateElgmov(content)
        }
        if(typeof(route.params.fromUrl) != "undefined") {
            goUrl = route.params.fromUrl;
            localStorage.fromLesUrl = goUrl;
        } else if(localStorage.fromLesUrl) {
            goUrl = localStorage.fromLesUrl
        }

        if (localStorage.fromTopUrl) {
            goUrl = localStorage.fromTopUrl;
        }

        if (goUrl === 'usr_top_children') {
            localStorage.fromTopUrl = goUrl;
        }
        init();
    });

    onBeforeUnmount(() => {
        localStorage.removeItem('fromLesUrl')
    })

    const dialogVisible = ref(false);
    const exatitles = ref([
    {
        field: "examinationId",
        title: "試験ID",
        order: "desc",
    },
    {
        field: "examinationName",
        title: "試験名",
        order: "none",
    },
    {
        field: "enforceDtm",
        title: "実施日時",
        order: "none",
    },
    {
        field: "point",
        title: "得点",
        order: "none",
    },
    ]);
    const exatableData = ref([]);
    const exacount = ref();
    let exasort = {}
    let exapaging = {}
    function exatableConfigInit(sorts, p) {
      exasort = sorts
      exapaging = p
    }
    function exatableChange(sorts, p) {
      exasort = sorts
      exapaging = p
      showExa();
    }
    function showExa() {
        exaElearningId.value =  exaform.value.elearningId;
        exaElearningName.value = exaform.value.elearningName.text;
        service.exa.show({codition:{...exaform.value,...exasort,...exapaging}}).then((res) => {
            for(let i = 0;i<res.list.length;i++) {
                res.list[i].enforceDtm = dateFtt("auto_time",res.list[i].enforceDtm,"en");
            }
            exacount.value = res.count;
            exatableData.value = res.list;
        }).catch((error) => {
            console.log(error);
        });
    }

    function init() {
        if(!route.params.lessonId) {
            router.push({ name: 'les'})
        } else {
            let showdata = {condition:{lessonId:route.params.lessonId,...sort,...paging}}
            service.les.detail(showdata).then((res) => {
                infos.value.push({title: 'ボックス名',value: res.model.lessonName})
                infos.value.push({title: '研修内容',value: res.model.unitDetail})
                infos.value.push({title: '添付ファイル1',value: res.model.reportFileName1,callBack: (item) => {
                    downloadFile(res.model.reportFileName1,res.model.reportFileDir1);
                }})
                infos.value.push({title: '添付ファイル2',value: res.model.reportFileName2,callBack: (item) => {
                    downloadFile(res.model.reportFileName2,res.model.reportFileDir2);
                }})
                completionUsrId.value = res.model.usrId;
                count.value = res.count;
                if(count.value > 0) {
                    for(let i = 0;i<res.list.length;i++) {
                        if(res.list[i].preconditiontype == 'eラーニング'){
                            if(res.list[i].elearningStudyflg == '1'){
                                res.list[i].name = {
                                text: res.list[i].name,
                                callBack: (item) => {
                                if (res.list[i].nonScormType == 1) {
                                    let applicationDtmStr =  dateFtt("auto",res.list[i].applicationDtm,"en").replace(new RegExp("/","gm"),"")
                                    let routePop = router.resolve({ name: 'popElgmov',query:{elearningId:res.list[i].id,lessonId:res.model.lessonId,applicationDtm:applicationDtmStr}})
		                            window.open(routePop.href, 'popElgmov', 'top=50,left=200,width=800,height=600')
                                }else {
                                    let applicationDtmStr =  dateFtt("auto",res.list[i].applicationDtm,"en").replace(new RegExp("/","gm"),"")
                                    let formdata = {elearningId:res.list[i].id,lessonId:res.model.lessonId,applicationDtm:applicationDtmStr,termOutside:res.list[i].termOutside}
                                            if (res.list[i].preFlg == 'false') {
                                                alert('「学習順」の前の学習が終了しておりませんので、終了後、再度お試しください。');
                                            } else {
                                                if (formdata.lessonId == '') {
                                                    formdata.lessonId = '9999999999';
                                                }
                                                var cd = '';
                                                var iv = '';
                                                var status_num = '';
                                                var sso_url = '';
                                                service.sso.setSso(formdata).then((res) => {
                                                var model = res;
                                                status_num = model.statusNum
                                                if (model.statusNum == '0') {
                                                        sso_url = model.elearningURL;
                                                        cd = model.cd;
                                                        iv = model.iv;
                                                        let form = document.createElement('form')
                                                        form.action = sso_url;
                                                        form.method = 'POST'
                                                        form.target = 'Cybax_lms_lesson'
                                                        document.body.append(form);

                                                        let cdhidden = document.createElement('input')
                                                        cdhidden.type = 'hidden'
                                                        cdhidden.name = 'cd'
                                                        cdhidden.value = cd
                                                        form.appendChild(cdhidden);

                                                        let ivhidden = document.createElement('input')
                                                        ivhidden.type = 'hidden'
                                                        ivhidden.name = 'iv'
                                                        ivhidden.value = iv;
                                                        form.appendChild(ivhidden);

                                                        form.submit();
                                                        form.remove();
                                                } else {
                                                        alert(model.statusMessage);
                                                }
                                                }).catch((error) => {
                                                console.log(error);
                                                alert('セッションタイムアウトしました。ログインし直してください。');
                                                router.push({ path: '/login' })
                                                });
                                            }
                                        }
                                    }
                                }

                            }
                            res.list[i].limitDtm = '－'
                            res.list[i].learningTime == "" || res.list[i].learningTime == null ? res.list[i].learningTime = '' : dateFtt("auto",res.list[i].learningTime,"en")
                            res.list[i].baseStudyTime == null ? res.list[i].baseStudyTime = '' : res.list[i].baseStudyTime = res.list[i].baseStudyTime + '時間'
                            res.list[i].progressRate == "" || res.list[i].progressRate == null ? res.list[i].progressRate = '' : res.list[i].progressRate = res.list[i].progressRate + '%'
                        }
                        if(res.list[i].preconditiontype == '自社研修'){
                            res.list[i].name = {
                                text: res.list[i].name,
                                    callBack: (item) => {
                                        router.push({ name: 'traselfstudyShow',params:{trainId:res.list[i].id,rank:'',fromUrl:'lesDetail',lessonId:res.model.lessonId}})
                                }
                            }
                            res.list[i].learningTime == "" || res.list[i].learningTime == null ? res.list[i].learningTime = '' : dateFtt("auto",res.list[i].learningTime,"en")
                            res.list[i].limitDtm = '－'
                            res.list[i].baseStudyTime == null ? res.list[i].baseStudyTime = '' : res.list[i].baseStudyTime = res.list[i].baseStudyTime + '時間'
                            res.list[i].progressRate == "" || res.list[i].progressRate == null ? res.list[i].progressRate = '' : res.list[i].progressRate = res.list[i].progressRate + '%'
                        }
                        if(res.list[i].preconditiontype == 'テスト'){
                            if(res.list[i].displayFlg == 1) {
                                res.list[i].name = {
                                    text: res.list[i].name,
                                        callBack: (item) => {
                                            if(res.list[i].preFlg == 'false'){
                                                alert('「学習順」の前の学習が終了しておりませんので、終了後、再度お試しください。');
                                            } else {
                                                if (res.list[i].answerFlg == 'false') {
                                                    alert('実際問題数は不足なので、問題作成後、再度お試しください。');
                                                } else if(res.list[i].deletedFlg == 0) {
                                                    router.push({ name: 'testConfirm',params:{id:res.list[i].id + "-" + res.model.lessonId, lessonId:res.model.lessonId
                                                    , fromUrl:'lesDetail'}})
                                                } else {
                                                    router.push({ name: 'testAnswer',params:{id:res.list[i].id + "-" + res.model.lessonId,fromUrl:'lesDetail'}})
                                                }
                                            }
                                        }
                                }
                            }
                            res.list[i].learningTime = '－'
                            res.list[i].baseStudyTime = '－'
                            res.list[i].progressRate = '－'
                            res.list[i].limitDtm = res.list[i].limitDtm == null ? '－' : dateFtt("auto",res.list[i].limitDtm,"en")
                        }
                        if(res.list[i].preconditiontype == 'アンケート'){
                            if(res.list[i].displayFlg == 1) {
                                res.list[i].name = {
                                    text: res.list[i].name,
                                        callBack: (item) => {
                                            if (res.list[i].preFlg == 'false') {
                                                alert('「学習順」の前の学習が終了しておりませんので、終了後、再度お試しください。');
                                            } else if(res.list[i].deletedFlg == 0) {
                                                    router.push({ name: 'anketoConfirm',params:{id:res.list[i].id + "-" + res.model.lessonId, lessonId:res.model.lessonId
                                                    , fromUrl:'lesDetail'}})
                                            } else {
                                                router.push({ name: 'anketoAnswer',params:{id:res.list[i].id + "-" + res.model.lessonId,fromUrl:'lesDetail'}})
                                            }
                                    }
                                }
                            }
                           res.list[i].learningTime = '－'
                           res.list[i].baseStudyTime = '－'
                           res.list[i].progressRate = '－'
                           res.list[i].limitDtm = res.list[i].limitDtm == null ? '－' : dateFtt("auto",res.list[i].limitDtm,"en")
                        }
                        if(res.list[i].preconditiontype == 'レポート'){
                            if(res.list[i].displayFlg == 1) {
                                res.list[i].name = {
                                        text: res.list[i].name,
                                            callBack: (item) => {
                                                if (res.list[i].preFlg == 'false') {
                                                    alert('「学習順」の前の学習が終了しておりませんので、終了後、再度お試しください。');
                                                } else if (res.list[i].deletedFlg == 0) {
                                                    router.push({ name: 'reportConfirm',params:{id:res.list[i].id + "-" + res.model.lessonId, lessonId:res.model.lessonId
                                                    , fromUrl:'lesDetail'}})
                                                } else {
                                                    router.push({ name: 'reportSend',params:{id:res.list[i].id + "-" + res.model.lessonId,fromUrl:'lesDetail'}})
                                                }
                                        }
                                    }
                            }
                            res.list[i].limitDtm = res.list[i].limitDtm == null ? '－' : dateFtt("auto",res.list[i].limitDtm,"en")
                            res.list[i].progressRate = '－'
                            res.list[i].learningTime = '－'
                            res.list[i].baseStudyTime = '－'
                        }
                        res.list[i].startdate = dateFtt("auto",res.list[i].startdate,"en")
                        res.list[i].enddate = dateFtt("auto",res.list[i].enddate,"en")
                    }
                    tableData.value = res.list;
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }


    function back() {
        router.push({ name: goUrl})
    }

	function downloadFile(filename,fileurl){
        var ua = new UAParser();
        let userAgent = ua.getUA();
        const downloadData = {filename:'',fileurl:'',userAgent:''}
		downloadData.filename = filename;
		downloadData.fileurl = fileurl;
		downloadData.userAgent = userAgent;
		service.common.downloadFile(downloadData)
	}
    function toCompletionPdf() {
      const url = router.resolve({
        name: 'completionPdf',
        query: {
          id : completionElgId.value + "-" + completionDtm.value
        }

      });
      window.open(url.href);
    }

    return {
        infos,
        init,back,tableConfigInit,tableChange,tableButtons,titles,tableData,count,
        exaform,exatableConfigInit,exatableChange,dialogVisible,exatitles,exatableData,exacount,exaElearningId,exaElearningName,
        isCompletion,toCompletionPdf,completionElgId,completionDtm
    };
  },
};
</script>

<style scoped>
    ::v-deep .rsk-p-xl {
        padding: 0px !important;
        margin-bottom: 40px;
    }
    ::v-deep .rsk-table__th--expand {
        padding-left: 8px;
        padding-right: 8px;
    }

    ::v-deep .table__td-content {
        white-space: normal;
    }
</style>
