import { instance } from '@/utils/request'
import { ElLoading, ElMessage } from 'element-plus'

export default class BaseService {
  request (options = {}) {
    if (!options.params) options.params = {}
    let ns = ''
    if (this.namespace) {
      ns += '/' + this.namespace
    }
    if (options.autoDowload == null) {
      options.autoDowload = true
    }
    if (options.showError == null) {
      options.showError = true
    }
    if (options.url.indexOf('http') !== 0) {
      options.url = ns + options.url
      options.url = options.url.replaceAll('//', '/')
    }
    // if( !options.headers ) {
    //   options.headers = {
    // 		'Content-type': 'application/x-www-form-urlencoded'
    // 	}
    //   if(options.data && typeof(options.data) == 'object'){
    //     options.data = qs.stringify(options.data,{skipNulls: true})
    //   }
    // }

    return new Promise((resolve, reject) => {
      const loadingInstance = ElLoading.service(options)
      instance(options).then(res => {
        if (options.responseType && options.responseType === 'blob') {
          if (options.autoDowload) {
            var name = options.data.filename
            var blob = new Blob([res.data])
            var url = window.URL.createObjectURL(blob)
            var aLink = document.createElement('a')
            aLink.style.display = 'none'
            aLink.href = url
            aLink.setAttribute('download', name)
            document.body.appendChild(aLink)
            aLink.click()
            document.body.removeChild(aLink)
            window.URL.revokeObjectURL(url)
          }

          resolve(res.data)
          return
        }
        if (res.data && res.data.code && res.data.code === 200) {
          resolve(res.data.data)
        } else if (res.data && res.data.code && (res.data.code === 401 || res.data.code === 403)) {
          window.location.href = '/session_timeout'
        } else {
          if (res.data && options.showError) {
            let msg = res.data.message
            if (options.t) {
              msg = options.t(msg)
            }
            ElMessage({
              type: 'error',
              message: msg,
              showClose: true,
              duration: 10000
            })
          }
          reject(res.data)
        }
      }).catch(e => {
        reject(e)
      }).finally(() => {
        loadingInstance.close()
      })
    })
  }
}
