/* components */
import { createApp } from 'vue'
import router from './router'
import { setupI18n } from './i18n'
import en from './locales/en.json'
import ja from './locales/ja.json'
import store from './store'

/* Global CSS */
import 'normalize.css'
import './style/jquery-ui-v1.13.3.css'
import './style/index.scss'
import 'element-plus/dist/index.css'
import App from '@/App.vue'

import { bootstrap } from "./core/auto";
/* Configuration */
import ElementPlus from 'element-plus'


var language = 'ja'
if(navigator.language == 'en'){
  language = 'en'
}

const i18n = setupI18n({
  globalInjection: true,
  legacy: false,
  global: true,
  locale: language,
  fallbackLocale: language,
  messages: {
    en,
    ja
  }
})

const app = createApp(App)
app.use(store)
app.use(i18n)
app.use(router)
app.use(ElementPlus)


bootstrap(app)
  .then(() => {
    app.mount('#app')
    store.dispatch("appLoad", router);
  })
// const globalPlugin = (app, options) => {
//   app.provide('i18n', i18n)
// }

// app.use(globalPlugin)
