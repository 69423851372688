<template>
    <div class="rsk-flex rsk-justify-between rsk-items-center rsk-mb-s">
            <div v-if="!describe" class="rsk-font-bold rsk-font-xl">{{title}}</div>
            <div v-else class="rsk-flex rsk-items-center">
              <div class="rsk-font-bold rsk-font-xl rsk-mr-s">{{title}}</div>
              <div class="describe">{{describe}}</div>
            </div>
            <!-- <select class="rsk-input rsk-input--narrow" placeholder="Placeholder">
              <option>追加順</option>
            </select> -->
            <el-select class="rsk-select" clearable v-if="options" size="mini" v-model="selectValue" placeholder="">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
          </div>
</template>
<script>
import { ref } from 'vue';
export default {
    props: {
        title: '',
        /**
         * {
         *  label,
         *  value
         * }
         */
        describe:'',
        options:null
    },
    setup(props) {
        let selectValue = ref('');
        return {
            selectValue,
            ...props
        }
    },
}
</script>
<style>
.rsk-select .el-input__inner {
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #A8B0BB;
    color: #30353B;
}


.describe {
    width: 280px;
    height: 17px;
    margin-top: 6px;
    margin-left: -8px;
    gap: 0px;
    font-size: 11px;
    font-weight: 300;
    line-height: 16.5px;
    text-align: left;
    color: #8B949E;
}
</style>