import { Service, BaseService } from "@/core/auto";

@Service('/api/passai/')
export default class passai extends BaseService{
    update(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/update",
			method: "POST"
		})
	}
	init() {
		return this.request({
            showError: false,
			url: "/init",
			method: "POST"
		})
	}
}