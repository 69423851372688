import axios from 'axios'
import { getToken } from './CookieUtils'

export const instance = axios.create({
  baseURL: process.env.BASE_API,
  timeout: 1000 * 180
})

instance.interceptors.request.use(
  config => {
    if (!!getToken()) {
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    return config
  },
  error => Promise.error(error)
)

export const get = (url, params = {}) => {
  return new Promise((resolve, reject) => {
    instance.get(url, {
      params
    }).then((response) => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

export const post = (url, data = {}) => {
  return new Promise((resolve, reject) => {
    instance.post(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then((response) => {
      resolve(response.data)
    }, err => {
      reject(err)
    })
  })
}

// export default { get, post, request:instance };