<template>
    <div :class="direction=='top'?'rsk-flex rsk-justify-between rsk-mb-s':'rsk-mb-9xl'">
        <div v-if="direction=='top'" class="rsk-flex">
            <button v-if="pagingButton && total > 0" @click="onClick" class="rsk-button rsk-button--medium rsk-button--user-primary">
                {{ pagingButton.text }}
            </button>
        </div>
        <div class="rsk-pagination-wrapper rsk-mb-xs">
            <div class="rsk-pagination-select" v-if="pageBoxFlag">
                <div class="rsk-pagination-select__label">表示件数</div>
                <rsk-selectpagesize style="margin-bottom:0;" @onChange="selectChange" v-model="select" :options="options" />
            </div>
            <div class="rsk-pagination-result">
                {{total}}件中 {{page*select-select+1}} - {{(page*select>total?total:page*select)}}件 表示
            </div>
            <el-pagination :page-size="select" :current-page="page" background @current-change="setPage" layout="prev, pager, next" :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { ref, watch } from 'vue';
export default {
    props: {
        total: {
            type: Number,
            default: 0
        },
        pageinfo:{
            type: Object,
            default: {
                page: 1,
                size: 20,
                sizes: [20, 50, 100, 1000],
            }
        },
        pagingButton: {
            type: Object,
            default: null
        },
        direction:{
            type: String,
            default: 'down'
        },
        pageBoxFlag:{
            type: Boolean,
            default: true
        },
    },
    setup(props, { emit }) {
        const options = ref([])
        const tableTotal = ref(props.total)
        for( let i = 0;i<props.pageinfo.sizes.length;i++ ){
            options.value.push({
                label:props.pageinfo.sizes[i] + '件表示',
                value:props.pageinfo.sizes[i]
            })
        }
        const select = ref(props.pageinfo.size)
        const page = ref(props.pageinfo.page)

        watch(()=>props.total,(old, newV)=> {
            tableTotal.value = old
        })
        watch(()=>props.pageinfo,(old, newV)=>{
            select.value = newV.size
            page.value = newV.page
        },{
            deep:true
        }
        )
        function setPage(item) {
            emit('pageInfoChange',{page:item, size:select.value})
        }
        function selectChange(item) {
            select.value = item
            emit('pageInfoChange',{page:page.value, size:select.value})
        }
        function onClick() {
            emit('pagingButtonClick')
        }
        return {
            onClick,
            pagingButton:props.pagingButton,
            setPage,
            selectChange,
            options,
            select,
            direction:props.direction,
            total:tableTotal,
            page
        }
    },
}
</script>