import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/query_set_sso')

export default class Sso extends BaseService{
	setSso(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/setSso",
			method: "POST",
		});
	}    
}