<template>
  <div class="content">
    <rsk-form :rules="rules" ref="formRef" v-model="model">
      <rsk-input type="hidden" v-model="model.lstEntDtm" />
      <div class="rsk-p-xl">
        <h2 class="rsk-page-title">{{ $t("usrProPages.adminProEditTitle") }}</h2>
        <rsk-tabs :list="tabList" v-model="tabSelect" @onChange="tabChange" />
        <div v-if="tabSelect == 0">
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <div class="rsk-form-control">
            <div class="rsk-flex rsk-justify-between rsk-width-250">
              <div class="rsk-form-control__label">{{ $t("usrProPages.adminProLoginId") }}</div>
              <div class="rsk-form rsk-mr-s">
              </div>
            </div>
            <div class="rsk-form-control__label">
              {{ model.loginId }}
            </div>
          </div>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <div class="rsk-form-control">
            <div class="rsk-flex rsk-justify-between rsk-width-250">
              <div class="rsk-form-control__label">{{ $t("usrProPages.adminProUsrNm") }}</div>
              <div class="rsk-form rsk-mr-s">
              </div>
            </div>
            <div class="rsk-form-control__label">
              {{ model.usrNm }}
            </div>
          </div>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <div class="rsk-form-control">
            <div class="rsk-flex rsk-justify-between rsk-width-250">
              <div class="rsk-form-control__label">{{ $t("usrProPages.adminProUsrNmKna") }}</div>
              <div class="rsk-form rsk-mr-s">
              </div>
            </div>
            <div class="rsk-form-control__label">
              {{ model.usrNmKna }}
            </div>
          </div>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :hintText="$t('common.adminApplicationRequired')" :label="$t('usrProPages.adminProUsrMlAdr')" prop="usrMlAdr">
              <rsk-input max="128" v-model="model.usrMlAdr" />
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminProUsrMlAdrEx')" prop="usrMlAdrEx">
              <rsk-input max="128" v-model="model.usrMlAdrEx" placeholder="例)XXXXXXX@.vcom" />
          </rsk-form-item>
          <template v-if="model.userProjectShowFlag">
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminProUsrSex')" prop="usrSex">
            <rsk-select isMin :options="[{label:$t('usrProPages.adminUsrSex0'), value:'0'}, {label:$t('usrProPages.adminUsrSex1'), value:'1'}]" v-model="model.usrSex" />
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminProUsrBirthDate')">
            <select class="rsk-mr-xxs" v-model="model.year" >
              <template v-for="item in birthYear" >
                <option>{{ item }}</option>
              </template>
            </select>
            <span class="rsk-mr-xs">{{ $t("common.adminApplicationYear") }}</span>
            <select class="rsk-mr-xxs" v-model="model.month">
              <template v-for="item in birthMonth" >
                <option>{{ item }}</option>
              </template>
            </select>
            <span class="rsk-mr-xs">{{ $t("common.adminApplicationMonth") }}</span>
            <select class="rsk-mr-xxs" v-model="model.day">
              <template v-for="item in birthDay" >
                <option>{{ item }}</option>
              </template>
            </select>
            <span class="rsk-mr-xs">{{ $t("common.adminApplicationDay") }}</span>
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminProUsrTel')" prop="usrTel">
            <rsk-input max="32" v-model="model.usrTel" />
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminProUsrZip')" prop="usrZip">
            <input class="rsk-input rsk-input--narrow rsk-mr-s" type="text" name="inCopZip" id="inCopZip" maxlength="32" v-model="model.usrZip" />
            <button class="rsk-button rsk-button--medium rsk-button--outline-admin-primary rsk-mr-s" @click="researchZip(model.usrZip.replace('-', ''))">{{ $t("usrProPages.adminProUsrZipLink") }}</button>
            <div class="rsk-text-gray060 rsk-font-xs">{{ $t("usrProPages.adminUsrProEditUsrZipExample") }}</div>
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminProUsrAdr')" prop="usrAdr">
              <rsk-input max="250" v-model="model.usrAdr" />
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminUsrProPost')" prop="post">
            <rsk-input max="30" v-model="model.post" />
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminUsrProUsrmstObject')" prop="usrmstObjects">
            <rsk-select :options="[
              {label:$t('usrProPages.adminUsrProUsrmstObject0'), value:'2'},
              {label:$t('usrProPages.adminUsrProUsrmstObject1'), value:'3'},
              {label:$t('usrProPages.adminUsrProUsrmstObject2'), value:'4'},
              {label:$t('usrProPages.adminUsrProUsrmstObject3'), value:'6'},
              {label:$t('usrProPages.adminUsrProUsrmstObject4'), value:'5'}
              ]" 
              v-model="model.usrmstObject" />
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <rsk-form-item isListUsrPro :label="$t('usrProPages.adminUsrProEditWorkYearMonth')">
            <select class="rsk-mr-xxs" v-model="model.workYear">
              <template v-for="item in birthYear" >
                <option>{{ item }}</option>
              </template>
            </select>
            <span class="rsk-mr-xs">{{ $t("common.adminApplicationYear") }}</span>
            <select class="rsk-mr-xxs" v-model="model.workMonth">
              <template v-for="item in birthMonth" >
                <option>{{ item }}</option>
              </template>
            </select>
            <span class="rsk-mr-xs">{{ $t("common.adminApplicationMonth") }}</span>
          </rsk-form-item>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <div class="rsk-form-control">
            <div class="rsk-flex rsk-justify-between rsk-width-250">
              <div class="rsk-form-control__label">{{ $t("usrProPages.adminUsrProMailFlg1") }}</div>
              <div class="rsk-form rsk-mr-s">
              </div>
            </div>
            <div class="rsk-form">
              <div class="rsk-checkbox-container">
                <input class="rsk-checkbox" type="checkbox" :value="model.mailFlg" v-model="model.mailFlg" id="item4">
                <label class="rsk-checkbox__image" for="item4"></label>
                <label for="item4" class="rsk-checkbox__label">{{ $t("usrProPages.adminUsrProMailFlg2") }}</label>
              </div>
              <div class="rsk-text-gray060 rsk-font-xs">{{ $t("usrProPages.adminUsrProMailFlg3") }}</div>
              <div class="rsk-text-gray060 rsk-font-xs">{{ $t("usrProPages.adminUsrProMailFlg4") }}</div>
            </div>
          </div>
          </template>
          <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
          <div class="rsk-p-s">
            <button class="rsk-button rsk-button--text-user-primary" @click="back">
                <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                    <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                </span>
                {{ $t("common.adminBackButton") }}
            </button>
          </div>
          <rsk-form-submit class="rsk-no-border">
            <rsk-button isExpand type="primary" @click="updateUsrPro">{{ $t("common.adminUpdateButton") }}</rsk-button>
          </rsk-form-submit>
        </div>
      </div>
    </rsk-form>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref, inject, watch } from "vue"
import { ElMessage, ElMessageBox } from 'element-plus'
import { phone } from '@/utils/validateUtils'
import { useI18n } from 'vue-i18n'
import { checkInvalid, validEmail } from '@/utils/validate'
import { HalfToFull } from '@/utils/common'
export default {
  setup() {
    const formRef  = ref({})
    const model = ref({})
    const router = useRouter()
    const tabSelect = ref(0)
    const birthYear = ref([])
    const birthMonth = ref([])
    const birthDay = ref([])
    const service = inject("service")
    const { locale, t } = useI18n({ useScope: 'global' })
    locale.value = localStorage.getItem('lang')
    const tabList = ref([
      { label: t('common.adminApplicationUsrPro'), value: 0, show: true },
      { label: t('common.adminApplicationPassword'), value: 1, show: true }
    ])
    getUsrPro()

    let day = ref(0)
    let myDate = new Date()
    for (let i = myDate.getFullYear(); i >= 1971; i--) {
      birthYear.value.push(i)
    }
    for (let i = 1; i <= 12; i++) {
      birthMonth.value.push(i)
    }
    watch(model, val => {
      if(val.month){
        switch (parseInt(val.month)) {
          case 1: case 3: case 5: case 7: case 8: case 10:
          case 12: day.value = 31
            break
          case 2:
            if ((model.value.year % 400 == 0) || (model.value.year % 4 == 0 && model.value.year % 100 != 0)) {
              day.value = 29
            } else {
              day.value = 28
            }
            break
          default: day.value = 30
            break
        }
        birthDay.value = []
        for (let i = 1; i <= day.value; i++) {
          birthDay.value.push(i)
        }
        if(model.value.day > day.value){
          model.value.day = null
        }
      }
    },{ deep:true })

    const rules = ref(
      {
        usrMlAdr: [
          { required: true, 
              message: t('common.adminErrorsRequiredstring', [t('usrProPages.adminProUsrMlAdr')])
          },
          {
            max: 128,
            message: t('common.adminErrorsLengthMax', [t('usrProPages.adminProUsrMlAdr'), '128'])
          },
          {
            validator: (rule,value,callback) => {
              if (value) {
                  if(!(/^(.+)@(.+)$/.test(value))) {
                    callback(new Error(t('common.adminErrorsEmail', [t('usrProPages.adminProUsrMlAdr'), '@'])));
                  } else {
                    const validateData = { invalidStr: value } 
                     validEmail(validateData,callback,t('common.adminErrorsEmailRfc'))
                  }
              } else {
                callback(new Error(t('common.adminErrorsRequiredstring', [t('usrProPages.adminProUsrMlAdr')])))
              }
              
            }
          }
        ],
        usrMlAdrEx: [
          {
            max: 128,
            message: t('common.adminErrorsLengthMax', [t('usrProPages.adminProUsrMlAdrEx'), '128'])
          },
          {
            validator: (rule,value,callback) => {
              if (value) {
                if(!(/^(.+)@(.+)$/.test(value))) {
                  callback(new Error(t('common.adminErrorsEmail', [t('usrProPages.adminProUsrMlAdrEx'), '@'])));
                } else {
                  const validateData = { invalidStr: value } 
                  validEmail(validateData,callback,t('common.adminErrorsEmailRfc'))
                }
              } else {
                callback();
              }
              
            }
          },
        ],
        usrTel: [
          {
            max: 32,
            message: t('common.adminErrorsLengthMax', [t('usrProPages.adminProUsrTel'), '32'])
          },
          phone(t('common.adminErrorsTel', [t('usrProPages.adminProUsrTel')]))
        ],
        usrZip: [
          {
            validator: (rule, value, callback) => {
              zipCodeValidate(callback);
            }
          }
        ],
        usrAdr: [
          {
            validator: (rule,value,callback) => {
              if (value.length > 250) {
                callback(new Error(t('common.adminErrorsLengthMax', [t('usrProPages.adminProUsrAdr'), '250'])))
              } else {
                const validateData = { invalidStr: value,isUnicoe:true }
                checkInvalid(validateData,service,callback,t('usrProPages.adminProUsrAdr'))
              }
            }
          }
        ],
        post: [
          {
            validator: (rule,value,callback) => {
              if (value.length > 30) {
                callback(new Error(t('common.adminErrorsLengthMax', [t('usrProPages.adminUsrProPost'), '30'])))
              } else {
                const validateData = { invalidStr: value,isUnicoe:true }
                checkInvalid(validateData,service,callback, t('usrProPages.adminUsrProPost'))
              }
            }
          }
        ]
      }
    )

    function zipCodeValidate(callback) {
      let zipCode = model.value.usrZip;
      const pattern = /^[0-9]{3}\-[0-9]{4}$/
      if(!zipCode) {
        callback();
      } else if (zipCode.length == 7) {
        zipCode = model.value.usrZip.substring(0, 3) + "-" + model.value.usrZip.substring(3);
      }
      if (pattern.test(zipCode)) {
        callback();
      }
      callback(new Error(t('common.adminErrorsZipcode', [t('usrProPages.adminProUsrZip')])))
    }

    function tabChange(index) {
      if(index == 0) {
        router.push({ name: 'usrpro'})
      } else if(index == 1) {
        router.push({ name: 'usrpas'})
      }
    }
    function getUsrPro() {
      service.usrpro.get().then((res) => {
        model.value = {...res};
      }).catch((error) => {
        ElMessage({
          type:'error',
          message: error.message,
          showClose: true,
          duration:3000
        })
      })
    }
    function researchZip(zipCode) {
      service.common.researchZip(zipCode).then((res) => {

        const zipData = res
        if (!zipCode) {
          alert(t('common.adminErrorsUsrZip'))
        } else if (!zipData) {
          alert(t('common.adminErrorsUsrZip'))
        } else {
          model.value.usrAdr = zipData.address1 + zipData.address2 + zipData.address3
        }
      }).catch((error) => {
        alert(t('common.adminErrorsUsrZip'))
      })
    }
    function updateUsrPro() {
      ElMessageBox.confirm(
          t('common.adminAskUpdate'),
          {
          cancelButtonClass: "btn-custom-cancel" ,
          confirmButtonText: 'OK',
          cancelButtonText: t('common.adminCancelButton'),
          type: 'warning',
          }
      )
      .then(() => {
        formRef.value.validate(model.value).then((res) => {
          if (res) {
            model.value.usrAdr = HalfToFull( model.value.usrAdr);
            model.value.post = HalfToFull( model.value.post);
            service.usrpro.update(model.value).then((res) => {
              getUsrPro()
              ElMessage({
                  type:'success',
                  message: res,
                  showClose: true,
                  duration:3000
              })
            }).catch((error) => {
              ElMessage({
                type:'error',
                message: error.message,
                showClose: true,
                duration:3000
              })
            })
          }
        })
      })
      .catch(() => {

      })
    }

    function back(){
      router.push({path:'/usr_top'})
    }

    return {
      rules,
      formRef ,
      model,
      birthYear,
      birthMonth,
      birthDay,
      tabList,
      tabSelect,
      tabChange,
      getUsrPro,
      researchZip,
      updateUsrPro,
      back
    }
  }
}
</script>
<style lang="scss" scoped>
.rsk-no-border {
    border: none;
}
</style>
