<template>
  <div>
    <div class="rsk-panel rsk-mb-xl">
      <rsk-form :rules="rules" v-model="form" ref="formRef" cacheKey="fromcache">
        <rsk-form-item
          prop="id"
          label="研修ID"
          annotation="（部分一致）"
        >
          <rsk-input max="10" v-model="form.id" />
        </rsk-form-item>

        <rsk-form-item
          isExpand
          prop="trainName"
          label="研修名"
          annotation="（部分一致）"
        >
          <rsk-input max="100" v-model="form.trainName" isExpand />
        </rsk-form-item>
        <rsk-form-item prop="type" label="分類・階層">
          <div class="rsk-flex">
            <div class="rsk-form rsk-icon-wrap">
              <span class="rsk-icon rsk-icon--question-gray">
              <label class="rsk-tooltip rsk-tooltip--250 rsk-tooltip--bottom">
                <span>指定した分類・階層に関連する研修を検索します
                  <br>指定と異なる分類・階層の研修が表示されることがあります</span>
              </label>
              </span>
            </div>
            <rsk-select
              v-model="form.type"
              label="分類・階層"
              :options="typeOptions"
            />
          </div>
        </rsk-form-item>
        <rsk-form-item prop="studyType" label="学習形式">
          <rsk-checkbox v-model="form.studyType" :options="studyOptions" />
        </rsk-form-item>
        <div class="rsk-form-control">
          <div class="rsk-flex rsk-justify-between rsk-width-196">
            <div class="rsk-form-control__label">種別</div>
            <div class="rsk-form rsk-mr--90 rsk-flex">
              <span class="rsk-icon rsk-icon--question-gray">
              <label class="rsk-tooltip rsk-tooltip--xxs rsk-tooltip--bottom" style="height:fit-content;">
                <span>通常コース：章または目次単位で分割していない、本編コース
                  <br>講師制作動画：研修講師から直接提供を受けて配信している動画（アクセス履歴は残りません）
                  <br>分割コース：本編を章または目次単位で分割して受講できるようにしたコース
                </span>
              </label>
              </span>
            </div>
          </div>
          <rsk-checkbox
            v-model="form.specification"
            :options="specificationOptions"
          />
        </div>
      </rsk-form>

      <rsk-form-submit>
        <rsk-button isExpand @click="refresh()" type="primary">検索</rsk-button>
        <rsk-button isExpand @click="clear" type="tertiary">クリア</rsk-button>
      </rsk-form-submit>
    </div>
    <div v-show="isRecommend">
      <div class="rsk-font-l">過去の受講履歴からおすすめのコースを表示しています。</div>
      <br/>
    </div>
    <rsk-table
      v-if="dispaly37Value"
      ref="tableRef"
      cacheKey="elearningcache"
      @init="tableConfigInit"
      :pagingButton="pagingButton"
      @pageInfoChange="tableChange"
      @sortChange="tableChange"
      :total="tableTotal"
      :dispaly37="dispaly37Value"
      :haveTrainName="haveTrainName"
      :evaluationContentList="evaluationContentList"
      checkbox
      :data="tableData"
      :titles="titles"
      :pageDisFlag="!isRecommend"
    />
    <rsk-table
      v-if="!dispaly37Value"
      ref="tableRef"
      cacheKey="elearningcache"
      @init="tableConfigInit"
      :pagingButton="pagingButton"
      @pageInfoChange="tableChange"
      @sortChange="tableChange"
      :total="tableTotal"
      :dispaly37="dispaly37Value"
      :haveTrainName="haveTrainName"
      :evaluationContentList="evaluationContentList"
      :data="tableData"
      :titles="titles"
      :pageDisFlag="!isRecommend"
    />
  </div>
</template>
<script>
import { ref, inject, onBeforeMount, onMounted, watch, toRefs  } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessageBox } from 'element-plus'
import input from '../../../../core/components/rsk/input.vue';
import { halfNumEg2 } from '@/utils/validateUtils';
import { toFull } from "@/utils/editnew";

export default {
  components: { input },
  props:{
    isFromInfo:{
            type:String,
            default: null
        },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const tableTotal = ref(0);
    const dispaly37Value = ref();
    const jyukoufukaFlg = ref();
    const haveTrainName = ref();
    const formRef = ref();
    const typeObject = ref();
    const priceApplicationName = ref("");
    const priceApplication = ref("");
    const message = ref("");
    const evaluationContentList = ref();
    const tableRef = ref();
    const form = ref({
      styleFlag: "1",
      isRecommend: true
    });
    let recommendFlag = true;
    const isRecommend = ref(false);
    let priceAppCount = 0;
    let fromWhere = "";
    const pagingButton = ref({
      text: "一括申し込み",
      callBack: (res) => {
        if(jyukoufukaFlg.value == "1"){
          alert("全体管理者または所属組織管理者のため、研修申込できません。");
          return;
        }

        if(res.length == 0) {
          ElMessageBox.alert('研修情報を選択してください。', '研修', {
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: 'OK',
            callback: () => {
            },
          })
          return;
        }

        priceApplicationName.value = "";
        priceApplication.value = "";
        for (let i = 0; i < res.length; i++) {
          if (res[i].priceApplication == "申込済") {
            priceApplicationName.value = priceApplicationName.value + "「" + res[i].name.text + "」";
          }else if(res[i].priceApplication == "有料申込済" || res[i].priceApplication == "有料"){
            priceAppCount = priceAppCount + 1;
            priceApplication.value = '有料の研修ですが、';
          }
        }
        if(priceApplication.value != "" && priceAppCount == 1){
          message.value = "有料の研修のため課金が発生しますが、申込してよろしいでしょうか？ \n" + "定価" + formatMoney(res) + "円（税抜）、研修期間は" 
                    + formatStudyTerm(res) + "です。申込後はキャンセルできません。";
          priceAppCount = 0;
        } else if (priceApplicationName.value != "" && priceAppCount <= 1) {
          message.value = priceApplicationName.value +'は申込済みです。申込してもよろしいですか？（申込済の研修は「研修管理」から確認できます。）';
          priceAppCount = 0;
        } else if(priceApplication.value != "" && priceAppCount > 1) {
          ElMessageBox.alert('有料の研修が複数選択されていますので、一括申し込みができません。有料の研修は、１コースのみ選択し、申込してください。', '研修', {
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: 'OK',
            callback: () => {
            },
          })
          priceAppCount = 0;
          return;
        }else{
          message.value = '申込してもよろしいですか？';
          priceAppCount = 0;
        }

        ElMessageBox.confirm(message.value,
          "研修",{
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: "OK",
            cancelButtonText: "キャンセル",
            type: "warning",
            customStyle: "white-space: pre-line",
          }
          ).then(() => {
            applyList(res);
          }).catch(() => {
        });
      },
    });
    const applyList = ( list ) => {
      for(let i = 0;i<list.length;i++){
        list[i].name=list[i].name.text;
      }
      service.elearning.applys(list).then(res=>{
      ElMessageBox({
          showClose: false,
          showCancelButton: true,
          cancelButtonClass: "btn-custom-cancel",
          confirmButtonText: '研修を確認する',
          cancelButtonText: "閉じる",
          customStyle: "white-space: pre-line",
          message: res,
          type:'success',
        }).then(() => {
          let columnCondition = 9
          let orderCondition = '2'
          if (list.length > 1) {
            columnCondition = 10
            orderCondition = '1'
          }
          router.push({ name: 'elgstudy', params: {tabSelect: 0, isFromInfo: 1, column: columnCondition, order: orderCondition}});
        }).catch(() => {
        });
      }).catch((error) => {

      });
      search();
    }
    const rules = ref({
      id: [
        halfNumEg2('研修ID は半角英数字で入力してください。'),
        {
          min: 2,
          max: 10,
          message: "研修ID は 2 文字以上で入力してください。",
        },
      ],
    });
    const titles = ref([
      {
        field: "name",
        title: "研修名",
        order: "none",
        BoldWord: true,
      },
      {
        field: "priceApplication",
        title: "区分",
        label: true,
        order: "none",
      },
      {
        field: "id",
        title: "研修ID",
        order: "none",
      },
      {
        field: "elearningType",
        title: "分類・階層",
        order: "none",
      },
      {
        field: "baseStudyTime",
        title: "標準学習時間",
        postfix: "時間",
        order: "none",
      },
      {
        field: "evaluationContent",
        title: "評価",
        changeEvaluationContent: true,
        order: "none",
      },
    ]);
    const tableData = ref([]);
    const typeOptions = ref();

    const studyOptions = ref([
      {
        label: "テキスト",
        value: "1",
      },
      {
        label: "映像",
        value: "2",
      },
      {
        label: "アニメーション",
        value: "3",
      },
    ]);

    const specificationOptions = ref([
      {
        label: "通常コース",
        value: "1",
      },
      {
        label: "講師制作動画",
        value: "4",
      },
      {
        label: "分割コース",
        value: "2",
      },
      {
        label: "オリジナルeラーニング",
        value: "3",
      },
    ]);
    const service = inject("service");

    let pagingInfo = {};
    let orders = {};
    function tableConfigInit(sorts, paging) {
      orders = sorts;
      pagingInfo = paging;
    }
    function tableChange(sorts, paging) {
      tableConfigInit(sorts, paging);
      search();
    }
    onBeforeMount(()=> {
      service.common.evaluationContent().then((res) => {
          evaluationContentList.value = res;
      }).catch((error) => {
          console.log(error);
      });
      service.common.typeObject().then((res) => {
        typeOptions.value = res;
      }).catch((error) => {
        console.log(error);
      }); 
    })
    onMounted(()=>{
      document.title = "サイバックスUniv.(研修(eラーニング))"
      if(props.isFromInfo == "0"){
        form.value = {};
        form.value.styleFlag = "1";
        form.value.trainName = "";
        recommendFlag = true;
        formRef.value.clear();
        tableRef.value.clear();
      }else{
        form.value.styleFlag = "1";
        recommendFlag = false;
        search()
      }

   
    })



    // watch(()=>route.path,(from)=> {
    //   if(from !== '/elearning/info') {
    //       formRef.value.clear();
    //       tableRef.value.clear();
    //       }
    //   })

    function refresh() {
      fromWhere = "search"
      form.value.trainName = toFull(form.value.trainName);
      tableRef.value.clear();
    }

    function search() {
      form.value.styleFlag = "1";
      form.value.isRecommend = recommendFlag;
      formRef.value.validate(form.value).then((res) => {
        if (res) {
          service.elearning
            .search({ ...pagingInfo, ...form.value, ...orders, fromWhere})
            .then((res) => {
              fromWhere = ""
              res.data.forEach((item) => {
                item.name = {
                  text: item.name,
                  elearningId: item.id,
                  elearningName: item.name,
                  loginRank: res.usrLoginRank,
                  dispaly37: res.dispaly37,
                  rank: item.rank,
                  priceApplication: item.priceApplication,
                  callBack: function (item) {
                    router.push({ name: 'elearning-info', params: {elearningId:item.elearningId, rank:item.rank, elearningName:item.elearningName,
                    loginRank: res.usrLoginRank, priceApplication: item.priceApplication, jyukoufukaFlg: res.jyukoufukaFlg}})
                  },
                };
                if(item.priceApplication == "有料申込済" || item.priceApplication == "申込済"){
                    item.disabled = true;
                }
                item.elearningType = "";
                if(item.typeObject != null){
                  item.elearningType = getLable(item.typeObject);
                }

              });
              dispaly37Value.value = res.dispaly37;
              jyukoufukaFlg.value = res.jyukoufukaFlg;
              isRecommend.value = res.isRecommend;
              recommendFlag = false;
              haveTrainName.value = "";
              if(form.value.trainName != undefined){
                haveTrainName.value = form.value.trainName;
              }
              tableTotal.value = res.count;
              tableData.value = res.data;
            });
        }
      });
    }

    function getLable(value) {
        for(let i = 0;i<typeOptions.value.length;i++) {
            if(value == typeOptions.value[i].value) {
                return typeOptions.value[i].label;
            }
        }
    }

    function clear() {
      form.value = {};
      formRef.value.clear();
      form.value.styleFlag = "1";
      form.value.trainName = "";
      recommendFlag = true;
      tableRef.value.clear();
    }

    function formatMoney(data) {
      var target;
      if (data.length > 1) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].priceApplication == "有料" || data[i].priceApplication == "有料申込済") {
            target = data[i];
          }       
        }
      } else {
        target = data[0];
      }
      if(target.fixedPrice == '' || target.fixedPrice == null) {
        return 0;
      } else {
          return (
            (String(target.fixedPrice).split(",").join("") || 0)
              .toString()
              .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
          );
      }
    }

    function formatStudyTerm(data) {
      var target;
      if (data.length > 1) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].priceApplication == "有料" || data[i].priceApplication == "有料申込済") {
            target = data[i];
          }       
        }
      } else {
        target = data[0];
      }
      return target.studyTerm == 0 ? "期間制限無し" : "本日から" + target.studyTerm + "ヶ月";
    }

    return {
      clear,
      search,
      getLable,
      pagingButton,
      haveTrainName,
      evaluationContentList,
      tableTotal,
      dispaly37Value,
      tableConfigInit,
      tableChange,
      rules,
      formRef,
      tableRef,
      form,
      studyOptions,
      specificationOptions,
      titles,
      tableData,
      typeOptions,
      refresh,
      formatMoney,
      formatStudyTerm,
      isRecommend
    };
  },
};
</script>
<style scoped>
</style>
