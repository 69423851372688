import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/elearning')
export default class Elearning extends BaseService {
	search(data) {
		return this.request({
			data: data,
			url: "/search",
			method: "POST"
		});
	}

	init() {
		return this.request({
			url: "/init",
			method: "POST"
		});
	}

	applys(data) {
		return this.request({
			data: data,
			url: "/applys",
			method: "POST"
		});
	}
}