<template>
    <div class="lec-header">
        <img class="logo" src="../../assets/logo.png" />
    </div>
    <div class="line" />
    <div class="lec-body">
        <div class="lec-title">JASSAキャリアカレッジ 申込フォーム</div>

        <ol class="stepBar step3">
            <li class="step">お客さま情報の入力</li>
            <li class="step">入力内容の確認</li>
            <li class="step current">お申込み完了</li>
        </ol>

        <div class="finish-text">
            お申込みいただきありがとうございます。<br/>
            ご登録いただきましたメールアドレス宛に<br/>
            ID/パスワードをお送りしておりますのでご確認ください。<br/>
            メールが届かない場合は、サポートデスクまでお問い合わせください。<br/>
            ＜フリーコール＞0120-953-850<br/>
            受付時間：10：00～16：00（土・日・祝日・年末年始を除く）<br/>
        </div>
    </div>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { onMounted} from 'vue'

    export default {
        name: 'jassa_finish',
        setup() {
            const router = useRouter()
            const route = useRoute()

            onMounted(() => {
                if (typeof(route.params.fromUrl) == "undefined") {
                    router.push({path:'/500'})
                } 
            })

            return {
            }
        },
    }
</script>
<style lang="scss" src="./css/common.scss" scoped />

