<template>
        <div class="rsk-form-control">
        <div  v-if="isList" class="rsk-flex rsk-justify-between" :style="'width:'+itemWidth">
            <div v-if="isYoshin" class="rsk-form-control__label2">{{label}}</div>
            <div v-else class="rsk-form-control__label">{{label}}</div>
            <div class="rsk-form rsk-mr-s">
                <span v-if="hintText" class="rsk-label-must">{{hintText}}</span>
            </div>
        </div>
        <div  v-else-if="isListUsrPro" class="rsk-flex rsk-justify-between" :style="'width:'+itemWidthUsrPro">
            <div class="rsk-form-control__label1">{{label}}</div>
            <div class="rsk-form rsk-mr-s">
                <span v-if="hintText" class="rsk-label-must">{{hintText}}</span>
            </div>
        </div>
        
        <div v-else class="rsk-form-control__label">{{label}}</div>

        <div :class="fields[prop] && !fields[prop].validate ?'rsk-form-item--error': ''" :style="isExpand?'flex-grow: '+flexGrow+';':''">
            <slot></slot>
        <div v-if="fields[prop] && !fields[prop].validate"  class="rsk-form-control__annotation rsk-annotation" v-html="fields[prop].msg"></div>
        </div>
        <div v-if="annotation" class="rsk-form-control__annotation">{{annotation}}</div>


    </div>
</template>
<script>
import { ref,inject, watch } from 'vue';
export default {
    props: {
        isList:{
            type: Boolean,
            default: false,
        },
        isListUsrPro:{
            type: Boolean,
            default:false
        },
        hintText:{
            type: String,
            default: '',
        },
        label:{
            type: String,
            default: ''
        },
        prop:{
            type: String,
            default: ''
        },
        annotation:{
            type: String,
            default: ''
        },
        isExpand:{
            type: Boolean,
            default: false
        },
        flexGrow: {
            type: String,
            default: 0.2
        },
        isYoshin:{
            type: Boolean,
            default: false,
        }
    },
    setup(props, { emit }) {
        const rskForm = inject('rskForm')
        const itemWidth = inject('rskForm-item-width')
        const itemWidthUsrPro = inject('resForm-item-usrPro-width')
        // rskForm.fields[props.prop] && !rskForm.fields[props.prop].validate
        // watch(()=>rskForm,(old, newV)=>{
        // },{
        //     deep:true
        // }
        // )
        return {
            itemWidth,
            itemWidthUsrPro,
            ...props,
            fields:rskForm.fields
        }
    },
}
</script>
<style scoped>
.rsk-label-must {
  display: inline-flex;
  align-items:center;
  justify-content:center;
  background-color:#EE4967;
  border-radius:4px;
  font-size:11px;
  color:#FFFFFF;
  /* width:38px; */
  min-width: 38px;
  max-width: 65px;
  height:21px;
  margin-left:16px
}
</style>
