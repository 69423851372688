<template>
    <div>
        <div class="rsk-ranking rsk-mb-s" v-for="(item, index) in list" :key="index">
          <div :class="'rsk-ranking__num rsk-ranking__num--' + item.index">{{item.index}}</div>
          <div class="rsk-ranking__text-box">
            <div v-if="item.clickFlag == 0" class="rsk-ranking__title_unclick">{{item.title}}</div>
            <div  @click="toElgInfo(item.elearningId, item.rank, item.title, item.loginRank, item.priceApplication)"  class="rsk-ranking__title" v-else>{{item.title}}</div>
            <div class="rsk-ranking__type">{{item.describe}}</div>
          </div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router'
export default {
    props: {
        /**
         * {
         *  title,
         *  describe,
         *  index,
         *  id
         * }
         */
        list:[]
    },
    setup(props) {
        const router = useRouter();
        function toElgInfo(elearningId, rank, elearningName, loginRank, priceApplication) {
            router.push({ name: 'elearning-info',
            params: {
                    elearningId: elearningId,
                    rank: rank,
                    elearningName: elearningName,
                    loginRank: loginRank,
                    priceApplication: priceApplication,
                    fromUrl: 'usr_top_children'
                }
            })
        }
        return {
            ...props,
            toElgInfo
        }
    },
}
</script>

<style lang="scss" scoped>
.rsk-ranking__title:hover {
    text-decoration: underline;
}
.rsk-ranking__title_unclick {
    font-size: 16px;
    margin-bottom: 8px;
    max-width: 380px;
    cursor: text;
}
</style>
