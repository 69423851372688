import { Service, BaseService } from "@/core/auto";

@Service('/api/invalid/')
export default class invalid extends BaseService{
    validate(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/validate",
			method: "POST"
		})
	}
}