<template>
<div class="rsk-flex rsk-justify-center rsk-container">
    <div class="rsk-pt-m rsk-pb-m">
        <div class="rsk-font-bold rsk-font-xl rsk-mb-s" style="color: #004F9A;font-size: 24px;"><img src="../../assets/pagetitle.gif" alt="."/>お問い合わせフォーム</div>
        <div class="rsk-mb-m rsk-panel">
            <div class="rsk-mb-s">送信しました。</div>
            <div class="rsk-mb-s">受付完了メールをお送りしておりますので、ご確認ください。</div>
            <div class="rsk-mb-s">もしメールが届かない場合、入力されたメールアドレスが間違っているか、受信許可設定の必要がある等の可能性があります。</div>
        </div>
        <div class="rsk-flex rsk-justify-center">
            <div class="rsk-form-button-wrapper__button">
                <button @click="close" class="rsk-button rsk-button--medium rsk-button--expand  rsk-button--user-primary">閉じる</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    setup(){
        function close(){
            window.close();
        }
        return{
            close
        }
    }
}
</script>

<style scoped>
.rsk-container {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #f5f7fa;
}
.new-textarea {
    height: initial !important;
}
</style>