import { Service, BaseService } from "@/core/auto";

@Service('/api/opejassa')
export default class jassa extends BaseService{
    create(data) {
		return this.request({
            data: data,
			url: "/confirm",
			method: "POST"
		});
	}
	prepare(data) {
		return this.request({
            data: data,
			url: "/prepare",
			method: "POST"
		});
	}

	validate(data) {
		return this.request({
            data: data,
			url: "/validate",
			method: "POST"
		});
	}
}