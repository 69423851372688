<template>
    <div class="content">
        <div class="rsk-p-xl">
        <div class="rsk-form-control">
            <h2 class="rsk-page-title rsk-mb-s">テスト</h2>
        </div>
        <rsk-tabs :list="['テスト', 'アンケート', 'レポート']" :modelValue="0" @onChange="tabsChange" />
        <div class="rsk-panel rsk-mb-m">
            <rsk-form v-model="form" :rules="rules" ref="formRef" cacheKey="testCache">
                <rsk-form-item label="テスト名" annotation="(部分一致)" prop="testName">
                    <rsk-input max="50" v-model="form.testName" />
                </rsk-form-item>
                <rsk-form-item hintText="必須" label="ステータス" prop="status" isList>
                    <rsk-checkbox v-model="form.status" :options="[{label:'未受験', value:'0'}, {label:'受験済', value:'1'}]" />
                </rsk-form-item>
            </rsk-form>

            <rsk-form-submit>
                <rsk-button isExpand @click="refresh()" type="primary">検索</rsk-button>
                <rsk-button isExpand @click="clear" type="tertiary">クリア</rsk-button>
            </rsk-form-submit>
        </div>
        <rsk-table cacheKey="testCache"  :total="tableTotal"
                   @init="tableConfigInit" @sortChange="tableChange" 
                   @pageInfoChange="tableChange" :data="tableData" 
                   :titles="titles" ref="tableRef"
        />
        </div>
    </div>
</template>
<script>
import { dateFtt } from '@/utils/common'
import { ref, onMounted, inject, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { checkInvalid } from '@/utils/validate'
import { full } from '@/utils/validateUtils'
import { tabsChangeEffect } from '../js/course'
import { useStore } from 'vuex'
export default {
    
    setup() {

    const formRef = ref()
    const tableRef = ref();
    const tableTotal = ref(0)
    const form = ref({
        status: [0, 1]
    })

    const store = useStore();
    const rules =  ref(
        {
            testName: [
                full('テスト名は全角で入力してください'),
                { 
                    validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value, isUnicoe: true }
                        checkInvalid(validateData, service, callback, 'テスト名')
                    }
                },
                {
                    max: 50,
                    message: 'テスト名は50文字以内で入力してください。'
                },
            ],
            status: [
                {
                    validator: (rule, value, callback) => {
                        if (form.value.status.length == 0) {
                            callback(new Error("ステータスを選択してください。"));
                        } else {
                            callback();
                        }
                    },
                },
            ],
        }
    )
        const titles = ref([
        {
            field: 'testId',
            title: 'ID',
            order: 'asc',     
        },
        {
            field: 'testName',
            title: 'テスト名',
            order: 'none',
        },
        {
            field: 'testStatus',
            title: 'ステータス',
            order: 'none',
        },
        {
            field: 'examMaxNumberDp',
            title: '受験回数',
            order: 'none',
        },
        {
            field: 'testResultStr',
            title: '結果',
            order: 'none',
        },
        {
            field: 'answerBeginTm',
            title: '受験日',
            order: 'none',
        },
        {
            field: 'limitDate',
            title: '受験期限日',
            order: 'none',
        },
    ])
    const tableData = ref([])

    const route = useRoute();
    const router = useRouter()
    const { tabsChange } = tabsChangeEffect(router);
    onMounted(() => {
        store.commit('setTestStartTime', null);
        localStorage.removeItem('testLessonId')
        localStorage.removeItem('testAnswerQuestionListTemp')
        submit();
    })
    const service = inject("service");
    function submit() {

        formRef.value.validate(form.value).then((resp) => {
            if(resp) {
                service.test.search({...pagingInfo, ...form.value,...columnInfo}).then(res => {
                res.list.forEach(item =>{
                    var testName = item.testName;
                    item.testStatus === "0" ? item.testStatus = "未受験" : item.testStatus = "受験済"
                    if (item.count == null || item.count == "0") {
                        item.examMaxNumberDp = "0回"
                    } else if(item.examMaxNumber == "999") {
                        item.examMaxNumberDp = item.count + "回/無制限"
                    } else {
                        item.examMaxNumberDp = item.count + "回/" + item.examMaxNumber + "回";
                    }
                    if (item.studyFlg == '1') {
                        item.testName = {
                            text:item.testName,
                            callBack: function() {
                                goAnswer(item.testId, item.lessonId);
                            }
                        }
                    } else if(item.deletedFlg != 1) {
                        item.testName = {
                            text:item.testName,
                            callBack: function() {
                                goConfirm(item.testId, item.lessonId)
                            }
                        }
                    }

                    if (item.studyFlg == '0' && item.testStatus == "未受験") {
                        item.testName = testName;
                    }
                    if(item.testResultStr === "不合格（再受験）") {
                        item.testResultStr = {
                            text: item.testResultStr,
                            callBack: function() {
                                goAnswer(item.testId, item.lessonId);
                            }
                        }
                    }
                    if(item.answerBeginTm != null) {
                        item.answerBeginTm = dateFtt('yyyy/MM/dd', item.answerBeginTm);
                    } else {
                        item.answerBeginTm = "-"
                    }

                    if(item.limitDate != null) {
                        item.limitDate = dateFtt('yyyy/MM/dd', item.limitDate);
                    } else {
                        item.limitDate = "-"
                    }
                    if(item.deleted == 1) {
                        item.disabled = true
                    }

                })
                    tableTotal.value = res.count
                    tableData.value = res.list
                })
            }
        })
    }

    function refresh() {
        tableRef.value.clear();
    }

    let pagingInfo = {}
    let columnInfo = {}
    function tableConfigInit(sorts, paging) {
        pagingInfo = paging
        if (sorts.column == 3) {
            if(sorts.order === '1') {
                sorts.column = 7
            } else {
                sorts.column = 8
            }
        }
        columnInfo = sorts;
    }
    var stopRequestFlag = false;
    function tableChange(sorts, paging) {     
        tableConfigInit(sorts, paging)
        if(!stopRequestFlag){
            submit()
        }
    }
    function goConfirm(testId, lessonId) {
        if(lessonId == null) {
            lessonId = 0;
        }
        let id = testId + "-" + lessonId
        router.push({
            name: 'testConfirm',
            params: {
                id : id
            }
        })
    }
    function goAnswer(testId, lessonId) {
        if(lessonId == null) {
            lessonId = 0;
        }
        let id = testId + "-" + lessonId;
        router.push({
            name: 'testAnswer',
            params: {
                id : id
            }
        })
    }

    function clear() {
      form.value = {};
      form.value.status = [0, 1];
      formRef.value.clear();
      tableRef.value.clear(); 
    }

    watch(()=>route.path,(to)=> {
        if(to !== '/listen/tes/confirm' && to !== '/listen/tes/answer') {
            stopRequestFlag = true
            formRef.value.clear();
            tableRef.value.clear();
        }
    }) 
    return {
        submit,
        clear,
        tabsChange,
        tableChange,
        form,
        tableData,
        titles,
        rules,
        tableTotal,
        pagingInfo,
        tableConfigInit,
        formRef,
        tableRef,
        columnInfo,
        refresh
    }
}
}
</script>