<template>
    <div class="content">
        <rsk-container title="Webセミナー詳細" :buttons="buttons">
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs" v-for="(item, index) in infos" :key="index">
                <div class="rsk-flex rsk-justify-between rsk-width-212">
                    <div class="rsk-table-like-form__heading rsk-table-like-form__table-column">{{item.title}}</div>
                </div>
                <div class="rsk-form rsk-form">
                    <div v-if="item.title == '研修内容' || item.title == '注意事項'" v-html="item.value"></div>
                    <div v-else-if="item.title == '定価（税抜）' && item.price == 0" class="rsk-text">0円</div>
                    <div v-else-if="item.title == '定価（税抜）' && item.price == 1" class="rsk-text">{{item.value}}円</div>
                    <div v-else-if="item.title == '定価（税抜）' && item.price == 2" class="rsk-text"><s>{{item.value}}円</s>（ご契約料金内でご利用いただけます）</div>
                    <div v-else-if="item.title == '申込人数'" class="rsk-text">
                        <div class="rsk-form">
                            <select class="rsk-input" 
                                    style="min-width: 0px" 
                                    :disabled="true"
                            >
                                <option>{{trainAppNum}}人</option>
                            </select>
                        </div>
                    </div>
                    <div v-else-if="!item.callBack" class="rsk-text">{{ item.value }}</div>
                    <div v-else-if="item.callBack && (item.value == '' || item.value == null)" class="rsk-text">{{item.value}}</div>
                    <el-button v-else type="text" @click="item.callBack(item)" >{{item.value}}</el-button>
                </div>
            </div>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>
        </rsk-container>
        <div class="rsk-p-s">
            <button class="rsk-button rsk-button--text-user-primary" @click="back">
                <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                    <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                </span>
                戻る
            </button> 
        </div> 
    </div>          
</template>

<script>
import { useRouter,useRoute } from 'vue-router'
import { ref } from "vue";
import { inject } from "vue";
import { dateFtt } from "@/utils/common";
import { UAParser } from "../../popenvironment/js/ua-parser.min";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const service = inject("service");
    const infos = ref([]);
    const typeObject = ref([]); 
    const trainAppNum = ref();
    let goUrl = "trastudy";

    if(typeof(route.params.fromUrl) != "undefined") {
        goUrl = route.params.fromUrl;
    }
    
    service.common.typeObject().then((res) => {
        typeObject.value = res;
    }).catch((error) => {
        console.log(error);
      });
          
    if(!route.params.trainId) {
        router.push({ name: 'trastudy'});
        return;
    } else {
        let formData = {trainId:route.params.trainId,rank:route.params.rank}
        service.trastudy.show(formData).then((res) => {
            infos.value.push({title: '研修ID',value: res.model.trainId})
            infos.value.push({title: '研修名',value: res.model.trainName})
            infos.value.push({title: '分類・階層',value: getLable(res.model.trainTypeObject)})
            infos.value.push({title: '提供会社',value:  res.model.trainSupplier})
            infos.value.push({title: '研修内容',value: res.model.unitDetail})
            infos.value.push({title: '注意事項',value: res.model.newExamPreMessage})
            infos.value.push({title: '研修場所',value: res.model.trainPlace})
            infos.value.push({title: '研修所在地',value: res.model.trainAddress})
            infos.value.push({title: '研修期間',value: dateFtt("auto_time",res.model.openStartDtm,"en") + " ～" + dateFtt("auto_time",res.model.openEndDtm,"en")})
            infos.value.push({title: '受付終了日',value:dateFtt("auto",res.model.endDate,"en") })
            infos.value.push({title: 'キャンセル受付終了日',value:dateFtt("auto", res.model.cancelEndDate,"en")})
            if(res.model.trainFlg == 0){
                infos.value.push({title: '学習形式',value: "Webセミナー"})     
            } else if(res.model.trainFlg == 1){
                infos.value.push({title: '学習形式',value: "自社研修"})       
            }
            infos.value.push({title: '添付ファイル1',value: res.model.name1,callBack: (item) => {
                downloadFile(res.model.name1,res.model.dir1);
            }})
            infos.value.push({title: '添付ファイル2',value: res.model.name2,callBack: (item) => {
                downloadFile(res.model.name2,res.model.dir2);
            }})
            if(res.model.reserveCount !== null && res.model.reserveCount !== 0){
                infos.value.push({title: '予定人数',value: res.model.reserveCount + "人"})            
            } else {
                infos.value.push({title: '予定人数',value: "0人"})
            }
            if(res.model.enforcementCount !== null && res.model.enforcementCount !== 0){
                infos.value.push({title: '実施人数',value: res.model.enforcementCount + "人"})
            } else {
                infos.value.push({title: '実施人数',value: "0人"})
            }
            if(res.model.fixedPrice == null || res.model.fixedPrice == '' || res.model.fixedPrice == 0) {
                infos.value.push({title: '定価（税抜）',value: "0",price:0})
            } else {
                if(res.model.usrLoginRank > res.model.rank) {
                    infos.value.push({title: '定価（税抜）',value: res.model.fixedPrice,price:1})
                } else {
                    infos.value.push({title: '定価（税抜）',value: res.model.fixedPrice,price:2})                
                }
            }
            if (res.model.trainFlg == 0) {
                infos.value.push({title: '申込人数', value: res.model.trainAppNumList})
                trainAppNum.value = res.model.trainAppNum;
            }

        }).catch((error) => {
            console.log(error);
        });                
    }

	function downloadFile(filename,fileurl){
        var ua = new UAParser();
        let userAgent = ua.getUA();
        const downloadData = {filename:'',fileurl:'',userAgent:''}   
		downloadData.filename = filename;
		downloadData.fileurl = fileurl;
		downloadData.userAgent = userAgent;
		service.common.downloadFile(downloadData)
	}    

    function back() {
        if (goUrl == 'usr_top_children') {
            router.push({ name: 'usr_top_children'}) 
        } else {
            router.push({ name: 'trastudy'})
        }
        
    }

    function getLable(value) {
        for(let i = 0;i<typeObject.value.length;i++) {
            if(value == typeObject.value[i].value) {
                return typeObject.value[i].label;
            }
        }
    }

    return {
        infos,
        typeObject,
        downloadFile,back,getLable,
        trainAppNum
    };
  },
};
</script>