<template>
    <div class="lec-header">
        <img class="logo" src="../../assets/logo_jassa.png" />
    </div>
    <div class="line" />
     <div class="lec-body">
        <div class="lec-title">JASSAキャリアカレッジ 申込フォーム</div>

        <div class="sub-title1">
            JASSAキャリアカレッジにお申し込みいただくまえに、画面下部の規約をお読みになり、同意の上お申し込みください。<br/>
            なお、※は必須項目になりますので、必ずご記入ください。
        </div>

        <ol class="stepBar step3 pc-on">
            <li class="step current">お客さま情報の入力</li>
            <li class="step">入力内容の確認</li>
            <li class="step">お申込み完了</li>
        </ol>

        <table class="tb-basic form sp-block">
            <tr>
                <th>JASSA会員番号<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="memberIdInput" v-model="model.memberId" placeholder="1234" maxlength="4"/>
                    <div class="error-text">{{memberIdMessage}}</div>
                    <div class="notice-text">半角（例）1234</div>
                    <a class="toUrl" href="https://www.jassa.or.jp/search/index.php" target="_blank">会員番号参照</a>
                </td>
            </tr>
            <tr>
                <th>会社名<br/>   <span class="must">必須</span></th>

               <td>
                   <input id="companyInput" v-model="model.company" placeholder="株式会社派遣商事" maxlength="40"/>
                   <div class="error-text">{{companyMessage}}</div>
                   <div class="notice-text">全角（例） 株式会社派遣商事</div>
                </td>
            </tr>
            <tr>
                <th>所属</th>

                <td>
                    <input id="belongInput" v-model="model.belong" placeholder="スタッフ管理部" maxlength="60"/>
                    <div class="error-text">{{belongMessage}}</div>
                    <div class="notice-text">全角（例） スタッフ管理部</div>
                </td>
                
            </tr>
            <tr>
                <th>申込担当者氏名<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="nameInput" v-model="model.name" placeholder="派遣　太郎"/>
                    <div class="error-text">{{nameMessage}}</div>
                    <div class="notice-text">全角（例） 派遣　太郎</div>
                </td>
                
            </tr>
            <tr>
                <th>郵便番号<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="zipCodeInput" v-model="model.zipCode" placeholder="123-4567" maxlength="32"/>
                    <div class="error-text">{{zipCodeMessage}}</div>
                    <div class="notice-text">半角（例）123-4567</div>
                </td>
            </tr>
            <tr>
                <th>住所<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="addressInput" v-model="model.address" placeholder="東京都港区○○１－２－３"/>
                    <div class="error-text">{{addressMessage}}</div>
                    <div class="notice-text">全角（例） 東京都港区○○１－２－３</div>
                </td>
            </tr>
            <tr>
                <th>電話番号</th>

                <td>
                    <input id="phoneInput" v-model="model.phone" placeholder="03-1234-5678" maxlength="32"/>
                    <div class="error-text">{{phoneMessage}}</div>
                    <div class="notice-text">半角（例）03-1234-5678</div>
                </td>
            </tr>
            <tr>
                <th>E-mail<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="mailAdrInput" v-model="model.mailAdr" placeholder="haken@example.co.jp" maxlength="128"/>
                    <div class="error-text">{{mailAdrMessage}}</div>
                    <div class="notice-text">半角（例） haken@example.co.jp</div>
                </td>
            </tr>
        </table>

        <div class="sub-title2">請求ご担当者様の連絡先を記入してください。</div>
        <div class="sub-title3">
            請求ご担当者様の情報は、以下の目的でのみ使用いたします。<br/>
            ・口座振替の手続のための書類の送付先（郵送での送付となります）<br/>
            ・その他、お支払いに関するご連絡<br/>
        </div>

        <table class="tb-basic form sp-block">
            <tr>
                <th>請求先所属<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="reBelongInput" v-model="model.reBelong" placeholder="スタッフ管理部" maxlength="60"/>
                    <div class="error-text">{{reBelongMessage}}</div>
                    <div class="notice-text">全角（例） スタッフ管理部</div>
                </td>
            </tr>
            <tr>
                <th>請求先担当者氏名<br/>   <span class="must">必須</span></th>

               <td>
                   <input id="reNameInput" v-model="model.reName" placeholder="派遣　太郎" maxlength="30"/>
                   <div class="error-text">{{reNameMessage}}</div>
                   <div class="notice-text">全角（例） 派遣　太郎</div>
                </td>
            </tr>
            <tr>
                <th>請求先郵便番号<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="reZipCodeInput" v-model="model.reZipCode" placeholder="123-4567" maxlength="32"/>
                    <div class="error-text">{{reZipCodeMessage}}</div>
                    <div class="notice-text">半角（例）123-4567</div>
                </td>
                
            </tr>
            <tr>
                <th>請求先住所<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="reAddressInput" v-model="model.reAddress" placeholder="東京都港区○○１－２－３" maxlength="250"/>
                    <div class="error-text">{{reAddressMessage}}</div>
                    <div class="notice-text">全角（例） 東京都港区○○１－２－３</div>
                </td>
                
            </tr>
            <tr>
                <th>請求先電話番号<br/>   <span class="must">必須</span></th>

                <td>
                    <input id="rePhoneInput" v-model="model.rePhone" placeholder="03-1234-5678" maxlength="32"/>
                    <div class="error-text">{{rePhoneMessage}}</div>
                    <div class="notice-text">半角（例）03-1234-5678</div>
                </td>
            </tr>
            <tr>
                <th>文字認証<br/>   <span class="must">必須</span> <br/>（入力欄画像の文字を入力）</th>

                <td>
                    <div class="identify-code" @click="refreshCode">
                        <!--验证码组件-->
                        <s-identify @func="getSidentifyCode" :isRefreshCode="isRefreshCode"></s-identify>
                    </div>
                    <span class="notice"> ※画像をクリックすると新しい認証画像が表示されます。</span>
                    <br>
                    <input id="verifyInput" v-model="model.verify" />
                    <div class="error-text">{{verifyMessage}}</div>
                </td>
            </tr>   
        </table>

        <div class="sub-title2">規約同意について</div>
        <div class="sub-title4">
            JASSAキャリアカレッジは委託先であるリスクモンスター株式会社のeラーニングサービス<br/>
            「サイバックスUniv.」で提供しております。<br/>
            「<a class="toUrl" href="#" @click="downloadUseConcat('JASSAキャリアカレッジサービス利用規約.pdf', '/pdf_file/JASSAキャリアカレッジサービス利用規約.pdf')">
                JASSAキャリアカレッジサービス利用規約
            </a>」
            「<a class="toUrl" href="https://www.jassa.or.jp/privacy/index.html#policy" target="_blank">個人情報の取り扱い</a>」<br/>
            「<a class="toUrl" href="#" @click="downloadUseConcat('サイバックスUniv.利用規約.pdf', '/pdf_file/サイバックスUniv.利用規約.pdf')">
                サイバックスUniv.利用規約
            </a>」
            「<a class="toUrl" href="#" @click="downloadUseConcat('個人情報の取り扱いについて.pdf', '/pdf_file/個人情報の取り扱いについて.pdf')">
                個人情報の取り扱いについて
            </a>」<br/>
            をお読みいただき、下記チェックボックスにチェックしてください。
        </div>
        
        <div class="wrap">
            <rsk-checkbox v-model="model.policyCheckArea" :options="[{ value: 1, label: '利用規約、個人情報の取り扱いを確認しました。' }]" />
        </div>
        <div class="error-text wrap">{{checkBoxMessage}}</div>
        
        <div class="wrap btn-line">
            <div class="btn-wrap">           
                <button @click="moveToShow" class="editnew-btn btn-success">確認画面へ進む</button>
            </div>
        </div>
     </div>
</template>

<script>
    import { inject, ref, onMounted} from 'vue'
    import { useRouter } from 'vue-router'
    import SIdentify from "../../components/verifty.vue";
    import { checkFullKnaNecessary, checkFullKna, checkPhone, checkZipCode, 
        checkMailAdr, checkVerify, checkBox, checkPhoneNecessary, checkHalfNumEgNecessary } from '@/utils/editnew'
    import { UAParser } from "../popenvironment/js/ua-parser.min";

    export default {
        name:'jassa_editnew',
        components: { SIdentify },
        setup() {
            const BORDER_COLOR_RED = 'red';
            const router = useRouter();
            const service = inject("service")
            const identifyCode = ref("");//获取验证码
            const isRefreshCode = ref(false);
            const model = ref({ checkCode: '' });
            const SECURITY_URL = '/ope_jassacom/JgVXwxjneTo=o1smNmmWiEs=/index';

            // 获取验证码
            function getSidentifyCode(data) {
                identifyCode.value = data;
                isRefreshCode.value = false;
            }
            
            // 点击切换验证码
            function refreshCode() {
                identifyCode.value = "";
                isRefreshCode.value = true;   //更改状态--SIdentify组件中监听该状态
            }
        
            function moveToShow() {
                checkFormat();
                
                if (checkFormat() == true) {
                    var formData = {
                        memo: model.value.memberId,
                        comNm: model.value.company,
                        appUserDepNm: model.value.belong,
                        appUsrNm: model.value.name,
                        comZip: model.value.zipCode,
                        comAdr: model.value.address,
                        comTel: model.value.phone,
                        appUsrMlAdr: model.value.mailAdr,
                        demPst: model.value.reBelong,
                        dpsnNm: model.value.reName,
                        demZip: model.value.reZipCode,
                        demAdr: model.value.reAddress,
                        demTel: model.value.rePhone
                    }
                    service.opejassa.validate(formData).then(res => {
                        if (res.comNmErrMsg != null) {
                            document.getElementById('companyInput').style.borderColor = 'red';
                            companyMessage.value = '会社名' + res.comNmErrMsg;
                        }
                        if (res.belongErrMsg != null) {
                            document.getElementById('belongInput').style.borderColor = 'red';
                            belongMessage.value = '所属' + res.belongErrMsg;
                        }
                        if (res.nameErrMsg != null) {
                            document.getElementById('nameInput').style.borderColor = 'red';
                            nameMessage.value = '申込担当者氏名' + res.nameErrMsg;
                        }
                        if (res.addressErrMsg != null) {
                            document.getElementById('addressInput').style.borderColor = 'red';
                            addressMessage.value = '住所' + res.addressErrMsg;
                        }
                        if (res.reBelongErrMsg != null) {
                            document.getElementById('reBelongInput').style.borderColor = 'red';
                            reBelongMessage.value = '請求先所属' + res.reBelongErrMsg;
                        }
                        if (res.reNameErrMsg != null) {
                            document.getElementById('reNameInput').style.borderColor = 'red';
                            reNameMessage.value = '請求先担当者氏名' + res.reNameErrMsg;
                        }
                        if (res.reAddress != null) {
                            document.getElementById('reAddressInput').style.borderColor = 'red';
                            reAddressMessage.value = '請求先住所' + res.reAddress;
                        }
                        if (res.mailErrMsg != null) {
                            setMessageAndBorderColor('mailAdrInput', res.mailErrMsg, BORDER_COLOR_RED, mailAdrMessage);
                        }
                        if (res.comNmErrMsg != null || res.belongErrMsg != null || res.nameErrMsg != null || res.addressErrMsg != null || res.reBelongErrMsg != null
                            || res.reNameErrMsg != null || res.reAddress != null || res.mailErrMsg != null) {
                           return;
                        } else {
                            var jassaMes = {
                                memberId: model.value.memberId,
                                company: model.value.company,
                                belong: model.value.belong,
                                name: model.value.name,
                                zipCode: model.value.zipCode,
                                address: model.value.address,
                                phone: model.value.phone,
                                mailAdr: model.value.mailAdr,
                                reBelong: model.value.reBelong,
                                reName: model.value.reName,
                                reZipCode: model.value.reZipCode,
                                reAddress: model.value.reAddress,
                                rePhone: model.value.rePhone
                            }   
                            router.push({name:'jassa_show', 
                            params: {
                                model: JSON.stringify(jassaMes)
                            }
                            })
                        }
                        
                    })
                    
                }
            }

            const memberIdMessage = ref();
            const companyMessage = ref();
            const belongMessage = ref();
            const nameMessage = ref();
            const zipCodeMessage = ref();
            const addressMessage = ref();
            const phoneMessage = ref();
            const mailAdrMessage = ref();

            const reBelongMessage = ref();
            const reNameMessage = ref();
            const reZipCodeMessage = ref();
            const reAddressMessage = ref();
            const rePhoneMessage = ref();

            const verifyMessage = ref();
            const checkBoxMessage = ref();                        

            function checkFormat() {
                const memberIdResult = checkHalfNumEgNecessary('memberIdInput', model.value.memberId, memberIdMessage, service, 4, 'JASSA会員番号');
                model.value.memberId = memberIdResult[1];
                const companyResult = checkFullKnaNecessary('companyInput', model.value.company, companyMessage, service, 40, '会社名');
                model.value.company = companyResult[1];
                const belongResult = checkFullKna('belongInput', model.value.belong, belongMessage, service, 60, '所属');
                model.value.belong = belongResult[1];
                const nameResult = checkFullKnaNecessary('nameInput', model.value.name, nameMessage, service, 30, '申込担当者氏名');
                model.value.name = nameResult[1];
                const zipCodeResult = checkZipCode('zipCodeInput', model.value.zipCode, zipCodeMessage, '郵便番号');
                model.value.zipCode = zipCodeResult[1]
                const addressResult = checkFullKnaNecessary('addressInput', model.value.address, addressMessage, service, 250, '住所');
                model.value.address = addressResult[1];
                const phoneResult = checkPhone('phoneInput', model.value.phone, phoneMessage, '電話番号');
                const mailAdrResult = checkMailAdr('mailAdrInput', model.value.mailAdr, mailAdrMessage, 'E-mailアドレス ');

                const reBelongResult = checkFullKnaNecessary('reBelongInput', model.value.reBelong, reBelongMessage, service, 60, '請求先所属');
                model.value.reBelong = reBelongResult[1];
                const reNameResult = checkFullKnaNecessary('reNameInput', model.value.reName, reNameMessage, service, 30, '請求先担当者氏名');
                model.value.reName = reNameResult[1];
                const reZipCodeResult = checkZipCode('reZipCodeInput', model.value.reZipCode, reZipCodeMessage, '請求先郵便番号');
                model.value.reZipCode = reZipCodeResult[1]
                const reAddressResult = checkFullKnaNecessary('reAddressInput', model.value.reAddress, reAddressMessage, service, 250, '請求先住所');
                model.value.reAddress = reAddressResult[1];
                const rePhoneResult = checkPhoneNecessary('rePhoneInput', model.value.rePhone, rePhoneMessage, '請求先電話番号');

                const verifyResult = checkVerify('verifyInput', model.value.verify, verifyMessage, identifyCode);
                const checkBoxResult =checkBox(model.value.policyCheckArea, checkBoxMessage, '利用規約、個人情報取り扱い', '確認')

                var result = memberIdResult[0] && companyResult[0] && belongResult[0] && nameResult[0] && zipCodeResult[0] && addressResult[0] && phoneResult && mailAdrResult
                 && reBelongResult[0] && reNameResult[0] && reZipCodeResult[0] && reAddressResult[0] && rePhoneResult && verifyResult && checkBoxResult

                return result;
            }

            function downloadUseConcat(name, url){
              var ua = new UAParser();
              let userAgent = ua.getUA();
              const downloadData = {filename:name, fileurl:url, userAgent:userAgent}
		      service.common.downloadSystemFile(downloadData)
	        }

            /**
             * DOMのIDから要素を取得する
             * @param {elementId}  DOMのID  
             * 
             *  return DOMオブジェクト
             */
            function getElementByIdIfNotNull(elementId) {
                const element = document.getElementById(elementId);
                return element ? element : null;
            }

            /**
             * DOMのIDに基づいて枠線色を設定するとエラーメッセージが表示されます
             * 
             * @param {elementId} DOMのID 
             * @param {message} エラーメッセージ 
             * @param {borderColor} 枠線の色 
             * @param {msgObj} メッセージ・オブジェクト 
             * 
             */
            function setMessageAndBorderColor(elementId, message, borderColor, msgObj) {
                const element = getElementByIdIfNotNull(elementId);
                if (element) {
                    element.style.borderColor = borderColor;
                    msgObj.value = message;
                }
            }

            onMounted(() => {
              var nowUrl = router.currentRoute.value.fullPath;
              var urlData = {
                url: nowUrl
              }
              service.opejassa.prepare(urlData).then(res => {
                  if (res) {
                    if (String(nowUrl).indexOf(SECURITY_URL) !== -1) {
                      router.push({path:'/ope_jassacom/new'});
                    } else {
                      const jassaMes = JSON.parse(localStorage.getItem("jassaInfo"));
                      if (jassaMes != null) {
                        model.value = jassaMes;
                      }
                    }
                  } else {
                    router.push({path:'/404'});
                  }
                
              }) 

            })

            return {
                model,
                identifyCode,
                isRefreshCode,
                getSidentifyCode,
                refreshCode,
                moveToShow,
                downloadUseConcat,
                memberIdMessage,companyMessage,belongMessage,nameMessage,zipCodeMessage,addressMessage,phoneMessage,mailAdrMessage,
                reBelongMessage,reNameMessage,reZipCodeMessage,reAddressMessage,rePhoneMessage,verifyMessage,checkBoxMessage
            }
        }
    }
</script>

<style lang="scss" src="./css/common.scss" scoped />