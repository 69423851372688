import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/popenv')
export default class Popenv extends BaseService{
    index() {
		return this.request({
			url: "/index",
			method: "POST"
		});
	}
}