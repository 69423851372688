<template>
  <section class="aside">
    <div class="rsk-fixed-bottom-right">

      <div class="rsk-mb-xs" v-if="toTopShow">
        <button class="rsk-button rsk-button--white-admin rsk-button--round rsk-button--round-large" @click="toTop()">
            <span class="rsk-button__icon-wrapper">
                <span class="rsk-icon rsk-icon--arrow-upward-admin-primary"></span>
            </span>
        </button>
      </div>
      <div v-if="msgBtnShow">
        <button @click="openWidget" class="rsk-button rsk-button--admin-primary rsk-button--round rsk-button--round-large">
            <span class="rsk-button__icon-wrapper">
                <span class="rsk-icon rsk-icon--question-answer-white"></span>
            </span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>

const useAsideEffect = () => {

  const toTop = () => {
    document.body.scrollTop ? document.body.scrollTop = 0 : document.documentElement.scrollTop = 0
  }

  return { toTop, }
}
import { onBeforeUnmount, onMounted, ref, watch } from "vue";
export default {
  name: "Aside",
  setup () {
    const { toTop } = useAsideEffect()
    const msgBtnShow = ref(false);
    const toTopShow = ref(false);
    let curTopNum = ref(0)

    const exts = document.createElement('script');
    exts.type = 'text/javascript';
    exts.id = 'ze-snippet'
    exts.src = 'https://static.zdassets.com/ekr/snippet.js?key=eaeeb030-513f-4edb-8f24-63b080e7239e';
    document.body.appendChild(exts);
    


    function handleScroll() {
      curTopNum.value = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
    }

    watch(() => curTopNum.value, (next) => { 
      if (next > 0) {
        toTopShow.value = true;
      } else {
        toTopShow.value = false;
      }
    })

    window.addEventListener("scroll", handleScroll, true);
  
    onMounted(() => {
      if(localStorage.getItem('isDispatchAgencyFlag') == 'false' && localStorage.getItem('InfoFlg') == 1){
        msgBtnShow.value = true;
      }
    });
    function closeMsg(){
      if(document.querySelector('#launcher') != null && document.querySelector('#launcher').style.visibility != 'hidden'){
        document.querySelector('#launcher').style.visibility = 'hidden'
      }          
    }
    var interval = setInterval(closeMsg, 200);
    function openWidget(){
      zE('webWidget', 'show');
      zE('webWidget', 'open');
    }
    onBeforeUnmount(()=>{
      clearInterval(interval)
    })
    
    return {
      toTop,
      msgBtnShow,
      toTopShow,
      openWidget
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../style/viriables.scss';
.aside {
  min-width: 980px;
  max-width: 1260px;
}
.rsk-fixed-bottom-right {
  position: fixed;
  bottom: 52px;
  right: 16px
}
.rsk-mb-xs {
  margin-bottom: 8px
}
.rsk-button {
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  height: 32px;
  border-color: transparent;
  line-height: 1;
  align-items: center;
  border-style: solid;
  padding: 0 8px;
  min-width: 32px;
  border-width: 1px;
  font-size: 14px
}
.rsk-button--white-admin {
  background-color: #fff;
  border-color: rgba(48, 53, 59, 0.16);
  color: #134F94
}
.rsk-button--white-admin:hover:not(:disabled) {
  background-color: #DEE9F7
}
.rsk-button--round {
  border-radius: 50%
}
.rsk-button--round-large {
  width: 52px;
  height: 52px
}
.rsk-button__icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center
}
.rsk-icon {
  background-repeat: no-repeat;
  display: inline-block;
  height: 24px;
  width: 24px;
  background-position: center
}
.rsk-icon--arrow-upward-admin-primary {
  background-image: $img-arrow-upward-user-primary
}
.rsk-button--admin-primary {
  color: #fff;
  background-color: #134F94
}
.rsk-button--admin-primary:hover:not(:disabled) {
  background-color: #073A76
}
.rsk-icon--question-answer-white {
  background-image: $img-question-answer-white
}
</style>
