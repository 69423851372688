<template>
    <div class="content">
        <rsk-container title="自社研修詳細" :buttons="buttons">
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs" v-for="(item, index) in infos" :key="index">
                <div class="rsk-flex rsk-justify-between rsk-width-212">
                    <div class="rsk-table-like-form__heading rsk-table-like-form__table-column">{{item.title}}</div>
                </div>
                <div class="rsk-form rsk-form">
                    <div v-if="item.title == '研修内容'" v-html="item.value"></div>
                    <div v-else-if="!item.callBack" class="rsk-text">{{ item.value }}</div>
                    <div v-else-if="item.callBack && (item.value == '' || item.value == null)" class="rsk-text">{{item.value}}</div>
                    <el-button v-else type="text" @click="item.callBack(item)" >{{item.value}}</el-button>
                </div>
            </div>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>
        </rsk-container>
        <div class="rsk-p-s">
            <button class="rsk-button rsk-button--text-user-primary" @click="back">
                <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                    <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                </span>
                戻る
            </button> 
        </div>  
    </div>         
</template>

<script>
import { useRouter,useRoute } from 'vue-router'
import { ref } from "vue";
import { inject } from "vue";
import { dateFtt } from "@/utils/common";
import { UAParser } from "../../popenvironment/js/ua-parser.min";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const service = inject("service");
    const infos = ref([]);
    const studyFlagList = ref([]);
    let goUrl = "traselfstudy";
    let lessonId = "9999999999";

    if(typeof(route.params.fromUrl) != "undefined") {
        goUrl = route.params.fromUrl;
    }
    if(typeof(route.params.lessonId) != "undefined") {
        lessonId = route.params.lessonId;
    }
          
    if(!route.params.trainId) {
        router.push({ name: 'traselfstudy'});
        return;
    } else {
        let formData = {trainId:route.params.trainId,rank:route.params.rank}
        service.traselfstudy.show(formData).then((res) => {
            studyFlagList.value = res.studyFlagList;
            infos.value.push({title: '研修ID',value: res.model.trainId})
            infos.value.push({title: '研修名',value: res.model.trainName})
            infos.value.push({title: '学習形式',value: getLable(res.model.studyFlag)})
            infos.value.push({title: '研修内容',value: res.model.unitDetail})
            infos.value.push({title: '標準学習時間',value: res.model.baseStudyTime + '時間'})
            infos.value.push({title: '研修場所',value: res.model.trainPlace})
            infos.value.push({title: '研修所在地',value: res.model.trainAddress})
            infos.value.push({title: '研修期間',value: dateFtt("auto_time",res.model.openStartDtm,"en") + " ～" + dateFtt("auto_time",res.model.openEndDtm,"en")})
            infos.value.push({title: '添付ファイル1',value: res.model.reportFileName1,callBack: (item) => {
                downloadFile(res.model.reportFileName1,res.model.reportFileDir1);
            }})
            infos.value.push({title: '添付ファイル2',value: res.model.reportFileName2,callBack: (item) => {
                downloadFile(res.model.reportFileName2,res.model.reportFileDir2);
            }})
            if(res.model.reserveCount !== null){
                infos.value.push({title: '予定人数',value: res.model.reserveCount + "人"})    
            } else {
                infos.value.push({title: '予定人数',value: ''})
            }
        }).catch((error) => {
            console.log(error);
        });                
    }

	function downloadFile(filename,fileurl){
        var ua = new UAParser();
        let userAgent = ua.getUA();
        const downloadData = {filename:'',fileurl:'',userAgent:''}   
		downloadData.filename = filename;
		downloadData.fileurl = fileurl;
		downloadData.userAgent = userAgent;
		service.common.downloadFile(downloadData)
	}    

    function back() {
        if(goUrl == 'lesDetail'){
            router.push({ name: 'lesDetail',params:{lessonId:lessonId}})
        } else {
            router.push({ name: 'traselfstudy'})            
        }
    }

    function getLable(value) {
        for(let i = 0;i<studyFlagList.value.length;i++) {
            if(value == studyFlagList.value[i].value) {
                return studyFlagList.value[i].label;
            }
        }
    }

    return {
        infos,
        studyFlagList,
        downloadFile,back,getLable
    };
  },
};
</script>