<template>
  <div class="content">
    <div class="rsk-px-xl rsk-pt-m">
      <div class="rsk-font-bold rsk-font-xl rsk-mb-s">{{$t("usrTrainingPages.adminTrainingManagement")}}</div>
      <rsk-tabs :list="tabList" v-model="tabSelect" @onChange="tabChange" />
      <trastudyList/>
    </div>
  </div>
</template>


<script>
import { useRouter } from 'vue-router'
import { ref, inject } from "vue";
import trastudyList from "../../listen/trastudy";
import { useI18n } from 'vue-i18n'
export default {
  components: {
      trastudyList
  },
  setup() {
    const test = (data) => {
      // console.log(data);
    }
    const router = useRouter()
    const tabSelect = ref(1);
    const { t } = useI18n()
    const tabList = ref([
      { label: t("usrTrainingPages.adminTrainingElearning"), value: 0, show: true },
      { label: t("usrTrainingPages.adminTrainingWebSeminar"), value: 1, show: true },
      { label: t("usrTrainingPages.adminTrainingInternalTraining"), value: 2, show: true },
    ]);

    const service = inject("service");
    service.elearning.init().then((res) => {
       tabList.value[1].show = res.traShowFlag == 0;
       tabList.value[2].show = res.traSelfShowFlag == 0;
     });

    function tabChange(index) {
      if(index == 0) {
        router.push({ name: 'elgstudy'})
      }
      if(index == 1) {
        router.push({ name: 'trastudy'})
      }
      if(index == 2) {
        router.push({ name: 'traselfstudy'})
      }      
    }

    return {
      tabList,
      tabSelect,
      tabChange,
      test
    };
  },
};
</script>