import { Service, BaseService } from "@/core/auto";

@Service('/api/user/')
export default class Login extends BaseService{
    loginInit(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/index",
			method: "POST"
		})
	}
	logout() {
		return this.request({
            showError: false,
			url: "/logout",
			method: "POST"
		})
	}
	ssoLogin(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/ssoLogin",
			method: "POST"
		})
	}
}