<template>
  <header class="rsk-header rsk-header--admin">
    <div class="rsk-header__left-block">
      <a href="javascript:void(0);" @click="moveToUsrTop()" :style="computedLogo" class="rsk-header__logo"></a>

      <button class="rsk-button rsk-button--text-user-primary" @click="showPopMulHandle">
            <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                <span class="rsk-icon rsk-icon--info-user-primary"></span>
            </span>
        {{$t('common.adminUsrHelp')}}
      </button>
    </div>
    <div class="rsk-header__right-block">
      <rsk-notification v-if="displayFlg5" />

      <div class="rsk-tabs rsk-tabs--user rsk-mr-m">
        <div class="rsk-tabs__item" v-if="model.manageFlg == 1 || model.manageFlg == 2" @click="moveToAdmin">{{$t('common.adminUsrToMngPage')}}</div>
        <div class="rsk-tabs__item rsk-tabs__item--active">{{$t('common.adminUsrToUsrPage')}}</div>
      </div>
      <div class="rsk-header__user">
        <div class="rsk-header__user-text" v-if="comIdShow">
          <span class="rsk-header__user-small-text">{{$t('common.adminApplicationComId')}}</span>
          <br>
          {{ model.comId }}
          <div>
            {{ model.comNm }}
          </div>
        </div>
        <div class="rsk-account-button-wrapper">
          <button @click="toggleModal" class="rsk-account-button" @blur="accountBlur()">
            <span class="rsk-icon rsk-icon--account-circle-admin-primary"></span>
            <div class="rsk-account-button__label">
              <div class="rsk-account-button__num">{{ model.loginId }}</div>
              <div class="rsk-account-button__name">{{ model.usrNm }}</div>
            </div>
          </button>
          <div class="rsk-account-button-wrapper__menu" :class="{'rsk-display-none': isActive}">
            <div class="rsk-account-button-wrapper__menu-item" @click="moveToUsrPro">
              <span class="rsk-icon rsk-icon--edit-gray rsk-mr-xxs"></span>
              {{$t('usrTopPages.adminUsrTopProDel')}}
            </div>
            <div class="rsk-account-button-wrapper__menu-item" @click="moveToUsrPas">
              <span class="rsk-icon rsk-icon--lock-gray rsk-mr-xxs"></span>
              {{$t('common.adminUsrTopPasSet')}}
            </div>
            <div class="rsk-account-button-wrapper__menu-item rsk-account-button-wrapper__menu-item--alert" @click="logout" v-show="logoutShow">
              <span class="rsk-icon rsk-icon--logout rsk-mr-xxs"></span>
              {{$t('common.adminApplicationLogout')}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!--navbar-->
  <navbar/>
  <pop-mul v-model='showPopMul' />
</template>

<script>
import Navbar from './Navbar'
import PopMul from '../views/popmul/index'
import { useRouter } from 'vue-router'
import { ref, reactive, toRefs, inject,onMounted, computed } from 'vue'
import { removeToken } from '../utils/CookieUtils'
import UAParser from 'ua-parser-js'
import { ElLoading, ElMessage } from 'element-plus'


const useHeaderEffect = (props) => {

  const router = useRouter()

  const state = reactive({
  })

  const isActive = ref(true)

  const toggleModal = () => {
    isActive.value = !isActive.value
  }

  const moveToAdmin = () => {
    ElLoading.service({ fullscreen: true, text:"処理中..."})
    location.href = location.protocol+ '//' +location.hostname + process.env.VUE_APP_PORT_DEFAULT_KEY + '/mng/mng_top'
  }

  const moveToUsrPro = () => {
    router.push({ path: '/usrpro' })
  }
  const moveToUsrPas = () => {
    router.push({ path: '/usrpas' })
  }

  const moveToUsrTop = () => {
    router.push({ path: '/usr_top' })
  }

  return { ...toRefs(state), isActive, toggleModal, moveToAdmin, moveToUsrPro, moveToUsrPas, moveToUsrTop }
}

export default {
  name: 'AppHeader',
  components: { Navbar, PopMul },
  setup (props) {
    const { isActive, toggleModal, moveToAdmin, moveToUsrPro, moveToUsrPas, moveToUsrTop } = useHeaderEffect(props)
    const model = ref({})
    const service = inject("service");
    const showPopMul = ref(false)
    const dispatchAgencyFlag = ref(false)
    const comIdShow = ref(false);
    const logoutShow = ref(true);
    const displayFlg5 = localStorage.getItem('displayFlg5') == "true" ? true : false;
    const comNm = ref(localStorage.getItem('comNm'));
    if(localStorage.getItem('getComIdFlag') == 1){
      comIdShow.value = true;
    } else {
      comIdShow.value = false;
    }
    if(localStorage.getItem('logoutBtnFlag') == 0){
      logoutShow.value = false;
    } else {
      logoutShow.value = true;
    }
    if (comNm.value) {
      if (comNm.value.length > 11) {
        comNm.value = comNm.value.substring(0, 11) + '...'
      }
    }
    
    function accountBlur(){
      setTimeout(() => {
        isActive.value = true
      }, 500)
    }

    let computedLogo = computed({
        get() {
        return {
          backgroundImage: `url(${localStorage.getItem('logoUrl')})`
        }
      }
    })

    onMounted(() => {
      var close = false;
      function closeMsg(){
        if(close){
          clearInterval(interval)
        }
      }
      var interval = setInterval(closeMsg, 200);
    });

    const showPopMulHandle = () => {
      showPopMul.value = true
    }
    getUsrInfo()

    function getUsrInfo() {
      service.usrpro.get().then((res) => {
        dispatchAgencyFlag.value = res.dispatchAgencyFlag
        model.value = {...res};
        model.value.comNm = comNm.value;
      });
    }

    function downloadFile(filename, fileurl){
      var ua = new UAParser();
      let userAgent = ua.getUA();
      const downloadData = {filename:'', fileurl:'', userAgent:''}
      downloadData.filename = filename;
      downloadData.fileurl = fileurl;
      downloadData.userAgent = userAgent;
      service.common.downloadSystemFile(downloadData)
    }

    function logout(){
      removeToken();
      service.login.logout().then(res=>{
        if(res.fromRismonFlag == 0){
          window.location = '/login'
        }
        if(res.fromRismonFlag == 1){
          if(res.isJcc){
            window.location = '/jcclogin'
          }else{
            window.location = '/login'
          }
        }
        if(res.fromRismonFlag == 2){
          if(res.isJcc){
            window.location = '/jcclogin/' + res.comid
          }else{
            window.location = '/login/' + res.comid
          }
        }
      }).catch(error => {
        console.log(error)
      })
    }

    return {
      displayFlg5, isActive, toggleModal, moveToAdmin, moveToUsrPro, moveToUsrPas, moveToUsrTop, showPopMul, showPopMulHandle, model, logout, accountBlur, computedLogo, comIdShow, logoutShow
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../style/viriables.scss';
.rsk-header {
  align-items:	center;
  background-color:	$bg-light;
  display:	flex;
  height:	61px;
  justify-content:	space-between;
  padding:	0 16px;
}
.rsk-header__right-block, .rsk-header__left-block, .rsk-header__user {
  align-items:	center;
  display:	flex;
}
.rsk-header__logo {
  background-repeat:	no-repeat;
  background-size:	166px 32px;
  display:	inline-block;
  height:	32px;
  margin-right:	16px;
  width:	166px;
}
.rsk-header__notification {
  position:	relative;
  width:	40px;

}
.rsk-header__notification-num {
  align-items:	center;
  background-color:	$bg-error;
  border-color:	$fg-light;
  border-radius:	9px;
  border-style:	solid;
  border-width:	2px;
  color:	$fg-light;
  display:	inline-flex;
  font-size:	10px;
  height:	18px;
  left:	17px;
  padding:	0 5px;
  position:	absolute;
  top:	0;
}
.rsk-header__user-text {
  color:	$fg-gray-080;
  font-size:	$font-size-s;
  margin-right:	8px;
  width: 170px;
}
.rsk-button {
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  height: 32px;
  border-color: transparent;
  line-height: 1;
  align-items: center;
  border-style: solid;
  padding: 0 8px;
  min-width: 32px;
  border-width: 1px;
  font-size: 14px
}
.rsk-button--text-user-primary {
  color: #066CE5;
  background-color: transparent
}
.rsk-button--text-user-primary:hover:not(:disabled) {
  background-color: rgba(35, 148, 247, 0.08)
}
.rsk-button__icon-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center
}
.rsk-button__icon-wrapper--with-text {
  padding-right: 5px
}
.rsk-icon {
  background-repeat: no-repeat;
  display: inline-block;
  height: 24px;
  width: 24px;
  background-position: center
}
.rsk-icon--account-circle-admin-primary {
  background-image: $img-account_circle;
  width: 32px;
  height: 32px
}
//.rsk-icon--add-admin-primary {
//  background-image: $img-add-admin-primary;
//}
//.rsk-icon--add-white {
//  background-image: $img-add-white;
//}
//.rsk-icon--alert-gray {
//  background-image: $img-alert-gray;
//}
//.rsk-icon--alert-white {
//  background-image: $img-alert-white;
//}
//.rsk-icon--arrow-upward-admin-primary {
//  background-image: $img-arrow-upward-admin-primary;
//}
//.rsk-icon--arrow-upward-user-primary {
//  background-image: $img-arrow-upward-user-primary;
//}
//.rsk-icon--calendar-black {
//  background-image: url("../assets/icon/calendar-black.svg")
//}
//.rsk-icon--calendar-gray {
//  background-image: url("../assets/icon/calendar_gray.svg")
//}
//.rsk-icon--carret-down {
//  background-image: url("../assets/icon/carret-down.svg")
//}
//.rsk-icon--carret-down-user-primary {
//  background-image: url("../assets/icon/carret_down_user_primary.svg")
//}
//.rsk-icon--carret-right-user {
//  background-image: url("../assets/icon/carret_right_user-admin.svg")
//}
//.rsk-icon--content10000 {
//  background-image: url("../assets/icon/content10000.svg")
//}
//.rsk-icon--content5000 {
//  background-image: url("../assets/icon/content5000.svg")
//}
//.rsk-icon--download {
//  background-image: url("../assets/icon/download.svg")
//}
.rsk-icon--edit-gray {
  background-image: $img-edit-gray;
}
//.rsk-icon--good {
//  background-image: url("../assets/icon/new.svg")
//}
//.rsk-icon--hot {
//  background-image: url("../assets/icon/hot.svg")
//}
.rsk-icon--info-user-primary {
  background-image: $img-info-user-primary;
}
//.rsk-icon--like {
//  background-image: url("../assets/icon/like.svg")
//}
.rsk-icon--lock-gray {
  background-image: $img-lock-gray;
}
.rsk-icon--logout {
  background-image: $img-logout;
}
//.rsk-icon--mail-admin-primary {
//  background-image: url("../assets/icon/mail_admin_primary.svg")
//}
//.rsk-icon--new {
//  background-image: url("../assets/icon/good.svg")
//}
//.rsk-icon--no1 {
//  background-image: url("../assets/icon/no1.svg")
//}
.rsk-icon--notification-gray {
  background-image: $img-notification-gray;
}
//.rsk-icon--question-answer-white {
//  background-image: url("../assets/icon/question_answer_light.svg")
//}
//.rsk-icon--question-gray {
//  background-image: url("../assets/icon/question_gray.svg")
//}
.rsk-mr-m {
  margin-right: 24px
}
.rsk-mr-xxs {
  margin-right: 4px
}
.rsk-button--text-tertialy {
  background-color: transparent;
  color: #30353B
}
.rsk-button--text-tertialy:hover:not(:disabled) {
  background-color: #EEF1F3
}
.rsk-button--round {
  border-radius: 50%
}
.rsk-button--round-large {
  width: 52px;
  height: 52px
}
.rsk-button--medium {
  min-width: 124px;
  height: 40px;
  padding: 0 16px
}
.rsk-button--round.rsk-button--medium {
  width: 40px;
  min-width: 40px;
  padding: 0
}
.rsk-tabs {
  border-radius: 4px;
  border-style: solid;
  display: inline-flex;
  border-width: 1px
}
.rsk-tabs--user {
  border-color: $bg-user_primary;
  color: $bg-user_primary;
  background-color: #fff
}
.rsk-tabs--user .rsk-tabs__item--active {
  background-color: $bg-user_primary;
}
.rsk-tabs__item {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  min-width: 120px;
  cursor: pointer;
  background-color: #fff;
  border-right: solid;
  border-color: $bg-user_primary;
  border-width: 1px
}
.rsk-tabs__item--active {
  color: #fff;
  background-color: $bg-user_primary;
  cursor: default
}
.rsk-tabs__item:hover:not(.rsk-tabs__item--active) {
  background-color: $bg-user-primary-hover-low;
}
.rsk-tabs__item:last-child {
  border: none;
  border-radius: 4px
}
.rsk-account-button {
  align-items:	center;
  background-color:	$bg-light;
  border-color:	$fg-boder-divider-black;
  border-radius:	4px;
  border-style:	solid;
  border-width:	1px;
  display:	inline-flex;
  height:	45px;
  min-width:	170px;
  padding-left:	8px;
  padding-right:	50px;
  position:	relative;
}
.rsk-account-button__label {
  margin-left:	4px;
}
.rsk-account-button__num {
  color: $fg-gray-080;
  font-size:	$font-size-xs;
}
.rsk-account-button__name {
  color:	$fg-gray-100;
  font-size:	$font-size-m;
}
.rsk-account-button:after {
  background-image:	$img-carretdown;
  background-position:	center;
  background-repeat:	no-repeat;
  content:	'';
  height:	45px;
  position:	absolute;
  right:	6px;
  top:	0;
  width:	20px;
}
.rsk-account-button:hover {
  background-color: $bg-user-primary-hover-low;
}
.rsk-header__user-small-text {
  font-size:	$font-size-xs;
}
.rsk-account-button-wrapper {
  height:	45px;
  position:	relative;
}
.rsk-account-button-wrapper__menu {
  background-color:	$bg-light;
  border-color:	$fg-boder-divider-black;
  border-radius:	4px;
  border-style:	solid;
  border-width:	1px;
  box-shadow:	0px 2px 8px rgba(36, 135, 251, 0.16);
  cursor:	pointer;
  margin-top:	12px;
  padding:	8px 0;
  position:	absolute;
  width:	170px;
}
.rsk-account-button-wrapper__menu-item {
  align-items:	center;
  color:	$fg-gray-100;
  display:	flex;
  font-size:	$font-size-m;
  height:	45px;
  padding:	0 21px;
}
.rsk-account-button-wrapper__menu-item:hover {
  background-color:	$bg-gray-005;
}
.rsk-account-button-wrapper__menu-item--alert {
  color:	$fg-error;
}
.rsk-display-none {
  display: none !important
}
</style>
