<template>
    <div class="lec-header">
        <img class="logo" src="../../assets/logo.png" />
    </div>
    <div class="line" />
    <div class="lec-body">
        <div class="lec-title">講師登録申請フォーム</div>

        <ol class="stepBar step3">
            <li class="step barTextSize">講師登録申請フォーム</li>
            <li class="step barTextSize current">入力内容確認</li>
            <li class="step barTextSize">登録完了</li>
        </ol>

        <table class="tb-basic form sp-block">
            <tr>
                <th>名前</th>

                <td><div>{{model.usrName}}</div></td>
            </tr>
            <tr>
                <th>住所</th>

               <td><div>{{model.usrAddr}}</div></td>
            </tr>
            <tr>
                <th>電話番号</th>

                <td><div>{{model.phone}}</div></td>
            </tr>
            <tr>
                <th>メールアドレス</th>

                <td><div>{{model.mailAdr}}</div></td>
            </tr>
        </table>

        <div class="wrap btn-line">
            <div class="btn-wrap">
                <button class="show-btn" @click="moveToEditnew">戻る</button>
            </div>
            <div class="btn-wrap">
                <button class="show-btn" @click="moveToFinish">OK</button>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { inject, onMounted, ref, watch, onUnmounted} from 'vue'

    export default {
        name: 'lec_reg_show',
        setup() {
            const router = useRouter()
            const route = useRoute()
            const service = inject("service")
            const model = ref({});
            const usrMes = ref();

            function moveToEditnew() {
                router.push({name:'lec_reg_editnew',
                    query: {
                        _lecturer_group: usrMes.value.queryGroup,
                    }
                })
            }

            function moveToFinish() {
                if (window.confirm('登録してもよろしいですか？')){
                    service.lecturer.insert(model.value).then(res => {
                        router.push({path:'/lec_reg_finish'})
                        localStorage.setItem("sendMail",1);
                        localStorage.removeItem("usrMes");
                    }).catch((error) => {
                        console.log(error)
                    })
                } 
            }

            onMounted(() => {
                if(null != route.params.model || undefined != route.params.model) {
                    usrMes.value = JSON.parse(route.params.model)
                    model.value = usrMes.value
                    localStorage.setItem("usrMes", route.params.model);
                }else {
                    if (localStorage.getItem("usrMes")) {
                        usrMes.value = JSON.parse(localStorage.getItem("usrMes"))
                        model.value = usrMes.value
                    } else {
                        router.push({path:'/404'});
                    }
                }
                
            })

            return {
                moveToEditnew,
                moveToFinish,
                model
            }
        },
    }
</script>

<style lang="scss" src="./css/common.scss" scoped />