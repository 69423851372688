<template>
  <div class="content">
    <div class="rsk-px-xl rsk-pt-m">
      <div class="rsk-flex rsk-mb-m">
        <div class="rsk-mr-m rsk-width-180">
          <div class="rsk-mb-s rsk-pie-chart-tip">
            <div id="rsk-pie-chart" class="rsk-pie-chart" :class="{ 'rsk-pie-chart--over50': isChartOver }">
              <div class="rsk-pie-chart__progress">
                <div class="rsk-pie-chart__progress-fill" :style="{ transform: 'rotate(' + chartOverDeg + 'deg)' }"></div>
              </div>
              <div class="rsk-pie-chart__percents">
                <div class="rsk-pie-chart__percents-wrapper">
                  <div class="rsk-pie-chart__percents-text">ToDo</div>
                  <div class="rsk-pie-chart__percents-value">{{ info.studyElgPercen != 0 ? info.studyElgPercen : '-' }}%
                  </div>
                </div>
                <label class="rsk-tooltip rsk-tooltip--250 rsk-tooltip--left"
                  style="height:fit-content;top:50%;left:100%;transform:translateY(-50%);width:300px">
                  <span>ToDoの「％」表示について：ボックス・自社研修・自身で申込した研修は対象外です。</span>
                </label>
              </div>
            </div>
          </div>
          <div class="rsk-flex rsk-justify-between rsk-mb-s" v-if="info.displayFlg7 != studyTimeUnShowFlag">
            <div class="rsk-text-gray100 rsk-width-180" style="display: flex; align-items: center; flex-wrap: wrap;">
              <div>
                <span class="rsk-font-size-xs">{{ learnTimeTitle }}</span>
                <span class="rsk-font-size-xs">{{ info.learningTimeShow }}</span>
              </div>
              <span class="rsk-icon rsk-icon--question-gray-s" style="width: 16px; height: 16px;margin-left: 4px;">
                <label class="rsk-tooltip rsk-tooltip--250 rsk-tooltip--left"
                  style="height:fit-content;top:50%;left:180%;transform:translateY(-50%);width:281px">
                  <span>
                    {{ learnTimeTooilp }}
                  </span>
                </label>
              </span>
            </div>
          </div>
          <div class="rsk-divider rsk-divider--horizontal rsk-mb-s"></div>
          <div class="rsk-font-size-xs rsk-text-gray080">{{ $t("usrTopPages.loginId") }}</div>
          <div class="rsk-text-gray100 rsk-mb-s">{{ info.loginId }}</div>
          <div class="rsk-font-size-xs rsk-text-gray080">{{ $t("usrTopPages.mailaddr") }}</div>
          <div class="rsk-text-gray100 rsk-mb-s rsk-word-break">{{ info.usrMlAdr }}</div>
          <div class="rsk-font-size-xs rsk-text-gray080">{{ $t("usrTopPages.adminUsrTopLoginBefEntDtm") }}</div>
          <div class="rsk-text-gray100 rsk-mb-s">{{ info.loginBefEntDtm }}</div>
          <div class="rsk-divider rsk-divider--horizontal rsk-mb-s"></div>
          <a href="javascript:void(0);" @click="moveToUsrPro()"
            class="rsk-hover-underline rsk-text-primary rsk-flex rsk-items-center">
            <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
              <span class="rsk-icon rsk-icon--edit-primary"></span>
            </span>
            {{ $t("usrTopPages.adminUsrTopProDel") }}
          </a>
        </div>

        <div class="rsk-flex-grow-1" v-if="useFlag">
          <span class="text-prompt">管理者の設定により、「利用停止」の状態になっています。研修利用ご希望の場合、管理者にお問い合わせください。<br>
          ※「利用停止」時には、お知らせ・ヘルプ・アカウント管理のみ利用できます。
          </span>
        </div>
        <div class="rsk-flex-grow-1" v-if="!useFlag">
          <rsk-panel :title="$t('usrTopPages.adminTopInfTitle')" v-if="displayFlg5 ? info.infList.length > 0 : false"
            :items="info.infList" @select="informationSelect">
          </rsk-panel>

          <rsk-title-bar :title="$t('usrTopPages.adminTopTodoListTilte')" v-if="!useFlag"
            :describe="'※修了後のコースは研修管理画面からご確認頂けます。'" />
          <div class="rsk-card rsk-mb-s"
            v-if="info.elgList.length <= 0 && info.tesList.length <= 0 && info.antList.length <= 0 && info.repList.length <= 0 && info.lesList.length <= 0 && !useFlag">
            <span>{{ $t("usrTopPages.adminTopTodoStudyTitle") }}</span>
          </div>
          <rsk-panel-list v-if="info.lesList.length > 0 && !useFlag" :goWhere="'les'" :list="info.lesList" />
          <div v-if="info.lesList.length > 0 && !useFlag" class="rsk-align-right rsk-mb-s">
            <a href="javascript:void(0);" @click="moveToPageByPath('/les')" 
              class="rsk-display_inline-flex rsk-text-primary rsk-flex rsk-items-center rsk-hover-underline">
              {{ $t("usrTopPages.adminTopAllLesPageToolip") }}
              <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                <span class="rsk-icon rsk-icon--carret-right-user"></span>
              </span>
            </a>
          </div>
            
          <rsk-panel-list v-if="info.elgList.length > 0 && !useFlag" :goWhere="'elg'" :list="info.elgList" />
          <div v-if="info.elgList.length > 0 && !useFlag" class="rsk-align-right rsk-mb-s">
            <a href="javascript:void(0);" @click="moveToPageByPath('/elgstudy')" 
              class="rsk-display_inline-flex rsk-text-primary rsk-flex rsk-items-center rsk-hover-underline">
              {{ $t("usrTopPages.adminTopAllElgPageToolip") }}
              <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                <span class="rsk-icon rsk-icon--carret-right-user"></span>
              </span>
            </a>
          </div>

          <rsk-panel-list v-if="info.tesList.length > 0 && !useFlag" :goWhere="'tes'" :list="info.tesList" />
          <div v-if="info.tesList.length > 0 && !useFlag" class="rsk-align-right rsk-mb-s">
            <a href="javascript:void(0);" @click="moveToPageByPath('/listen/tes')" 
              class="rsk-display_inline-flex rsk-text-primary rsk-flex rsk-items-center rsk-hover-underline">
              {{ $t("usrTopPages.adminTopAllTesPageToolip") }}
              <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                <span class="rsk-icon rsk-icon--carret-right-user"></span>
              </span>
            </a>
          </div>
          <rsk-panel-list v-if="info.antList.length > 0 && !useFlag" :goWhere="'ant'" :list="info.antList" />
          <div v-if="info.antList.length > 0 && !useFlag" class="rsk-align-right rsk-mb-s">
            <a href="javascript:void(0);" @click="moveToPageByPath('/listen/ant')" 
              class="rsk-display_inline-flex rsk-text-primary rsk-flex rsk-items-center rsk-hover-underline">
              {{ $t("usrTopPages.adminTopAllAntPageToolip") }}
              <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                <span class="rsk-icon rsk-icon--carret-right-user"></span>
              </span>
            </a>
          </div>
          <rsk-panel-list v-if="info.repList.length > 0 && !useFlag" :goWhere="'rep'" :list="info.repList" />
          <div v-if="info.repList.length > 0 && !useFlag" class="rsk-align-right rsk-mb-s">
            <a href="javascript:void(0);" @click="moveToPageByPath('/listen/rep')" 
              class="rsk-display_inline-flex rsk-text-primary rsk-flex rsk-items-center rsk-hover-underline">
              {{ $t("usrTopPages.adminTopAllRepPageToolip") }}
              <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                <span class="rsk-icon rsk-icon--carret-right-user"></span>
              </span>
            </a>
          </div>

          <rsk-title-bar v-if="(info.applyElgList.length > 0 || info.traList.length > 0) && !useFlag"
            :title="$t('usrTopPages.adminTopSelfStudyTitle')" :describe="'※修了後のコースは研修管理画面からご確認頂けます。'" />

          <rsk-panel-list v-if="info.applyElgList.length > 0 && !useFlag" :goWhere="'elg'" :list="info.applyElgList" />
          <div v-if="info.applyElgList.length > 0 && !useFlag" class="rsk-align-right rsk-mb-s">
            <a href="javascript:void(0);" @click="moveToPageByPath('/elgstudy')" 
              class="rsk-display_inline-flex rsk-text-primary rsk-flex rsk-items-center rsk-hover-underline">
              {{ $t("usrTopPages.adminTopAllElgPageToolip") }}
              <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                <span class="rsk-icon rsk-icon--carret-right-user"></span>
              </span>
            </a>
          </div>
          <rsk-panel-list v-if="info.traList.length > 0 && !useFlag" :goWhere="'tra'" :list="info.traList" />
          <div v-if="info.traList.length > 0 && !useFlag" class="rsk-align-right rsk-mb-s">
            <a href="javascript:void(0);" @click="moveToPageByPath('/trastudy')" 
              class="rsk-display_inline-flex rsk-text-primary rsk-flex rsk-items-center rsk-hover-underline">
              {{ $t("usrTopPages.adminTopAllTraPageToolip") }}
              <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                <span class="rsk-icon rsk-icon--carret-right-user"></span>
              </span>
            </a>
          </div>

            <div class="rsk-flex rsk-justify-between" v-if="(info.applyElgQstList.length > 0 ) && !useFlag">
              <div class="rsk-flex">
                <rsk-title-bar :title="$t('usrTopPages.adminTopAppQstTitle')" />
                <div style="padding: 2px">
                  <span class="rsk-icon rsk-icon--question-gray" >
                    <label class="rsk-tooltip rsk-tooltip--250 rsk-tooltip--bottom" style="height: fit-content;right: 188px;transform: translateY(-38%);width: 400px;">
                      <span>
                        リスクモンスター株式会社（サービス提供会社）からのお願いです。今後のサービス向上のため、お客様が受講されたeラーニングについて感想をお聞かせください。
                        <br>
                        ※回答済みのアンケートは非表示になります。<br>
                        ※最大３件表示されます。<br>
                        ※受講修了後、未回答の状態で６ヶ月経過したアンケートは非表示になります。
                      </span>
                    </label>
                  </span>
                </div>
              </div>
            </div>
            

          <rsk-panel-list v-if="info.applyElgQstList.length > 0 && !useFlag" :goWhere="'elgQst'" :list="info.applyElgQstList" />

          <rsk-panel v-if="displayFlg1 && !useFlag && !isDispatchAgencyFlag">
            <template v-slot:title>
              <div class="rsk-flex rsk-items-center rsk-mb-s">
                <span class="rsk-icon rsk-icon--star-gray rsk-mr-xs"></span>
                <div class="rsk-text-gray100 rsk-font-bold rsk-font-l rsk-mr-m">{{
              $t("usrTopPages.adminTopPopularTitle1") }}</div>
              </div>
            </template>
            <template v-slot:items>
              <div class="rsk-font-bold rsk-text-gray100 rsk-mb-s">
                {{ $t("usrTopPages.adminTopPopularTitle2") }}
              </div>
              <rsk-radio :options="rskRadioList" @onChange="radioChange" class="rsk-mb-m" />
              <rsk-article-list v-if="info.hotList.length > 0" :list="info.hotList" />
              <div class="rsk-font-size-s rsk-align-right rsk-text-gray080">{{ $t("usrTopPages.adminTopPopularTitle3")
                }}</div>
              <div class="rsk-font-size-s rsk-align-right rsk-text-gray080">{{ $t("usrTopPages.adminTopPopularTitle4")
                }}</div>
              <div class="rsk-font-size-s rsk-align-right rsk-text-gray080">{{ $t("usrTopPages.adminTopPopularTitle5")
                }}</div>
            </template>
          </rsk-panel>

        </div>
      </div>
    </div>
  </div>
  <!-- <rsk-tool :type="['backtop', 'feedback']"/> -->
</template>

<script>
import { dateFtt, yyyymmddToDate } from '@/utils/common'
import { ref, inject, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

export default {
  setup() {
    const router = useRouter()
    const service = inject("service");
    const { locale, t } = useI18n({ useScope: 'global' })
    locale.value = localStorage.getItem('lang')
    const displayFlg5 = localStorage.getItem('displayFlg5') == "true" ? true : false;
    const displayFlg1 = localStorage.getItem('displayFlg1') == "true" ? true : false;
    const useFlag = ref(false)
    const learnTimeTitle = ref('');
    const learnTimeTooilp = ref('');
    const studyTimeUnShowFlag = ref(2)
    const radios = {
      '0': t('usrTopPages.adminTopPopularTypeAll'),
      '1': t('usrTopPages.adminTopPopularType1'),
      '2': t('usrTopPages.adminTopPopularType2'),
      '3': t('usrTopPages.adminTopPopularType3'),
      '4': t('usrTopPages.adminTopPopularType4'),
      '5': t('usrTopPages.adminTopPopularType5'),
      '6': t('usrTopPages.adminTopPopularType6'),
      '7': t('usrTopPages.adminTopPopularType7'),
      '8': t('usrTopPages.adminTopPopularType8'),
      '9': t('usrTopPages.adminTopPopularType9'),
      '10': t('usrTopPages.adminTopPopularType10'),
      '11': t('usrTopPages.adminTopPopularType11'),
      '12': t('usrTopPages.adminTopPopularType12'),
      '13': t('usrTopPages.adminTopPopularType13'),
      '14': t('usrTopPages.adminTopPopularType14'),
    }

    localStorage.removeItem("fromTopUrl");

    const isDispatchAgencyFlag = localStorage.getItem('isDispatchAgencyFlag') == 'false' ? ref(false) : ref(true);

    const info = ref({
      loginId: '',
      loginBefEntDtm: new Date(),
      usrMlAdr: '',
      studiedElgCnt: 0,
      allStudyElgCnt: 0,
      studyElgPercen: 0,
      infList: [],
      elgList: [],
      tesList: [],
      antList: [],
      repList: [],
      lesList: [],
      applyElgList: [],
      hotList: [],
      rskRadioList: [],
      traList: [],
      applyElgQstList: []
    })
    service.top.getUsrTopInfo().then(res => {
      info.value.loginId = res.loginId
      info.value.loginBefEntDtm = dateFtt('yyyy/MM/dd', res.loginBefEntDtm)
      info.value.usrMlAdr = res.usrMlAdr
      info.value.studyElgPercen = res.studyElgPercen
      info.value.usrStdFlg = res.usrStdFlg;
      info.value.displayFlg7 = res.displayFlag7
      info.value.learningTimeShow = res.learningTimeShow
      if (res.useFlag && res.useFlag === 1) {
				useFlag.value = true
			} else {
				useFlag.value = false
			}
      if (info.value.displayFlg7 != studyTimeUnShowFlag.value) {
        learnTimeTitle.value = info.value.displayFlg7 == 0 ? t('usrTopPages.adminUsrTopLearnTimeBase') : t('usrTopPages.adminUsrTopLearnTimeReal')
        learnTimeTooilp.value = info.value.displayFlg7 == 0 ? t('usrTopPages.adminUsrTopLearnTimeToolipBase') : t('usrTopPages.adminUsrTopLearnTimeToolipReal')
      }
      res.infList.forEach(item => {
        info.value.infList.push({
          date: dateFtt('yyyy/MM/dd', item.iniEntDtm),
          text: item.content,
          id: item.informationId,
        })
      });

      res.elgList.forEach(item => {
        info.value.elgList.push({
          elearningId: item.elearningId,
          lessonId: item.lessonId,
          startDate: dateFtt('yyyy/MM/dd', item.jyukouStartDay),
          endDate: dateFtt('yyyy/MM/dd', item.limitDate),
          title: t('usrTopPages.adminTopElgTitle'),
          describe: item.elearningName,
          count: item.progressRate | 0,
          url: item.eLearningURL,
          startDateTitle: t('usrTopPages.adminUsrTopFromDate'),
          endDateTitle: t('usrTopPages.adminUsrTopElgLimitDate'),
          studyFlg: item.studyFlg,
          applicationDtm: item.applicationDtm,
          preconditionFlg: item.preconditionFlg,
          termOutside: item.termOutside,
          nonScormType: item.nonScormType
        })
      });

      res.tesList.forEach(item => {
        info.value.tesList.push({
          testId: item.testId,
          lessonId: item.lessonId,
          limitDate: dateFtt('yyyy/MM/dd', item.limitDate),
          title: t('usrTopPages.adminTopTesTitle'),
          describe: item.testName,
          count: item.progress,
          dateTitle: t('usrTopPages.adminUsrTopTestLimitDate'),
          preconditionFlg: item.preconditionFlg,
          answerFlg: item.answerFlg
        })
      });

      res.antList.forEach(item => {
        info.value.antList.push({
          anketoId: item.anketoId,
          lessonId: item.lessonId,
          limitDate: dateFtt('yyyy/MM/dd', item.limitDate),
          title: t('usrTopPages.adminTopAntTitle'),
          describe: item.anketoName,
          count: 0,
          dateTitle: t('usrTopPages.adminUsrTopAntLimitDate'),
          preconditionFlg: item.preconditionFlg
        })
      });

      res.repList.forEach(item => {
        info.value.repList.push({
          reportId: item.reportId,
          lessonId: item.lessonId,
          limitDate: dateFtt('yyyy/MM/dd', item.limitDate),
          title: t('usrTopPages.adminTopRepTitle'),
          describe: item.reportName,
          count: 0,
          dateTitle: t('usrTopPages.adminUsrTopRepLimitDate'),
          preconditionFlg: item.preconditionFlg
        })
      });

      res.lesList.forEach(item => {
        info.value.lesList.push({
          lessonId: item.lessonId,
          startDate: dateFtt('yyyy/MM/dd', item.startDate),
          endDate: dateFtt('yyyy/MM/dd', item.endDate),
          title: t('usrTopPages.adminTopBoxTitle'),
          describe: item.lessonName,
          count: 0,
          startDateTitle: t('usrTopPages.adminUsrTopFromDate'),
          endDateTitle: t('usrTopPages.adminUsrTopToDate'),
          count: (item.tourokufincount / item.tourokucount * 100).toFixed(0) | 0,
        })
      });

      res.applyElgList.forEach(item => {
        info.value.applyElgList.push({
          elearningId: item.elearningId,
          lessonId: item.lessonId,
          startDate: dateFtt('yyyy/MM/dd', item.jyukouStartDay),
          endDate: dateFtt('yyyy/MM/dd', item.limitDate),
          title: t('usrTopPages.adminTopElgTitle'),
          describe: item.elearningName,
          count: item.progressRate,
          startDateTitle: t('usrTopPages.adminUsrTopFromDate'),
          endDateTitle: t('usrTopPages.adminUsrTopElgLimitDate'),
          url: item.eLearningURL,
          studyFlg: item.studyFlg,
          applicationDtm: item.applicationDtm,
          preconditionFlg: item.preconditionFlg,
          termOutside: item.termOutside,
          nonScormType: item.nonScormType
        })
      });

      res.applyElgQstList.forEach(item => {
        info.value.applyElgQstList.push({
          elearningId: item.elearningId,
          describe: item.elearningName,
          title: t('usrTopPages.adminTopElgTitle'),
          url: '/ope_elgant/' + item.appQstKEY + '/edit'
          
        })
      });

      let index = 1;
      res.hotList.forEach(item => {
        info.value.hotList.push({
          elearningId: item.id,
          title: item.name,
          describe: !!radios[item.type] ? t('usrTopPages.adminTopPopularTitle2') + ': ' + radios[item.type] : t('usrTopPages.adminTopPopularTitle2') + ': ',
          index: index,
          rank: item.rank,
          loginRank: res.usrLoginRank,
          priceApplication: item.priceApplication,
          clickFlag: item.clickFlag
        })
        index++;
      });

      res.traList.forEach(item => {
        info.value.traList.push({
          trainId: item.trainId,
          openStartDtm: dateFtt('yyyy/MM/dd hh:mm', item.openStartDtm),
          openEndDtm: dateFtt('yyyy/MM/dd hh:mm', item.openEndDtm),
          title: t('usrTopPages.adminTopTraoTitle'),
          describe: item.trainName,
          dateTitle: t('usrTopPages.adminUsrTopTraDate'),
        })
      })
    })

    function getHotList(type) {
      info.value.hotList = []
      service.top.getHotList(type).then(res => {
        let index = 1;
        res.hotList.forEach(item => {
          info.value.hotList.push({
            elearningId: item.id,
            title: item.name,
            describe: !!radios[item.type] ? t('usrTopPages.adminTopPopularTitle2') + ': ' + radios[item.type] : t('usrTopPages.adminTopPopularTitle2') + ': ',
            index: index,
            rank: item.rank,
            loginRank: res.usrLoginRank,
            priceApplication: item.priceApplication,
            clickFlag: item.clickFlag
          })
          index++;
        })
      })
    }

    function radioChange(type) {
      getHotList(type)
    }

    function informationSelect(item, index) {
      router.push({ name: 'notification', params: { id: item.id, index } })
    }

    const rskRadioList = []
    {
      rskRadioList.push(
        { label: radios[0], value: 0 },
        { label: radios[14], value: 14 },
        { label: radios[13], value: 13 },
        { label: radios[12], value: 12 },
        { label: radios[11], value: 11 },
        { label: radios[10], value: 10 },
        { label: radios[4], value: 4 },
        { label: radios[5], value: 5 },
        { label: radios[6], value: 6 },
        { label: radios[1], value: 1 },
        { label: radios[2], value: 2 },
        { label: radios[3], value: 3 },
        { label: radios[7], value: 7 },
        { label: radios[8], value: 8 },
        { label: radios[9], value: 9 },
      )
    }

    function moveToUsrPro() {
      router.push({ path: '/usrpro' })
    }
    function moveToPageByPath(path) {
      router.push({ path: path })
    }

    return {
      info,
      rskRadioList,
      useFlag,
      informationSelect,
      radioChange,
      moveToUsrPro,
      displayFlg5,
      displayFlg1,
      isDispatchAgencyFlag,
      learnTimeTitle,
      learnTimeTooilp,
      studyTimeUnShowFlag,
      moveToPageByPath
    }
  },
}
</script>

<style lang="scss" src="./css/usr_top.scss" scoped />
