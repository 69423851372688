import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/rep')
export default class Report extends BaseService{
    search(data) {
		return this.request({
            data: {
				'condition' : data
			},
			url: "/search",
			method: "POST"
		});
	}

	getReportConfirm(id) {
		const data = {'id': id}
		return this.request({
            data: data,
			url: "/confirm",
			method: "POST"
		});
	}

	getReportSend(id) {
		const data = {'id': id}
		return this.request({
            data: data,
			url: "/send",
			method: "POST"
		});
	}

	update(data, upload1, upload2) {
		const form = new FormData()

		let fromData = {
			model: data
		}
		form.append("reqDto", new Blob([JSON.stringify(fromData)], { type: "application/json" }));
		if (upload1) {
			form.append('upload1', upload1);
		}
		if (upload2) {
			form.append('upload2', upload2);
		}
		return this.request({
			data: form,
			url: "/update",
			method: "POST",	
            Headers: {
                'Content-Type': 'multipart/form-data',
            }
		});
	}
}