<template>
    <div>
        <el-radio-group v-model="select" @change="onChange(select)" class="rsk-radio">
            <template  v-for="(item, index) in options" :key="index">
                <el-radio-button :label="item.value" >{{item.label}}</el-radio-button>
                <div v-if="index!=0 && (index+1) % 5 == 0"></div>
            </template>
        </el-radio-group>
    </div>
</template>
<script>
import { ref } from 'vue';
export default {
    props: {
        modelValue: {
            type: [String, Number],
            default: 0
        },
        /**
         * label
         * value
         * 
         */
        options: []
    },
    setup(props, { emit }) {
        let select = ref(props.modelValue)

        function onChange(val){
            emit("update:modelValue", val);
            emit('onChange', val)
        }
        return {
            onChange,
            select,
            ...props
        }
    },
}
</script>
<style>
.rsk-radio .el-radio-button__inner{
    margin-left: 4px;
    margin-bottom: 8px;
    border-radius: 40px !important;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(48, 53, 59, 0.16);
}
.rsk-radio .el-radio-button__original-radio:checked+.el-radio-button__inner{
    background-color: #134F94;
    color: #fff;
    border-color: #134F94;
}
</style>