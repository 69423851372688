<template>
  <body>
    <header>
      <div class="header" id="top">
        <p class="header-logo"><img src="./png/cx_univ_logo.png" alt="サイバックスUniv."></p>
        <div class="headline-board">
          <p class="header-headline">無料体験版お申込みフォーム</p>
        </div>
      </div>
    </header>

    <section>
      <div class="form-step">
        <ul class="form-step-list full-step input">
          <li class="form-step-item on">
            <span class="form-step-num">01</span>
            <p class="form-step-text">お客様情報<br>の入力</p>
          </li>
          <li class="form-step-item on">
            <span class="form-step-num">02</span>
            <p class="form-step-text">入力内容<br>の確認</p>
          </li>
          <li class="form-step-item on">
            <span class="form-step-num">03</span>
            <p class="form-step-text">お申込み<br>完了</p>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div class="thanks-msg">
				<div class="inner">
					<div class="thanks-msg-box">
						<p class="thanks-msg-text">自動返信メールを送信させていただきます。<br>2時間程度過ぎても自動返信メールが届かない場合は、ご入力いただいたメールアドレスが誤っている場合があります。お手数ですが下記アドレスまでお知らせいただきますようお願い致します。</p>
						<p class="thanks-msg-text"><span class="email-text"><a href="mailto:info-cbx@riskmonster.co.jp">info-cbx@riskmonster.co.jp</a></span></p>
						<p class="thanks-msg-text">お問い合わせ内容によっては、回答に時間がかかる場合や、回答を差し控えさせていただく場合もございますので、あらかじめご了承ください。</p>
					</div>
				</div>
			</div>
    </section>


    <footer>
      <div class="footer">
        <div class="inner">
          <p class="footer-headline">本件に関するお問い合わせ先</p>
          <dl class="footer-menu-box">
            <dt class="footer-menu-headline">カスタマーセンター</dt>
            <dd class="footer-menu-contents">
              <ul class="footer-menu-list">
                <li class="footer-menu-item"><b>フリーダイアル</b><span>0120-035-205</span></li>
                <li class="footer-menu-item"><b>携帯電話</b><span>03-5202-7121</span></li>
                <li class="footer-menu-item"><b>受付時間</b><span>平日10:00～16:00</span></li>
              </ul>
            </dd>
          </dl>
        </div>
      </div>
    </footer>
  </body>
</template>

<script>
import { useRoute } from 'vue-router'
export default {
    setup() {
        const route = useRoute();
        const model = route.params.corporateName;
        if (model != null || model != undefined) {
          window.dataLayer.push({
            'cbx_free_corporateName':model,
            'event':'cbx_free'
          })
        }
        return {
        }
    }
}
</script>

<style lang="scss" src="./css/contact.scss" scoped />