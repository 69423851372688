import { dateFtt } from '@/utils/common'
import { ref, inject, watch, onMounted,nextTick } from 'vue';
import { useRouter } from 'vue-router'
import { checkInvalid } from '@/utils/validate'
import { dateDtmCheck } from "@/utils/dateCheck"
import { full } from '@/utils/validateUtils'

export default {
    setup() {
        const showDate = ref(true)
    
        function reload(){
        showDate.value = false
        nextTick(() => {
            showDate.value = true
        })
        }
        const service = inject("service")
        const formData = ref({})
        const answerFlg = ref(null)
        const titles = ref([
            {
                field: 'status',
                title: 'ステータス',
                icon: {
                    list: [
                        { label: '未確認', value: '0' },
                        { label: '確認済', value: '1' },
                    ],
                },
                label: true,
                size: 'l',
                order:'none'
            },
            {
                field: 'title',
                title: 'お知らせタイトル',
                order: 'none'
            },
            {
                field: 'usrNm',
                title: '掲載者',
                size: 'l',
                order:'none'
            },
            {
                field: 'startedDtm',
                title: '掲載開始日時',
                size: 'l',
                order:'desc'
            },
            {
                field: 'finishedDtm',
                title: '掲載終了日時',
                size: 'l',
                order:'none'
            }
        ])

        function checkStartWithEndDtm(callback) {
            if (formData.value.startedDtm !== '' && formData.value.finishedDtm !== '') {
                var startedDtm = new Date(formData.value.startedDtm);
                var finishedDtm = new Date(formData.value.finishedDtm);
                if (startedDtm.getTime() > finishedDtm.getTime()) {
                    callback(new Error('掲載日 の期間指定が正しくありません。'));
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }

        const rules = ref(
            {
                title: [
                    full('お知らせタイトルは全角で入力してください'),
                    {
                        validator: (rule, value, callback) => {
                            const validateData = { invalidStr: value, isUnicoe: true }
                            checkInvalid(validateData, service, callback, 'お知らせタイトル')
                        }
                    },
                    {
                        max: 50,
                        message: 'お知らせタイトルは50文字以内で入力してください。'
                    },
                ],
                content: [
                    full('お知らせ本文は全角で入力してください'),
                    {
                        validator: (rule, value, callback) => {
                            const validateData = { invalidStr: value, isUnicoe: true }
                            checkInvalid(validateData, service, callback, 'お知らせ本文')
                        }
                    },
                    {
                        max: 1000,
                        message: 'お知らせ本文は50文字以内で入力してください。'
                    },

                ],
                startWithEndDtm: [
                    {
                        validator: (rule, value, callback) => {
                            let res = dateDtmCheck("startedDtm","finishedDtm",formData.value.startedDtm,formData.value.finishedDtm)
                            disErrFlag.value = res.disErrFlag;
                            disErrFlagOpen.value = res.disErrFlagOpen;
                            disErrFlagEnd.value = res.disErrFlagEnd;
                            if(res.disErrFlag == true || res.disErrFlagOpen == true || res.disErrFlagEnd == true) {
                                callback(new Error())
                            }else {
                                callback()
                            }
                        }
                    }
                ],
                status: [
                    {
                        validator: (rule, value, callback) => {
                            if (formData.value.status.length == 0) {
                                callback(new Error("ステータスを選択してください。"));
                            } else {
                                callback();
                            }
                        },
                    },
                ]
            }
        )
        const formRef = ref()
        const tableRef = ref()

        const router = useRouter()
        const tableData = ref([])
        const totalTable = ref(0)
        let exasort = {}
        let exapaging = {}

        const disErrFlag = ref(false)
        const disErrFlagOpen = ref(false)
        const disErrFlagEnd = ref(false)
        function changeStatus() {
            if (formData.value.status.length == 2) {
                formData.value.answerFlg = null
            }
            else {
                formData.value.answerFlg = formData.value.status[0]
            }
        }

        const clear = () => {
            formData.value.title = ''
            formData.value.content = ''
            formData.value.startedDtm = ''
            formData.value.finishedDtm = ''
            formData.value.pageNumber = 20
            formData.value.status = [0, 1]
            formData.value.answerFlg = null
            formData.value.startedDtm = ''
            formData.value.finishedDtm = ''
            formRef.value.validate(formData.value)
            tableRef.value.clear();
            
            reload()
            index()
            disErrFlag.value = false;
            disErrFlagOpen.value = false;
            disErrFlagEnd.value = false;
        }

        const index = () => {
            service.usrinf.index({ condition: { ...formData.value, ...exasort, ...exapaging } }).then(res => {
                tableData.value.length = 0
                if (res) {
                    totalTable.value = res.count ? res.count : 0
                    if (res.models) setTableData(res.models)
                }
            })
        }

        const search = () => {
            formRef.value.validate(formData.value).then((resp) => {
                if (resp) {
                    changeStatus()
                    service.usrinf.search({ condition: { ...formData.value, ...exasort, ...exapaging } }).then(res => {
                        tableData.value.length = 0
                        if (res) {
                            totalTable.value = res.count ? res.count : 0
                            if (res.models) setTableData(res.models)
                        }
                    })
                }
            })
        }

        const changePage = (sorts, p) => {
            exasort = sorts
            exapaging = p
            search()
        }

        const orderTable = (sorts, p) => {
            exasort = sorts
            exapaging = p
            search()
        }

        function refresh() {
            tableRef.value.clear();
        }

        function exatableConfigInit(sorts, p) {
            exasort = sorts
            exapaging = p
        }

        function setTableData(models) {
            for (let i = 0; i < models.length; i++) {
                let data = {
                    status: {
                        text: '',
                    },
                    title: {
                        text: '',
                        callBack: function (e) {
                            router.push({ name: 'notification', params: { id: e.informationId, from: 'usr_inf' } })
                        },
                    },
                    usrNm: {
                        text: '',
                    },
                    startedDtm: {
                        text: ''
                    },
                    finishedDtm: {
                        text: ''
                    }
                }
                data.status.text = models[i].answerFlg == 0 ? "未確認" : "確認済"
                data.title.text = models[i].title
                data.title.informationId = models[i].informationId
                data.usrNm.text = models[i].usrNm
                data.startedDtm.text = dateFtt("auto_time", models[i].startedDtm, "en")
                data.finishedDtm.text = dateFtt("auto_time", models[i].finishedDtm, "en")
                if (models[i].deleted == 1) {
                    data.usrNm.text = "（削除されたユーザー）"
                }
                tableData.value[i] = data
            }
        }


        watch(answerFlg, () => {
            formData.value.answerFlg = answerFlg
            search()
        })
        onMounted(() => {
            formData.value.title = ''
            formData.value.content = ''
            formData.value.startedDtm = ''
            formData.value.finishedDtm = ''
            formData.value.pageNumber = 20
            formData.value.status = [0, 1]
            formData.value.answerFlg = null
            formData.value.startedDtm = ''
            formData.value.finishedDtm = ''
            index()
        })

        return {
            formRef,
            titles,
            tableData,
            totalTable,
            formData,
            answerFlg,
            rules,
            search,
            clear,
            index,
            orderTable,
            changePage,
            exatableConfigInit,
            tableRef,
            showDate,
            refresh,
            disErrFlag,
            disErrFlagOpen,
            disErrFlagEnd
        }
    },

}