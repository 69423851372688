<template>
    <div class="content">
        <div class="rsk-p-xl">
            <div class="rsk-form-control">
                <h2 class="rsk-page-title rsk-mb-s">アンケート回答内容</h2>
            </div>
            <div class="rsk-panel rsk-mb-m">
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">アンケート名</div>
                    </div>
                    <div v-text="detail.anketoName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">ボックス名</div>
                    </div>
                    <div v-text="detail.lessonName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">回答日</div>
                    </div>
                    <div v-text="detail.answerBeginTm" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>                                                                                  
            </div>
            <table class="rsk-table rsk-mb-l" id="rsk-table" v-if="list.length > 0">
                <thead class="rsk-table__thead">
                    <tr class="rsk-table__tr">                             
                        <th class="rsk-table__th rsk-table__th--xs">
                            <div class="rsk-table__th-content-center">出題順</div>
                        </th>
                        <th class="rsk-table__th rsk-table__th--expand">
                            <div class="rsk-table__th-content-center ">問題</div>
                        </th>                        
                    </tr>
                </thead>
                <tbody class="rsk-table__tbody">               
                    <tr class="rsk-table__tr" v-for="(item, index) in list" :key="index">
                        <td class="rsk-table__td">
                            <div class="rsk-table__td-content">
                            問題{{ item.sortNo }}
                            </div>
                            <span class="rsk-label-must" v-if="item.requisite == 1">必須</span>
                        </td>
                        <td class="rsk-table__td" v-if="item.questionType == 1">
                            <div class="table__td-content rsk-img-container rsk-mt-s rsk-block whiteSpace" v-html="item.questionName"></div>
                            <template  v-for="(item2, index2) in item.detailList" :key="index2">
                            <div class="table__td-content text-wrap">
                                <div class="rsk-radio">
                                    <label class="rsk-radio--label">
                                        <input v-if="item2.select" type="radio" :checked="item2.select"  class="rsk-radio__input" disabled="disabled" />
                                        <input v-else type="radio"  class="rsk-radio__input" disabled="disabled" />
                                        <span class="rsk-radio__text">
                                            <span class="select-wrap" v-if="item2.select"><b>{{ item2.selection }}</b></span>
                                            <span class="select-wrap" v-else>{{ item2.selection }}</span>
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-table__td-content rsk-mb-xs" v-if="item2.otherFlg == 1">
			        		    <textarea class="rsk-input rsk-textarea__input rsk-input--expand" :value="item2.answer" disabled="disabled"></textarea>
			        	    </div>
                            </template>
                        </td>
                        <td class="rsk-table__td" v-if="item.questionType == 2">
                            <div class="table__td-content rsk-img-container rsk-mt-s rsk-block whiteSpace" v-html="item.questionName"></div>
                            <template  v-for="(item2, index2) in item.detailList" :key="index2">
                            <div class="table__td-content text-wrap">
                                <div class="rsk-checkbox-container">
                                    <input class="rsk-checkbox"  type="checkbox" disabled="disabled" :id="'list[' + index + '].detailList[' + index2 + ']'"  :checked="item2.select" />
                                    <label class="rsk-checkbox__image" :for="'list[' + index + '].detailList[' + index2 + ']'"></label>
                                    <label :for="'list[' + index + '].detailList[' + index2 + ']'" class="rsk-checkbox__label rsk-text-gray100 text-opacity">
                                        <span v-if="item2.select"><b>{{ item2.selection }}</b></span>
                                        <span v-else>{{ item2.selection }}</span>
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-table__td-content rsk-mb-xs" v-if="item2.otherFlg == 1">
			        		    <textarea class="rsk-input rsk-textarea__input rsk-input--expand" :value="item2.answer" disabled="disabled"></textarea>
			        	    </div>
                            </template>
                        </td>
                        <td class="rsk-table__td" v-if="item.questionType == 3">
                            <div class="table__td-content rsk-img-container rsk-mt-s rsk-block whiteSpace" v-html="item.questionName"></div>
                            <div class="table__td-content rsk-mb-xs text-wrap">
                                <template v-if="item.answerList.length > 0">
                                    <input  v-for="(item2, index2) in item.answerList" :key="index2" class="rsk-input rsk-input--expand" :value="item2.answer" disabled="disabled" />
                                </template>
                                <input v-else class="rsk-input rsk-input--expand" disabled="disabled" />
                            </div>
                        </td>
                        <td class="rsk-table__td" v-if="item.questionType == 4">
                            <div class="table__td-content rsk-img-container rsk-mt-s rsk-block whiteSpace" v-html="item.questionName"></div>
                            <div class="table__td-content rsk-mb-xs text-wrap">
                                <template  v-if="item.answerList.length > 0">
                                    <textarea v-for="(item2, index2) in item.answerList" :key="index2" class="rsk-input rsk-textarea__input rsk-input--expand" :value="item2.answer" disabled="disabled" > </textarea>
                                </template>
                                <textarea v-else class="rsk-input rsk-textarea__input rsk-input--expand" disabled="disabled" > </textarea> 
                            </div>   
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="rsk-p-s">
                <button @click="index" class="rsk-button rsk-button--text-user-primary">
                    <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                        <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                    </span>
                    戻る
                </button> 
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, inject, onBeforeUnmount } from 'vue';
import { useRoute,useRouter } from 'vue-router'
import { dateFtt } from '@/utils/common'
import { useStore } from 'vuex'
export default {
    setup() {
        const detail = ref({});
        const list = ref([]);
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        let id = "";
        let goUrl = "ant";
        let lessonId = "";
        onMounted(() => {
            if(typeof(route.params.id) != "undefined") {
                id = route.params.id;
                localStorage.anketoLessonId = id; 
            } else {
                id = localStorage.anketoLessonId
            }
            if(!id) {
                router.push({ name: 'ant'});
                return;
            }
            store.commit('setCourseId', id);

            if(typeof(route.params.fromUrl) != "undefined") {
                goUrl = route.params.fromUrl;
            }
            if(typeof(route.params.fromUrl) != "undefined") {
                lessonId = route.params.lessonId;
            }
            getAnketoConfirmDetail()
        })
        
        const service = inject("service");
        function getAnketoConfirmDetail() {
            service.anketo.getAnketoConfirm(store?.getters?.getCourseId).then(res => {
                detail.value = res.model
                detail.value.answerBeginTm = dateFtt('yyyy/MM/dd', detail.value.answerBeginTm);
            
                list.value = res.questionList;   
            })
        }

        function index() {
            router.push({ 
                name: goUrl,
                params: {
                    lessonId : lessonId
                }
            });
        }

        function setCourseId() {
            localStorage.anketoLessonId = store?.getters?.getCourseId; 
        }

        window.addEventListener('beforeunload', setCourseId);

        onBeforeUnmount(() => {
            localStorage.removeItem("anketoLessonId");
            window.removeEventListener('beforeunload', setCourseId)
        })

        return {
            detail,
            list,
            index
        }
    }
}
</script>

<style lang="scss" scoped>
.rsk-label-must {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #EE4967;
    border-radius: 4px;
    font-size: 11px;
    color: #FFFFFF;
    width: 38px;
    height: 21px;
    margin-left: 8px;
}
.rsk-textarea__input {
    height: 200px;
}

.table__td-content {
    color: #30353B;
    height: 100%;
    min-width: 30px;
    overflow-wrap: break-word;
    word-break: break-all;
    word-wrap: break-word;
    position: relative;
    min-height: 36px;
    padding: 0 8px;
    font-size: 12px;
}

.whiteSpace {
    white-space: normal;
}

.select-wrap {
    left: 24px;
    position: relative;
}

.text-wrap {
    width: calc(100% - 24px);
    white-space: normal;
}

.text-opacity {
    opacity: 0.4;
}
</style>
