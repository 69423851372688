<template>
  <section class="main" style="min-width: 9.80rem;min-height: 58.80rem;">
    <transition
      name="fade"
      mode="out-in">
        <router-view :key="key"/>
    </transition>
  </section>
</template>

<script>
// import { reactive, toRefs, ref } from 'vue'
import { useRoute } from 'vue-router'

const useAppMainEffect = () => {
  const route = useRoute()

  const key = () => {
    return route.name + new Date()
  }

  return { key }
}
export default {
  name: 'AppMain',
  setup () {
    const { key } = useAppMainEffect()
    return {
      key
    }
  }
}
</script>

<style lang="scss" scoped>

.main {
  padding: .40rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

</style>
