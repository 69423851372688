<template>
    <div class="content">
        <rsk-container title="お知らせ確認" :buttons="buttons">
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs" v-for="(item, index) in infos" :key="index">
                <div class="rsk-flex rsk-justify-between rsk-width-212">
                    <div class="rsk-table-like-form__heading rsk-table-like-form__table-column">{{item.title}}</div>
                </div>
                <div class="rsk-form rsk-form--expand">
                    <div v-if="!item.url && !item.html" class="rsk-text">{{ item.value }}</div>
                    <div v-else-if="item.html" class="rsk-text ck-content" v-html='item.value'></div>
                    <a href="#" @click="downloadFile(item.filename, item.filepath)" v-else>{{item.value}}</a>
                </div>
            </div>
            <div class="rsk-form-control rsk-toggle-form rsk-mb-xs"></div>
        </rsk-container>
    </div>
</template>
<script>
import { dateFtt } from '@/utils/common'
import { ref,  inject, onMounted } from 'vue';
import { useRoute,useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const service = inject("service");
    const infos = ref([])
    const route = useRoute()
    let id = route.params.id ? route.params.id : localStorage.getItem('notifiId');
    let from = route.params.from
    const index = route.params.index ? ref(route.params.index * 1) : ref(localStorage.getItem('notifiIndex') * 1);
    const upId = ref()
    const downId = ref()
    // const list = store?.getters?.getNotificationList
    const list = JSON.parse(localStorage.getItem('notificationList'));

    function getInfo(_id){
        if(_id) {
            id = _id
            if(list.length != 0 && !from) {
                upId.value = index.value !=0? list[index.value-1].id: null;
                downId.value = index.value != list.length-1? list[index.value+1].id : null;
            }
            from ? buttons.value = detailButton : buttons.value = button
            infos.value = []
            service.notification.getInfo(_id).then(res=>{
                if(res) {
                    let usrName = res.usrNm
                    if (res.deleted == 1) {
                        usrName = "（削除されたユーザー）"
                    }
                    if (res.answerFlg === 1) {
                        buttons.value.centre.show = function() { return false }
                    }
                    infos.value.push({title: 'お知らせタイトル',value: res.title})
                    infos.value.push({title: 'お知らせ本文',value: res.content, html: true})
                    infos.value.push({title: '掲載者',value: usrName})
                    infos.value.push({title: '掲載日時',value: dateFtt('auto_time', res.startedDtm, "en") + '~' + dateFtt('auto_time', res.finishedDtm, "en")})
                    infos.value.push({title: '添付ファイル1',value: res.name1, url: res.informationFileName1, filename: res.name1, filepath: res.informationFileName1})
                    infos.value.push({title: '添付ファイル2',value: res.name2, url: res.informationFileName2, filename: res.name2, filepath: res.informationFileName2})
                }
            })
        } else {
            router.go(-1);
        }
    }
    function updateNotificationDetailAnswerFlg() {
      service.notification.updateNotificationDetailAnswerFlg(id).then(res=>{
        if (res === 1) {
          router.push({ name: 'usrinf'})
          
          setTimeout(() => {
                location.reload({name: 'usrinf'})
            }, 200)
          ElMessage({
            type:'success',
            message: 'お知らせを確認しました。',
            showClose: true,
            duration:3000
          })
        }
      })
    }

    function downloadFile (filename, fileurl){
        const downloadData = {filename:'', fileurl:'', userAgent:''}
        downloadData.filename = filename
        downloadData.fileurl = fileurl
        service.common.downloadFile(downloadData)
    }

    const buttons = ref({})

    const button = {
        left: {
            text: '前ページ',
            show: function() {
                return upId.value != null;
            },
            callBack: function() {
                index.value--;
                getInfo(upId.value)
            }
        },right: {
            text:'次ページ',
            show: function() {
                return downId.value != null;
            },
            callBack: function() {
                index.value++;
                getInfo(downId.value)
            }
        },centre: {
            text: '確認',
            show: function() {
                return id != null;
            },
            callBack: function() {
                updateNotificationDetailAnswerFlg();
            }
        },
    }

    const detailButton = {
        left: {
            text: '戻る',
            callBack: function() {
                router.go(-1)
            }
        },
        centre: {
            text: '確認',
            callBack: function() {
                updateNotificationDetailAnswerFlg();
            }
        },
    }

    onMounted(() => {
        getInfo(id)
    })

    return {
        buttons,
        downId,
        upId,
        infos,
        downloadFile
    }
  }
}
</script>
