<template>
  <OpeElgant>
    <div class="main-one-col">
      <div id="contents-wrap" class="inner-form">
        <section>
          <h1 class="ttl-head01 center">コンテンツ満足度アンケート</h1>
          <div class="sub_ttl">
            eラーニング で受講した以下のコースについて感想をお聞かせください。<br>
            <span><br class="sp-on">
              {{ model.elearningName }}
            </span>
          </div>

          <div class="survey_form">
            <div id="list_group"></div>
            <div v-if="questionList.length > 0">
              <div v-for="(question, index) in questionList" :key="index">
                <dl v-if="question.showFlg == 0">
                  <dt>
                    <span v-if="question.questionType == 1" class="required">必須</span>
                    <span v-else class="self">任意</span>
                    【質問 {{ question.sortNo }} 】 {{ question.questionName }}
                  </dt>
                  <dd>

                    <ul style="padding-left: 5px;" v-if="question.questionType == 1">
                      <li v-for="(detail, index_d) in question.detailList" :key="index_d" class="li_select">
                        <input type="radio" :id="`questionList_${index}__detailId${detail.detailId}`"
                          :disabled="!isError && !isBack" :checked="detail.detailId === question.detailId"
                          @change="getRadioVal(question.questionId, detail.detailId)">
                        <label :for="`questionList_${index}__detailId${detail.detailId}`">{{ detail.selection }}</label>
                      </li>
                      <div v-if="isError" class="error-text">{{ errMsgArray[question.questionId] }}</div>
                    </ul>
                    <div v-else-if="question.questionType == 4">
                      <textarea :disabled="!isError && !isBack" v-model="question.answer" cols="15" rows="8"
                        @change="getTextareaVal(question.questionId, question.answer)"></textarea>
                      <div v-if="isError" class="error-text">{{ errMsgArray[question.questionId] }}</div>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>
            <div class="formSubmit txtC">
              <button v-if="!isError && !isBack" @click="back" class="btn-base inline-b back_btn_sp">入力に戻る</button>
              &numsp;
              <button v-if="!isError && !isBack" @click="confirm_send" class="btn-base inline-b">アンケート送信</button>
              <button v-if="isError || isBack" @click="confirm_submit" class="btn-base inline-b">確認画面へ進む</button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </OpeElgant>
</template>

<script>
import { RouterLink, RouterView, useRoute, useRouter } from "vue-router"
import OpeElgant from "./ope_elgant.vue"
import { inject, ref, onMounted } from 'vue'

export default {
  components: {
    OpeElgant,
    RouterLink,
    RouterView
  },
  setup() {
    const service = inject("service")
    const route = useRoute()
    const router = useRouter()
    let model = ref({})
    let questionList = ref({})
    let errMsgArray = ref()
    const isError = ref()   // 检证是否报错   true ：报错  false：不报错
    const isBack = ref() // 是否返回  true: 是 false:否
    let messageData =''
    // 页面初始化时触发
    onMounted(() => {

      // !=null 由上一个页面跳转过来; null:浏览器刷新
      if (null != route.params.model || undefined != route.params.model) {
        isBack.value = false
        localStorage.setItem('ope_elgant_confirm_back', isBack.value)

        model.value = JSON.parse(route.params.model)
        if (null != route.params.questionList) {
          questionList.value = JSON.parse(route.params.questionList)
          Reflect.set(model.value, 'questionList', questionList.value)
        }
      } else {
        model.value = JSON.parse(sessionStorage.getItem('ope_elgant_model'))
        questionList.value = JSON.parse(sessionStorage.getItem('ope_elgant_questionList'))
        Reflect.set(model.value, 'questionList', questionList.value)
      }
      if (null != route.params.windowOpen || undefined != route.params.windowOpen) {
        messageData = route.params.windowOpen
      }
      if (model.value != null) {
        update(model) 
      }
    })


    function getRadioVal(questionId, detailId) {
      questionList.value.forEach(question => {
        if (question.questionType == 1 && question.questionId == questionId) {
          question.detailId = detailId;
        }
      })
    }

    function getTextareaVal(questionId, answer) {
      questionList.value.forEach(question => {
        if (question.questionType == 4 && question.questionId == questionId) {
          question.answer = answer;
        }
      })
    }

    function confirm_submit() {
      isBack.value = false
      localStorage.setItem('ope_elgant_confirm_back', isBack.value)

      Reflect.set(model.value, 'questionList', questionList)
      update(model)
    }

    function update(model) {
      isBack.value = localStorage.getItem('ope_elgant_confirm_back') == "true" ? true : false
      sessionStorage.setItem('ope_elgant_model', JSON.stringify(model.value))
      sessionStorage.setItem('ope_elgant_questionList', JSON.stringify(questionList.value))

      errMsgArray.undefined
      service.opeelgant.opeelgant.update(model.value).then(res => {
        console.log("res",res)
        if (null != res && null != res.errorMessages) {
          errMsgArray.value = res.errorMessages
          isError.value = true
        } else {
          questionList.value = res.questionList
          model.value = res.model
          isError.value = false
        }
      }).catch(error => {
        
      })
    }

    function confirm_send() {
      if (window.confirm('送信してもよろしいですか？')) {
        //block_ui("送信中…");
        // showSpin();
        // location.href = url;
        // return true;
        service.opeelgant.opeelgant.confirm().then(res => {
          if (res.pageFlag == 'finish') {
            if(messageData === '2'){
              router.push({ name: 'ope_elgant_finish', params: {windowOpen: '2'} })
            } else{
              router.push({ name: 'ope_elgant_finish' })
            }
          } else if (res.pageFlag == 'completed') {
            if(messageData === '2'){
              router.push({ name: 'ope_elgant_completed', params: {windowOpen: '2'} })
            } else{
              router.push({ name: 'ope_elgant_completed' })
            }
          }
        }).catch(error => {
          if (error.pageFlag === 'confirm') {

          }
        })
      }

    }

    function back() {
      isBack.value = true
      localStorage.setItem('ope_elgant_confirm_back', isBack.value)
    }

    return {
      OpeElgant,
      confirm_send,
      model,
      questionList,
      back,
      errMsgArray,
      getRadioVal,
      getTextareaVal,
      confirm_submit,
      isError,
      isBack
    }
  }
}
</script>
<style lang="scss" src="./css/ope_elgant.scss" scoped/>