import { createStore } from 'vuex'
import { menuList, permissionMenuFlg } from '@/config/menu'
import { addViews } from '@/utils/common'
import { cookieIsValid } from '../utils/CookieUtils.js'
export default createStore({

  state: {
    token: '',
    useFlag: false,
    displayFlg1: false,
    displayFlg2: false,
    displayFlg3: false,
    displayFlg4: false,
    displayFlg5: false,
    displayFlg6: false,
    agencyId: false,
    notificationList:[],
    permissionList:['1', '2', '3'],
    formCondition: cookieIsValid() ? localStorage.getItem('formCondition') : null,
    tableCondition: cookieIsValid() ? localStorage.getItem('tableCondition') : null,
  },
  getters: {
    formCondition (state) {
      if(state.formCondition && 'string' ==  typeof state.formCondition ) {
        state.formCondition = JSON.parse(state.formCondition)
      }
      return state.formCondition
    },
    tableCondition (state) {
      if(state.tableCondition && 'string' ==  typeof state.tableCondition ) {
        state.tableCondition = JSON.parse(state.tableCondition)
      }
      return state.tableCondition
    },
    getPermissionList (state) {
      return state.permissionList
    },
    getNotificationList (state) {
      return state.notificationList
    },
    getToken (state) {
      return state.token
    },
    getUseFlag(state) {
      return state.useFlag
    },
    getDisplayFlg1(state) {
      return state.displayFlg1
    },
    getDisplayFlg2(state) {
      return state.displayFlg2
    },
    getDisplayFlg3(state) {
      return state.displayFlg3
    },
    getDisplayFlg4(state) {
      return state.displayFlg4
    },
    getDisplayFlg5(state) {
      return state.displayFlg5
    },
    getDisplayFlg6(state) {
      return state.displayFlg6
    },
    getAgencyId(state) {
      return state.agencyId
    },
    getTestStartTime(state) {
      return state.testStartTime;
    },
    getCourseId(state) {
      return state.courseId;
    }
  },
  mutations: {
    formCondition (state, condition) {
      state.formCondition = condition
      if(condition){
        localStorage.setItem('formCondition', JSON.stringify(condition))
      }else {
        localStorage.removeItem('formCondition')
      }
    },
    tableCondition (state, condition) {
      state.tableCondition = condition
      if(condition){
        localStorage.setItem('tableCondition', JSON.stringify(condition))
      }else {
        localStorage.removeItem('tableCondition')
      }
      
    },
    setNotificationList(state, list) {
      state.notificationList = list
    },
    setToken(state, token) {
      state.token = token
    },

    changeUseFlag(state) {
      state.useFlag = true
    },

    changeDisplayFlg1(state) {
      state.displayFlg1 = true
    },

    changeDisplayFlg2(state) {
      state.displayFlg2 = true
    },

    changeDisplayFlg3(state) {
      state.displayFlg3 = true
    },

    changeDisplayFlg4(state) {
      state.displayFlg4 = true
    },

    changeDisplayFlg5(state) {
      state.displayFlg5 = true
    },

    changeDisplayFlg6(state) {
      state.displayFlg6 = true
    },

    changeAgencyId(state) {
      state.agencyId = true
    },
    setTestStartTime(state, testStartTime) {
      state.testStartTime = testStartTime;
    },
    setCourseId(state, courseId) {
      state.courseId = courseId;
    }
  },
  actions: {
    appLoad({ getters, dispatch }, router) {
      dispatch("permMenu", router);
	},
    permMenu({ commit, state, getters }, router) {
      return new Promise((resolve, reject) => {
        const childrenList = []
        const permissions = getters.getPermissionList
        menuList.forEach(item => {
          if( permissions.indexOf(item.id) != -1 || !permissionMenuFlg){
            childrenList.push(item)
          }
        });
        addViews(childrenList, router)
        resolve(childrenList)
      })
    }
  },
  modules: {
  }
})
