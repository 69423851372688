import { useRouter } from "vue-router";
import { inject } from "vue";
import { ref } from 'vue'
import { checkInvalid, validEmail } from '@/utils/validate'
import { full,fullKna,phone } from '@/utils/validateUtils'
export default {
    name: 'AssentusrNew',
    setup() {
        const router = useRouter();
        const service = inject("service");
        
        const login = JSON.parse(localStorage.getItem("login"));
        if (!login) {
            router.push({ path: '/login' });
            return;
        }

        var updData = ref({ comNm: '', comAdr: '', usrNm: '', appUsrKna: '', appUserDepNm: '', appPost: '', appUsrTel: '', usrMlAdr: '' })

        service.assentusr.assentusr.editNew(login).then(res => {
            updData.value = res;
        }).catch(error => {
            console.log(error)
        })

        const rules = ref(
            {
                comNm: [
                    { required: true, message: '会社名を入力してください。' },
                    {
                        max: 60,
                        message: '会社名 は 60 文字以内で入力してください。'
                    },
                    full('会社名は全角で入力してください'),
                    {validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value,isUnicoe:true }
                        checkInvalid(validateData,service,callback,'会社名')
                    }}
                ],
                comAdr: [
                    {
                        max: 250,
                        message: '所在地 は 60 文字以内で入力してください。'
                    },
                    full('所在地は全角で入力してください'),
                    {validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value,isUnicoe:true,isAddress:true }
                        checkInvalid(validateData,service,callback,'所在地')
                    }}
                ],
                usrNm: [
                    { required: true, message: '氏名を入力してください。' },
                    {
                        max: 30,
                        message: '氏名 は 30 文字以内で入力してください。'
                    },
                    full('氏名は全角で入力してください'),
                    {validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value,isUnicoe:true }
                        checkInvalid(validateData,service,callback,'氏名')
                    }}
                ],
                appUsrKna: [
                    {
                        max: 60,
                        message: 'フリガナ は 60 文字以内で入力してください。'
                    },
                    fullKna('フリガナは全角カナで入力してください。')
                ],
                appUserDepNm: [
                    {
                        max: 60,
                        message: '部署 は 60 文字以内で入力してください。'
                    },
                    full('部署は全角で入力してください'),
                    {validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value,isUnicoe:true }
                        checkInvalid(validateData,service,callback,'部署')
                    }}
                ],
                appPost: [
                    {
                        max: 20,
                        message: '役職 は 20 文字以内で入力してください。'
                    },
                    full('役職は全角で入力してください'),
                    {validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value,isUnicoe:true }
                        checkInvalid(validateData,service,callback,'役職')
                    }}             
                ],
                appUsrTel: [
                    { required: true, message: '電話番号を入力してください。' },
                    phone('電話番号は電話番号の形式(例 11-1111-1111)で入力してください。')
                ],
                usrMlAdr: [
                    { required: true, message: 'Ｅｍａｉｌアドレス(1)を入力してください。' },
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if(!(/^(.+)@(.+)$/.test(value))) {
                                    callback(new Error("Ｅｍａｉｌアドレス(1)は Email の形式(例 XXXXXXX@XX.com)で入力してください。"));
                                } else {
                                    const validateData = { invalidStr: value } 
                                    validEmail(validateData,callback,'RFC違反のメールアドレスの為、登録できません。')
                                }
                            } else {
                                callback(new Error('Ｅｍａｉｌアドレス(1)を入力してください。'));
                            }
                        }
                    }
                ]
            }
        )

        const formRef = ref()

        function usrConfirm() {
            formRef.value.validate(updData.value).then((res) => {
                if (res) {
                    const sendData = { updData: updData.value, login }
                    service.assentusr.assentusr.create(sendData).then(res => {
                        router.push({ name: 'Popenvironment',query:{popbtnShow:true}})
                    }).catch(error => {
                        console.log(error)
                    })
                }
            })
        }

        return {
            updData,
            rules, formRef,
            usrConfirm
        };
    }
}