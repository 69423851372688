
export const tabsChangeEffect = (router) => {
    const tabsChange = (select) => {
        if (select === 0) {
            router.push({ path: '/listen/tes'});
        } else if (select === 1) {
            router.push({ path: '/listen/ant'});
        } else {
            router.push({ path: '/listen/rep'});
        }
    }
    return {tabsChange}
}
