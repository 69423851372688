<template>
    <div class="item" style="align-items: center;">
        <div class="label" >
            {{label}}
        </div>
        <div class="value value-color" >
            <template v-if="value">
                <span >{{value}}</span>
                <div>{{describe}}</div>
            </template>
            <template v-else>
                <slot />
            </template>
            
            <span class=" ">3个月 hahahah</span>
            <el-rate v-model="rate" :colors="['#99A9BF', '#F7BA2A', '#FF9900']"></el-rate>
            <div style="padding: 0 5px;" >3，33</div>
            <span class=" rsk-label-enable">满足度高</span>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        label:'',
        value:'',
        describe:'',

    },
    setup(props) {
        return {
            ...props
        }
    },
}
</script>