<template>
  <el-container class="wrapper login_wrapper" :class="{'jcc-main' : isJcc || noticeList.length == 0}">
    <el-main style="overflow:visible;" :class="{ 'login-main': !isJcc && noticeList.length != 0}" :style="fromRismonFlag == '0' ? 'padding: 38px 0 38px 0;' : ''">
      <el-row justify="center" align="middle" v-if="noticeList.length > 0">
        <div class="login_application_wrapper">
          <div class="login_application" style="white-space: pre-line"> 
          <div
            class="notice-content"
            v-for="notice in noticeList"
            :key="notice"
          >
            <li class="notice_title">
              <div class="notice_date">
                {{notice.dateYmd}}&nbsp;&nbsp;&nbsp;{{notice.title}}
              </div>
            </li>
            <li>{{notice.content}}</li>
            <li v-if="notice.relateUrl"><a href="#" @click="winopen(notice.relateUrl)">{{ $t("loginPages.link1") }}</a></li>
            <li v-if="notice.reportFileName1 || notice.reportFileName2 || notice.reportFileName3">{{ $t("loginPages.attfile") }}</li>
            <li v-if="notice.reportFileName1">
              <a href="#" @click="downloadFile(notice.reportFileName1,notice.reportFileDir1)">
                {{ notice.reportFileName1 }}
              </a>
            </li>
            <li v-if="notice.reportFileName2">
              <a href="#" @click="downloadFile(notice.reportFileName2,notice.reportFileDir2)">
                {{ notice.reportFileName2 }}
              </a>
            </li>
            <li v-if="notice.reportFileName3">
              <a href="#" @click="downloadFile(notice.reportFileName3,notice.reportFileDir3)">
                {{ notice.reportFileName3 }}
              </a>
            </li>
          </div>
        </div>
        </div>
      </el-row>
      <el-row justify="center" align="middle" :style="!isJcc && noticeList.length == 0 && fromRismonFlag != '0' ? 'top: -37px' : ''">
        <div class="wrapper__login-window" style="z-index: 1">
          <el-row type="flex" justify="center" align="top" class="wrapper__login-window__header">
            <div>
              <img
                v-if="!isJcc"
                src="../../assets/pagetitle.gif"
                alt="."
                class="wrapper__gif-pagetitle"
              />
              <img
                v-if="isJcc"
                src="../../assets/logo_jassa.png"
                alt="."
                class="wrapper__gif-pagetitle"
              />
            </div>
            <div class="menu-wrapper">
              <div class="menu-btn" :class="{ 'menu-btn_active': locale=='ja' || locale == null}" @click="selectJa">日本語</div>
              <div style="margin: 0 20px; color: #A8B0BB;">|</div>
            <div class="menu-btn" :class="{ 'menu-btn_active': locale=='en'}" @click="selectEn">English</div>
        </div>
          </el-row>
          
          
          <el-row justify="center" align="middle" v-show="data.errorMsg" class="error-wrapper">
            <div style="display: none">サーバ名:{{inetAddress}}</div>
            <img
              src="../../assets/icon_err.svg"
              alt="."
            />
            <span v-html="data.errorMsg"></span>
          </el-row>
          <el-row
            type="flex"
            justify="center"
            align="middle"
            class="wrapper__input"
            v-if="fromRismonFlag !== '2'"
          >
              <el-input maxlength="18" v-model="data.comId" :placeholder="$t('loginPages.comId')" v-if="fromRismonFlag == '0'"></el-input>
              <el-input maxlength="15" v-model="data.comId" :placeholder="$t('loginPages.comId')" v-if="fromRismonFlag != '0'"></el-input>
          </el-row>
          <br />
          <el-row
            type="flex"
            justify="center"
            align="middle"
            class="wrapper__input"
          >
              <el-input v-model="data.userId" maxlength="128" :placeholder="$t('loginPages.userIdMladr')" v-if="fromRismonFlag == '0'"/>
              <el-input v-model="data.userId" maxlength="9" :placeholder="$t('loginPages.userId')" v-if="fromRismonFlag !== '0'"/>
          </el-row>
          <br />
          <el-row
            type="flex"
            justify="center"
            align="middle"
            class="wrapper__input"
          >
            <el-input type="password" maxlength="40" v-model="data.password" :placeholder="$t('loginPages.password')"></el-input>
          </el-row>
          <el-row type="flex" justify="center" align="middle" style="margin-top: 15px;margin-bottom: 15px;">
            <el-checkbox
              v-model="loginChecked"
              style="padding-right: 10px"
            ></el-checkbox>
            <span style="color: black">{{ $t("loginPages.checked") }}</span>
          </el-row>
          <el-row type="flex" justify="center" align="middle" style="margin-bottom: 15px;">
            <el-col :span="20" align="middle" justify="center">
              <el-button
                type="primary"
                class="wrapper__login-button"
                @click="handleLogin"
                >{{ $t("loginPages.handleLogin") }}</el-button
              >
            </el-col>
          </el-row>
          <div class="forget-wrapper">
            <el-row v-if="!isJcc" type="flex" justify="center" align="middle">
              <el-col :span="20" align="middle" justify="center">
                <el-link type="primary" class="wrapper__login-legend__link" @click="topasdeh">
                  {{ $t("loginPages.forget") }}
                </el-link>
              </el-col>
            </el-row>
            <el-row type="flex" justify="center" align="middle" v-if="fromRismonFlag == '0'">
              <el-col :span="20" align="middle" justify="center">
                <el-link type="primary" class="wrapper__login-legend__link" @click="addYoshinUsr">
                  {{ $t("loginPages.addyoshinUser") }}
                </el-link>
              </el-col>
            </el-row>
          </div>
          <el-row type="flex" justify="center" align="middle">
            <div class="wrapper__login-legend">
              <span class="wrapper__login-legend__content">
                {{ $t("loginPages.text1") }}
              </span>
              <span class="wrapper__login-legend__content" style="white-space: nowrap;">
                {{ $t("loginPages.text2") }}
                <el-link type="primary" class="wrapper__login-legend__link" @click="toEnv">
                  {{ $t("loginPages.text3") }}
                </el-link>
                  {{ $t("loginPages.text4") }}
              </span>
              <span class="wrapper__login-legend__content">
                {{ $t("loginPages.text5") }}
                <el-link type="primary" class="wrapper__login-legend__link" @click="toZendesk">
                  {{ $t("loginPages.text6") }}
                </el-link>
                {{ $t("loginPages.text7") }}
              </span>
            </div>
          </el-row>
          <el-row v-if="!isJcc && fromRismonFlag != '0'" type="flex" justify="center" align="middle" style="margin-top: 15px;margin-bottom: 15px;">
            <div class="phone_tip_wrapper">
              <div class="phone_tip_content">
                <span>{{ $t("loginPages.text8") }}</span>
                <img
                  style="width: 24px; height: 17px"
                  src="./png/icon_right.svg"
                  alt="."
                />
                <img
                  style="width: 75px; height: 75px"
                  src="./png/web_QR.png"
                  alt="."
                />
              </div>
            </div>
          </el-row>
          <el-row type="flex" justify="center" align="middle"  v-if="fromRismonFlag == '0'">
            <el-divider></el-divider>  
            <div class="wrapper__login-legend">
              <span class="wrapper__login-legend__content">{{ $t("loginPages.yoshintext1") }}</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext2")}}</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext3")}}</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext4")}}</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext5")}}</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext6")}}</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext7")}}</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext8")}}</span>
              <span class="wrapper__login-legend__content" style="white-space: nowrap;">{{$t("loginPages.yoshintext9")}}</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext10")}}: seminar@riskmonster.co.jp</span>
              <span class="wrapper__login-legend__content">{{$t("loginPages.yoshintext11")}}</span>
            </div>
          </el-row>
        </div>
        
      </el-row>
    </el-main>
    <el-footer class="loginFooter">
    <div class="footer_copylight">
      <rsk-footer-help v-if="!isJcc" class="rsk-footer-help" :style="fromRismonFlag == '0' ? 'bottom: 52px;' : ''"/>
      <div class="rsk-footer_copylight" :style="isJcc || fromRismonFlag == '0' ? 'bottom: 16px;' : ''">
        Copyright © 2012-{{year}} Riskmonster.com All Reserved.︎
      </div>
    </div>
    <div v-if="!isJcc && fromRismonFlag != '0'" class="footer_content">
        <div>
          <span class="footer_text">{{$t("loginPages.text9")}}</span>
        </div>
        <div class="footer_img">
          <div>
          <img
            @click="toAppStore"
            style="height: 54px;cursor: pointer;"
            src="./png/app_store.png"
            alt="."
          />
          <img
            style="width: 75px; height: 75px"
            src="./png/iOS_QR.png"
            alt="."
          />
        </div>
        <div>
          <img
            @click="toGooglePlay"
            style="height: 43px;cursor: pointer;"
            src="./png/google_play.png"
            alt="."
          />
          <img
            style="width: 75px; height: 75px"
            src="./png/Android_QR.png"
            alt="."
          />
        </div>
        </div>
    </div>
    </el-footer>
  </el-container>
</template>

<script>
export { default } from "./js/login_view.js";
</script>

<style scoped lang="scss" src="./css/login.scss"/>
