<template>
  <div>
    <div class="rsk-panel rsk-mb-m">
      <rsk-form v-model="form" :rules="rules" ref="formRef" cacheKey="elgcache">
        <rsk-form-item
          label="研修ID"
          annotation="（完全一致）"
          prop="elearningId"
        >
          <rsk-input max="10" v-model="form.elearningId" />
          
        </rsk-form-item>
        <rsk-form-item
          isExpand
          label="研修名"
          annotation="(部分一致)"
          prop="elearningName"
        >
          <rsk-input isExpand max="100" v-model="form.elearningName" />
        </rsk-form-item>
        <rsk-form-item label="分類・階層">
          <div class="rsk-flex">
            <div class="rsk-form rsk-icon-wrap">
              <span class="rsk-icon rsk-icon--question-gray">
              <label class="rsk-tooltip rsk-tooltip--250 rsk-tooltip--bottom">
                <span>指定した分類・階層に関連する研修を検索します
                  <br>指定と異なる分類・階層の研修が表示されることがあります</span>
              </label>
              </span>
            </div>
            <rsk-select
              v-model="form.elearningTypeObject"
              label="分類・階層"
              :options="typeOptions"
            />
          </div>
        </rsk-form-item>
        <rsk-form-item prop="finishFlg" hintText="必須" label="ステータス" isList>
          <rsk-checkbox v-model="form.finishedFlg" :options="finishFlgValue" />
        </rsk-form-item>
      </rsk-form>

      <rsk-form-submit>
        <rsk-button isExpand @click="refresh()" type="primary">検索</rsk-button>
        <rsk-button isExpand type="tertiary" @click="clear">クリア</rsk-button>
      </rsk-form-submit>
    </div>
    
    <rsk-table
      class="elgstydy-table"
      ref="tableRef"
      :buttons="tableButtons"
      :total="count"
      :data="tableData"
      :titles="titles"
      cacheKey="elgcache"
      @init="tableConfigInit"
      @pageInfoChange="tableChange"
      @sortChange="tableChange"
    />
   
    <el-dialog title="成績" v-model="dialogVisible" width="60%">
      <el-divider></el-divider>
      ID<a style="color:rgb(96, 98, 102);margin-left:45px;" >{{exaElearningId}}</a>
      <el-divider></el-divider>
      研修名<a style="color:rgb(96, 98, 102);margin-left:20px;" >{{exaElearningName}}</a>
      <el-divider></el-divider>
      <rsk-table 
      ref="exatableRef"
      :titles="exatitles"
      :total="exacount"
      :data="exatableData" 
      @init="exatableConfigInit"
      @pageInfoChange="exatableChange"
      @sortChange="exatableChange"
      />
      <rsk-form-submit style="border: none">
        <div class="rsk-form-button-wrapper__button" v-if="isCompletion">
          <button class="rsk-button rsk-button--medium rsk-button--expand rsk-button--primary" @click="toCompletionPdf()">
            修了証印刷
          </button>
        </div>
        <div class="rsk-form-button-wrapper__button" v-else>
          <button class="rsk-button rsk-button--medium rsk-button--expand rsk-button--tertiary" disabled>
            修了証印刷
          </button>
        </div>
      </rsk-form-submit>
    </el-dialog>
  </div>
</template>
<script>
import { useRouter,useRoute } from 'vue-router'
import { ref,onMounted,watch } from "vue";
import { inject } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import { dateFtt } from "@/utils/common";
import { toFull } from "@/utils/editnew";
import { checkInvalid } from "@/utils/validate";

export default {
  setup() {
    const router = useRouter()
    const route = useRoute()
    const service = inject("service");
    const form = ref({});
    const exaform = ref({});
    const finishFlgValue = ref([
      { value: "0", label: "未修了" },
      { value: "1", label: "修了" },
    ]);
    const usrLoginRank = ref();
    const dialogVisible = ref(false);
    const exaElearningId = ref('');
    const exaElearningName = ref('');
    const isCompletion = ref(true);
    const completionElgId = ref('');
    const completionDtm = ref('');
    const completionUsrId = ref('');

    const rules = ref({
      finishFlg: [
        {
          validator: (rule, value, callback) => {
            if (form.value.finishedFlg.length == 0) {
              callback(new Error("ステータスを選択してください。"));
            } else {
              callback();
            }
          },
        },
      ],
      elearningId: [
        {
          validator: (rule, value, callback) => {
            const validateData = { invalidStr: value, isUnicoe: true };
            checkInvalid(validateData, service, callback, "研修ID");
          },
        },
      ],
      elearningName: [
        {
          validator: (rule, value, callback) => {
            const validateData = { invalidStr: value, isUnicoe: true };
            checkInvalid(validateData, service, callback, "研修名");
          },
        },
      ],
    });
    const formRef = ref();

    const titles = ref([
      {
        field: "elearningId",
        title: "研修ID",
        order: "none",
      },  
      {
        field: "finishFlg",
        label: true,
        title: "ステータス",
        options: finishFlgValue.value,
        order: "none"
      },
      {
        field: "elearningName",
        title: "研修名",
        order: "none",
      },
      {
        field: "baseStudyTime",
        title: "標準学習時間",
        postfix: "時間",
        order: "none",
      },
      {
        field: "learningTime",
        title: "学習時間",
        order: "none",
      },
      {
        field: "studyStartDtm",
        title: "学習開始日",
        order: "none",
      },
      {
        field: "progressRate",
        title: "進捗",
        postfix: "%",
        order: "none",
      },
      {
        field: "jyukouStartDay",
        title: "研修開始日",
        order: "none",
      },
      {
        field: "jyukouEndDay",
        title: "研修終了日",
        order: "desc",
      },
    ]);
    const tableRef = ref();
    const exatableRef = ref();

    const exatitles = ref([
      {
        field: "examinationId",
        title: "試験ID",
        order: "desc",
      },
      {
        field: "examinationName",
        title: "試験名",
        order: "none",
      },
      {
        field: "enforceDtm",
        title: "実施日時",
        order: "none",
      },
      {
        field: "point",
        title: "得点",
        order: "none",
      },
    ]);

    const tableButtons = [
      {
        type: "tertialy",
        text: "成績・修了証",
        icon: "copy-admin",
        show: (item) => {
          if (item.studyFlg == 1) {
            return true;
          } else {
            return false;
          }
        },
        callBack: (item) => {
        exaform.value = {elearningId:item.elearningId,applicationDtm:dateFtt("auto",item.applicationDtm,"en"),elearningName:item.elearningName}
        dialogVisible.value = true;
        if(exatableRef.value != undefined){
          exatableRef.value.clear();
        }
        if (item.progressRate == 100) {
          isCompletion.value = true;
          completionElgId.value = item.elearningId;
          completionDtm.value = item.applicationDtm.replace(new RegExp("/","gm"), "");
          completionUsrId.value = item.usrId
        } else {
          isCompletion.value = false;
        }
        setTimeout(showExa, 200)
        },
      },
      {
        type: "error",
        text: "キャンセル",
        icon: "delete",
        show: (item) => {
          if (item.elgAppFlag == 0 && (usrLoginRank.value < item.rank || usrLoginRank.value == item.rank)) {
            return true;
          } else {
            return false;
          }
        },
        callBack: (item) => {
          ElMessageBox.confirm(
            "キャンセルしますがよろしいですか？",
            {
            cancelButtonClass: "btn-custom-cancel" ,
            confirmButtonText: 'OK',
            cancelButtonText: 'キャンセル',
            type: 'warning',
            }
          ).then(() => {
            let formdata = {elearningId:item.elearningId,applicationDtm:dateFtt("auto",item.applicationDtm,"en"),usrId:item.usrId}
            service.elgstudy.cancel(formdata).then((res) => {
              submit();
              ElMessage({
                  type:'success',
                  message: res,
                  showClose: true,
                  duration:3000
              })
            }).catch((error) => {
                console.log(error);
                ElMessage({
                    type:'error',
                    message: error.message,
                    showClose: true,
                    duration:3000
                })            
            }); 
          })         
        },
      },
    ];

    const tableData = ref([]);
    const count = ref();
    const exatableData = ref([]);
    const exacount = ref();

    const typeOptions = ref();

    let sort = {}
    let paging = {}
    
    function tableConfigInit(sorts, p) {
      sort = sorts
      paging = p
    }

    var stopRequestFlag = false;
    function tableChange(sorts, p) {
      sort = sorts
      paging = p
      if(!stopRequestFlag){
        console.log(stopRequestFlag)
        submit(stopRequestFlag);
      }
    }

    let exasort = {}
    let exapaging = {}
    function exatableConfigInit(sorts2, p2) {
      exasort = sorts2
      exapaging = p2
    }
    function exatableChange(sorts2, p2) {
      exasort = sorts2
      exapaging = p2
      
      showExa();
    }
    function showExa() {
      exaElearningId.value =  exaform.value.elearningId;
      exaElearningName.value = exaform.value.elearningName.text;
        service.exa.show({codition:{...exaform.value,...exasort,...exapaging}}).then((res) => {
            for(let i = 0;i<res.list.length;i++) {
                res.list[i].enforceDtm = dateFtt("auto_time",res.list[i].enforceDtm,"en");
            }
            exacount.value = res.count;
            exatableData.value = res.list;
        }).catch((error) => {
            console.log(error);
        });
    }

    function submit() {
      formRef.value.validate(form.value).then((res) => {
        if (res) {
          if (route.params.column != undefined) {
            sort.column = route.params.column
            route.params.column = undefined
          }
          if (route.params.order != undefined) {
            sort.order = route.params.order
            route.params.order = undefined
          }

          let formdata = {condition:{...form.value,...sort,...paging},finishedFlg:form.value.finishedFlg,elgappFlag:[0,1]}
          service.elgstudy.search(formdata).then((res) => {
              usrLoginRank.value = res.usrLoginRank;
              count.value = res.count;
              if (count.value > 0) {
                for (let i = 0; i < res.list.length; i++) {
                  if(res.list[i].studyFlg == 1) {
                    res.list[i].elearningName = {
                      text: res.list[i].elearningName,
                        callBack: (item) => {
                          if(res.list[i].nonScormType == 1) {
                            let applicationDtmStr =  dateFtt("auto",res.list[i].applicationDtm,"en").replace(new RegExp("/","gm"),"")
                            let route = router.resolve({ name: 'popElgmov', query:{elearningId:res.list[i].elearningId,lessonId:'',applicationDtm:applicationDtmStr}})
		                        window.open(route.href, 'popElgmov', 'top=50,left=200,width=800,height=600')
                          }else{
                            let applicationDtmStr =  dateFtt("auto",res.list[i].applicationDtm,"en").replace(new RegExp("/","gm"),"")
                            let formdata = {elearningId:res.list[i].elearningId,lessonId:'',applicationDtm:applicationDtmStr,termOutside:res.list[i].termOutside}
                                  if (formdata.lessonId == '') {
                                      formdata.lessonId = '9999999999';
                                  }
                                  var cd = '';
                                  var iv = '';
                                  var status_num = '';
                                  var sso_url = '';
                                  service.sso.setSso(formdata).then((res) => {
                                  var model = res;
                                  status_num = model.statusNum
                                  if (model.statusNum == '0') {
                                          sso_url = model.elearningURL;
                                          cd = model.cd;
                                          iv = model.iv;
                                          let form = document.createElement('form')
                                          form.action = sso_url;
                                          form.method = 'POST'
                                          form.target = 'Cybax_lms_lesson'
                                          document.body.append(form);

                                          let cdhidden = document.createElement('input')
                                          cdhidden.type = 'hidden'
                                          cdhidden.name = 'cd'
                                          cdhidden.value = cd
                                          form.appendChild(cdhidden);

                                          let ivhidden = document.createElement('input')
                                          ivhidden.type = 'hidden'
                                          ivhidden.name = 'iv'
                                          ivhidden.value = iv;
                                          form.appendChild(ivhidden);

                                          form.submit();
                                          form.remove();
                                    } else {
                                          alert(model.statusMessage);
                                    }                                  
                                  }).catch((error) => {
                                    console.log(error);
                                    alert('セッションタイムアウトしました。ログインし直してください。');
                                    router.push({ path: '/login' })
                                  });       
                          }
                                            
                        }
                    }
                  }                  
                  res.list[i].studyStartDtm = dateFtt(
                    "auto",
                    res.list[i].studyStartDtm,
                    "en"
                  );
                  res.list[i].jyukouStartDay = dateFtt(
                    "auto",
                    res.list[i].jyukouStartDay,
                    "en"
                  );
                  res.list[i].jyukouEndDay = dateFtt(
                    "auto",
                    res.list[i].jyukouEndDay,
                    "en"
                  );
                }
                tableData.value = res.list;
              } else {
                tableData.value = {};
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    }

    function refresh() {
      form.value.elearningName = toFull(form.value.elearningName);
      tableRef.value.clear();
    }

    function clear() {
      form.value = {};
      form.value.finishedFlg = ["0"];
      form.value.elearningName = '';
      formRef.value.clear();
      tableRef.value.clear();
      if(exatableRef.value != undefined){
        exatableRef.value.clear();
      }
    }

    function toCompletionPdf() {
      const url = router.resolve({
        name: 'completionPdf',
        query: {
          id : completionElgId.value + "-" + completionDtm.value
        }
        
      });
      window.open(url.href);
    }


    function updateElgmov(content) {
      service.popelgmov.update(content).then((res) => {
      })
    }
    watch(()=>route.path,(to)=> {
        stopRequestFlag = true
        formRef.value.clear();
        tableRef.value.clear();
        if(exatableRef.value != undefined){
          exatableRef.value.clear();
        }
    }) 

    onMounted(() => {
      window.reConnect = (content) => {
        updateElgmov(content)
      }
      form.value = {};
      form.value.finishedFlg = ["0"];
      form.value.elearningName = '';
      service.common.typeObject().then((res) => {
        typeOptions.value = res;
          }).catch((error) => {
        console.log(error);
      });
      submit();
    });

    return {
      tableConfigInit,
      tableChange,
      exatableConfigInit,
      exatableChange,
      tableButtons,
      typeOptions,
      dialogVisible,
      form,
      exaform,
      rules,
      formRef,
      tableRef,
      tableData,count,
      exatableRef,
      exatableData,exacount,
      finishFlgValue,
      usrLoginRank,
      titles,
      exatitles,
      submit,
      showExa,
      clear,
      exaElearningId,
      exaElearningName,
      refresh,
      isCompletion,
      toCompletionPdf,
      completionElgId,
      completionDtm
    };
  },
};
</script>

<style lang="scss" scoped>
.elgstydy-table ::v-deep .table__td-content{
  max-width: 810px !important;
  white-space: normal !important;
  text-align: left !important;
}
</style>