import { useRouter } from "vue-router";
import { inject } from "vue";
import { ref } from 'vue'
export default {
    name: 'Assentusr',
    setup() {
        const router = useRouter();
        const service = inject("service");

        const hennkouFlag = localStorage.getItem("hennkouFlag");
        const login = JSON.parse(localStorage.getItem("login"));
        if (!login) {
            router.push({ path: '/login' });
            return;
        }
        const rule = ref(false);

        function Confirm() {
            if (rule.value == false) {
                alert("同意をチェックしてください。");
            } else {
                service.assent.assent.update(login).then(res => {
                    if(res.tourl == 'environment'){
                        router.push({ name: 'Popenvironment',query:{popbtnShow:true}})
                    } else {
                        router.push({ name: 'AssentusrNew'})
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        }

        return {
            hennkouFlag, rule,
            Confirm
        };
    }
}