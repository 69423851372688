<template>
    <div class="lec-header">
        <img class="logo" src="../../assets/logo.png" />
    </div>
    <div class="line" />
     <div class="lec-body">
        <div class="lec-title">講師登録申請フォーム</div>

        <ol class="stepBar step3 pc-on">
            <li class="step barTextSize current">講師登録申請フォーム</li>
            <li class="step barTextSize">入力内容確認</li>
            <li class="step barTextSize">登録完了</li>
        </ol>

        <table class="tb-basic form sp-block">
            <tr>
                <th>名前   <span class="must">必須</span></th>

                <td>
                    <input id="nameInput" v-model="model.usrName" placeholder="例）山田太郎" maxlength="30"/>
                    <div class="error-text">{{nameMessage}}</div>
                </td>
            </tr>
            <tr>
                <th>住所</th>

               <td>
                   <input id="addrInput" v-model="model.usrAddr" placeholder="例）東京都中央区日本橋２ー１　６ー５ＲＭＧビル" maxlength="250"/>
                   <div class="error-text">{{addrMessage}}</div>
                </td>
            </tr>
            <tr>
                <th>電話番号</th>

                <td>
                    <input id="phoneInput" v-model="model.phone" placeholder="例）03-5202-7121" maxlength="32"/>
                    <div class="error-text">{{phoneMessage}}</div>
                </td>
                
            </tr>
            <tr>
                <th>メールアドレス  <span class="must">必須</span></th>

                <td>
                    <input id="mailAdrInput" v-model="model.mailAdr" placeholder="例）xxxx@cybaxuniv.com" maxlength="128"/>
                    <div class="error-text">{{mailAdrMessage}}</div>
                </td>
                
            </tr>
            <tr>
                <th>メールアドレス（確認） <span class="must">必須</span></th>

                <td>
                    <input id="mailAdrConfirmInput" v-model="model.mailAdrConfirm" placeholder="例）xxxx@cybaxuniv.com" maxlength="128"/>
                    <div class="error-text">{{mailAdrConfirmMessage}}</div>
                </td>
            </tr>
            <tr>
                <th>文字認証   <span class="must">必須</span> <br/>（入力欄画像の文字を入力）</th>

                <td>
                    <div class="identify-code" @click="refreshCode">
                        <!--验证码组件-->
                        <s-identify @func="getSidentifyCode" :isRefreshCode="isRefreshCode"></s-identify>
                    </div>
                    <span class="notice"> ※画像をクリックすると新しい認証画像が表示されます。</span>
                    <br>
                    <input id="verifyInput" v-model="model.verify"/>
                    <div class="error-text">{{verifyMessage}}</div>
                </td>
            </tr>   
        </table>

        <div class="wrap">
            <Treaty :treaty="queryGroup" />
        </div>

        <div class="wrap">
            <span class="must">必須</span>
            <input type="checkbox" id="checkbox1" @click="checkboxOnclick" class="checkbox" />
            <div>規約に同意する</div>
        </div>

        
        <notice-treaty :notice="true" />
        
       
        <br>
        <div class="wrap">
            個人情報の取り扱いについてと会員規約に同意する
        </div>
        <br>
        <div class="wrap">
            <span class="must">必須</span>
            <input type="checkbox" id="checkbox2" class="checkbox" @click="checkboxOnclick"/>
            <div>同意する</div>
        </div>
        
        <div class="wrap btn-line">
            <div class="btn-wrap">           
                <button v-if="submit" @click="moveToShow" class="editnew-btn btn-success">申請</button>
                <button v-if="unsubmit" class="editnew-btn btn-false">申請</button>
            </div>
        </div>
     </div>

     <div class="rsk-fixed-bottom-right">
        <div class="rsk-mb-xs">
          <button class="rsk-button rsk-button--white-admin rsk-button--round rsk-button--round-large" @click="toTop()">
              <span class="rsk-button__icon-wrapper">
                  <span class="rsk-icon rsk-icon--arrow-upward-admin-primary"></span>
              </span>
          </button>
        </div>
      </div>
</template>

<script>
    import { inject, onMounted, ref, watch } from 'vue'
    import { useRouter } from 'vue-router'
    import SIdentify from "../../components/verifty.vue";
    import { checkFullKnaNecessary, checkFullKna, checkPhone, checkMailAdr, checkMailAdrConfirm, checkVerify } from '@/utils/editnew'
    import Treaty from "./treaty.vue"
    import NoticeTreaty from "./notice_treaty.vue"

    export default {
        name:'lec_reg_editnew',
        components: { SIdentify,Treaty,NoticeTreaty},
        setup() {
            const router = useRouter();
            const service = inject("service")
            const identifyCode = ref("");
            const isRefreshCode = ref(false);
            const model = ref({ checkCode: '' });
            const submit = ref(false);
            const unsubmit = ref(true);
            const URLS = ['/supplier/new?_lecturer_group=01','/supplier/new?_lecturer_group=02','/supplier/new?_lecturer_group=03'];
            const queryGroup = ref()

            function getSidentifyCode(data) {
                identifyCode.value = data;
                isRefreshCode.value = false;
            }
            
            function refreshCode() {
                identifyCode.value = "";
                isRefreshCode.value = true;   
            }

            function moveToShow() {
                var checked2 = document.getElementById('checkbox2');

                if (checked2.checked == false) {
                    alert("注意事項の同意する を選択してください。");
                    return;
                }
                checkFormat(); 
            }

            function checkboxOnclick() {
                var checked1 = document.getElementById('checkbox1');
                var checked2 = document.getElementById('checkbox2');
                if (checked1.checked == true && checked2.checked == true) {
                    submit.value = true;
                    unsubmit.value = false;
                } else {
                    submit.value = false;
                    unsubmit.value = true;
                }
            }
            const nameMessage = ref("");
            const addrMessage = ref();
            const phoneMessage = ref();
            const mailAdrMessage = ref();
            const mailAdrConfirmMessage = ref();
            const verifyMessage = ref();

            function checkFormat() {
                const usrNameResult = checkFullKnaNecessary('nameInput', model.value.usrName, nameMessage, service, 30, '名前');
                model.value.usrName = usrNameResult[1];
                const usrAddrResult = checkFullKna('addrInput', model.value.usrAddr, addrMessage, service, 250, '住所');
                model.value.usrAddr = usrAddrResult[1];
                
                const phoneResult = checkPhone('phoneInput', model.value.phone, phoneMessage, '電話番号');
                const mailAdrResult = checkMailAdr('mailAdrInput', model.value.mailAdr, mailAdrMessage, 'メールアドレス');
                const mailAdrConfirmResult = checkMailAdrConfirm('mailAdrConfirmInput', model.value.mailAdr,model.value.mailAdrConfirm, mailAdrConfirmMessage);
                const verifyResult = checkVerify('verifyInput', model.value.verify, verifyMessage, identifyCode);

                    if (usrNameResult[0]
                        && usrAddrResult[0]
                        && phoneResult
                        && mailAdrResult
                        && mailAdrConfirmResult
                        && verifyResult)
                    {
                        service.lecturer.findMail(model.value).then(res => {
                            if (res.addressErrMsg != null) {
                                document.getElementById('addrInput').style.borderColor = 'red';
                                addrMessage.value = '住所' + res.addressErrMsg ;
                                usrAddrResult[0] = false;
                                toTop();
                            }
                            if (res.nameErrMsg != null) {
                                document.getElementById('nameInput').style.borderColor = 'red';
                                nameMessage.value = '名前' + res.nameErrMsg ;
                                usrNameResult[0] = false;
                                toTop();
                            }
                            if (res.mailErrMsg != null) {
                                document.getElementById('mailAdrInput').style.borderColor = 'red';
                                mailAdrMessage.value = res.mailErrMsg ;
                                mailAdrResult = false;
                                toTop();
                            }
                            if (res.mailConfirmErrMsg != null) {
                                document.getElementById('mailAdrConfirmInput').style.borderColor = 'red';
                                mailAdrConfirmMessage.value = res.mailConfirmErrMsg ;
                                mailAdrConfirmResult = false;
                                toTop();
                                return;
                            }
                            if (res.supplier != null && res.supplier > 0) {
                                document.getElementById('mailAdrInput').style.borderColor = 'red';
                                mailAdrMessage.value = '既に使われているメールアドレスです。';
                                toTop();
                                return;
                            }
                            if (res.nameErrMsg == null && res.addressErrMsg == null && res.mailErrMsg == null && res.mailConfirmErrMsg == null && (res.supplier == null || res.supplier == 0)) {
                                var params={
                                    usrName: model.value.usrName,
                                    usrAddr :model.value.usrAddr,
                                    phone: model.value.phone,
                                    mailAdr: model.value.mailAdr,
                                    mailAdrConfirm: model.value.mailAdrConfirm,
                                    queryGroup: queryGroup.value,
                                }
                                router.push({name:'lec_reg_show',
                                    params: {
                                        model: JSON.stringify(params)
                                    }
                                })
                            }
                            
                        }).catch((error) => {
                            console.log(error)
                        })
                    }
                    toTop()     
            }

            function toTop() {
             document.body.scrollTop ? document.body.scrollTop = 0 : document.documentElement.scrollTop = 0
            }
            onMounted(() => {
                document.getElementById('checkbox1').checked=false;
                document.getElementById('checkbox2').checked=false;
                const usrMes = JSON.parse(localStorage.getItem("usrMes"));
                let url = router.currentRoute.value.fullPath
                if (usrMes != null) {
                    model.value.usrName = usrMes.usrName;
                    model.value.usrAddr = usrMes.usrAddr;
                    model.value.phone = usrMes.phone;
                    model.value.mailAdr = usrMes.mailAdr;
                    model.value.mailAdrConfirm = usrMes.mailAdrConfirm
                }
                if (URLS[0] != url && URLS[1] != url && URLS[2] != url) {
                    router.push({path:'/404'})
                }else {
                    queryGroup.value = router.currentRoute.value.query._lecturer_group;
                }
            })

            return {
                identifyCode,
                isRefreshCode,
                getSidentifyCode,
                refreshCode,
                moveToShow,
                model,
                submit,
                unsubmit,
                checkFormat,
                checkboxOnclick,
                toTop,
                nameMessage,
                addrMessage,
                phoneMessage,
                mailAdrMessage,
                mailAdrConfirmMessage,
                verifyMessage,
                queryGroup,
            }
        }
    }
</script>

<style lang="scss" src="./css/common.scss" scoped />

