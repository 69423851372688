<template>
    <div class="body">
        <rsk-form :rules="rules" ref="formRef" v-model="modelInf">
        <meta name="viewport"
            content="width=device-width, user-scalable=no, initial-scale=0, maximum-scale=0, minimum-scale=0" />
            <div>
                <div id="header">
                        <div class="pagetitle1_notbg">
                            <img src="../../assets/pagetitle.gif" alt="サイバックスUniv." width="216" height="42" />
                        </div>
                </div>
            </div>

        <div id="page">
            <table class="rule__table-bg">
                <tr>
                    <td class="rsk-flex rsk-items-center rsk-flex-col rsk-gap-20">
                        <div>
                            <span class="rule__page-title">サイバックスUniv.ご利用について</span>
                        </div>
                        <span class="rule__page-title-text" v-if="fromWhere">
                            2013年４月１日より、サイバックス株式会社に代わって親会社であるリスクモンスター株式会社が「サイバックスUniv.」サービスを提供いたします。<br>
                        </span>
                        <span class="rule__page-title-text">
                        サイバックスUniv.のサービスをご利用頂くにあたり、以下「個人情報の取り扱いについて」「サイバックスUniv.会員規約」をお読み頂き、同意欄にチェックの上、<br>
                        「利用環境チェックページへ」ボタンをクリックしてください。
                        </span>
                    </td>
                </tr>
                <tr>
                    <td align="center" style="padding-top: 30px;">
                        <div class="scr_con">
                            <div class="Scroller-Container">
                                
                                <div class="newslist" style="overflow-x: hidden; overflow-y: auto; height: 318px;">
                                    <div class="privacy-box-inner">
                                        <dt style="font-weight: bold;"> 【個人情報の取扱いについて】</dt><br />
                                        <p class="privacy-info-text">
                                            リスクモンスター株式会社（以下、「当社」という）は、与信管理サービスや研修サービスをはじめ、当社サービスにおいて、お客様の個人情報を利用するにあたり、個人情報を適切に取り扱うことは社会的責務であると考えております。<br>
                                            つきましては、以下をご確認いただき、同意をいただきますようお願い申し上げます。同意いただけない場合は、本フォームへの入力はできませんので、予めご了承願います。
                                        </p><br><br>
                                        <p class="privacy-info-text text-weight">１．個人情報の利用目的</p>
                                        <p class="privacy-info-text">当社は、収集した個人情報について、以下の目的で個人情報を利用させて頂くことがございます。 </p>
                                        <ul class="privacy-info-list text-padding-l">
                                            <li class="privacy-info-item">
                                                &nbsp;&nbsp;&nbsp;&nbsp;（１）当社グループ（下記３の「共同利用する者の範囲」に記載する全ての会社を意味する。以下同様。）の商品・サービスの提供その他それらに付随する諸対応のため
                                            </li>
                                            <li class="privacy-info-item">
                                                &nbsp;&nbsp;&nbsp;&nbsp;（２）当社グループの及び提携先企業等の新商品、新サービス、優待、イベント等に関する情報をご送付・ご案内するため</li>
                                            <li class="privacy-info-item">&nbsp;&nbsp;&nbsp;&nbsp;（３）当社グループへの各種お問い合わせ、資料請求等に関する対応等を行うため</li>
                                            <li class="privacy-info-item">
                                                &nbsp;&nbsp;&nbsp;&nbsp;（４）当社グループからのアンケート依頼、作品公募、市場調査・顧客動向分析、特定個人を識別できない統計的データの作成及びその公表のため</li>
                                            <li class="privacy-info-item">&nbsp;&nbsp;&nbsp;&nbsp;（５）当社グループのサービス・商品の改良や、新たなサービス・商品を開発するため</li>
                                        </ul><br />
                                        <p class="privacy-info-text text-weight">２．個人情報を入力するに当たっての注意事項</p>
                                        <p class="privacy-info-text">
                                            当社サービス利用に際して当社に個人情報を提供されることは任意ですが、必要な情報が提供されていない場合、もしくは提供された情報に誤りがあった場合、お問い合わせ等にご対応ができない場合があります。
                                        </p><br />
                                        <p class="privacy-info-text text-weight">３．当社グループにおける共同利用</p>
                                        <p class="privacy-info-text">
                                            当社は、会員様、お取引先様の個人情報を、上記の利用目的のため以下のとおり当社グループ内で共同利用いたします。この場合には、当社が当該情報の管理についての責任を負います。
                                        </p>
                                        <div style="padding-left: 30px;">
                                            <table width="80%" style="border: solid 1px #000;border-collapse: collapse;border-spacing: 0">
                                                <tbody>
                                                    <tr>
                                                        <td width="30%" align="center"
                                                            style="border: solid 1px #000;font-size: 14px;line-height: normal;">
                                                            共同利用する者の範囲</td>
                                                        <td
                                                            style="border: solid 1px #000;font-size: 14px;line-height: normal;">
                                                            リスクモンスター株式会社並びにその子会社（外国含む）及び関連会社</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="30%" align="center"
                                                            style="border: solid 1px #000;font-size: 14px;line-height: normal;">
                                                            共同利用する個人情報の項目</td>
                                                        <td 
                                                            style="border: solid 1px #000;font-size: 14px;line-height: normal;">
                                                            氏名、生年月日、性別、住所、電話番号、FAX番号、メールアドレス、勤務先の情報（会社名、所属部課、役職、住所、電話番号、FAX番号）、当社グループのサービスご利用実績
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="30%" align="center"
                                                            style="border: solid 1px #000;font-size: 14px;line-height: normal;">
                                                            共同利用する個人情報の管理責任者</td>
                                                        <td
                                                            style="border: solid 1px #000;font-size: 14px;line-height: normal;">
                                                            リスクモンスター株式会社　セキュリティ向上委員会　委員長（東京都中央区日本橋二丁目１６号５号　代表取締役社長　藤本
                                                            太一）</td>
                                                    </tr>
                                                    <tr>
                                                        <td width="30%" align="center"
                                                            style="border: solid 1px #000;font-size: 14px;line-height: normal;">
                                                            お問い合わせ</td>
                                                        <td
                                                            style="border: solid 1px #000;font-size: 14px;line-height: normal;">
                                                            下記お問い合わせ窓口までお願いします。</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div><br>
                                        <p class="privacy-info-text text-weight">４．個人情報の第三者への提供</p>
                                        <p class="privacy-info-text">
                                            当社は、個人情報について以下の場合を除き、あらかじめご本人の同意を得ること無く個人情報を第三者に提供することはございません。 </p>
                                        <ul class="privacy-info-list text-padding-l">
                                            <li class="privacy-info-item">&nbsp;&nbsp;&nbsp;&nbsp;（１）法令にもとづく場合。</li>
                                            <li class="privacy-info-item">
                                                &nbsp;&nbsp;&nbsp;&nbsp;（２）人の生命、健康、財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
                                            <li class="privacy-info-item">
                                                &nbsp;&nbsp;&nbsp;&nbsp;（３）公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき。</li>
                                            <li class="privacy-info-item">
                                                &nbsp;&nbsp;&nbsp;&nbsp;（４）警察・裁判所等の公的機関又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼす恐れがあるとき。
                                            </li>
                                            <li class="privacy-info-item">&nbsp;&nbsp;&nbsp;&nbsp;（５）利用目的の範囲内において当社の業務を委託する場合。</li>
                                            <li class="privacy-info-item">&nbsp;&nbsp;&nbsp;&nbsp;（６）当社プライバシーポリシーにしたがい当社グループ各社間で共同利用する場合。</li>
                                        </ul><br />
                                        <p class="privacy-info-text text-weight">５．開示対象個人情報の開示について</p>
                                        <p class="privacy-info-text">
                                            当社は、ご本人様からお申し出があったときは、当社が指定する手続に基づいて、当社保有の個人情報の利用目的の通知・開示・内容の訂正・追加または削除・利用の停止・消去および第三者への提供の停止に応じます。開示等のお申し出については以下のお問い合わせ窓口にて受け付けいたします。なお、開示、利用目的の通知に置きましては手数料をいただくことがありますので、あらかじめ御了承ください。
                                        </p><br />
                                        <p class="privacy-info-text text-weight">６．個人情報に関するお問い合わせ先</p>
                                        <p class="privacy-info-text">
                                            〒103-0027　東京都中央区日本橋２-16-5　RMGビル<br>
                                            リスクモンスター株式会社　セキュリティ向上委員会　委員長<br>
                                            TEL：03-6214-0331<br>
                                            窓口受付時間；当社営業日の９：00～18：00
                                        </p><br><br>
                                        
                                    </div>
                                    <div style="font-weight: bold;text-decoration: underline;">サイバックスUniv.会員規約</div>
                                    <br /> <br />
                                    リスクモンスター株式会社（以下「当社」といいます）は、当社が提供するサービス（以下「本件サービス」といいます）の利用に関して、以下の通り会員規約（以下「本規約」といいます）を定めます。<br />
                                    <br /> 第１条（定義）<br /> 本規約において、次の各号記載の用語はそれぞれ次の意味で使用します。<br />
                                    （1）「Univ.サイト」とは、当社が本件サービスを提供するためにインターネット上にて運営する会員専用WEBサイトを指します。<br />
                                    （2）「会員」とは、本規約に基づき当社が別途定める手続に従って入会申込を行い、当社に登録され、本件サービスを利用することができる法人を指します。（別紙の入会申込書記載の申込会社に該当します）<br />
                                    （3）「実務担当者」とは、会員より本件サービスを利用するために、本件サービスに関する諸手続（実務担当者の変更を含む）について会員を代表して行う者として当社に登録され、実際に本件サービスを利用する際に操作を行う者を指します。（別紙の入会申込書記載の申込者に該当します）<br />
                                    （4）「利用者」とは、会員に所属する者または会員が採用を予定している者で、会員が本件サービスを利用する者として指定した個人をいうものとします。<br />
                                    （5）「コンテンツ」とは、Univ.サイト等にて提供されるサービス全般を指します。<br />
                                    （6）「コンテンツ運営者」とは、「コンテンツ」を運営する法人を指します。<br />
                                    （7）「会員ID」とは当社が会員に対し、その会員を識別するために発行するIDを指します。<br />
                                    （8）「ポータルサービス」とは、Univ.サイトからのリンク等により、当社以外のコンテンツ運営者がインターネット上にて運営するWEBサイトにアクセス可能とし、会員へ商品または情報等を提供するサービスを指します。<br />
                                    （9）「個人情報」とは、「個人情報の保護に関する法律」（平成15年法律第57条、以下「法」といいます）第２条第１項に規定する個人情報であって、当該情報に含まれる個人の氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができるものを含みます）及び個人識別符号（法第２条第２項に規定する個人識別符号をいいます）が含まれるものを指します。<br />
                                    （10）「個別利用契約」とは、本規約に基づき当社と会員との間で締結する本件サービスの利用に関する各契約（Univ.サイト等に掲示される規約や注文書等を含みます）をいうものとします。<br />
                                    <br /> 第２条（本件サービスの内容）<br />
                                    （1）本件サービスの内容は、以下の通りとします。なお、Univ.サイトの掲示内容または本件サービスの内容は各会員の申込内容に応じて異なります。会員はこれをあらかじめ承諾するものとします。<br />
                                    （ア）Univ.サイト等において当社がコンテンツ運営者として、会員へ独自に商品、情報等を提供するサービス。<br />
                                    （イ）当社以外のコンテンツ運営者が提供するポータルサービス。<br /> （ウ）その他当社が会員を対象に提供する各種サービス。<br />
                                    （エ）当社のサポートセンター（会員お問い合わせ窓口）における対応等のアフターサービス。<br />
                                    （2）本件サービスの詳細な内容については、本規約に定めるほか、別途Univ.サイトヘ掲示するものとします。<br />
                                    （3）Univ.サイトを通じて提供される本件サービスの提供時間帯は、第20条第１項の規定に基づくサービスの一時中断または停止の期間を除き、１年365日（うるう年の場合は366日）、毎日24時間とします。<br />
                                    （4）本件サービスのお問い合わせ対応時間は、別途Univ.サイトヘ掲示するお問い合わせ一覧の内容に準じるものとします。<br /> <br />
                                    第３条（ポータルサービスの扱い）<br />
                                    （1）ポータルサービスのうち会員が当社との間で取引を行うサービスにおいては、取引の成否及び内容等並びに当社及び会員の権利義務等は本規約の定めに従うものとします。<br />
                                    （2）ポータルサービスのうち会員がコンテンツ運営者との間で直接に取引を行うサービスにおいては、当社は、かかる取引の成否、内容及び履行等について一切責任を負いません。<br />
                                    （3）当社は、前項の場合、会員に対し、ポータルサービスにおける情報の正確性、完全性、最新性、目的適合性、有効性等について一切保証いたしません。また、以下の事由を含むコンテンツ運営者の行為については、一切責任を負いません。<br />
                                    （ア）会員がコンテンツ運営者から、コンテンツ運営者の商品等の提供を受けられない、または、拒否された場合。<br />
                                    （イ）会員とコンテンツ運営者との取引に関し、錯誤、詐欺、債務不履行、不法行為、コンテンツ運営者の提供した商品もしくはサービス等の瑕疵、その他のトラブルが生じた場合、または、コンテンツ運営者サイトにおいて誇大広告その他の不正な表示が行われた場合。<br />
                                    <br /> 第４条（第三者による履行）<br />
                                    本規約に定める当社の行為、権利・義務の行使については、当社の指定する第三者にその一部を代行・代理させることがあります。<br /> <br />
                                    第５条（知的財産権）<br />
                                    （1）当社の提供する本件サービスの著作権等の知的財産権は、当社または当社以外の権利者に帰属し、会員は、当社の承諾（当社以外の著作権者等が存在する場合にはその者の承諾）なく、第三者に対し、本件サービスの全部または一部の再使用許諾、譲渡、転売、貸与、複製の作成等をすることはできません。<br />
                                    （2）本件サービスを利用して入手したコンピュータ・プログラムに対し、方法如何を問わず、ソースコードの解読、リバースエンジニアリング、デコンパイル、及び逆アセンブル等を行わないものとします。また、著作権侵害防止のための技術的保護手段の施されたデータ等に対し、当該手段の回避を行わないものとします。<br />
                                    （3）会員は、本条に違反する行為を第三者にさせないものとします。<br /> <br /> 第６条（入会申込及び審査）<br />
                                    （1）本件サービスを利用するためには、当社が別途定める手続にしたがった入会申込手続を行い、当社の審査を経て会員となることが必要です。<br />
                                    （2）入会申込にあたっては、本規約を熟読し、その内容を理解し、その内容に拘束されることを承諾した上で申込手続を行っていただくものとします。当社は、本サービスの申込があった場合には、本規約に同意したものとみなします。<br />
                                    （3）入会申込手続を行う方には、入会申込の際、以下の事項を保証していただきます。<br />
                                    （ア）入会申込手続を行う方が法人を代表する者、または個人本人でない場合、その所属する法人または個人本人より入会申込を行う権限を授与されていること。<br />
                                    （イ）本件サービスの利用に関する実務担当者の行為について、その所属する法人が責任を負うことに同意していること。<br />
                                    （4）当社は、前項その他当社の基準に従って、入会希望者の入会申込に対して審査を実施し、当社が入会を認める場合には、その旨を入会希望者に電子メール等当社所定の方法で通知します。かかる通知により、入会希望者の会員登録は完了するものとします。ただし、以下の場合には承諾しないことがあります。なお、入会申込者は、この不承諾につき異議申立等を行えないものとします。<br />
                                    （ア）入会申込時に虚偽の事項を通知したことが判明したとき。<br /> （イ）その他、当社が入会を不適当と判断したとき。<br /> <br />
                                    第７条（会費等）<br /> （1）本件サービスは会費制の有料サービスです。会員は会費を支払うことにより、本件サービスを利用することができます。<br />
                                    （2）会員は、本件サービスの会費その他の料金及びその算定方法・支払方法について、当社が別途定める内容・条件に従うものとします。また、ポータルサービスにおいて、コンテンツ運営者が個別に会費その他の料金及びその算定方法・支払方法を規定している場合には、その内容、条件に従うものとします。<br />
                                    （3）会員は、会費その他の料金にかかわる消費税及びその他賦課される税を負担するものとします。<br />
                                    （4）会員が当社に対して支払った会費その他の料金は、当社に会費徴収事務の過誤があった場合及び個別利用契約等において別途返金を約した場合を除き、返還されないものとします。<br />
                                    （5）会員が会費その他の料金の支払を遅滞した場合は、年14.6％の割合による遅延損害金を当社に支払うものとします。<br />（6）当社は、会員に対する60日前の第18条規定の方法による通知によって、会費その他の料金の改訂を行うことができるものとします。<br />
                                    <br /> 第８条（ID及びパスワード）<br />
                                    （1）当社は、入会申込を承諾した場合、会員の登録を行い、会員に対し会員ID、パスワードを発行します。また、必要に応じて認証デバイス（パスワード生成・表示カードまたはICカード）を別途貸与します。会員は、会員ID及び認証デバイスをその責任において管理し、第三者には使用させてはならないものとします。また、会員ID及び認証デバイスは、第三者へ貸与、譲渡、名義変更・売買・質入その他いかなる処分も行ってはならないものとします。また、利用者を限定して発行されたID及びパスワードは、当該利用者以外の利用者に使用させてはならないものとします。<br />
                                    （2）認証デバイスの発行を受けた会員は、別途Univ.サイトに掲示する認証デバイス取扱要領を遵守しなければならないものとします。<br />
                                    （3）会員は、会員ID、パスワード及び認証デバイスを管理監督する義務を負うものとします。会員ID、パスワード及び認証デバイスを利用して行われた行為、会員ID、パスワード及び認証デバイスの管理不十分、使用上の過誤、もしくは、第三者の使用等についてはすべて会員が責任を負い、これにより当社が損害を被った場合は、賠償の義務を負うものとします。<br />
                                    （4）会員は、会員ID及び認証デバイスの紛失、盗難または第三者による不正使用の事実が判明した場合は、直ちに当社に連絡し、当社の指示に従うものとします。<br />
                                    <br /> 第９条（会員設備等の設置・維持）<br />
                                    会員は、本件サービスを利用するにあたり必要となるインターネット接続環境（プロバイダー利用契約、電話等の通信回線利用契約を含みます）、コンピュータその他機器、ソフトウェア等を自らまたは利用者の費用で設置し、維持するものとします。<br />
                                    <br /> 第１０条（会員情報の扱い）<br />
                                    （1）当社及びコンテンツ運営者は、保有する以下の会員情報を厳正に管理し、会員のプライバシー保護のために十分に注意を払うとともに本条に定めた場合以外には、会員情報の利用を行いません。<br />
                                    （ア）会員が本件サービスヘの入会申込時に届け出た情報及び会員より登録された実務担当者に関する情報（第11条第１項の定めに基づき変更された情報も含みます。以下「会員登録情報」といいます）。<br />
                                    （イ）本件サービスの利用履歴及びその他本件サービスの利用に伴う種々の情報（以下「会員取引情報」といいます）。<br />
                                    （2）会員は、本件サービスが円滑に提供されることを目的に、当社がコンテンツ運営者に対して、必要な範囲内で会員登録情報を提供し、コンテンツ運営者がその目的の範囲内で利用することをあらかじめ承諾するものとします。<br />
                                    （3）会員は、当社が以下の目的のために必要な範囲内でコンテンツ運営者から会員とコンテンツ運営者との会員取引情報の提供を受けることをあらかじめ承諾するものとします。<br />
                                    （ア）商品、新サービスの企画・開発。<br /> （イ）ダイレクトメール、E-MAIL等の発送・送信。<br /> （ウ）会員の管理。<br />
                                    （エ）その他本件サービスの内容を向上させるために必要な行為。<br />
                                    （4）会員は、当社が本件サービスを提供する際に、前項各号と同一の目的のために、必要な範囲内で会員情報を利用すること、及び当該目的のために必要な範囲で会員情報を当社グループ内で共同利用することをあらかじめ承諾するものとします。<br />
                                    （5）当社は、本条第２項及び第４項の他以下の場合を除き、会員情報を第三者に開示しないものとします。<br /> （ア）あらかじめ会員の同意が得られた場合。<br />
                                    （イ）法令により開示が求められた場合。<br />
                                    （6）会員及び実務担当者は、自身の会員情報の開示・訂正、及び利用・提供の中止の請求を随時行えるものとします。その場合は、当社が指定する方法にて当社に届け出るものとします。<br />
                                    （7）当社は、当社が定める所定の期間を経過したときは、会員情報を破棄することができるものとします。<br />
                                    （8）当社は、個人情報の取扱いについては、別途規定の「プライバシーポリシー」、「個人情報の取り扱いについて 同意事項」および個別利用契約に従うものとし、これらが矛盾抵触する場合には、個別利用契約、「個人情報の取り扱いについて 同意事項」、「プライバシーポリシー」の順に優先するものとします。<br />
                                    <br /> 第１１条（登録事項の変更）<br />
                                    （1）会員は、入会申込時に登録した事項に変更があった場合、速やかに当社が指定する方法にて当社に届け出るものとし、それ以後も同様とします。<br />
                                    （2）会員が前項の届出を怠ったことにより不利益を被った場合、当社は一切その責任を負わないものとします。<br /> <br /> 第１２条（会員の責任）<br />
                                    会員は、本件サービスを利用するにあたり、本規約に定める事項を誠実に遵守するほか、以下の事項を遵守するものとします。<br />
                                    （1）本件サービスで得た情報、Univ.サイト上で得た情報、その他本件サービスに付随して得た情報全てを不正の目的、公序良俗に反する目的をもって利用しないものとします。<br />
                                    （2）本件サービスで得た情報、Univ.サイト上で得た情報、その他本件サービスに付随して得た情報（以下「本件サービス情報等」といいます）全ての使用は、会員内部での利用に限定するものとし、第三者に一切開示しないものとします。<br />
                                    （3）本件サービス情報等は、当社の事前の書面による承諾がない限り日本国外に持ち出してはならないものとし、また、媒体が日本国内にある場合においても、電気通信回線等を用いて日本国外からアクセスしてはならないものとします。<br />
                                    （4）自分以外の人物を名乗ったり、代表権や代理権がないにもかかわらず会社等の組織を名乗ったり、または他の人物や組織との提携・協力関係を偽る等の行為を行ってはならないものとします。<br />
                                    （5）手段を問わず、当社の運営を妨害する行為、またはそのおそれのある行為、当社の信用を毀損するような行為及び当社に不利益を与えるような行為を行ってはならないものとします。<br />
                                    （6）本件サービス及びUniv.サイト上で提供される情報の利用により発生した損害は全て会員の責任において処理し、当社に対し求償できないものとします。<br />
                                    （7）本件サービスを通じてまたは本件サービスに関連して、営利を目的とした行為またはその準備行為を行ってはならないものとします。<br />
                                    （8）会員資格の有効期間中及び退会後５年間は、本件サービスにおける会社の信用情報の格付け業務と同一ないし類似のサービスを提供してはならないものとします。<br />
                                    （9）実務担当者、従業員及び利用者等に対し、本規約及び個別利用契約のうち会員の義務に関する規定を遵守させるものとします。<br /> <br />
                                    第１３条（利用者に関する取扱い、会員及び利用者の責任）<br />
                                    （1）会員は、本件サービス利用のために利用者を本件サービス用のシステムに登録した場合、会員の責任において、利用者用のID及びパスワードを、会員の責任において利用者に対して通知するものとします。<br />
                                    （2）当社は、当社が必要と判断した場合、ID及びパスワードの登録を受けた利用者の本人確認を行うため、会員に対し必要な書類等の提示を求めることが出来るものとします。<br />
                                    （3）会員は、本件サービスに含まれる個別のサービスを利用するにあたって当社が利用者の登録を必要とする場合は、当社が別途定める期限までに、利用者が本サービスを利用するために必要な利用者のデータ（以下「利用者データ」といいます）を当社が別途指定する方法によって登録（登録のために当社にデータを提供することを含みます）するものとします。会員が期限までに利用者データを登録しなかったことにより、会員または利用者が被った損害は、会員の負担とし、当社は責任を負わないものとします。<br />
                                    （4）当社は、利用者に対し、利用者が会員に帰属している期間または会員から有効な採用内定がなされている期間に限り、本件サービスの利用資格を与えるものとし、利用者が退職、内定取り消しあるいは内定辞退等により利用資格を喪失した場合は、会員の責任において直ちに当該利用者のIDを無効にするものとします。<br />
                                    （5）本件サービスのうち、利用者を限定してIDが発行されるサービスにおいては、当該IDの発行を受けた利用者のみが当該サービスを利用できるものとし、会員は、利用者が、かかるサービスの利用により得た情報を、当該利用者以外の会員の従業員その他の第三者に開示したり、社内閲覧しないよう、必要な措置を講ずるものとします。<br />
                                    <br />
                                    第１４条（地位の譲渡）<br /> 会員は、その権利義務の一部または全部について、当社の事前の書面による承諾がない限り第三者に譲渡できません。<br /> <br />
                                    第１５条（有効期間）<br />
                                    会員資格の有効期間は登録日より１年間とし、期間満了１ヶ月前までに退会の届出がない場合は自動的に１年間期間を延長するものとし、以後も同様とします。ただし、本規約に違反する行為があった場合、または当社が会員として不適当と認めた場合には、有効期間を延長しない場合があります。なお、初回の登録日は、当社より会員に対して、第８条第１項に定める会員ID、パスワード及び認証デバイスを発行した日を指します。<br />
                                    <br /> 第１６条（退会）<br />
                                    （1）会員が退会を希望する場合には、退会希望月の前月末までに当社の指定する方法にて当社に届け出るものとし、退会希望月末をもって退会するものとします。なお、会員が当社に対し何らかの債務を負担している場合は、退会時に全額当社に支払うものとします。<br />
                                    （2）当社は、会員が退会する場合であっても、既に会員から支払われた会費等の払い戻しは一切行いません。<br />
                                    （3）会員が退会した場合も、本条、第５条（知的財産権）、第10条（会員情報の扱い）、第12条（会員の責任）、第23条（損害賠償及び損害賠償額の予定）、第24条（免責事項）及び28条（紛争の解決）については引き続き効力が継続します。<br />
                                    （4）会員は、退会した場合、本件サービス及びUniv.サイト上で提供された情報を、退会後においても本規約に基づく利用の範囲及び責任において、引き続き利用できるものとします。なお、当社が指定した情報についてはこの限りでありません。ただし、本規約に定める事項に違反した場合は、直ちに当社の指示に従い、返却または廃棄・削除するものとします。<br />
                                    （5）会員は、退会した場合、第８条第１項に定める認証デバイスを、当社の指示に従い、返却するものとします。<br /> <br />
                                    第１７条（会員資格の停止・抹消）<br />
                                    （1）会員が以下の事由の何れかに該当した場合、当社は、会員に何ら事前の通知をすることなしに会員資格を一時停止、または抹消できるものとします。この場合、抹消後に当社及び会員が有する権利義務については、前条を準用するものとします。<br />
                                    （ア）本規約のいずれかの条項に違反した場合。<br />
                                    （イ）入会申込時または登録事項変更時に虚偽の事項を通知したことが判明した場合。<br /> （ウ）会費その他の料金の支払いを１回でも怠った場合。<br />
                                    （エ）支払いの停止または手形交換所の取引停止処分を受けた場合。<br />
                                    （オ）会員について、差押、仮差押、仮処分、租税滞納処分その他これに準じる処分を受けたり、民事再生、会社更生、破産の申立を受け、または、自ら民事再生、会社更生、破産の申立を行った場合。<br />
                                    （カ）その他会員として不適当と当社が判断した場合。<br />
                                    （2）会員が前項各号のいずれかに該当した場合、会員が当社に対して負担する一切の金銭債務は当然に期限の利益を失い、会員は直ちに当該金銭債務を当社に対し、現金で弁済するものとします。<br />
                                    <br /> 第１８条（会員への通知）<br />
                                    （1）当社から会員への通知は、Univ.サイトへの掲示、E-MAILまたはその他相当な方法により行います。<br />
                                    （2）前項の通知がE-MAILにて行われる場合、当社は、会員が届け出たE-MAILアドレスのサーバーにE-MAILが到達したことをもって会員への通知が完了したものとみなします。<br />
                                    （3）第11条第１項に基づく変更届出がないため、当社から会員への通知が延着または到着しなかった場合でも、通常到着すべきときに通知が到着したものとみなします。<br />
                                    <br /> 第１９条（サービス内容の変更）<br />
                                    （1）当社は、会員に事前に通知することなく本件サービスの内容を変更することができます。当該変更をおこなった場合は、当社は、変更実施後に第18条に規定された方法により会員へ通知することとします。<br />
                                    （2）当該変更によって、会員へ不利益または損害が生じた場合であっても、当社はその責任を負わないものとします。<br />
                                    <br /> 第２０条（サービスの一時中断または停止）<br />
                                    （1）当社は、以下のいずれかの事由に該当する場合、本件サービスの提供の一部もしくは全部を一時中断、または停止することがあります。<br />
                                    （ア）本件サービス提供にかかわる装置またはシステムの点検保守、更新の場合。<br />
                                    （イ）天災地変、戦争、内乱、騒擾、労働争議、その他労使関係上の紛争、不可避の事故、法的制限、その他当事者の支配し得ない一切の原因により、本件サービスの提供が困難な場合。<br />
                                    （ウ）電気通信事業者その他本件サービスの提供に必要な第三者の役務が提供されない場合。<br />
                                    （エ）その他、運用上あるいは技術上、当社が本件サービスの一時中断、もしくは停止が必要であるか、または不可避の事態により当社が本件サービスの提供を困難と判断した場合。<br />
                                    （オ）原因の如何を問わず、当社がコンテンツの全部または一部について提供する権限を失ったとき<br />
                                    （2）本件サービスの提供の一時中断・停止の発生により、会員または第三者が被った不利益について、当社は、理由を問わず一切の責任を負わないものとします。<br />
                                    （3）当社は、本件サービスの提供を一時中断、または停止する場合は、Univ.サイトへの提示をもってあらかじめ会員へ通知します。但し、緊急の場合は、会員への事前通知を省略できるものとします。<br />
                                    <br /> 第２１条（サービスの利用制限）<br />
                                    （1）当社は、本件サービスの運営に関し本件サービスの利用を監視し、必要と認める場合、自己の裁量において本件サービスへのアクセスを制限することができます。<br />
                                    （2）当社は、その裁量により本サービス上の掲示板等において当社が不適切と認める書き込み等を削除することができるものとします。<br /> <br />
                                    第２２条（サービスの中止）<br />
                                    （1）当社は３ヶ月間の予告期間をもって、会員に第18条規定の方法による通知の上、本件サービスの提供を中止することができるものとします。<br />
                                    （2）前項は、当社が第18条規定の方法により会員に通知し、３ヶ月を経過した時点で全ての会員が了承したものとみなし、前項の手続きを経る事で当社は中止に伴う損害賠償の責を免れるものとします。<br />
                                    <br /> 第２３条（損害賠償及び損害賠償額の予定）<br />
                                    （1）会員が本規約に違反する行為、または不正もしくは違法な行為によって当社に損害を与えた場合は、当社は当該会員に対して相応の損害賠償の請求ができるものとします。<br />
                                    （2）当社は、当社の責に帰すべき事由により、会員に損害を与えた場合は、その損害が生じた当該期にその会員が支払う会費の範囲内で賠償するものとし、その他の場合には、会員に対し一切損害の賠償はしません。<br />
                                    <br /> 第２４条（免責事項）<br />
                                    （1）本件サービスの内容は、当社がその時点で提供可能なものに限定されます。<br />
                                    （2）当社及び当社に対しサービス・情報等を提供している提供元（提供元に対し、サービス・情報等を提供している供給元も含みます。以下、当社、提供元、供給元をまとめて「当社及び提供元」といいます）は、本件サービスの提供、報告、解釈、収集、編集その他本規約の遂行に起因して会員及び第三者に生じた損失及び損害に対し、いかなる責任も負いません。<br />
                                    （3）当社及び提供元は、本件サービスにおいて提供するあらゆる情報について、その完全性、正確性、最新性、目的適合性、有効性等いかなる保証も行いません。<br />
                                    （4）当社及び提供元は、本件サービスにおいて情報を提供する際に生じた誤謬、欠陥及び送信の遅延、支障に関し、会員または第三者に対し、いかなる責任も負いません。<br />
                                    （5）当社及び提供元は、その予見及び予見可能性の有無を問わず、会員または第三者に生じたいかなる特別損害、付属的損害、間接損害、逸失利益その他の拡大損害について一切の責任を負いません。また、本件サービスの利用により会員データ・プログラム等無体物が損傷しても、一切責任を負いません。<br />
                                    <br />
                                    第２５条（反社会的勢力の排除）<br />
                                    （1）当社及び会員は、暴力団、暴力団員、暴力団関係企業、暴力団準構成員、総会屋、社会運動等標ぼうゴロまたは特殊知能暴力集団、その他これらに準ずるもの（これらを総称して「反社会的勢力」といいます）との関係を一切持ちません。また、反社会的勢力は会員となることができません。<br />
                                    （2)
                                    当社及び会員は、現在、反社会的勢力のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを確約するものとします。<br /> （3)
                                    当社及び会員は、自らまたは第三者を利用して、暴力的な要求行為、法的な責任を超えた不当な要求行為、取引に関して脅迫的な言動または暴力を用いる行為、風説を流布し偽計を用いまたは威力を用いて相手方の信用を毀損しまたは相手方の業務を妨害する行為、その他これらに準ずる行為を行わないことを確約するものとします。<br />
                                    （4)
                                    当社は、本条第２項の表明及び確約に虚偽の申告をする等の違反行為、または前項の確約に違反する行為を行ったことが判明した場合において、本件サービスの利用を継続することが不適切であると当社が判断した場合には、通知、催告その他何らの手続をとることなく、会員資格を抹消することができるものとします。<br />
                                    <br />
                                    第２６条（規約の範囲及び変更）<br />
                                    （1）本規約は、本サービス利用に関する当社と会員との間の権利義務関係を定めるものとし、会員と当社の間において本サービスの利用に関する一切の件に適用されます。<br />
                                    （2）当社が別途定める個別利用契約は、本規約の一部を構成します。本規約と個別利用契約とが矛盾抵触する場合には、原則として個別利用契約が優先するものとします。<br />
                                    （3）当社は、会員の事前の承諾なく本規約（サイバックスUniv.サイト等に掲示される規約や諸規定等を含みます。以下本項において同じ）を随時変更・改訂できるものとします。本規約が変更された場合、変更後の本サービスの提供条件は、変更後の会員規約に従うものとし、会員は、あらかじめこれを承諾するものとします。本規約の変更・改訂の結果、会員に不利益が生じた場合でも、当社は補償その他の義務を負いません。<br />
                                    （4）当社は、前項の変更を行う場合は、変更後の会員規約の効力発生日の前に相当な期間をもって、変更後の会員規約の内容とかかる効力発生日を会員に通知又は、Univ.サイトヘ掲示するものとします。ただし、変更が軽微で会員に特に不利益にならないと当社が判断した場合は通知をせず、Univ.サイトへの掲示のみを行うものとします。<br />（5）当社が会員に変更後の会員規約の内容を通知又は、Univ.サイトヘ掲示し、変更後の会員規約の効力発生日以降に会員が本サービスを利用した場合は、会員は会員規約の変更に同意したものとみなします。<br>
                                    <br /> 第２７条（準拠法）<br />
                                    本規約の及び個別利用契約の成立・効力・履行及び解釈に関しては、日本国法が適用されるものとします。<br /> <br /> 第２８条（紛争の解決）<br />
                                    （1）本規約の条項の解釈について疑義が生じ、または本規約に定めのない事項について疑義等が生じた場合、会員と当社は誠意をもって協議し円満に解決するものとします。<br />
                                    （2）本件サービスの利用に関して万一紛争が生じた場合は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br />
                                    <div align="right">2024 年４月改定</div>
                                    附則<br>
                                    （適用期日）本規約は、2024 年４月１日から適用します。<br>
                                    （競業避止条項に関する経過措置）本規約第12条（8）の規定は、2022年４月21日以後入会した会員について適用します。<br>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;padding-top:30px;font-size:14px;"><input type="checkbox" v-model="rule"
                            value="true" />
                        同意します
                    </td>
                </tr>
                <tr>
                    <td style="padding-top: 30px;">
                        <div style="width: 89%; margin: 0 auto;">
                            <div v-if="initModelInf.loginId">
                                <div class="rsk-form-control">
                                    <div class="rsk-flex rsk-justify-between rsk-width-250">
                                        <div class="rsk-form-control__label color_form_gray676">ログインID</div>
                                        <div class="rsk-form rsk-mr-s"></div>
                                    </div>
                                    <div class="rsk-form-control__label color_form_gray676">
                                        {{ modelInf.loginId }}
                                    </div>
                                </div>
                                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                            </div>
                            <div v-if="initModelInf.usrNm">
                                <div class="rsk-form-control">
                                    <div class="rsk-flex rsk-justify-between rsk-width-250">
                                        <div class="rsk-form-control__label color_form_gray676">氏名</div>
                                        <div class="rsk-form rsk-mr-s"></div>
                                    </div>
                                    <div class="rsk-form-control__label color_form_gray676" style="flex: 1">
                                        {{ modelInf.usrNm }}
                                    </div>
                                </div>
                                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                            </div>
                            <div v-if="initModelInf.usrNmKna">
                                <div class="rsk-form-control">
                                    <div class="rsk-flex rsk-justify-between rsk-width-250">
                                        <div class="rsk-form-control__label color_form_gray676">カナ氏名</div>
                                        <div class="rsk-form rsk-mr-s"></div>
                                    </div>
                                    <div class="rsk-form-control__label color_form_gray676" style="flex: 1">
                                        {{ modelInf.usrNmKna }}
                                    </div>
                                </div>
                                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                            </div>
                            <div v-if="initModelInf.usrMlAdr">
                                <div class="rsk-form-control">
                                    <div class="rsk-flex rsk-justify-between rsk-width-250">
                                        <div class="rsk-form-control__label color_form_gray676">Ｅｍａｉｌアドレス(1)</div>
                                        <div class="rsk-form rsk-mr-s"></div>
                                    </div>
                                    <div class="rsk-form-control__label color_form_gray676" style="flex: 1">
                                        {{ modelInf.usrMlAdr }}
                                    </div>
                                </div>
                                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                            </div>
                            <div v-if="initModelInf.usrMlAdrEx">
                                <div class="rsk-form-control">
                                    <div class="rsk-flex rsk-justify-between rsk-width-250">
                                        <div class="rsk-form-control__label color_form_gray676">Ｅｍａｉｌアドレス(2)</div>
                                        <div class="rsk-form rsk-mr-s"></div>
                                    </div>
                                    <div class="rsk-form-control__label color_form_gray676" style="flex: 1">
                                        {{ modelInf.usrMlAdrEx }}
                                    </div>
                                </div>
                                <div v-if="modelInf.userProjectShowFlag">
                                    <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                                </div>
                            </div>
                                <template v-if="modelInf.userProjectShowFlag">
                                    <div v-if="initModelInf.usrSex == 0 || initModelInf.usrSex == 1">
                                        <rsk-form-item isListUsrPro label="性別">
                                        <rsk-select isMin :options="[{label:'男', value:'0'}, 
                                            {label:'女', value:'1'}]" v-model="modelInf.usrSex" />
                                        </rsk-form-item>
                                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                                    </div>
                                    <div v-if="initModelInf.year && initModelInf.month && initModelInf.day">
                                        <rsk-form-item isListUsrPro label="誕生日">
                                            <select class="rsk-mr-xxs" v-model="modelInf.year" >
                                                <template v-for="item in birthYear" >
                                                    <option>{{ item }}</option>
                                                </template>
                                            </select>
                                            <span class="rsk-mr-xs">年</span>
                                            <select class="rsk-mr-xxs" v-model="modelInf.month">
                                                <template v-for="item in birthMonth" >
                                                    <option>{{ item }}</option>
                                                </template>
                                            </select>
                                            <span class="rsk-mr-xs">月</span>
                                            <select class="rsk-mr-xxs" v-model="modelInf.day">
                                                <template v-for="item in birthDay" >
                                                    <option>{{ item }}</option>
                                                </template>
                                            </select>
                                            <span class="rsk-mr-xs">日</span>
                                        </rsk-form-item>
                                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                                    </div>
                                    <div v-if="initModelInf.usrTel">
                                        <rsk-form-item isListUsrPro label="電話番号" prop="usrTel">
                                            <rsk-input max="32" v-model="modelInf.usrTel" />
                                        </rsk-form-item>
                                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                                    </div>
                                    <div v-if="initModelInf.usrZip">
                                        <rsk-form-item isListUsrPro label="郵便番号" prop="usrZip">
                                            <input class="rsk-input rsk-input--narrow rsk-mr-s" type="text" name="inCopZip" id="inCopZip" 
                                                maxlength="32" v-model="modelInf.usrZip" />
                                            <button class="rsk-button rsk-button--medium btn_usr-zip rsk-mr-s" 
                                                    @click="researchZip(modelInf.usrZip.replace('-', ''))">郵便番号から検索</button>
                                            <div class="color_form_gray676 font_size11">ハイフン不要</div>
                                        </rsk-form-item>
                                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                                    </div>
                                    <div v-if="initModelInf.usrAdr">
                                        <rsk-form-item isListUsrPro label="住所" prop="usrAdr">
                                            <rsk-input max="250" v-model="modelInf.usrAdr" />
                                        </rsk-form-item>
                                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                                    </div>
                                    <div v-if="initModelInf.post">
                                        <rsk-form-item isListUsrPro label="役職" prop="post">
                                            <rsk-input max="30" v-model="modelInf.post" />
                                        </rsk-form-item>
                                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                                    </div>
                                </template>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="rsk-flex rsk-justify-center">
                            <div class="rsk-form-button-wrapper__button">
                                <rsk-button isExpand type="primary" @click="jump()"><span class="btn_font">利用環境チェックページへ</span></rsk-button>
                            </div>
                        </div>
                    </td>
                </tr>

            </table>
        </div>
        
    </rsk-form>
    </div>

</template>

<script>
export { default } from './js/ass_entmng_view.js'
</script>



<style lang="scss" src="./css/ass_ent.scss" scoped />
<style lang="scss" scoped>
#page {
  background: white;
}
.rule__page-title-text {
    font-size: 11px;
}
.font_size11 {
    font-size: 11px;
}
.color_form_gray676 {
    color: #676F79;
}
.btn_usr-zip {
    border-color: #134f94;
    background-color: transparent;
    color: #007bff;
}
</style>