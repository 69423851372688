<template>
<div class="contents">
    <div class="wrapper">
        <div id="main">
            <dl class="faq">
                <h1>利用環境チェック結果（パソコン版）</h1>
                <div id="faq01">
                    <span style="color: red;font-weight:bold;font-size:15px;">あなたのパソコンの利用環境チェック結果です。eLearning受講前に必ず内容をご確認ください。</span>
                    <div style="font-size: 13px;line-height: 20px;margin-bottom: 20px;">
                        <span>
                            判定結果がすべて「〇」の場合は、このままご利用ください。一部のコースは受講にあたり、
                            <a
                                href="#aTable"
                                style="color: #066CE5;"
                                >ブラウザの対応が限られる</a
                                >場合がございますので、チェック結果詳細をご確認ください。<br />
                                判定結果に「×」がある場合、「お客様環境」欄２段目の対処方法をご確認ください。
                        </span>
                    </div>
                    <p style="color: red;"></p>
                    <table
                        class="clr environTable"
                        id="environTab"
                        style="margin-bottom: 30px;"
                    >
                        <thead>
                            <tr>
                                <th colspan="4">お客様環境</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="25%">OSの種類とバージョン</td>
                                <td width="25%">Webブラウザの種類とバージョン</td>
                                <td width="25%">JavaScript</td>
                                <td width="25%">Cookie</td>
                            </tr>
                            <tr>
                                <td width="25%">
                                    <div class="rsk-flex rsk-items-center rsk-justify-center" style="gap:8px">
                                         <img v-if="OS(versionWin)=='Windows 7'" src="../../assets/browser_vatu.svg" alt="vatu" width="24" height="24"/>
                                         <img v-else-if="String(OS(versionWin)).search('Windows') != -1 || String(OS(versionWin)).search('Mac') != -1" src="../../assets/browser_circle.svg" alt="circle" width="24" height="24"/>
                                         <img v-else src="../../assets/browser_vatu.svg" alt="vatu" width="24" height="24"/>
                                         <span style="font-size: 16px;line-height: 24px;">{{OS(versionWin)}}</span>
                                    </div> 
                                </td>
                                <td
                                    width="25%"
                                    id="scriptSta">
                                    <div
                                        class="rsk-flex rsk-items-center rsk-justify-center"
                                        style="gap:8px">
                                        <img v-if="browerVerify(versionWin)" src="../../assets/browser_circle.svg" alt="circle" width="24" height="24"/>
                                        <img v-else="browerVerify(versionWin)" src="../../assets/browser_vatu.svg" alt="vatu" width="24" height="24"/>
                                        <span v-if="'ActiveXObject' in window" style="font-size: 16px;line-height: 24px;">
                                            Internet Explorer <br/>
                                            {{document_brower()}}<br/>
                                            {{base_browser()}}
                                        </span>
                                        <span v-else style="font-size: 16px;line-height: 24px;">{{base_browser()}}</span>
                                    </div>
                                </td>
                                <td width="25%">
                                    <div
                                        class="rsk-flex rsk-items-center rsk-justify-center"
                                        style="gap:8px">
                                        <img src="../../assets/browser_circle.svg" alt="circle" width="24" height="24"/>
                                        <span style="font-size: 16px;line-height: 24px;">有効です</span>
                                        <NOSCRIPT>
                                            <span>
                                                <img
                                                src="../../assets/browser_vatu.svg"
                                                alt="vatu"
                                                width="24"
                                                height="24"/>
                                                <span style="font-size: 16px;line-height: 24px;">無効です</span>
                                            </span>
                                        </NOSCRIPT>
                                    </div>
                                </td>
                                <td width="25%">
                                     <div
                                        v-if="cookieCheck()"
                                        class="rsk-flex rsk-items-center rsk-justify-center"
                                        style="gap:8px">
                                        <img src="../../assets/browser_circle.svg" alt="circle" width="24" height="24"/>
                                        <span style="font-size: 16px;line-height: 24px;">有効です</span>
                                    </div>
                                    <div
                                        v-else
                                        class="rsk-flex rsk-items-center rsk-justify-center"
                                        style="gap:8px">
                                        <img src="../../assets/browser_vatu.svg" alt="circle" width="24" height="24"/>
                                        <span style="font-size: 16px;line-height: 24px;">無効です</span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">
                                    <div v-if="osVerify(versionWin)">
                                        <div v-if="'Windows 10'== OS(versionWin)">
                                            Windows10をタブレットモードで利用さ<br />
                                            れている方は、非対応のため
                                            <a
                                                href="https://cybaxuniv-support2user.zendesk.com/hc/ja/articles/12565801954329"
                                                target="_blank"
                                                style="color: #066CE5;"
                                                >こちら</a>
                                            を<br />
                                            ご確認ください。
                                        </div>
                                        <div v-else>
                                            <div class="rsk-flex rsk-items-center rsk-justify-center">
                                                <img
                                                src="../../assets/ignore.svg"
                                                alt="ignore"
                                                width="24"
                                                height="24" />
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else>
                                        必須動作環境のOSのパソコンをご利用ください。
                                    </div>
                                </td>

                                <td width="25%">
                                    <div v-if=browerVerify(versionWin) class="rsk-flex rsk-items-center rsk-justify-center">
                                        <img src="../../assets/ignore.svg" alt="ignore" width="24" height="24"/>
                                    </div>
                                    <div v-else>必須動作環境のWebブラウザを<br />ご利用ください。</div>
                                </td>
                                <td width="25%">
                                    <div class="rsk-flex rsk-items-center rsk-justify-center">
                                        <img
                                        src="../../assets/ignore.svg"
                                        alt="ignore"
                                        width="24"
                                        height="24" />
                                    </div>
                                    <NOSCRIPT>
                                        <div style="text-align: left;">
                                            JavaScriptを有効にする方法はこちらをご覧ください。<br /> 
                                            <p style="text-align: left;">
                                                <a href="https://cybaxuniv-support2user.zendesk.com/hc/ja/articles/12565852982041" target="_blank">
                                                    https://cybaxuniv-<br>
                                                    support2user.zendesk.com/hc/ja<br>/articles/12565852982041
                                                </a>
                                            </p>
                                        </div>
                                    </NOSCRIPT> 
                                </td>
                                <td width="25%">
                                    <div v-if="cookieCheck()" class="rsk-flex rsk-items-center rsk-justify-center">
                                        <img
                                        src="../../assets/ignore.svg"
                                        alt="ignore"
                                        width="24"
                                        height="24" />
                                    </div>
                                    <div v-else>
                                        Cookieを有効にする方法は<br />
                                        <a
                                            href="https://cybaxuniv-support2user.zendesk.com/hc/ja/articles/12565828981145"
                                            target="_blank"
                                            style="color: #066CE5;">こちら</a
                                        >をご覧ください。
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table
                        class="environTable"
                        style="margin-bottom: 15px;">
                        <thead>
                            <tr>
                                <th colspan="4">必須動作環境</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="25%">OSの種類とバージョン</td>
                                <td width="25%">Webブラウザの種類とバージョン</td>
                                <td width="25%">JavaScript</td>
                                <td width="25%">Cookie</td>
                            </tr>
                            <tr>
                                <td width="25%">
                                    <img src="../../assets/windows.svg" alt="windows" />
                                    <br />
                                    Windows
                                </td>
                                <td width="25%">
                                    <div class="rsk-flex rsk-justify-around">
                                        <div>
                                            <img
                                                src="../../assets/edge.svg"
                                                alt="edge"
                                                width="50"
                                                height="50" />
                                            <br />Microsoft Edge
                                        </div>
                                        <div>
                                            <img
                                                src="../../assets/chrome.svg"
                                                alt="vatu"
                                                width="50"
                                                height="50" />
                                            <br />Google Chrome
                                        </div>
                                    </div>
                                </td>
                                <td width="25%">有効であること</td>
                                <td width="25%">有効であること</td>
                            </tr>
                            <tr>
                                <td width="25%">
                                    <img
                                        src="../../assets/mac.svg"
                                        alt="mac"
                                        width="50"
                                        height="50" />
                                    <br />
                                    Mac
                                </td>
                                <td width="25%">
                                    <img
                                        src="../../assets/safari.svg"
                                        alt="safari"
                                        width="50"
                                        height="50" />
                                    <br />
                                    Safari
                                </td>
                                <td width="25%">有効であること</td>
                                <td width="25%">有効であること</td>
                            </tr>
                        </tbody>
                    </table>
                   <div style="font-size:11px; line-height: 150%;margin-bottom: 30px;">
                        ※モニターの画面解像度は「1024×768ピクセル」以上、画面の色は「中（16ビット）」以上を推奨しています。<br />
                        ※eラーニングによって、必須動作環境の範囲内であっても、OSやブラウザの組み合わせ等により、一部表示不具合や各種機能がご利用になれない場合がございます。<br />
                        ※次のeラーニングは、必須動作環境に一部対応しておりません。ご了承ください。
                    </div>

                    <table
                        id="aTable"
                        class="environTable"
                        style="margin-bottom: 15px;">
                        <thead>
                            <tr>
                                <th colspan="2">必須動作環境未対応</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td width="25%">未対応ブラウザ</td>
                                <td width="75%">e-ラーニング名（コースID一部）</td>
                            </tr>
                            <tr>
                                <td width="25%">Edge（Windows）</td>
                                <td width="75%" style="d">
                                    <div class="rsk-flex rsk-items-center rsk-justify-center">
                                        <img
                                        src="../../assets/ignore.svg"
                                        alt="ignore"
                                        width="24"
                                        height="24" />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td width="25%">Safari（MacOS）</td>
                                <td width="75%">エッセンシャルシリーズ (LW0132~0142)</td>
                            </tr>
                            <tr>
                                <td width="25%"></td>
                                <td width="75%">Office2016 基礎編 応用編シリーズ (LW0694~0699)</td>
                            </tr>
                            <tr>
                                <td width="25%"></td>
                                <td width="75%">Office2019 エントリー ベーシック　アドバンスシリーズ（LW0876～LW0884）</td>
                            </tr>
                            <tr>
                                <td width="25%"></td>
                                <td width="75%">MBAマネジメント養成講座シリーズ (LW0600~0606)</td>
                            </tr>
                            <tr>
                                <td width="25%"></td>
                                <td width="75%">論理構築シリーズ (LW0607~0608)</td>
                            </tr>
                            <tr>
                                <td width="25%"></td>
                                <td width="75%">管理者必修シリーズ (LW0609~0611)</td>
                            </tr>
                            <tr>
                                <td width="25%"></td>
                                <td width="75%">中間管理職のためのチームマネジメントシリーズ (LW0612~0617)</td>
                            </tr>
                            <tr>
                                <td width="25%"></td>
                                <td width="75%">働き方改革シリーズ (LW0618~0619)</td>
                            </tr>
                            <tr>
                                <td width="25%"></td>
                                <td width="75%">職場のケアシリーズ (LW0620~0621)</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <div style="font-size:11px; line-height: 150%;margin-bottom: 70px;">
                        ※2024年4月21日現在<br />
                        ※改訂に伴い予告なく変更する場合があります。
                    </div>
                </div>
                <h1>【参考】 モバイルでのご利用について</h1>
                <div style="font-size: 13px;line-height: 20px;margin-bottom: 20px;">
                    モバイル端末をご利用の場合、次の環境をご用意ください。モバイル端末の利用環境チェックは、ログイン画面上のリンクより行えます。
                </div>


                <table
                    class="environTable"
                    style="margin-bottom: 15px;">
                    <thead>
                        <tr>
                            <th colspan="3">モバイル版動作環境</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="calc(100%/3)">端末</td>
                            <td width="calc(100%/3)">OS</td>
                            <td width="calc(100%/3)">ブラウザ</td>
                        </tr>
                        <tr>
                            <td width="calc(100%/3)">
                                <div
                                    class="rsk-flex rsk-justify-center"
                                    style="align-items: flex-end">
                                    <img
                                        src="../../assets/iphone.svg"
                                        alt="iphone"
                                        height="44" />
                                    <img
                                        src="../../assets/ipad.svg"
                                        alt="ipad"
                                        width="48"
                                        height="62" />
                                </div>
                                iPhone / iPad
                            </td>
                        <td width="calc(100%/3)">iOS 14～17</td>
                        <td width="calc(100%/3)">Safari</td>
                    </tr>
                    <tr>
                        <td width="calc(100%/3)">
                            <img
                                src="../../assets/android.svg"
                                alt="android"
                                width="35"
                                height="55" />
                            <br />
                            Android (スマホ)
                        </td>
                        <td width="calc(100%/3)">Android 10.X～ 14.X</td>
                        <td width="calc(100%/3)">Google Chrome</td>
                    </tr>
                    </tbody>
                </table>
                <div style="font-size: 13px;line-height: 20px">
                    ※eラーニングは、スマホ対応版のみご利用いただけます。<br />
                    ※ログイン画面で「表示モード」を「モバイル」に変更するか、アプリストアで「サイバックスUniv.」アプリをダウンロードしてください。<br />
                    ※以下のコースはAndroidに対応しておりません。<br />
                    <div style="text-indent: 1em;font-size: 13px;line-height: 20px">職場のケアシリーズ（LW0620～0621）</div>
                    <div style="text-indent: 1em;font-size: 13px;line-height: 20px">
                        社会人なら知っておきたい　ビジネスマナー（CB01690001）
                    </div>
                    <div style="text-indent: 1em;font-size: 13px;line-height: 20px">
                        SQL言語入門（NS00420003）
                    </div>
                    ※2024年4月21日現在<br />
                    ※改訂に伴い予告なく変更する場合があります。
                </div>
            </dl>
            <div class="btn_std" style="background-color:unset">
                <!-- <button v-if="state == 0" @click="jump" class="rsk-button rsk-button--medium rsk-button--expand rsk-button--user-primary" style="width: 170px;">確認しました</button> -->
                <button @click="jump" v-if="btnShowFlag == 1" class="rsk-button rsk-button--medium rsk-button--expand rsk-button--user-primary" style="width: 282px;margin-bottom:50px;">確認しました</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
  export { default } from './js/pop_environment_view.js'
</script>

<style lang="scss" src="./css/pop_environment.scss" scoped />
