export function halfNum(msg){
    let pattern =  {
        pattern: /^[0-9]*$/,
        message: msg
    }
    return pattern;
  }

export function full(msg){
    let pattern =  {
        pattern: /^[^ -~｡-ﾟ]*$/,
        message: msg
    }
    return pattern;
  }

export function fullKna(msg){
    let pattern =  {
        pattern: /^[\u30A0-\u30FF\uFF65-\uFF9F\uFF10-\uFF19\uFF0D\u3000\uFF08\uFF09\uFF65]+$/,
        message: msg
    }
    return pattern;
  }

export function phone(msg){
    let pattern =  {
        pattern: /^[0-9]+-{0,1}[0-9]+-{0,1}[0-9]+$/,
        message: msg
    }
    return pattern;
  }

export function mail(msg){
    let pattern =  {
        pattern: /^(.+)@(.+)$/,
        message: msg
    }
    return pattern;
  }

export function entPwd(msg){
    let pattern =  {
        pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])[!-~\\ ]+/,
        message: msg
    }
    return pattern;
  }

export function halfNumEg(msg){
    let pattern =  {
        pattern: /[0-9a-zA-Z]+/,
        message: msg
    }
    return pattern;
  }

export function halfNumEg2(msg){
    let pattern =  {
        pattern: /^[A-Za-z0-9]+$/,
        message: msg
    }
    return pattern;
  }