<template>
    <div class="content">
        <div class="rsk-p-xl">
            <div class="rsk-form-control">
                <h2 class="rsk-page-title rsk-mb-s">レポート提出内容</h2>
            </div>
            <div class="rsk-panel rsk-mb-m">
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">レポート名</div>
                    </div>
                    <div v-text="detail.reportName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">ボックス名</div>
                    </div>
                    <div v-text="detail.lessonName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">提出日時</div>
                    </div>
                    <div v-text="detail.submitBeginTm" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">内容</div>
                    </div>
                    <div v-text="detail.unitDetail" class="rsk-form rsk-form--expand rsk-text-gray060 rsk-pre-wrap"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">提出コメント</div>
                    </div>
                    <div v-text="detail.sendDetail" class="rsk-form rsk-form--expand rsk-text-gray060 rsk-pre-wrap"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">提出ファイル1</div>
                    </div>
                    <div class="rsk-form rsk-form--expand rsk-text-gray060">
                        <a href="#" @click="downloadFile(detail.reportFileName1, detail.reportFileDir1)"
                           style="text-decoration:underline;" v-text="detail.reportFileName1"
                           v-if="detail.reportFileName1 != '' || detail.reportFileName1 != null"
                        />
                    </div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">提出ファイル2</div>
                    </div>
                    <div class="rsk-form rsk-form--expand rsk-text-gray060">
                        <a href="#" @click="downloadFile(detail.reportFileName2, detail.reportFileDir2)" 
                           style="text-decoration:underline;" v-text="detail.reportFileName2"
                           v-if="detail.reportFileName2 != '' || detail.reportFileName2 != null"
                        />
                    </div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">評価状態</div>
                    </div>
                    <div v-text="detail.unitLearningProgressStr" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">点数</div>
                    </div>
                    <div v-text="detail.userReportScore" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label">評価コメント</div>
                    </div>
                    <div v-text="detail.reportEvalDetail" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>                                                                                                
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>                                                                                  
            </div>
            <div class="rsk-p-s">
                <button @click="index" class="rsk-button rsk-button--text-user-primary">
                    <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                        <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                    </span>
                    戻る
                </button> 
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, inject, onBeforeUnmount } from 'vue';
import { useRoute,useRouter } from 'vue-router'
import { dateFtt } from '@/utils/common'
import { useStore } from 'vuex'
export default {
    setup() {
        const detail = ref({});
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        let id = "";
        let goUrl = "rep";
        let lessonId = "";
        onMounted(() => {
            if(typeof(route.params.id) != "undefined") {
                id = route.params.id;
                localStorage.reportLessonId = id; 
            } else {
                id = localStorage.reportLessonId
            }
            if(!id) {
                router.push({ name: 'rep'});
                return;
            }
            store.commit('setCourseId', id);
            if(typeof(route.params.fromUrl) != "undefined") {
                goUrl = route.params.fromUrl;
            }
            if(typeof(route.params.lessonId) != "undefined") {
                lessonId = route.params.lessonId;
            }
            getReportConfirmDetail()
        })
        
        const service = inject("service");
        function getReportConfirmDetail() {
            service.report.getReportConfirm(store?.getters?.getCourseId).then(res => {
                detail.value = res.model
                detail.value.submitBeginTm = dateFtt('yyyy/MM/dd hh:mm', detail.value.submitBeginTm);  
            })
        }

        function setCourseId() {
            localStorage.reportLessonId = store?.getters?.getCourseId; 
        }

        window.addEventListener('beforeunload', setCourseId);

        onBeforeUnmount(() => {
            localStorage.removeItem("reportLessonId");
            window.removeEventListener('beforeunload', setCourseId)
        })

        function index() {
            router.push({ 
                name: goUrl,
                params: {
                    lessonId : lessonId
                }
            });
        }

        function downloadFile (filename, fileurl){
            const downloadData = {filename:'', fileurl:'', userAgent:''}
            downloadData.filename = filename
            downloadData.fileurl = fileurl
            service.common.downloadFile(downloadData)
        }

        return {
            detail,
            index,
            downloadFile
        }
    }
}
</script>
