<template>
    <OpeElgant>
        <div class="main-one-col">
            <div id="contents-wrap" class="inner-form">
                <form name="form" id="form" method="post" action="/api/opeElgant/update" class="survey_form">
                    <!-- <div id="form" class="survey_form"> -->
                    <h1 class="ttl-head01 center">コンテンツ満足度アンケート</h1>
                    <div class="sub_ttl">
                        eラーニング で受講した以下のコースについて感想をお聞かせください。<br> <span><br class="sp-on">
                            {{ model.elearningName }}</span>
                    </div>
                    <div v-if="questionList.length > 0">
                        <div v-for="(question, index) in questionList" :key="index">
                            <dl v-if="question.showFlg == 0">
                                <dt>
                                    <span v-if="question.questionType == 1" class="required">必須</span>
                                    <span v-else class="self">任意</span>
                                    【質問 {{ question.sortNo }} 】 {{ question.questionName }}
                                </dt>
                                <dd>
                                    <ul style="padding-left: 5px;" v-if="question.questionType == 1">
                                        <li v-for="(detail, index_d) in question.detailList" :key="index_d"
                                            class="li_select">
                                            <input type="radio" :id="`questionList_${index}__detailId${detail.detailId}`"
                                                :checked="detail.detailId === question.detailId"
                                                @change="getRadioVal(question.questionId, detail.detailId)">
                                            <label
                                                :for="`questionList_${index}__detailId${detail.detailId}`">{{ detail.selection }}</label>
                                        </li>
                                    </ul>
                                    <textarea v-else-if="question.questionType == 4" v-model="question.answer" cols="15"
                                        rows="8" @change="getTextareaVal(question.questionId, question.answer)"></textarea>
                                </dd>
                            </dl>
                        </div>
                    </div>
                    <div class="formSubmit txtC">
                        <a href="#" class="btn-base inline-b" @click="form_submit">確認画面へ進む</a>
                    </div>
                    <!-- </div> -->
                </form>
            </div>
        </div>
    </OpeElgant>
</template>

<script>
import { useRoute, useRouter } from 'vue-router'
import { inject, ref, onMounted } from 'vue'
import OpeElgant from "./ope_elgant.vue"

export default {
    components: {
        OpeElgant
    },
    setup() {
        const route = useRoute()
        const router = useRouter()
        const service = inject("service")
        let questionList = ref({})
        let model = ref({})

        let messageData = ''

        onMounted(() => {
            if (window.opener) {
                messageData = window.opener.getElgQstMsg()
            }
            let formdata = { parameterId: route.params.comid }
            service.opeelgant.opeelgant.edit(formdata).then(res => {
                if (res.pageFlag == 'completed') {
                    const params = { name: 'ope_elgant_completed' }
                    if(messageData === 'windowOpen') {
                        params.params = { windowOpen: '2' }
                    }
                    router.push(params)
                }else {
                    questionList.value = res.questionList
                    model.value = res.model
                }
            }).catch(error => {
                console.log(error)
            })


        })

        function form_submit() {
            if(messageData === 'windowOpen') {
                router.push({ name: 'ope_elgant_confirm', params: { model: JSON.stringify(model.value), questionList: JSON.stringify(questionList.value), windowOpen: '2' } })
            } else {
                router.push({ name: 'ope_elgant_confirm', params: { model: JSON.stringify(model.value), questionList: JSON.stringify(questionList.value) } })
            }
        }

        function getRadioVal(questionId, detailId) {
            questionList.value.forEach(question => {
                if (question.questionType == 1 && question.questionId == questionId) {
                    question.detailId = detailId;
                }
            })
        }

        function getTextareaVal(questionId, answer) {
            questionList.value.forEach(question => {
                if (question.questionType == 4 && question.questionId == questionId) {
                    question.answer = answer;
                }
            })
        }

        return {
            questionList,
            model,
            OpeElgant,
            form_submit,
            getRadioVal,
            getTextareaVal
        }
    }
}
</script>
<style lang="scss" src="./css/ope_elgant.scss" scoped/>

