import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/usrstdtraInfo')
export default class UsrstdtraInfo extends BaseService {
	init(data) {
		return this.request({
			data: data,
			url: "/init",
			method: "POST"
		});
	}

	studyStart(data) {
		return this.request({
			data: data,
			url: "/studyStart",
			method: "POST"
		});
	}
}
