<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>
<style  src="./style/fonts.css"></style>
<style>
body {
  line-height: initial;
  margin: 0;
}
</style>
