import Cookies from 'js-cookie'
import { DefaultCookieExpires } from '@/config.js'

const TokenKey = 'Bearer-Token'

let cookieExpires = {}

if (DefaultCookieExpires !== '') {
  cookieExpires = { expires: DefaultCookieExpires }
}

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function cookieIsValid() {
  if(navigator.cookieEnabled && typeof window.localStorage !== 'undefined') {
    return true;
  }
  return false
}
