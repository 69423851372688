import { Service, BaseService } from "@/core/auto";

@Service('/api/opeElgant')
export default class opeelgant extends BaseService {
	edit(data) {
		return this.request({
			showError: true,
			data: data,
			url: "/edit",
			method: "POST"
		})
	}
	update(data) {
		return this.request({
			showError: true,
			data: data,
			url: "/update",
			method: "POST"
		})
	}
	confirm() {
		return this.request({
			showError: true,
			url: "/confirm",
			method: "POST"
		})
	}
}