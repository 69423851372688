<template>
  <div>
    <div class="rsk-panel rsk-mb-xl">
      <rsk-form :rules="rules" v-model="form" ref="formRef" cacheKey="oneselfcache">
        <rsk-form-item
          prop="id"
          label="研修ID"
          annotation="（完全一致）"
        >
          <rsk-input max="10" v-model="form.id" />
        </rsk-form-item>
        <rsk-form-item
          isExpand
          prop="trainName"
          label="研修名"
          annotation="（部分一致）"
        >
          <rsk-input max="100" v-model="form.trainName" isExpand />
        </rsk-form-item>
        <rsk-form-item prop="type" label="学習形式">
          <rsk-select
            v-model="form.studyFlagTra"
            label="学習形式"
            :options="studyOptions"
          />
        </rsk-form-item>
      </rsk-form>

      <rsk-form-submit>
        <rsk-button isExpand @click="refresh()" type="primary">検索</rsk-button>
        <rsk-button isExpand @click="clear" type="tertiary">クリア</rsk-button>
      </rsk-form-submit>
    </div>
    
    <rsk-table
      v-if="dispaly37Value"
      ref="tableRef"
      cacheKey="oneselfcache" 
      @init="tableConfigInit"
      :pagingButton="pagingButton"
      @pageInfoChange="tableChange"
      @sortChange="tableChange"
      :total="tableTotal"
      :dispaly37="dispaly37Value"
      checkbox
      :data="tableData"
      :titles="titles"
    />
    <rsk-table
      v-if="!dispaly37Value"
      ref="tableRef"
      cacheKey="oneselfcache" 
      @init="tableConfigInit"
      :pagingButton="pagingButton"
      @pageInfoChange="tableChange"
      @sortChange="tableChange"
      :total="tableTotal"
      :dispaly37="dispaly37Value"
      :data="tableData"
      :titles="titles"
    />   
    
  </div>
</template>
<script>
import { ref, inject, onMounted } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox } from 'element-plus';
import input from '../../../../core/components/rsk/input.vue';
import { dateFtt } from "@/utils/common";
import { checkInvalid } from '@/utils/validate'
import { full, halfNumEg2 } from '@/utils/validateUtils'
import { toFull } from "@/utils/editnew";

export default {
  props:{
    isFromInfo:{
            type:String,
            default: null
        },
  },
  setup(props) {

    const router = useRouter();
    const tableTotal = ref(0);
    const dispaly37Value = ref();
    const jyukoufukaFlg = ref();
    const formRef = ref();
    const priceApplicationName = ref("");
    const priceApplication = ref("");
    const message = ref("");
    const tableRef = ref();
    const form = ref({
      styleFlag: "3"
    });
    let fromWhere = "";
    const pagingButton = ref({
      text: "一括申し込み",
      callBack: (res) => {
        if(jyukoufukaFlg.value == "1"){
          alert("全体管理者または所属組織管理者のため、研修申込できません。");
          return;
        }

        if(res.length == 0) {
          ElMessageBox.alert('研修情報を選択してください。', '研修', {
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: 'OK',
            callback: () => {
            },
          })
          return;
        }

        priceApplicationName.value = "";
        priceApplication.value = "";
        for (let i = 0; i < res.length; i++) {
          if (res[i].priceApplication == "有料申込済" || res[i].priceApplication == "申込済") {
            priceApplicationName.value = priceApplicationName.value + "「" + res[i].name.text + "」";
          }else if(res[i].priceApplication == "有料"){
            priceApplication.value = '有料の研修ですが、';
          }
        }

        if(priceApplicationName.value != ""){
          message.value = priceApplicationName.value +'は申込済みです。申込してもよろしいですか？';
        }else if(priceApplication.value != ""){
          message.value = priceApplication.value +'申込してもよろしいですか？';
        }else{
          message.value = '申込してもよろしいですか？';
        }

        ElMessageBox.confirm(message.value,
          "研修",{
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: "OK",
            cancelButtonText: "キャンセル",
            type: "warning",
          }
          ).then(() => {
            applyList(res);
          }).catch(() => {
        });
      },
    });
    const applyList = ( list ) => {
      for(let i = 0;i<list.length;i++){
        list[i].name=list[i].name.text;
      }
      service.elearning.applys(list).then(res=>{
          ElMessageBox({
              cancelButtonClass: "btn-custom-cancel",
              type:'success',
              message: res,
              showClose: true,
              duration:3000
            })
      }).catch((error) => {
            
      });
      search();
    }

    const rules = ref({
      id: [
       halfNumEg2('研修ID は半角英数字で入力してください。'),
        {
          min: 2,
          max: 10,
          message: "研修ID は 2 文字以上で入力してください。",
        },
      ],
      trainName: [
        { 
            validator: (rule, value, callback) => {
                const validateData = { invalidStr: value, isUnicoe: true }
                checkInvalid(validateData, service, callback, '研修名')
            }
        },
        {
            max: 100,
            message: '研修名は100文字以内で入力してください。'
        },
      ]
    });
    const titles = ref([
      {
        field: "name",
        title: "研修名",
        order: "none",
      },
      {
        field: "priceApplication",
        title: "区分",
        label: true,
        order: "none",
      },
      {
        field: "id",
        title: "研修ID",
        order: "desc",
      },
      {
        field: "studyFlagTraShow",
        title: "学習形式",
        order: "none",
      },
      {
        field: "baseStudyTime",
        title: "標準学習時間",
        postfix: "時間",
        order: "none",
      },
      {
        field: "openStartAndEdnDtm",
        title: "研修期間",
        order: "none",
      },
    ]);
    const tableData = ref([]);

    const studyOptions = ref([
      {
        label: "eラーニング",
        value: "1",
      },
      {
        label: "通信講座",
        value: "2",
      },
      {
        label: "集合研修",
        value: "3",
      },{
        label: "教材配布",
        value: "4",
      },
    ]);
    const service = inject("service");

    function refresh() {
      fromWhere = "search"
      form.value.trainName = toFull(form.value.trainName);
      tableRef.value.clear();
    }

    let pagingInfo = {};
    let orders = {};
    function tableConfigInit(sorts, paging) {
      orders = sorts;
      pagingInfo = paging;
    }
    function tableChange(sorts, paging) {
      tableConfigInit(sorts, paging);
      search();
    }
    onMounted(()=>{
      document.title = "サイバックスUniv.(研修(自社研修))"
      if(props.isFromInfo == "0"){
        form.value = {};
        form.value.styleFlag = "3";
        form.value.trainName = "";
        formRef.value.clear();
        tableRef.value.clear();
      }else{
        form.value.styleFlag = "3";
        search()
      }
    })

    function getLable(list,value) {
      for(let i = 0;i<list.length;i++) {
          if(list[i].value == value) {
              return list[i].label;
          }
      }
    }

    function search() {
      formRef.value.validate(form.value).then((res) => {
        if (res) {
          service.elearning
            .search({ ...pagingInfo, ...form.value, ...orders, fromWhere  })
            .then((res) => {
              fromWhere = ""
              res.data.forEach((item) => {
                item.name = {
                  text: item.name,
                  trainId: item.id,
                  trainName: item.name,
                  loginRank: res.usrLoginRank,
                  dispaly37: res.dispaly37,
                  rank: item.rank,
                  trainFlg: item.showFlag,
                  userComment: item.userComment,
                  trainAppNum: item.trainAppNum,
                  priceApplication: item.priceApplication,
                  callBack: function (item) {
                    router.push({ name: 'oneself-info', params: {trainId:item.trainId, rank:item.rank, trainName:item.trainName, loginRank: res.usrLoginRank, jyukoufukaFlg: res.jyukoufukaFlg
                                    ,dispaly37: res.dispaly37,trainFlg:item.trainFlg,userComment:item.userComment,trainAppNum:item.trainAppNum, priceApplication: item.priceApplication}})
                  },
                };
                if(item.openStartDtm == null && item.openEndDtm == null) {
                    item.openStartAndEdnDtm = "";
                }                        
                if(item.openStartDtm != null && item.openEndDtm == null) {
                    item.openStartAndEdnDtm = dateFtt("auto_time",item.openStartDtm,"en");
                }
                if(item.openStartDtm != null && item.openEndDtm != null) {
                    item.openStartAndEdnDtm = dateFtt("auto_time",item.openStartDtm,"en") + "～" + dateFtt("auto_time",item.openEndDtm,"en");
                }
                item.studyFlagTraShow = getLable(res.studyFlagList,item.studyFlagTra);
                if(item.priceApplication == "有料申込済" || item.priceApplication == "申込済"){
                    item.disabled = true;
                }
              });
              dispaly37Value.value = res.dispaly37;
              jyukoufukaFlg.value = res.jyukoufukaFlg;
              tableTotal.value = res.count;
              tableData.value = res.data;
            });
        }
      });
    }

    function clear() {
      form.value = {};
      formRef.value.clear();
      form.value.styleFlag = "3";
      form.value.trainName = "";
      tableRef.value.clear(); 
    }

    return {
      getLable,
      clear,
      pagingButton,
      tableTotal,
      dispaly37Value,
      tableConfigInit,
      tableChange,
      search,
      rules,
      formRef,
      tableRef, 
      form,
      studyOptions,
      titles,
      tableData,
      refresh
    };
  },
};
</script>
<style scoped>
</style>