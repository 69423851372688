import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/info')
export default class UsrInfo extends BaseService{
    usrEdit(from) {
		return this.request({
            data: from,
			url: "/usrEdit",
			method: "POST"
		});
	}
	getUsrInfo() {
		return this.request({
			url: "/getUsrInfo",
			method: "POST"
		});
	}
	
	updatePassword(from) {
		return this.request({
			url: "/updatePassword",
			method: "POST",
			data: from
		});
	}
}