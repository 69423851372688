import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/inquire')
export default class UsrInquire extends BaseService {
	index() {
		return this.request({
			url: "/index",
			method: "POST"
		});
	}
    create(fromData, files) {
        var form = new FormData();
		if (files) {
			for (var i = 0; i < files.length; i++) {
				form.append("upload", files[i]);
			}
		} else {
			form.append("upload", null);
		}

     	form.append("reqDto", new Blob([JSON.stringify(fromData)], { type: "application/json" }));
		return this.request({
            data: form,
			url: "/create",
			method: "POST",
            Headers: {
                'Content-Type': 'multipart/form-data',
            }
		});
	}
	send() {
		return this.request({
			url: "/send",
			method: "POST",
		});
	}
}