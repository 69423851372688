<template>
  <div class="pasdeh-bac-div">
    <div class="main-wrap">
      <rsk-form labelWidth="50px" ref="formRef" :rules="rules">
        <div class="main-div">
          <div class="pasdeh-title-warp">
            <img
              src="../../assets/pagetitle.gif"
              alt="."
            />
            <div class="pasdeh-title">
              {{$t('pasdehPages.title1')}}
            </div>
            <div class="pasdeh-title-content" v-if="fromRismonFlag != '0'">
              <span>{{$t('pasdehPages.title2')}}</span>
              <span>{{$t('pasdehPages.title3')}}</span>
            </div>
            <div class="pasdeh-title-content" v-if="fromRismonFlag == '0'">
              <span>{{$t('pasdehPages.title4')}}</span>
            </div>
          </div>
          <div class="form-warp" :class="{'form-warp-flag' : fromRismonFlag != '0'}">
            <rsk-form-item
              isList
              prop="comId"
              v-if="sendData.comIdShowFlg != '1' && fromRismonFlag != '0'"
            >
              <rsk-input v-model="sendData.comId" max="15" :placeholder="$t('pasdehPages.memberId')"/>
            </rsk-form-item>
            <rsk-form-item
            isList
            prop="comId"
            hintText="必須"
            v-if="fromRismonFlag == '0'"
          >
            <rsk-input v-model="sendData.comId" :placeholder="$t('pasdehPages.memberId')"/>
            </rsk-form-item>
            <rsk-form-item
            isList
            prop="loginId"
            v-if="fromRismonFlag != '0'"
          >
            <rsk-input v-model="sendData.loginId" max="9" :placeholder="$t('pasdehPages.loginId')"/>
            </rsk-form-item>
            <rsk-form-item
            isList
            prop="loginId"
            v-if="fromRismonFlag == '0'"
          >
            <rsk-input v-model="sendData.loginId" max="9" :placeholder="$t('pasdehPages.loginId')"/>
            </rsk-form-item>
            <rsk-form-item
            isList
            prop="usrMlAdr"
            v-if="fromRismonFlag != '0'"
          >
            <rsk-input v-model="sendData.usrMlAdr" :placeholder="$t('pasdehPages.mailaddr')"/>
            </rsk-form-item>
            <rsk-form-item
            prop="usrMlAdr"
            isList
            hintText="必須"
            v-if="fromRismonFlag == '0'"
          >
            <rsk-input v-model="sendData.usrMlAdr" max="128" :placeholder="$t('pasdehPages.mailaddr')"/>
            </rsk-form-item>
          </div>
          <div class="pasdeh-submit-warp">
            <rsk-button :class="{'pasdeh-button-flag': fromRismonFlag == '0'}" isExpand type="primary" @click="send">{{
              $t("pasdehPages.sendmail")
            }}</rsk-button>
            <div class="pasdeh-link-item">
            <el-link type="primary" @click="loginback">
              <img
                  style="height: 20px"
                  src="./png/carret_left_user_primary.svg"/>
              {{$t("pasdehPages.loginback")}}
            </el-link>
          </div>
          </div>
          <div
            class="pasdeh-synopsis-warp"
            style="color: #676f79; margin-top: 30px"
          >
            <div v-if="fromRismonFlag != '0'" class="pasdeh-synopsis-flag">
              <span>{{ $t("pasdehPages.text1") }}</span>
              <span>{{ $t("pasdehPages.text2") }}</span>
              <span>{{ $t("pasdehPages.text3") }}</span>
            </div>
            <div style="text-align: center" v-if="fromRismonFlag == '0'">
              <span>{{ $t("pasdehPages.text4") }}</span>
            </div>
          </div>
        </div>
      </rsk-form>
    </div>
    
    <div class="footer-wrap">
      <rsk-login-footer  />
    </div>
  </div>
</template>

<script>
export { default } from "./js/pasdeh_view.js";
</script>

<style scoped lang="scss" src="./css/pasdeh.scss" />