<template>
    <div class="container">
        <div class="line">
            <slot />
        </div>
    </div>
</template>
<style scoped>
.line {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.container {
    padding-bottom: 16px;
}
</style>