<template>
  <div>
    <div class="rsk-panel rsk-mb-xl">
      <rsk-form :rules="rules" v-model="form" ref="formRef" cacheKey="publiccache">
        <rsk-form-item
          prop="id"
          label="研修ID"
          annotation="（完全一致）"
        >
          <rsk-input max="10" v-model="form.id" />
        </rsk-form-item>
        <rsk-form-item
          isExpand
          prop="trainName"
          label="研修名"
          annotation="（部分一致）"
        >
          <rsk-input max="100" v-model="form.trainName" isExpand />
        </rsk-form-item>
        <rsk-form-item prop="type" label="分類・階層">
          <div class="rsk-flex">
            <div class="rsk-form rsk-icon-wrap">
              <span class="rsk-icon rsk-icon--question-gray">
              <label class="rsk-tooltip rsk-tooltip--250 rsk-tooltip--bottom">
                <span>指定した分類・階層に関連する研修を検索します
                  <br>指定と異なる分類・階層の研修が表示されることがあります</span>
              </label>
              </span>
            </div>
            <rsk-select
              v-model="form.type"
              label="分類・階層"
              :options="typeOptions"
            />
          </div>
        </rsk-form-item>
        <rsk-form-item label="開催日" v-if="showDate">
          <div style="display: flex; align-items: center"  prop="openDtm">
            <div class="rsk-form rsk-mr-xs">
              <rsk-start-date v-model="form.openStartDtm" id="openStartDtm"/>
            </div>
            <div class="rsk-mr-xs">〜</div>
            <div class="rsk-form rsk-mr-xs">
              <rsk-end-date v-model="form.openEndDtm" id="openEndDtm"/>
            </div>
            
            <div class="rsk-ml-s" />
            <rsk-checkbox
              v-model="form.oldCheck"
              :options="[{ value: 1, label: '過去開催分も含む' }]"
            />
          </div>
          <div v-show="disErrFlagOpen" ><span style="color: red;font-size:12px">開催開始日 は正しい日付を入力してください。</span><br></div>
          <span v-show="disErrFlagEnd" style="color: red;font-size:12px">開催終了日 は正しい日付を入力してください。</span>
          <span v-show="disErrFlag" style="color: red;font-size:12px">開催日 の期間指定が正しくありません。</span>
        </rsk-form-item>
      </rsk-form>

      <rsk-form-submit>
        <rsk-button isExpand @click="refresh()" type="primary">検索</rsk-button>
        <rsk-button isExpand @click="clear" type="tertiary">クリア</rsk-button>
      </rsk-form-submit>
    </div>

    <rsk-table
      v-if="dispaly37Value"
      ref="tableRef"
      cacheKey="publiccache" 
      @init="tableConfigInit"
      :pagingButton="pagingButton"
      @pageInfoChange="tableChange"
      @sortChange="tableChange"
      :total="tableTotal"
      :dispaly37="dispaly37Value"
      checkbox
      :data="tableData"
      :titles="titles"
    />
    <rsk-table
      v-if="!dispaly37Value"
      ref="tableRef"
      cacheKey="publiccache" 
      @init="tableConfigInit"
      :pagingButton="pagingButton"
      @pageInfoChange="tableChange"
      @sortChange="tableChange"
      :total="tableTotal"
      :dispaly37="dispaly37Value"
      :data="tableData"
      :titles="titles"
    /> 
    <popTraComment ref="poptracommemtRefs" :fromWhere="5" :trainId="poptrainId" :usrId="popusrId" v-model="dialogFormVisible" @setUserCommentValue="getUserComment"></popTraComment>
  </div>
</template>
<script>
import { ref, inject, onMounted, reactive, nextTick } from "vue";
import { useRouter } from "vue-router";
import { ElMessageBox } from 'element-plus'
import input from '../../../../core/components/rsk/input.vue';
import { dateFtt } from "@/utils/common";
import { dateDtmCheck } from "@/utils/dateCheck"
import popTraComment from "../../../poptracommemt/index";
import { halfNumEg2 } from '@/utils/validateUtils';
import { toFull } from "@/utils/editnew";

export default {
  components: {
        popTraComment
  },
  props:{
    isFromInfo:{
            type:String,
            default: null
        },
  },
  setup(props) {
    const showDate = ref(true)
    
    function reload(){
      showDate.value = false
      nextTick(() => {
        showDate.value = true
      })
    }
    const router = useRouter();
    const tableTotal = ref(0);
    const dispaly37Value = ref();
    const jyukoufukaFlg = ref();
    const formRef = ref();
    const typeObject = ref();
    const priceApplicationName = ref("");
    const priceApplication = ref("");
    const message = ref("");
    const poptrainId = ref();
    const popusrId = ref();
    const userCommentValue = ref("");
    const dialogFormVisible = ref(false);
    const poptracommemtRefs = ref();
    const changeCommentIndex = ref(0);
    const tableRef = ref();
    const resPop = ref();
    const form = ref({
      styleFlag: "2",
      openStartDtm: '',
      openEndDtm:''

    });
    const disErrFlagOpen = ref(false);
    const disErrFlagEnd = ref(false);
    const disErrFlag = ref(false);
    let priceAppCount = 0;
    let fromWhere = "";
    const pagingButton = ref({
      text: "一括申し込み",
      callBack: (res) => {
        if(jyukoufukaFlg.value == "1"){
          alert("全体管理者または所属組織管理者のため、研修申込できません。");
          return;
        }
        
        if(res.length == 0) {
          ElMessageBox.alert('研修情報を選択してください。', '研修', {
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: 'OK',
            callback: () => {
            },
          })
          return;
        }

        resPop.value = res;
        let openpoptracommemt = false;
        let isPluralPrice = false;
        let isPluralPriceCount = 0;
        for (let i = 0; i < res.length; i++) {
            if(res[i].priceApplication == "有料" || res[i].priceApplication == "有料申込済"){
              isPluralPriceCount = isPluralPriceCount + 1;
            }
        }
        if (isPluralPriceCount >= 2) {
          isPluralPrice = true;
        } 

        let commentExistDiv12Count=0;
        for(let i = 0; i < res.length; i++){
          if(res[i].commentExistDiv==1||res[i].commentExistDiv==2){
            commentExistDiv12Count++;
          }
        }
        
        if(commentExistDiv12Count>=2){
          ElMessageBox.alert('コメント入力可能なWebセミナーが2件以上含まれています。お手数ですが、１件ずつお申込みください。', '研修', {
              cancelButtonClass: "btn-custom-cancel",
              confirmButtonText: 'OK',
              callback: () => {
              },
          })
          return;
        }

        for(let i = 0; i < res.length; i++){
          if(res[i].commentInputFlg != 1 && res[i].commentShowFlag == 0 && !isPluralPrice){
            if(res[i].commentPastFlag == 0) {
              alert("コメント入力期限を過ぎています。\nカスタマーセンターまでご連絡ください。");
              return;
            }
            poptrainId.value = res[i].id;
            popusrId.value = res[i].usrId;
            changeCommentIndex.value = i+1;
            openpoptracommemt = true;
            setTimeout(()=>{
                poptracommemtRefs.value.open();
            },0)
            break;
          }
        }

        if(!openpoptracommemt){
          priceApplicationName.value = "";
          priceApplication.value = "";
          for (let i = 0; i < res.length; i++) {
            if (res[i].priceApplication == "申込済") {
              priceApplicationName.value = priceApplicationName.value + "「" + res[i].name.text + "」";
            }else if(res[i].priceApplication == "有料" || res[i].priceApplication == "有料申込済"){
              priceAppCount = priceAppCount + 1;
              priceApplication.value = '有料の研修ですが、';
            }
          }

          if(priceApplication.value != "" && priceAppCount == 1){
            message.value = "有料の研修（定価" + formatMoney(res) + "円）のため課金が発生しますが、申込してよろしいでしょうか？";
            priceAppCount = 0;
          } else if (priceApplicationName.value != "" && priceAppCount <= 1) {
            message.value = priceApplicationName.value +'は申込済みです。申込してもよろしいですか？';
            priceAppCount = 0;
          } else if(priceApplication.value != "" && priceAppCount > 1){
            ElMessageBox.alert('有料の研修が複数選択されていますので、一括申し込みができません。有料の研修は、１コースのみ選択し、申込してください。', '研修', {
              cancelButtonClass: "btn-custom-cancel",
              confirmButtonText: 'OK',
              callback: () => {
              },
            })
            priceAppCount = 0;
            return;
          } else{
            message.value = '申込してもよろしいですか？';
            priceAppCount = 0;
          }

          ElMessageBox.confirm(message.value,
            "研修",{
              cancelButtonClass: "btn-custom-cancel",
              confirmButtonText: "OK",
              cancelButtonText: "キャンセル",
              type: "warning",
            }
            ).then(() => {
              if(changeCommentIndex.value > 0){
                if(userCommentValue.value != ""){
                  res[changeCommentIndex.value-1].userComment = userCommentValue.value;
                  res[changeCommentIndex.value-1].dialogShowFlag = 0;
                }
              }
              applyList(res);
            }).catch(() => {
          });
        }

        
      },
    });
    const applyList = ( list ) => {
      for(let i = 0;i<list.length;i++){
        list[i].name=list[i].name.text;
      }
      service.elearning.applys(list).then(res=>{
          ElMessageBox({
              cancelButtonClass: "btn-custom-cancel",
              type:'success',
              message: res,
              showClose: true,
              duration:3000
            })
      }).catch((error) => {
            
        });
        search();
    }
    const rules = ref({
      id: [
        halfNumEg2('研修ID は半角英数字で入力してください。'),
        {
          min: 2,
          max: 10,
          message: "研修ID は 2 文字以上で入力してください。",
        },
      ],
      openDtm:[
          {
            validator: (rule, value, callback) => {
                let res = dateDtmCheck("openStartDtm","openEndDtm",form.value.openStartDtm,form.value.openEndDtm)
                disErrFlag.value = res.disErrFlag;
                disErrFlagOpen.value = res.disErrFlagOpen;
                disErrFlagEnd.value = res.disErrFlagEnd;
                if(res.disErrFlag == true || res.disErrFlagOpen == true || res.disErrFlagEnd == true) {
                  callback(new Error())
                }else {
                  callback()
              }
            }
          }         
      ],
    });
    const typeOptions = ref();
    const titles = ref([
      {
        field: "name",
        title: "研修名",
        order: "none",
      },
      {
        field: "priceApplication",
        title: "区分",
        label: true,
        order: "none",
      },
      {
        field: "id",
        title: "研修ID",
        order: "desc",
      },
      {
        field: "traintType",
        title: "分類・階層",
        order: "none",
      },
      {
        field: "openStartAndEdnDtm",
        title: "開催日時",
        order: "none",
      }
    ]);
    const tableData = ref([]);
    
    const specificationOptions = ref([
      {
        label: "通常コース",
        value: "1",
      },
      {
        label: "分割コース",
        value: "2",
      },
      {
        label: "オリジナルeラーニング",
        value: "3",
      },
    ]);
    const service = inject("service");

    let pagingInfo = {};
    let orders = {};
    function tableConfigInit(sorts, paging) {
      orders = sorts;
      pagingInfo = paging;
    }
    function tableChange(sorts, paging) {
      tableConfigInit(sorts, paging);
      search();
    }
    onMounted(()=>{
      document.title = "サイバックスUniv.(研修(Webセミナー))"
      service.common.typeObject().then((res) => {
        typeOptions.value = res;
      }).catch((error) => {
          console.log(error);
      });
      if(props.isFromInfo == "0"){
        form.value = {};
        form.value.styleFlag = "2";
        form.value.openStartDtm = '';
        form.value.openEndDtm = '';
        form.value.trainName = '';
        formRef.value.clear();
        tableRef.value.clear();
      }else{
        form.value.styleFlag = "2";
        search()
      }

    })

    function search() {
      formRef.value.validate(form.value).then((res) => {
        if (res) {
          service.elearning
            .search({ ...pagingInfo, ...form.value, ...orders, fromWhere })
            .then((res) => {
              fromWhere = ""
              res.data.forEach((item) => {
                item.name = {
                  text: item.name,
                  trainId: item.id,
                  trainName: item.name,
                  loginRank: res.usrLoginRank,
                  dispaly37: res.dispaly37,
                  rank: item.rank,
                  trainFlg: item.showFlag,
                  userComment: item.userComment,
                  trainAppNum: item.trainAppNum,
                  priceApplication: item.priceApplication,
                  oldFlg: item.oldFlg,
                  callBack: function (item) {
                    router.push({ name: 'public-info', params: {trainId:item.trainId, rank:item.rank, trainName:item.trainName, loginRank: res.usrLoginRank, jyukoufukaFlg: res.jyukoufukaFlg
                                    ,dispaly37: res.dispaly37,trainFlg:item.trainFlg,userComment:item.userComment,trainAppNum:item.trainAppNum, priceApplication: item.priceApplication, oldFlg: item.oldFlg}})
                  },
                };
                item.traintType = getLable(item.typeObject);
                if(item.openStartDtm == null && item.openEndDtm == null) {
                    item.openStartAndEdnDtm = "";
                }                        
                if(item.openStartDtm != null && item.openEndDtm == null) {
                    item.openStartAndEdnDtm = dateFtt("auto_time",item.openStartDtm,"en");
                }
                if(item.openStartDtm != null && item.openEndDtm != null) {
                    item.openStartAndEdnDtm = dateFtt("auto_time",item.openStartDtm,"en") + "～" + dateFtt("auto_time",item.openEndDtm,"en");
                }
                if(item.priceApplication == "有料申込済" || item.priceApplication == "申込済"){
                    item.disabled = true;
                }
              });
              dispaly37Value.value = res.dispaly37;
              jyukoufukaFlg.value = res.jyukoufukaFlg;
              tableTotal.value = res.count;
              tableData.value = res.data;
            });
        }
      });
    }

    function refresh() {
      fromWhere = "search"
      form.value.trainName = toFull(form.value.trainName)
      tableRef.value.clear();
    }

    function clear() {
      form.value = {};
      formRef.value.clear();
      reload();
      form.value.styleFlag = "2";
      tableRef.value.clear(); 
      form.value.openStartDtm = '';
      form.value.openEndDtm = '';
      form.value.trainName = '';
      disErrFlagOpen.value = false;
      disErrFlagEnd.value = false;
      disErrFlag.value = false;
    }

    function getLable(value) {
        for(let i = 0;i<typeOptions.value.length;i++) {
            if(value == typeOptions.value[i].value) {
                return typeOptions.value[i].label;
            }
        }
    }

    function formatMoney(data) {
      var target;
      if (data.length > 1) {
        for (let i = 0; i < data.length; i++) {
          if (data[i].priceApplication == "有料" || data[i].priceApplication == "有料申込済") {
            target = data[i];
          }       
        }
      } else {
        target = data[0];
      }
      if(target.fixedPrice == '' || target.fixedPrice == null) {
        return 0;
      } else {
          return (
            (String(target.fixedPrice).split(",").join("") || 0)
              .toString()
              .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
          );
      }
    }
    
    const getUserComment = function(changeUserComment) {
        priceAppCount = 0
        userCommentValue.value = changeUserComment;
        priceApplicationName.value = "";
        priceApplication.value = "";
        for (let i = 0; i < resPop.value.length; i++) {
          if (resPop.value[i].priceApplication == "申込済") {
            priceApplicationName.value = priceApplicationName.value + "「" + resPop.value[i].name.text + "」";
          }else if(resPop.value[i].priceApplication == "有料" || resPop.value[i].priceApplication == "有料申込済"){
            priceAppCount = priceAppCount + 1
            priceApplication.value = '有料の研修ですが、';
          }
        }
        
        if(priceApplication.value != "" && priceAppCount == 1){
          message.value = "有料の研修（定価" + formatMoney(resPop.value) + "円）のため課金が発生しますが、申込してよろしいでしょうか？";
          priceAppCount = 0;
        } else if (priceApplicationName.value != "" && priceAppCount <= 1) {
          message.value = priceApplicationName.value +'は申込済みです。申込してもよろしいですか？';
          priceAppCount = 0;
        } else if(priceApplication.value != "" && priceAppCount > 1){
          ElMessageBox.alert('有料の研修が複数選択されていますので、一括申し込みができません。有料の研修は、１コースのみ選択し、申込してください。', '研修', {
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: 'OK',
            callback: () => {
            },
          })
          priceAppCount = 0;
          return;
        } else{
          message.value = '申込してもよろしいですか？';
          priceAppCount = 0;
        }

        ElMessageBox.confirm(message.value,
          "研修",{
            cancelButtonClass: "btn-custom-cancel",
            confirmButtonText: "OK",
            cancelButtonText: "キャンセル",
            type: "warning",
          }
          ).then(() => {
            if(changeCommentIndex.value > 0){
              if(userCommentValue.value != ""){
                resPop.value[changeCommentIndex.value-1].userComment = userCommentValue.value;
                resPop.value[changeCommentIndex.value-1].dialogShowFlag = 0;
              }
            }
            applyList(resPop.value);
          }).catch(() => {
        });
    }

    return {
      showDate,
      clear,
      getLable,
      pagingButton,
      tableTotal,
      dispaly37Value,
      tableConfigInit,
      tableChange,
      search,
      rules,
      formRef,
      tableRef, 
      poptrainId,
      popusrId,
      dialogFormVisible,
      poptracommemtRefs,
      getUserComment,
      form,
      specificationOptions,
      titles,
      tableData,
      typeOptions,
      refresh,
      disErrFlagOpen,
      disErrFlagEnd,
      disErrFlag,
      formatMoney,
    };
  },
};
</script>
<style scoped>
</style>