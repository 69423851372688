import { inject, ref, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
export default {

    setup() {
        const router = useRouter();
        const route = useRoute();
        const service = inject("service");
        var model = ref({
            comNm: '',
            appDepNmPost: '',
            appUsrNm: '',
            appUsrMlAdr: '',
            appUsrTel: '',
            causeWayContent: '',
            cause: ''   
        });
        const formRef = ref();

        function editNew() {
            router.push({ name: 'ope_com_editnew' })
        }

        function show() {
            ElMessageBox.confirm('登録してもよろしいですか？',
                "", {
                cancelButtonClass: "btn-custom-cancel",
                confirmButtonText: "OK",
                cancelButtonText: "キャンセル",
                type: "warning",
            }
            ).then(() => {
                service.opecom.opecom.show(model.value).then(res => {
                    if (localStorage.getItem('formCondition') != null) {
                        localStorage.removeItem('formCondition')
                    }
                    if (sessionStorage.getItem('ope_com_model')) {
                        sessionStorage.removeItem('ope_com_model');
                    }
                    router.push({ name: 'ope_com_finish', params: { corporateName: model.value.comNm } })
                }).catch(error => {
                    console.log(error)
                })
            }).catch(() => {
            });
        }

        onMounted(() => {
            if (null != route.params.model || undefined != route.params.model) {
                model.value = JSON.parse(route.params.model)
                sessionStorage.setItem('ope_com_model', route.params.model)
            } else {
                if (sessionStorage.getItem('ope_com_model')) {
                    model.value = JSON.parse(sessionStorage.getItem('ope_com_model'))
                } else {
                    router.push({path:'/404'});
                }
            }
        });



        return {
            formRef,
            model,
            editNew,
            show,
        }
    }
}