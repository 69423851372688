import CryptoJS from 'crypto-js'

const privateKey = process.env.VUE_APP_CRY_PRIVATE_KEY;

export const cryptoEffect = () => {
    /**
 * 加密
 */
    const encrypt = (word) => {
        var key = CryptoJS.enc.Utf8.parse(privateKey);
        var srcs = CryptoJS.enc.Utf8.parse(word);
        var encrypted = CryptoJS.AES.encrypt(srcs, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return encrypted.toString();
    }
    /**
     * 解密
     */
    const decrypt = (word) => {
        var key = CryptoJS.enc.Utf8.parse(privateKey);
        var decrypt = CryptoJS.AES.decrypt(word, key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        });
        return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }
    return {encrypt, decrypt}
} 