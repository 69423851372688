<template>
    <div>
        <div class="rsk-card rsk-mb-s" v-for="(item, index) in list" :key="index">
          <div class="rsk-flex rsk-justify-between rsk-items-center">
            <div>
              <div v-if="goWhere === 'tra'" class="rsk-text-gray080 rsk-mb-xs">
                <span class="rsk-mr-xxs rsk-font-size-xs">{{item.dateTitle}}：{{item.openStartDtm}} ～ {{item.openStartDtm}}</span>
              </div>
              <div v-else-if="goWhere === 'tes' || goWhere === 'ant' || goWhere === 'rep'" class="rsk-text-gray080 rsk-mb-xs">
                <span class="rsk-mr-xxs rsk-font-size-xs">{{item.dateTitle}}：{{item.limitDate}}</span>
              </div>
              <div v-if="goWhere === 'elg'" class="rsk-text-gray080 rsk-mb-xs" >
                <span class="rsk-mr-xxs rsk-font-size-xs">{{item.startDateTitle}}：{{item.startDate}}</span>
                <span class="rsk-font-size-xs">{{item.endDateTitle}}：{{item.endDate}}</span>
              </div>
              <div class="rsk-flex">
                <div class="rsk-text-gray080 rsk-font-size-xs rsk-mr-xs" style="min-width: 100px">{{item.title}}</div>
                <div v-if="goWhere === 'elg'">
                  <el-button class="rsk-text-primary text-btn" type="text"
                  @click="goElg(item.elearningId, item.lessonId, item.applicationDtm, item.preconditionFlg, item.termOutside, item.nonScormType)"
                  v-if="item.studyFlg == 1">
                    {{item.describe}}
                  </el-button>
                  <span v-else>{{item.describe}}</span>
                </div>
                <div v-else-if="goWhere === 'elgQst'">
                  <el-button class="rsk-text-primary text-btn" type="text"
                  @click="goElgQst(item.url)" >
                    {{item.describe}}
                  </el-button>
                </div>
                <div class="rsk-text-primary" v-else>
                  <el-button type="text" v-if="goWhere === 'tes'" @click="goTes(item.testId, item.lessonId, item.preconditionFlg, item.answerFlg)" class="rsk-text-primary text-btn">{{item.describe}}</el-button>
                  <el-button type="text" v-else-if="goWhere === 'ant'" @click="goAnt(item.anketoId, item.lessonId, item.preconditionFlg)" class="rsk-text-primary text-btn">{{item.describe}}</el-button>
                  <el-button type="text" v-else-if="goWhere === 'rep'" @click="goRep(item.reportId, item.lessonId, item.preconditionFlg)" class="rsk-text-primary text-btn">{{item.describe}}</el-button>
                  <el-button type="text" v-else-if="goWhere === 'les'" @click="goLes(item.lessonId)" class="rsk-text-primary text-btn">{{item.describe}}</el-button>
                  <el-button type="text" v-else-if="goWhere === 'tra'" @click="goTra(item.trainId)" class="rsk-text-primary text-btn">{{item.describe}}</el-button>
                  <el-button type="text" v-else class="rsk-text-primary text-btn">{{item.describe}}</el-button>
                </div>
              </div>
            </div>
            <div v-if="item.count" class="rsk-flex rsk-items-center">
              <div class="rsk-text-primary rsk-mr-xxs">{{item.count}}%</div>
              <div class="rsk-round-meter rsk-round-meter--s rsk-mr-m">
                <div :style="'width: '+ item.count +'%'" class="rsk-round-meter__value"></div>
              </div>
              <span class="rsk-icon rsk-icon--carret-right-user" v-if="goWhere === 'elg'"
              @click="goElg(item.elearningId, item.lessonId, item.applicationDtm, item.preconditionFlg, item.termOutside, item.nonScormType)"></span>
              <span class="rsk-icon rsk-icon--carret-right-user" v-else-if="goWhere === 'tes'"
              @click="goTes(item.testId, item.lessonId, item.preconditionFlg, item.answerFlg)"></span>
              <span class="rsk-icon rsk-icon--carret-right-user" v-else-if="goWhere === 'ant'"
              @click="goAnt(item.anketoId, item.lessonId, item.preconditionFlg)"></span>
              <span class="rsk-icon rsk-icon--carret-right-user" v-else-if="goWhere === 'rep'"
              @click="goRep(item.reportId, item.lessonId, item.preconditionFlg)"></span>
              <span class="rsk-icon rsk-icon--carret-right-user" v-else-if="goWhere === 'les'"
              @click="goLes(item.lessonId)"></span>
              <span class="rsk-icon rsk-icon--carret-right-user" v-else></span>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
import { ref, inject } from 'vue';
import { useRouter } from 'vue-router'
import { dateFtt } from "@/utils/common";
export default {
    props: {
        /**
         * {
         *  startDate,
         *  endDate,
         *  title,
         *  describe,
         *  count
         *  id
         * }
         */
        list:null,
        goWhere: null
    },
    setup(props) {
        let selectValue = ref('');
        const router = useRouter();
        const service = inject("service");

        function goElg(elearningId, lessonId, applicationDtm, flg, termOutside, nonScormType) {
          if (flg == 'false') {
              alert("「学習順」の前の学習が終了しておりませんので、終了後、再度お試しください。");
          } else {
              if (nonScormType == 1) {
                let applicationDtmStr =  dateFtt("auto",applicationDtm,"en").replace(new RegExp("/","gm"),"")
                let route = router.resolve({ name: 'popElgmov',query:{elearningId:elearningId,lessonId:lessonId,applicationDtm:applicationDtmStr}})
		            window.open(route.href, 'popElgmov', 'top=50,left=200,width=800,height=600')
              } else {
                if (lessonId == '' || lessonId == null) {
                    lessonId = '9999999999';
                }
                let cd = '';
                let iv = '';
                let sso_url = '';
                let applicationDtmStr =  dateFtt("auto",applicationDtm,"en").replace(new RegExp("/","gm"),"")
                const data = {
                  'elearningId': elearningId,
                  'lessonId': lessonId,
                  'applicationDtm': applicationDtmStr,
                  'termOutside': termOutside
                }
                service.sso.setSso(data).then((res) => {
                  let model = res;
                  if (model.statusNum == '0') {
                          sso_url = model.elearningURL;
                          cd = model.cd;
                          iv = model.iv;
                          let form = document.createElement('form')
                          form.action = sso_url;
                          form.method = 'POST'
                          form.target = 'Cybax_lms_lesson'
                          document.body.append(form);

                          let cdhidden = document.createElement('input')
                          cdhidden.type = 'hidden'
                          cdhidden.name = 'cd'
                          cdhidden.value = cd
                          form.appendChild(cdhidden);

                          let ivhidden = document.createElement('input')
                          ivhidden.type = 'hidden'
                          ivhidden.name = 'iv'
                          ivhidden.value = iv;
                          form.appendChild(ivhidden);

                          form.submit();
                          form.remove();
                  } else {
                      alert(model.statusMessage);
                  }
                  }).catch((error) => {
                  console.log(error);
                  alert('セッションタイムアウトしました。ログインし直してください。');
                  router.push({ path: '/login' })
                });
              }
          }
        }
        function goTes(testId, lessonId, flg, answerFlg) {
          if (flg == 'false') {
              alert("「学習順」の前の学習が終了しておりませんので、終了後、再度お試しください。");
          } else {
            if (answerFlg == 'false') {
              alert("実際問題数は不足なので、問題作成後、再度お試しください。");
            } else {
              let id = testId + "-" + lessonId;
              router.push({
                name: 'testAnswer',
                params: {
                    id : id,
                    fromUrl: 'usr_top_children'
                }
              })
            }
          }
        }
        function goAnt(anketoId, lessonId, flg) {
          if (flg == 'false') {
              alert("「学習順」の前の学習が終了しておりませんので、終了後、再度お試しください。");
          } else {
            let id = anketoId + "-" + lessonId;
            router.push({
              name: 'anketoAnswer',
              params: {
                  id : id,
                  fromUrl: 'usr_top_children'
              }
            })
          }
        }
        function goRep(reportId, lessonId, flg) {
          if (flg == 'false') {
              alert("「学習順」の前の学習が終了しておりませんので、終了後、再度お試しください。");
          } else {
            let id = reportId + "-" + lessonId;
            router.push({
              name: 'reportSend',
              params: {
                  id : id,
                  fromUrl: 'usr_top_children'
              }
            })
          }
        }
        function goLes(lessonId) {
          router.push({
            name: 'lesDetail',
            params: {
                lessonId : lessonId,
                fromUrl: 'usr_top_children'
            }
          })
        }
        function goTra(trainId) {
          router.push({
            name: 'trastudyShow',
            params: {
                trainId : trainId,
                fromUrl: 'usr_top_children'
            }
          })
        }
        // 受講後アンケート（未回答）の研修名を押下した場合
        function goElgQst(url) {
          window.open(url)
        }
        window.reConnect = (content) => {
          updateElgmov(content)
        }
        window.getElgQstMsg = () => {
          return "windowOpen"
        }
        function updateElgmov(content) {
          service.popelgmov.update(content).then((res) => {
          })
        }
        return {
            selectValue,
            ...props,
            goElg,
            goTes,
            goAnt,
            goRep,
            goLes,
            goTra,
            goElgQst
        }
    },
}
</script>
<style>
.rsk-select .el-input__inner {
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #A8B0BB;
    color: #30353B;
}
</style>
