import { Service, BaseService } from "@/core/auto";

@Service('/api/usr/tes')
export default class Test extends BaseService{
    search(data) {
		return this.request({
            data: {
				'condition' : data
			},
			url: "/search",
			method: "POST"
		});
	}
	answerConfirm(data) {
		return this.request({
			data: data,
			url: "/answerConfirm",
			method: "POST"
		});
	}

	getTestConfirm(id, tempList) {
		const data = {
			'id': id,
			'questionList': tempList
		}
		return this.request({
			data: data,
			url: "/confirm",
			method: "POST"
		});
	}

	getTestAnswer(id, startTime, list, correctIndex) {
		const data = {
			'id': id,
			'startTimeInMillis': startTime,
			'questionList': list,
			'correctIndex': correctIndex
		}
		return this.request({
			data: data,
			url: "/answer",
			method: "POST"
		});
	}

	getCpKey() {
		return this.request({
			url: "/cryptoKey",
			method: "GET"
		});
	}
}