<template>
    <div class="content">
        <div class="rsk-p-xl">
            <div class="rsk-form-control">
                <h2 class="rsk-page-title rsk-mb-s">レポート提出</h2>
            </div>
            <div class="rsk-panel rsk-mb-m">
                <rsk-form v-model="model" :rules="rules" ref="formRef">
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">レポート名</div>
                    </div>
                    <div v-text="model.reportName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">ボックス名</div>
                    </div>
                    <div v-text="model.lessonName" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">添付ファイル1</div>
                    </div>
                    <div class="rsk-form rsk-form--expand rsk-text-gray060">
                        <a href="#" @click="downloadFile(model.reportFileName3, model.reportFileDir3)" style="text-decoration:underline;"
                           v-text="model.reportFileName3" v-if="model.reportFileName3 != '' || model.reportFileName3 != null" />
                    </div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">添付ファイル2</div>
                    </div>
                    <div class="rsk-form rsk-form--expand rsk-text-gray060">
                        <a href="#" @click="downloadFile(model.reportFileName4, model.reportFileDir4)" style="text-decoration:underline;" 
                           v-text="model.reportFileName4" v-if="model.reportFileName4 != '' || model.reportFileName4 != null"
                        />
                    </div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top my-flex">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">内容</div>
                    </div>
                    <div v-text="model.unitDetail" class="rsk-form rsk-form--expand rsk-text-gray060 rsk-pre-wrap"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top my-flex">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label input-width">提出コメント</div>
                    </div>
                    <div class="rsk-form rsk-form--expand">
                        <rsk-form-item isExpand flexGrow="1" prop="sendDetail">
                            <div class="rsk-text-gray060 rsk-font-xs rsk-text-right rsk-textarea">{{model.sendDetail ? model.sendDetail.length : 0}}/1000</div>
                            <textarea placeholder="(1000文字以内で入力してください)" maxlength="1000" class="rsk-input rsk-input--expand rsk-textarea__input " v-model="model.sendDetail"></textarea>
                        </rsk-form-item>
                    </div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">提出ファイル1</div>
                    </div>
                    <div class="control-none">
                        <rsk-form-item prop="upload1" formControlHide>
                            <div>
                                <div v-if="model.reportFileName1 != '' || model.reportFileName1 != null">
                                    <a href="#" @click="downloadFile(model.reportFileName1, model.reportFileDir1)" style="text-decoration:underline;">
                                        {{model.reportFileName1}}
                                    </a>
                                </div>
                                <div class="rsk-flex rsk-items-center rsk-mb-xxs">
                                    <div class="rsk-checkbox-container">
                                        <label for="upload1" class="rsk-button rsk-button--user-primary rsk-button--medium">
                                            <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                                                <span class="rsk-icon rsk-icon--add-white"></span>
                                            </span>
                                            ファイルを選択
                                        </label>
                                        <input @change="getFile1($event)" id="upload1" type="file" name="upload1" class="rsk-toggle__input" />
                                    </div>
                                    <span class="rsk-text-gray060 rsk-ellipsis rsk-width--250" v-text="uploadText1"></span>
                                    <div class="rsk-form-control__annotation">最大2ファイル。20MBまで</div>
                                </div>
                                <div class="rsk-flex rsk-justify-between" v-if="model.fileNameCheck == 1">
                                    <div class="rsk-checkbox-container">
                                        <input class="rsk-checkbox" type="checkbox" id="model_fileCheck1" v-model="model.reportFileName1Check" value="true">
                                        <label class="rsk-checkbox__image" for="model_fileCheck1"></label>
                                        <label for="model_fileCheck1" class="rsk-checkbox__label rsk-text-gray060">
                                            チェックオンの場合、上記添付ファイル1を削除します。
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </rsk-form-item>
                    </div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">提出ファイル2</div>
                    </div>
                    <div class="control-none">
                        <rsk-form-item prop="upload2" >
                            <div>
                                <div v-if="model.reportFileName2 != '' || model.reportFileName2 != null">
                                    <a href="#" @click="downloadFile(model.reportFileName2, model.reportFileDir2)" style="text-decoration:underline;">
                                        {{model.reportFileName2}}
                                    </a>
                                </div>
                                <div class="rsk-flex rsk-items-center rsk-mb-xxs">
                                    <div class="rsk-checkbox-container">
                                        <label for="upload2" class="rsk-button rsk-button--user-primary rsk-button--medium">
                                            <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                                                <span class="rsk-icon rsk-icon--add-white"></span>
                                            </span>
                                            ファイルを選択
                                        </label>
                                        <input id="upload2" @change="getFile2($event)" type="file" name="upload2" class="rsk-toggle__input" />
                                    </div>
                                    <span class="rsk-text-gray060 rsk-ellipsis rsk-width--250" v-text="uploadText2"></span>
                                    <div class="rsk-form-control__annotation">最大2ファイル。20MBまで</div>
                                </div>
                                <div class="rsk-flex rsk-justify-between" v-if="model.fileName2Check == 1">
                                    <div class="rsk-checkbox-container">
                                        <input class="rsk-checkbox" type="checkbox" id="model_fileCheck2" v-model="model.reportFileName2Check" value="true">
                                        <label class="rsk-checkbox__image" for="model_fileCheck2"></label>
                                        <label for="model_fileCheck2" class="rsk-checkbox__label rsk-text-gray060">
                                            チェックオンの場合、上記添付ファイル2を削除します。
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </rsk-form-item>
                    </div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">評価状態</div>
                    </div>
                    <div v-text="model.unitLearningProgressStr" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">点数</div>
                    </div>
                    <div v-text="model.userReportScore" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                <div class="rsk-form-control rsk-form-control--top">
                    <div class="rsk-flex rsk-justify-between">
                        <div class="rsk-form-control__label text-width">評価コメント</div>
                    </div>
                    <div v-text="model.reportEvalDetail" class="rsk-form rsk-form--expand rsk-text-gray060"></div>
                </div>                                                                                                
                <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                </rsk-form>                                                                                  
            </div>
            <div class="rsk-p-s">
                <button @click="index" class="rsk-button rsk-button--text-user-primary">
                    <span class="rsk-button__icon-wrapper rsk-button__icon-wrapper--with-text">
                        <span class="rsk-icon rsk-carret-left-admin-primary"></span>
                    </span>
                    戻る
                </button> 
            </div>
            <rsk-form-submit class="rsk-no-border">
                <rsk-button isExpand @click="send" type="primary">提出</rsk-button>
            </rsk-form-submit>
        </div>
    </div>
</template>
<script>
import { ref, onMounted, inject, onBeforeUnmount } from 'vue';
import { useRoute,useRouter } from 'vue-router'
import { dateFtt } from '@/utils/common'
import { checkInvalid } from '@/utils/validate'
import { full } from "@/utils/validateUtils";
import { ElMessageBox, ElMessage } from 'element-plus'
import { useStore } from 'vuex'
export default {
    setup() {
        const model = ref({});
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        let id = "";
        let goUrl = "rep"
        onMounted(() => {
            if(typeof(route.params.id) != "undefined") {
                id = route.params.id;
                localStorage.reportLessonId = id; 
            } else {
                id = localStorage.reportLessonId
            }
            if(!id) {
                 router.push({ name: 'rep'});
                 return;
            }
            store.commit('setCourseId', id);

            if(typeof(route.params.fromUrl) != "undefined") {
                goUrl = route.params.fromUrl;
                localStorage.fromLesUrl = goUrl;
            } else if(localStorage.fromLesUrl) {
                goUrl = localStorage.fromLesUrl
            }
            
            getReportSendModel()
        })

        function setCourseId() {
            localStorage.reportLessonId = store?.getters?.getCourseId; 
        }

        window.addEventListener('beforeunload', setCourseId);

        onBeforeUnmount(() => {
            localStorage.removeItem("fromLesUrl");
            localStorage.removeItem("reportLessonId");
            window.removeEventListener('beforeunload', setCourseId)
        })

        const service = inject("service");
        function getReportSendModel() {
            service.report.getReportSend(store?.getters?.getCourseId).then(res => {
                model.value = res.model
                model.value.submitBeginTm = dateFtt('yyyy/MM/dd hh:mm', model.value.submitBeginTm);
            })
        }

        const formRef = ref()
        const rules =  ref(
            {
                sendDetail: [
                    full('提出コメントは全角で入力してください'),
                    { 
                        validator: (rule, value, callback) => {
                            const validateData = { invalidStr: value, isUnicoe: true }
                            checkInvalid(validateData, service, callback, '提出コメント名')
                        }
                    },
                    {
                        max: 1000,
                        message: '提出コメントは1000文字以内で入力してください。'
                    },
                ],
                upload1: [
                    { 
                        validator: (rule, value, callback) => {
                            checkFileType(callback, model.value.reportFileName1Check, model.value.upload1);
                        } 
                    },
                ],
                upload2: [
                    { 
                        validator: (rule, value, callback) => {
                            checkFileType(callback, model.value.reportFileName2Check, model.value.upload2);
                        } 
                    },
                ],
            }
        )

        function getFileType(fileName) {
            var startIndex = fileName.lastIndexOf(".");
            if(startIndex != -1)
                return fileName.substring(startIndex+1, fileName.length).toLowerCase();
            else return "";
        }

        const checkFileType = (callback, fileCheck, upload) => {
            if(fileCheck == true){
                callback() 
                return
            } 
            if(upload != null) {
                let fileMaxSize = 20971520;
                let type = getFileType(upload.name)

                if(upload.size == 0) {
                    callback(new Error('添付ファイルは空ファイルです。'))
                }

                if((type == "exe" || type == "do" || type == "pl" || type =="sh" || type =="jsp" || type =="html")) {
                    callback(new Error('添付ファイルが許可されていないファイル形式です。'))
                }

                if(upload.size > fileMaxSize) {
                    callback(new Error('添付ファイルのサイズは20MB以内にしてください。'))
                }
                callback()
            } else {
                callback()
            }
        }
        
        function send(){
            ElMessageBox.confirm(
                '提出してもよろしいですか？',
                {
                cancelButtonClass: "btn-custom-cancel" ,
                confirmButtonText: 'OK',
                cancelButtonText: 'キャンセル',  
                type: 'warning',
                }
            )
            .then(() => {
                formRef.value.validate(model.value).then((resp) => {
                    if (resp) {
                        service.report.update(model.value, model.value.upload1, model.value.upload2).then(res => {
                            
                            index();
                            ElMessage({
                                type:'success',
                                message: res,
                                showClose: true,
                                duration:3000
                            })        
                        }).catch(err => {
                            console.log(err);
                        }) 
                    }
                })
            })
            .catch(() => {

            })        
        }

        let uploadText1 = ref();
        function getFile1(e) {
            if (e.target.files[0]) {
                uploadText1.value = '選択済みファイル：' + e.target.files[0].name
            } else {
                uploadText1.value = ''
            }
            model.value.upload1 = e.target.files[0]
        }

        let uploadText2 = ref();
        function getFile2(e) {
            if (e.target.files[0]) {
                uploadText2.value = '選択済みファイル：' + e.target.files[0].name
            } else {
                uploadText2.value = ''
            }
            model.value.upload2 = e.target.files[0]
        }

        function downloadFile (filename, fileurl){
            const downloadData = {filename:'', fileurl:'', userAgent:''}
            downloadData.filename = filename
            downloadData.fileurl = fileurl
            service.common.downloadFile(downloadData)
        }

        function index() {
            router.push({ 
                name: goUrl,
                params: {
                    lessonId: model.value.lessonId
                }
            });   
        }

        return {
            model,
            formRef,
            index,
            send,
            getFile1,
            getFile2,
            downloadFile,
            uploadText1,
            uploadText2,
            rules
        }
    }
}
</script>

<style lang="scss" scoped>
.rsk-textarea__input {
    height: 200px;
    display: flex;
    justify-content: flex-end;
    max-width: 1313px;
}
.rsk-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.rsk-width--250 {
    max-width: 250px;
}
.rsk-no-border {
    border: none;
}

.my-flex{
    display: flex;
    align-items: center;
}
.text-width{
    width: 450px !important;
}
.input-width{
    width: 254px;
}
.my-margin{
    margin-left: 238px;
}
.control-none .rsk-form-control {
       display: initial;
}
</style>
