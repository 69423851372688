import { useRouter } from 'vue-router'
import { inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import SIdentify from "../../../components/verifty.vue";
import { full, phone } from '@/utils/validateUtils'
import { checkInvalid, validEmail } from '@/utils/validate'

export default {
    components: {
        SIdentify,
    },
    setup() {
        const model = ref({ checkCode: '' })
        const loading = false;
        const verificationCode = "";//输入验证码
        const identifyCode = ref("");//获取验证码
        const isRefreshCode = ref(false);
        const formRef = ref();
        const router = useRouter()
        const service = inject("service");
        const { locale, t } = useI18n({ useScope: 'global' })
        locale.value = localStorage.getItem('lang')

        // 获取验证码
        function getSidentifyCode(data) {
            identifyCode.value = data;
            isRefreshCode.value = false;
        };
        // 验证码验证
        function handelVerfity() {
            if (model.value.checkCode == identifyCode.value) {
                return true;
            } else {
                return false;
            }
        };

        // 点击切换验证码
        function refreshCode() {
            identifyCode.value = "";
            isRefreshCode.value = true;   //更改状态--SIdentify组件中监听该状态
        };

        const rules = ref(
            {
                checkCode: [
                    { required: true, message: '新パスワードを入力してください。' },
                    {
                        validator: (rule, value, callback) => {
                            if (value == "") {
                                callback(new Error('文字認証 を入力してください。'));
                            } else if (!handelVerfity()) {
                                callback(new Error('入力された文字認証が一致しません。'));
                            } else {
                                callback();
                            }
                        }
                    }
                ],
                appUsrMlAdr: [
                    { required: true, message: t('メールアドレス を入力してください。') },
                    {
                        validator: (rule, value, callback) => {
                            if (value) {
                                if(!(/^(.+)@(.+)$/.test(value))){
                                    callback(new Error(t('メールアドレスはEmail の形式(例 XXXXXXX@XX.com)で入力してください。')));
                                } else {
                                    const validateData = { invalidStr: value } 
                                    validEmail(validateData,callback,'RFC違反のメールアドレスの為、登録できません。')
                                }
                            } else {
                                callback(new Error('メールアドレスを入力してください。'));
                            }
                        }
                    }
                ],
                appUsrNm: [
                    { required: true, message: t('担当者名を入力してください。') },
                    full(t('担当者名は全角で入力してください。')),
                    {
                        validator: (rule, value, callback) => {
                            const validateData = { invalidStr: value, isUnicoe: true }
                            checkInvalid(validateData, service, callback, '担当者名')
                        }
                    },
                ],
                comNm: [
                    { required: true, message: t('貴社名を入力してください。') },
                    full(t('貴社名は全角で入力してください。')),
                    {
                        validator: (rule, value, callback) => {
                            const validateData = { invalidStr: value, isUnicoe: true }
                            checkInvalid(validateData, service, callback, '貴社名')
                        }
                    },
                ],
                appDepNmPost: [
                    { required: true, message: t('部署名を入力してください。') },
                    full(t('部署名は全角で入力してください。')),
                    {
                        validator: (rule, value, callback) => {
                            const validateData = { invalidStr: value, isUnicoe: true }
                            checkInvalid(validateData, service, callback, '部署名')
                        }
                    },
                ],
                cause: [
                    {
                        max: 1000,
                        message: t('お問い合わせ内容 は 1000 文字以内で入力してください。')
                    },
                ],
                appUsrTel: [
                    phone(t('電話番号は電話番号の形式(例 11-1111-1111)で入力してください。'))
                ],
            }
        )

        function confirm_assent_open() {
            if (model.value.policyCheckArea == undefined || model.value.policyCheckArea.length <= 0) {
                alert("同意をチェックしてください。");
                return;
            }
            formRef.value.validate(model.value).then((res) => {
                if (res) {
                    service.opecom.opecom.create(model.value).then(res => {
                        router.push({ name: 'ope_com_show', params: { model: JSON.stringify(res) } })
                    }).catch(error => {
                        console.log(error)
                    })
                }

            });
        }
		
		function toTop(){
            document.body.scrollTop ? document.body.scrollTop = 0 : document.documentElement.scrollTop = 0
        }

        return {
            rules, formRef,
            model,
            confirm_assent_open,
            getSidentifyCode,
            refreshCode,
            isRefreshCode,
            identifyCode,
            toTop
        }
    }
}
