<template>
<div class="rsk-justify-center rsk-flex">
    <div class="rsk-flex" style="overflow: auto;">
        <div class="rsk-flex  rsk-container" style="display: flex;align-items: center;flex-direction: column;">
            <div class="rsk-pt-m rsk-pb-m">
                <div class="rsk-font-bold rsk-font-xl rsk-mb-s" style="color: #004F9A;font-size: 24px;"><img src="../../assets/pagetitle.gif" alt="."/>お問い合わせフォーム</div>
                <div class="rsk-mb-s">
                    操作に関するお問い合わせは以下のフォームにご入力ください。
                </div>
                <div class="rsk-mb-s">
                ※各社運用に関することや、個人情報に関することはお答えできかねます。<br>
                    　また、ご契約内容により、お返事を差し上げられないことがございます。ご了承ください。<br>
                ※エラーに関するお問い合わせの場合、エラーが発生した日時、画面や目次の場所、操作手順などの詳細も記載してください。<br>
                ※ご利用端末のOS・ブラウザ等の情報が自動でカスタマーセンターに送られます。ご了承ください。<br>
                ※営業時間について（土日祝日・年末年始休業です）<br>
                ・フリーコール：平日10:00～16:00<br>
                ・チャット：平日9:00～18:00<br>
                ・お問い合せフォーム：平日9:00～18:00（順次メールにて回答差し上げます）
                </div>
                <div class="rsk-panel rsk-mb-xl">
                    <rsk-form labelWidth="220px" ref="formRef" :rules="rules" :model="formData">
                        <rsk-form-item label="お問い合わせの種類" isList hintText="必須" prop="inquireType">
                            <div class="rsk-flex">
                                <div class="rsk-checkbox-container rsk-mb-xxs">
                                    <input type="checkbox" v-model="formData.inquireType1" id="item1">
                                    <label for="item1" class="rsk-checkbox__label">
                                        ログインについて
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-flex">
                                <div class="rsk-checkbox-container rsk-mb-xxs">
                                    <input type="checkbox" v-model="formData.inquireType2" id="item2">
                                    <label for="item2" class="rsk-checkbox__label">
                                        動作環境について
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-flex">
                                <div class="rsk-checkbox-container rsk-mb-xxs">
                                    <input type="checkbox" v-model="formData.inquireType3" id="item3">
                                    <label for="item3" class="rsk-checkbox__label">
                                        eラーニングについて
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-flex">
                                <div class="rsk-checkbox-container rsk-mb-xxs">
                                    <input type="checkbox" v-model="formData.inquireType4" id="item4">
                                    <label for="item4" class="rsk-checkbox__label">
                                        Webセミナーについて
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-flex">
                                <div class="rsk-checkbox-container rsk-mb-xxs">
                                    <input type="checkbox" v-model="formData.inquireType5" id="item5">
                                    <label for="item5" class="rsk-checkbox__label">
                                        操作方法について（画面の見方）
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-flex">
                                <div class="rsk-checkbox-container rsk-mb-xxs">
                                    <input type="checkbox" v-model="formData.inquireType6" id="item6">
                                    <label for="item6" class="rsk-checkbox__label">
                                        要望
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-flex">
                                <div class="rsk-checkbox-container rsk-mb-xxs">
                                    <input type="checkbox" v-model="formData.inquireType7" id="item7">
                                    <label for="item7" class="rsk-checkbox__label">
                                        その他
                                    </label>
                                </div>
                            </div>
                        </rsk-form-item>
                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                        <rsk-form-item isList hintText="必須" label="会社名" prop="comNm">
                            <rsk-input v-model="formData.comNm" max="60" />
                        </rsk-form-item>
                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                        <rsk-form-item isList hintText="必須" label="会員ID" prop="comId">
                            <rsk-input v-model="formData.comId" max="15" />
                        </rsk-form-item>
                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                        <rsk-form-item isList hintText="必須" label="お名前" prop="usrNm">
                            <rsk-input v-model="formData.usrNm" max="30" />
                        </rsk-form-item>
                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                        <rsk-form-item isList label="電話番号" prop="tel">
                            <rsk-input v-model="formData.tel" max="32" />
                        </rsk-form-item>
                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                        <rsk-form-item isList hintText="必須" label="メールアドレス" annotation="※あらかじめ「cybaxuniv.com」からのメールを受信できるようにしてください。" prop="mlAdr">
                            <rsk-input v-model="formData.mlAdr" max="128" />
                        </rsk-form-item>
                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s"></div>
                        <rsk-form-item isList label="添付ファイル" prop="upload">
                            <a href="#" @click="downloadFile(formData.fileName, formData.fileUrl)" v-if="formData.fileName != '' || formData.fileName != null" style="text-decoration:underline; display: block;">
                                {{formData.fileName}}
                            </a>
                            <input type="file" @change="getFiles($event)" />
                            <div class="rsk-flex rsk-pt-s">
                                <div class="rsk-checkbox-container rsk-mb-xxs">
                                    <input type="checkbox" v-model="formData.fileCheck" id="item8">
                                    <label for="item8" class="rsk-checkbox__label">
                                        チェックオンの場合、上記添付ファイルを削除します。
                                    </label>
                                </div>
                            </div>
                            <div class="rsk-text-gray060 rsk-font-xs">※エラー表示されている画面キャプチャを添付してください。 (最大サイズ: 10 MB)</div>
                            <div class="rsk-text-gray060 rsk-font-xs">※添付できるファイルはjpg,png,xls,xlsx,doc,docx形式のファイルのみになります。</div>
                        </rsk-form-item>
                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                        <rsk-form-item isList label="対象の研修名" annotation="※300 文字以内で入力してください。" prop="trainName">
                            <rsk-input v-model="formData.trainName" max="300" />
                        </rsk-form-item>
                        <div class="rsk-divider rsk-divider--horizontal rsk-mt-s rsk-mb-s" ></div>
                        <rsk-form-item isList hintText="必須" label="問い合わせ内容" isExpand prop="content">
                            <textarea class="rsk-input rsk-input--expand rsk-textarea__input new-textarea" rows="15" v-model="formData.content" maxlength="1000"></textarea>
                            <div class="rsk-text-gray060 rsk-font-xs">※1000 文字以内で入力してください。</div>
                        </rsk-form-item>

                        <rsk-form-submit>
                            <rsk-button isExpand type="primary" @click="confirm">確認する</rsk-button>
                            <rsk-button isExpand type="primary" @click="close">閉じる</rsk-button>
                        </rsk-form-submit>
                    </rsk-form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { ref, inject, reactive, onMounted } from 'vue';
import { checkInvalid, validEmail } from '@/utils/validate'
import { useRouter, useRoute } from 'vue-router'
import { getBrowserMess } from '@/utils/common'
import { halfNumEg2,full,phone } from '@/utils/validateUtils'
export default {
    setup() {
        const service = inject("service");
        const router = useRouter()
        const route = useRoute()
        const browserMess = getBrowserMess()

        const { rules, formRef, formData } = initDataEffect(service, browserMess)
        const { confirm, getFiles, index, downloadFile, close } = handleEffect(formRef, formData, service, router)
        onMounted(()=>{
            if(null != route.params.model || undefined != route.params.model) {
                let params = JSON.parse(route.params.model)
                formData.comId = params.comId
                formData.comNm = params.comNm
                formData.usrNm = params.usrNm
                formData.tel = params.tel
                formData.mlAdr = params.mlAdr
                formData.trainName = params.trainName
                formData.content = params.content
                formData.fileName = params.fileName
                for (let i=0; i<params.inquireType.length; i++) {
                    if(params.inquireType[i] == "ログインについて") {
                        formData.inquireType1 = true;
                    }
                    if(params.inquireType[i] == "動作環境について") {
                        formData.inquireType2 = true;
                    }
                    if(params.inquireType[i] == "eラーニングについて") {
                        formData.inquireType3 = true;
                    }
                    if(params.inquireType[i] == "Webセミナーについて") {
                        formData.inquireType4 = true;
                    }
                    if(params.inquireType[i] == "操作方法について（画面の見方）") {
                        formData.inquireType5 = true;
                    }
                    if(params.inquireType[i] == "要望") {
                        formData.inquireType7 = true;
                    }
                    if(params.inquireType[i] == "その他") {
                        formData.inquireType7 = true;
                    }
                }
            } else {
                index()
            }
            
        })

        return {
            rules,
            formRef,
            formData,
            confirm,
            getFiles,
            downloadFile,
            close
        }
    },
}

const initDataEffect = (service, browserMess) => {
    const checkInquireType = (callback) => {
        formData.inquireType.length = 0
        let flag = false
        if(formData.inquireType1 !== false) {
            formData.inquireType.push("ログインについて")
            flag = true
        }
        if(formData.inquireType2 !== false) {
            formData.inquireType.push("動作環境について")
            flag = true
        }
        if(formData.inquireType3 !== false) {
            formData.inquireType.push("eラーニングについて")
            flag = true
        }
        if(formData.inquireType4 !== false) {
            formData.inquireType.push("Webセミナーについて")
            flag = true
        }
        if(formData.inquireType5 !== false) {
            formData.inquireType.push("操作方法について（画面の見方）")
            flag = true
        }
        if(formData.inquireType6 !== false) {
            formData.inquireType.push("要望")
            flag = true
        }
        if(formData.inquireType7 !== false) {
            formData.inquireType.push("その他")
            flag = true
        }

        if(flag) {
            callback()
        } else {
            callback(new Error('お問い合わせの種類チェックボックスを選択してください。'));
        }
    }

    function getFileType(fileName) {
      var startIndex = fileName.lastIndexOf(".");
      if(startIndex != -1)
        return fileName.substring(startIndex+1, fileName.length).toLowerCase();
      else return "";
    }

    const checkFileType = (callback) => {
        if(formData.fileCheck == true){
            callback() 
            return
        } 

        if(formData.upload != null) {
            let fileMaxSize = 1024 * 10;
            for (let i = 0; i < formData.upload.length; i++) {
                let type = getFileType(formData.upload[i].name)
                if(!(type == "jpg" || type == "png" || type == "xls" || type =="xlsx" || type =="doc" || type =="docx")) {
                    callback(new Error('添付ファイルが許可されていないファイル形式です。'))
                    break
                }

                if((formData.upload[i].size / 1024) > fileMaxSize) {
                    callback(new Error('添付ファイルのサイズは10MB以内にしてください。'))
                    break
                }
            }
            callback()
        } else {
            callback()
        }
    }

     const rules =  ref(
        {
            comId: [
                halfNumEg2('会員IDは半角英数字で入力してください'),
                { min: 15, max: 15, message: '会員IDは15文字で入力してください', trigger: 'blur' },
                { required: true, message: '会員IDを入力してください。' },
            ],
            comNm: [
                { required: true, message: '会社名を入力してください。' },
                full('会社名は全角で入力してください'),
                { 
                    validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value, isUnicoe: true }
                        checkInvalid(validateData, service, callback, '会社名')
                    }
                },
                {
                    max: 60,
                    message: '会社名は60文字以内で入力してください。'
                },
            ],
            usrNm: [
                { required: true, message: 'お名前を入力してください。' },
                full('お名前は全角で入力してください'),
                { 
                    validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value, isUnicoe: true }
                        checkInvalid(validateData, service, callback, 'お名前')
                    }
                },
                {
                    max: 30,
                    message: 'お名前は30文字以内で入力してください。'
                },
            ],
            tel: [
                {
                    max: 32,
                    message: '電話番号は32文字以内で入力してください。'
                },
                phone('電話番号は電話番号の形式(例 11-1111-1111)で入力してください。'),

            ],
            mlAdr: [
                {
                    validator: (rule, value, callback) => {
                        if (value) {
                            if(value.length == 0) {
                                callback(new Error('メールアドレスを入力してください。'));
                            } else if (value.length > 128) {
                                callback(new Error('メールアドレスは128文字以内で入力してください。'));
                            } else if(!(/^(.+)@(.+)$/.test(value))) { 
                                callback(new Error(' メールアドレスは Email の形式(例 XXXXXXX@XX.com)で入力してください。'));
                            } else {
                                const validateData = { invalidStr: value } 
                                validEmail(validateData,callback,'RFC違反のメールアドレスの為、登録できません。')
                            }
                        } else {
                            callback(new Error('メールアドレスを入力してください。'));
                        }
                    }
                }
            ],
            trainName: [
                {
                    validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value, isUnicoe:　true }
                        checkInvalid(validateData, service, callback, '対象の研修名')
                    }
                },
                {
                    max: 300,
                    message: '対象の研修名は300文字以内で入力してください。'
                },

            ],
            inquireType: [
                {
                    validator: (rule, value, callback) => {
                        checkInquireType(callback)
                    }
                },
            ],
            content: [
                { required: true, message: '問い合わせ内容を入力してください。' },
                { 
                    validator: (rule, value, callback) => {
                        const validateData = { invalidStr: value, isUnicoe:true }
                        checkInvalid(validateData, service, callback, '問い合わせ内容')
                    }
                },
                {
                    max: 1000,
                    message: '問い合わせ内容は1000文字以内で入力してください。'
                },
            ],
            upload: [
                { 
                    validator: (rule, value, callback) => {
                        checkFileType(callback)
                    } 
                },
            ],
        }
    )
    const formRef = ref()

    const formData = reactive({
        comId: '',
        comNm: '',
        usrNm: '',
        tel: '',
        mlAdr: '',
        trainName: '',
        inquireType: [],
        inquireType1: false,
        inquireType2: false,
        inquireType3: false,
        inquireType4: false,
        inquireType5: false,
        inquireType6: false,
        inquireType7: false,
        content: '',
        fileCheck: false,
        upload: '',
        fileUrl: '',
        fileName: '',
        uploadFileName: [],
        enOs: browserMess.enOs,
        enBrowser: browserMess.enBrowser,
        enJs: browserMess.enJs,
        enCookie: browserMess.enCookie,
        enFlash: browserMess.enFlash,
        enActiveX: browserMess.enActiveX,
        // OSの種類とバージョン
    })

    return {
        rules,
        formRef,
        formData
    }
}

const handleEffect = (formRef, formData, service, router) => {
    const confirm = () => {
        formRef.value.validate(formData).then((resp) => {
            if(resp) {
                let reqDto = {
                    model: {
                        comId: formData.comId,
                        comNm: formData.comNm,
                        usrNm: formData.usrNm,
                        tel: formData.tel,
                        mlAdr: formData.mlAdr,
                        trainName: formData.trainName,
                        inquireType: formData.inquireType,
                        content: formData.content,
                        fileCheck: formData.fileCheck,
                        fileUrl: formData.fileUrl,
                        fileName: formData.fileName,
                        uploadFileName: formData.uploadFileName,                                                                                        
                        enOs: formData.enOs,
                        enBrowser: formData.enBrowser,
                        enJs: formData.enJs,
                        enCookie: formData.enCookie,
                        enFlash: formData.enFlash,
                        enActiveX: formData.enActiveX,
                    }
                }

                let paramsData = {
                    comId: formData.comId,
                    comNm: formData.comNm,
                    usrNm: formData.usrNm,
                    tel: formData.tel,
                    mlAdr: formData.mlAdr,
                    trainName: formData.trainName,
                    inquireType: formData.inquireType,
                    content: formData.content,
                    fileName: formData.fileName
                }

                let upload = formData.upload
                service.usrinquire.create(reqDto, upload).then(res => {
                    paramsData.fileName = res
                    router.push({name:'usrinquireshow', params: { model: JSON.stringify(paramsData) }})
                }).catch(error => {
                    console.log(error)
                })
            }
        })
    }

    const getFiles = (e) => {
        formData.upload = e.target.files
    }

    const index = () => {
        service.usrinquire.index().then(res => {
            const model = res.model
            formData.comId = model.comId
            formData.comNm = model.comNm
            formData.usrNm = model.usrNm
            formData.tel = model.tel
            formData.mlAdr = model.mlAdr
        })
    }

    const downloadFile = (filename, fileurl)  => {
        const downloadData = {filename:'', fileurl:'', userAgent:'', infoPage: ''}
		downloadData.filename = filename
		downloadData.fileurl = fileurl
        downloadData.infoPage = '1'
		service.common.downloadFile(downloadData)
	}

    function close() {
        window.close()
    }

    return {
       confirm,
       getFiles,
       index,
       downloadFile,
       close
    }
}

</script>

<style scoped>
.rsk-container {
    background: #f5f7fa;
}
.new-textarea {
    height: initial !important;
}
</style>