export const dateDtmCheck = (startIdValue,endIdValue,value1,value2) => {
    let res = {
        disErrFlagOpen: false,
        disErrFlagEnd: false,
        disErrFlag: false
    }
    let openStartDtm;
    let openEndDtm;
    if (getValidateMsg(startIdValue) == '' && getValidateMsg(endIdValue) == '' && value1 == '' && value2 == '') {
        openStartDtm = document.getElementById(startIdValue).firstChild;
        openStartDtm.style.borderColor = "";
        openEndDtm = document.getElementById(endIdValue).firstChild;
        openEndDtm.style.borderColor = "";
        res.disErrFlag = false;
        res.disErrFlagOpen = false;
        res.disErrFlagEnd = false;
        return res;
    } else if (getValidateMsg(startIdValue) == '' && getValidateMsg(endIdValue) != ''){
        openStartDtm = document.getElementById(startIdValue).firstChild;
        openStartDtm.style.borderColor = "";
        openEndDtm = document.getElementById(endIdValue).firstChild
        openEndDtm.style.borderColor = "red"
        res.disErrFlagOpen = false;
        res.disErrFlagEnd = true;
        res.disErrFlag = false;
        return res;
    } else if (getValidateMsg(startIdValue) != '' && getValidateMsg(endIdValue) == '') {
        openStartDtm = document.getElementById(startIdValue).firstChild;
        openStartDtm.style.borderColor = "red";
        openEndDtm = document.getElementById(endIdValue).firstChild
        openEndDtm.style.borderColor = ""
        res.disErrFlagOpen = true;
        res.disErrFlagEnd = false;
        res.disErrFlag = false;
        return res;
    } else if (getValidateMsg(startIdValue) != '' && getValidateMsg(endIdValue) != '') {
        openStartDtm = document.getElementById(startIdValue).firstChild;
        openStartDtm.style.borderColor = "red";
        openEndDtm = document.getElementById(endIdValue).firstChild
        openEndDtm.style.borderColor = "red"
        res.disErrFlagOpen = true;
        res.disErrFlagEnd = true;
        res.disErrFlag = false;
        return res;
    } else if (Date.parse(value1) > Date.parse(value2)) {
        openStartDtm = document.getElementById(startIdValue).firstChild;
        openStartDtm.style.borderColor = "red";
        openEndDtm = document.getElementById(endIdValue).firstChild
        openEndDtm.style.borderColor = "red"
        res.disErrFlagOpen = false;
        res.disErrFlagEnd = false;
        res.disErrFlag = true;
        return res;
    } else {
        openStartDtm = document.getElementById(startIdValue).firstChild;
        openStartDtm.style.borderColor = "";
        openEndDtm = document.getElementById(endIdValue).firstChild;
        openEndDtm.style.borderColor = "";
        res.disErrFlag = false;
        res.disErrFlagOpen = false;
        res.disErrFlagEnd = false;
        return res;
    }      
    
}
function getValidateMsg(value) {
      let validateMsg = document.getElementById(value).firstChild.validationMessage;
      return validateMsg
  }