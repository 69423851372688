import { Service, BaseService } from "@/core/auto";

@Service('/api/user/')
export default class assentmng extends BaseService{
    loginInit(data) {
		return this.request({
            showError: false,
            data: data,
			url: "/updateUsrAssentEnt",
			method: "POST"
		})
	}
}